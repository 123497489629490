<p-card header="Info">
  <div class="grid">
    <app-form-text-input id="mandant" label="USERS.MANDANT" [form]="form"></app-form-text-input>

    <app-form-text-input
      id="creatorName"
      label="VARIABLES.CREATOR"
      [form]="form"
      [twoColumns]="true"></app-form-text-input>

    <app-form-text-input
      id="creatorEmail"
      label="VARIABLES.CREATOR_EMAIL"
      [form]="form"
      [twoColumns]="true"></app-form-text-input>

    @if (variableApproval.variable?.isBetriebsVariable) {
      <app-form-text-input
        id="variable.parentTacsId"
        label="VARIABLES.PARENT_TACS_ID"
        [form]="form"
        [twoColumns]="true"></app-form-text-input>
    }

    @if (variableApproval.variable?.isBetriebsVariable) {
      <app-form-text-input
        id="variable.parentName"
        label="VARIABLES.PARENT_NAME"
        [form]="form"
        [twoColumns]="true"></app-form-text-input>
    }

    <app-form-text-input
      id="variable.tacsId"
      label="VARIABLES.TACS_ID"
      [form]="form"
      [twoColumns]="true"></app-form-text-input>

    <app-form-text-input
      id="variable.name"
      [label]="variableApproval.variable?.isBetriebsVariable ? 'GLOBAL.NAME' : 'VARIABLES.NAME'"
      [form]="form"
      [twoColumns]="true"></app-form-text-input>

    @if (!variableApproval.variable?.isBetriebsVariable) {
      @for (language of languages; track language) {
        <app-form-text-input
          [form]="form"
          [id]="'variable.customName.' + language"
          [label]="'GLOBAL.NAME_' + language"
          [twoColumns]="true"></app-form-text-input>
      }
    }
  </div>
</p-card>

@for (message of variableApproval.messages; track message) {
  <p-card [header]="message.creatorName" [styleClass]="message.isRodix ? 'rodix' : 'customer'">
    <textarea pInputTextarea rows="3" [autoResize]="true" [disabled]="true" [value]="message.message"></textarea>
    <p style="color: grey; text-align: right">{{ message.created | date: 'dd.MM.yyyy HH:mm' }}</p>
  </p-card>
}

<p-card [header]="name" [styleClass]="isRodixAdmin() ? 'rodix' : 'customer'">
  <textarea pInputTextarea rows="3" [autoResize]="true" [autofocus]="true" [formControl]="commentControl"></textarea>
  <ng-template pTemplate="footer">
    <p-button
      icon="fal fa-comment-plus"
      (onClick)="comment()"
      [disabled]="commentControl.invalid"
      [label]="'ACTIONS.COMMENT' | translate"
      [style]="{ 'margin-right': '0.5rem' }"></p-button>
    @if (isRodixAdmin()) {
      <p-button
        icon="fal fa-check"
        styleClass="p-button-success"
        (onClick)="accept()"
        [disabled]="commentControl.invalid"
        [label]="'ACTIONS.ACCEPT' | translate"
        [style]="{ 'margin-right': '0.5rem' }"></p-button>
    }
    @if (isRodixAdmin()) {
      <p-button
        icon="fal fa-times"
        styleClass="p-button-danger"
        (onClick)="reject()"
        [disabled]="commentControl.invalid"
        [label]="'ACTIONS.REJECT' | translate"></p-button>
    }
  </ng-template>
</p-card>
