<app-form-element [control]="formControl" [id]="id" [label]="label" [required]="required" [twoColumns]="twoColumns">
  <div [class.invalid]="!formControl.valid">
    @for (item of values(); track item; let i = $index) {
      <div class="flex flex-wrap md:flex-row w-full mb-4 gap-2 align-items-center">
        <div class="flex flex-column gap-1">
          <label for="varId">{{ 'VARIABLES.VAR_HEALTHCARE_DOCUMENT_ID' | translate }}</label>
          <p-inputNumber
            inputId="varId"
            [format]="false"
            [ngModel]="item.documentId"
            (ngModelChange)="update(i, $event)"
            [disabled]="formControl.disabled"></p-inputNumber>
        </div>

        <div class="flex flex-column gap-1 flex-1">
          <label for="varDescription">{{ 'VARIABLES.VAR_HEALTHCARE_TITLE' | translate }}</label>
          <div class="flex gap-1">
            <input id="varDescription" pInputText [disabled]="true" [value]="item.title ?? ''" class="flex-grow-1" />

            <p-button
              [text]="true"
              [disabled]="!item.url"
              (onClick)="openLink(item.url)"
              [icon]="'fal fa-arrow-up-right-from-square'"
              class="flex"></p-button>

            <p-button
              icon="fal fa-magnifying-glass"
              iconPos="left"
              type="button"
              [text]="true"
              (onClick)="openVarDialog(i)"></p-button>

            @if (!formControl.disabled) {
              <p-button
                class="first-button"
                icon="fal fa-plus"
                iconPos="left"
                type="button"
                (onClick)="add(i)"></p-button>
            }
            @if (!formControl.disabled) {
              <p-button icon="fal fa-minus" iconPos="left" type="button" (onClick)="remove(i)"></p-button>
            }
          </div>
        </div>
      </div>
      @if (values().length > 1 && i !== values().length - 1) {
        <hr />
      }
    }
    @if (!values().length && !formControl.disabled) {
      <p-button class="first-button" icon="fal fa-plus" iconPos="left" type="button" (onClick)="add()"></p-button>
    }
  </div>
</app-form-element>

<p-dialog
  (visibleChange)="$event ? null : varDialogIndex.set(undefined)"
  [closable]="true"
  [closeOnEscape]="true"
  [modal]="true"
  [style]="{ width: 'calc(100vw - 2rem)', height: 'calc(100vh - 2rem)' }"
  [visible]="varDialogVisible()">
  <ng-template pTemplate="content">
    <div style="height: 100%">
      <p-table
        #table
        (onLazyLoad)="lazyLoad($event)"
        [filterDelay]="300"
        [globalFilterFields]="['title']"
        [lazyLoadOnInit]="false"
        [lazy]="true"
        [loading]="loading()"
        [scrollHeight]="'flex'"
        [scrollable]="true"
        [value]="documents()">
        <ng-template pTemplate="caption">
          <div class="flex">
            <p-inputGroup>
              <p-inputGroupAddon>
                <i class="fal fa-magnifying-glass"></i>
              </p-inputGroupAddon>
              <input (input)="table.filterGlobal($any($event.target).value, 'contains')" pInputText type="text" />
            </p-inputGroup>
          </div>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th translate>VARIABLES.VAR_HEALTHCARE_DOCUMENT_ID</th>
            <th translate>VARIABLES.VAR_HEALTHCARE_TITLE</th>
            <th translate>VARIABLES.VAR_HEALTHCARE_DOCUMENT_URL</th>
            <th translate>VARIABLES.VAR_HEALTHCARE_DOCUMENT_LAST_UPDATED</th>
            <th translate>VARIABLES.VAR_HEALTHCARE_DOCUMENT_CHANGE_TYPE</th>
          </tr>
        </ng-template>
        <ng-template let-document pTemplate="body">
          <tr>
            <td (click)="selectVarDocument(document)" class="cursor-pointer underline text-primary">
              {{ document.id }}
            </td>
            <td>{{ document.title }}</td>
            <td><a [href]="document.url" target="_blank">VAR Portal</a></td>
            <td>{{ document.lastUpdated | date: 'dd.MM.yyyy' }}</td>
            <td>{{ document.changeType }}</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </ng-template>
</p-dialog>
