import { Injectable } from '@angular/core';
import { MandantEdit } from '@entity/mandant';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Organisation } from '@entity/organisation';
import { Holiday } from '@entity/holiday';
import { Workday } from '@entity/workday';
import { PersonnelCost, PersonnelCostEntry } from '@entity/personnel-cost';
import { TimeCredit } from '@entity/time-credit';
import { TargetJobPlan, TargetTime } from '@entity/target-job-plan';

@Injectable({
  providedIn: 'root',
})
export class MandantService {
  private readonly baseUrl = `api/1.0/mandanten`;

  constructor(private httpClient: HttpClient) {}

  deleteMandant(mandantId: number, dataOnly = true): Observable<unknown> {
    const params = new HttpParams().append('dataOnly', dataOnly + '');
    return this.httpClient.delete(`${this.baseUrl}/${mandantId}`, { params });
  }

  getMandant(mandantId: number): Observable<MandantEdit> {
    return this.httpClient.get<MandantEdit>(`${this.baseUrl}/${mandantId}`);
  }

  updateMandant(mandantId: number, mandant: MandantEdit): Observable<MandantEdit> {
    return this.httpClient.put<MandantEdit>(`${this.baseUrl}/${mandantId}`, mandant);
  }

  createMandant(mandant: MandantEdit): Observable<MandantEdit> {
    return this.httpClient.post<MandantEdit>(`${this.baseUrl}`, mandant);
  }

  getOrganisationalUnits(mandantId: number): Observable<Organisation[]> {
    return this.httpClient.get<Organisation[]>(`${this.baseUrl}/${mandantId}/organisational-units`);
  }

  getHolidays(mandantId: number, year: number): Observable<Holiday[]> {
    const params = new HttpParams().append('year', year + '');
    return this.httpClient.get<Holiday[]>(`${this.baseUrl}/${mandantId}/holidays`, { params });
  }

  updateHoliday(mandantId: number, holiday: Holiday): Observable<Holiday> {
    return this.httpClient.put<Holiday>(`${this.baseUrl}/${mandantId}/holidays/${holiday.id}`, holiday);
  }

  addHoliday(mandantId: number, holiday: Holiday): Observable<Holiday> {
    return this.httpClient.post<Holiday>(`${this.baseUrl}/${mandantId}/holidays`, holiday);
  }

  deleteHoliday(mandantId: number, holiday: Holiday): Observable<unknown> {
    return this.httpClient.delete(`${this.baseUrl}/${mandantId}/holidays/${holiday.id}`);
  }

  addPublicHolidays(mandantId: number, year: number): Observable<unknown> {
    const params = new HttpParams().append('year', year + '');
    return this.httpClient.post<unknown>(`${this.baseUrl}/${mandantId}/add-public-holidays`, null, { params });
  }

  addLastYearsHolidays(mandantId: number, year: number): Observable<unknown> {
    const params = new HttpParams().append('year', year + '');
    return this.httpClient.post<unknown>(`${this.baseUrl}/${mandantId}/add-last-years-holidays`, null, { params });
  }

  getWorkdays(mandantId: number, focusDate: Date): Observable<Workday[]> {
    const params = new HttpParams().append('focusDate', focusDate.toJSON());
    return this.httpClient.get<Workday[]>(`${this.baseUrl}/${mandantId}/workdays`, { params });
  }

  getWorkdaysForOrganisation(mandantId: number, organisationId: number, year: number): Observable<Date[]> {
    const params = new HttpParams().append('year', year + '');
    return this.httpClient.get<Date[]>(`${this.baseUrl}/${mandantId}/workdays/${organisationId}`, { params });
  }

  updateWorkdays(mandantId: number, focusDate: Date, workdays: Workday[]): Observable<Workday[]> {
    const params = new HttpParams().append('focusDate', focusDate.toJSON());
    return this.httpClient.put<Workday[]>(`${this.baseUrl}/${mandantId}/workdays`, workdays, { params });
  }

  updateWorkdaysForOrganisation(
    mandantId: number,
    organisationId: number,
    year: number,
    workdays: Date[]
  ): Observable<Date[]> {
    const params = new HttpParams().append('year', year + '');
    return this.httpClient.put<Date[]>(`${this.baseUrl}/${mandantId}/workdays/${organisationId}`, workdays, { params });
  }

  getPersonnelCosts(mandantId: number, focusDate: Date): Observable<PersonnelCost[]> {
    const params = new HttpParams().append('focusDate', focusDate.toJSON());
    return this.httpClient.get<PersonnelCost[]>(`${this.baseUrl}/${mandantId}/personnel-costs`, { params });
  }

  updatePersonnelCostEntries(
    mandantId: number,
    personnelCostId: number,
    personnelCostEntries: PersonnelCostEntry[]
  ): Observable<PersonnelCost[]> {
    return this.httpClient.put<PersonnelCost[]>(
      `${this.baseUrl}/${mandantId}/personnel-costs/${personnelCostId}`,
      personnelCostEntries
    );
  }

  getTimeCredits(mandantId: number, focusDate: Date): Observable<TimeCredit[]> {
    const params = new HttpParams().append('focusDate', focusDate.toJSON());
    return this.httpClient.get<TimeCredit[]>(`${this.baseUrl}/${mandantId}/time-credits`, { params });
  }

  updateTimeCredits(mandantId: number, focusDate: Date, workdays: TimeCredit[]): Observable<TimeCredit[]> {
    const params = new HttpParams().append('focusDate', focusDate.toJSON());
    return this.httpClient.put<TimeCredit[]>(`${this.baseUrl}/${mandantId}/time-credits`, workdays, { params });
  }

  getTargetJobPlans(mandantId: number, focusDate: Date): Observable<TargetJobPlan[]> {
    const params = new HttpParams().append('focusDate', focusDate.toJSON());
    return this.httpClient.get<TargetJobPlan[]>(`${this.baseUrl}/${mandantId}/target-job-plans`, { params });
  }

  updateTargetTimes(
    mandantId: number,
    organisationId: number,
    personnelCategoryId: number,
    targetTimes: TargetTime[]
  ): Observable<TargetTime[]> {
    const params = new HttpParams()
      .append('organisationId', String(organisationId))
      .append('personnelCategoryId', String(personnelCategoryId));
    return this.httpClient.post<TargetTime[]>(
      `${this.baseUrl}/${mandantId}/target-job-plans/target-times`,
      targetTimes,
      { params }
    );
  }
}
