import { TableService } from '@shared/table/table-service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MainDataService } from '@service/main-data.service';
import { map } from 'rxjs/operators';
import { PublicHoliday } from '@entity/public-holiday';

@Injectable()
export class PublicHolidayTableService extends TableService<PublicHoliday> {
  static cantons = [
    'ZH',
    'BE',
    'LU',
    'UR',
    'SZ',
    'OW',
    'NW',
    'GL',
    'ZG',
    'FR',
    'SO',
    'BS',
    'BL',
    'SH',
    'AI',
    'AR',
    'SG',
    'GR',
    'AG',
    'TG',
    'TI',
    'VD',
    'VS',
    'NE',
    'GE',
    'JU',
    'LI',
  ];

  constructor(private mainDataService: MainDataService) {
    super();
  }

  create(holiday: PublicHoliday): Observable<PublicHoliday> {
    this.updatePublicHoliday(holiday);
    return this.mainDataService.addPublicHoliday(holiday);
  }

  createEntry(): PublicHoliday {
    return {} as PublicHoliday;
  }

  getData(focusYear?: number): Observable<{ entities: PublicHoliday[] }> {
    return this.mainDataService
      .getPublicHolidays(focusYear ?? new Date().getFullYear())
      .pipe(
        map((holidays) =>
          holidays.map((holiday) => {
            const activatedCantons: { [language: string]: boolean } = {};
            PublicHolidayTableService.cantons.forEach((c) => {
              activatedCantons[c] = holiday.cantons?.includes(c) ?? false;
            });
            holiday.activatedCantons = activatedCantons;
            return holiday;
          })
        )
      )
      .pipe(map((entities) => ({ entities })));
  }

  remove(holiday: PublicHoliday): Observable<unknown> {
    return this.mainDataService.deletePublicHoliday(holiday);
  }

  update(holiday: PublicHoliday): Observable<PublicHoliday> {
    this.updatePublicHoliday(holiday);
    return this.mainDataService.updatePublicHoliday(holiday);
  }

  private updatePublicHoliday(holiday: PublicHoliday): void {
    holiday.cantons = PublicHolidayTableService.cantons.filter((c) =>
      holiday.activatedCantons ? holiday.activatedCantons[c] : false
    );
  }
}
