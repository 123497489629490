<p-table
  styleClass="p-datatable-sm p-datatable-gridlines p-datatable-striped"
  [columns]="columns"
  [paginator]="true"
  [rows]="10"
  [style]="{ margin: '1rem' }"
  [value]="entries"
  [scrollable]="true"
  #table>
  <ng-template pTemplate="header" let-columns>
    <tr>
      @for (col of columns; track col) {
        <th [pSortableColumn]="col.field" [width]="'200px'">
          {{ col.header | translate }}
          <p-sortIcon [field]="col.field"></p-sortIcon>
        </th>
      }
    </tr>
    <tr>
      @for (col of columns; track col) {
        <th [width]="'200px'">
          <input pInputText type="text" (input)="table.filter($any($event.target).value, col.field, 'contains')" />
        </th>
      }
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-rowData let-columns="columns">
    <tr>
      @for (col of columns; track col) {
        <td [width]="'200px'">
          @switch (col.field.toLowerCase()) {
            @case ('fallid') {
              <a (click)="showCustomer(rowData, rowData[col.field])">{{ rowData[col.field] }}</a>
            }
            @case ('personalid') {
              <a (click)="showEmployee(rowData, rowData[col.field])">{{ rowData[col.field] }}</a>
            }
            @default {
              {{ rowData[col.field] }}
            }
            @case ('empfaengermitarbeiter') {
              @if (isArray(rowData[col.field])) {
                <ul>
                  @for (entry of rowData[col.field]; track entry) {
                    <li>
                      <a (click)="showEmployee(entry, entry['PersonalId'])">{{ entry['PersonalId'] }}</a>
                    </li>
                  }
                </ul>
              } @else {
                @if (rowData[col.field] && rowData[col.field]['PersonalId']) {
                  <a (click)="showEmployee(rowData[col.field], rowData[col.field]['PersonalId'])">
                    {{ rowData[col.field]['PersonalId'] }}
                  </a>
                }
              }
            }
          }
        </td>
      }
    </tr>
  </ng-template>
</p-table>

<p-dialog [(visible)]="showDetail" [header]="'JOBS.DETAIL' | translate" [modal]="true" [style]="{ width: '700px' }">
  <p-treeTable
    styleClass="p-treetable-sm p-treetable-gridlines p-treetable-striped"
    [columns]="[
      { field: 'name', header: 'Name' },
      { field: 'value', header: 'Value' }
    ]"
    [style]="{ 'overflow-x': 'visible' }"
    [value]="detail">
    <ng-template pTemplate="body" let-rowNode let-rowData="rowData" let-columns="columns">
      <tr>
        @for (col of columns; track col; let i = $index) {
          <td>
            @if (i === 0) {
              <p-treeTableToggler [rowNode]="rowNode"></p-treeTableToggler>
            }
            {{ rowData[col.field] }}
          </td>
        }
      </tr>
    </ng-template>
  </p-treeTable>
</p-dialog>
