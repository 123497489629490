<app-form-element [control]="formControl" [id]="id" [label]="label" [required]="required" [twoColumns]="twoColumns">
  <p-multiSelect
    [appendTo]="'body'"
    [placeholder]="'GLOBAL.CHOOSE' | translate"
    [formControl]="formControl"
    [id]="id"
    [options]="options"
    (onChange)="change()"
    [style]="{ width: '100%' }"></p-multiSelect>
</app-form-element>
