import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { MandantService } from '@service/mandant.service';
import { Workday } from '@entity/workday';
import { MessageService, SelectItem } from 'primeng/api';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';

import { FormsModule } from '@angular/forms';
import { ScrollIntoViewDirective } from '@shared/scroll-into-view.directive';
import { DropdownModule } from 'primeng/dropdown';

@Component({
  selector: 'app-workday-overview',
  templateUrl: './workday-overview.component.html',
  styleUrls: ['./workday-overview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [DropdownModule, ScrollIntoViewDirective, FormsModule, ButtonModule, TranslateModule],
})
export class WorkdayOverviewComponent implements OnInit {
  calendar!: Calendar;
  workdays?: number[];
  changed: number[] = [];
  years!: SelectItem[];
  year!: number;
  private workday!: Workday;
  private mandantId!: number;

  constructor(
    private dynamicDialogConfig: DynamicDialogConfig,
    private dynamicDialogRef: DynamicDialogRef,
    private mandantService: MandantService,
    private messageService: MessageService,
    private translateService: TranslateService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.workday = this.dynamicDialogConfig.data.workday as Workday;
    this.year = this.dynamicDialogConfig.data.year;
    this.mandantId = this.dynamicDialogConfig.data.mandantId;
    this.years = [];
    for (let i = 0; i < 10; i++) {
      const year = new Date().getFullYear() - 5 + i;
      this.years.push({ label: '' + year, value: year });
    }
    this.loadYear();
  }

  loadYear(): void {
    this.mandantService
      .getWorkdaysForOrganisation(this.mandantId, this.workday.organisationId, this.year)
      .subscribe((data) => {
        this.workdays = data.map((d) => d.getTime());
        this.changeDetectorRef.markForCheck();
      });
    this.calendar = new Calendar(this.year);
  }

  isSelected(day: Date): boolean {
    return this.workdays?.includes(day.getTime()) ?? false;
  }

  isChanged(day: Date): boolean {
    return this.changed?.includes(day.getTime());
  }

  clickDay(day: Date): void {
    if (this.isChanged(day)) {
      this.changed = this.changed.filter((c) => c !== day.getTime());
    } else {
      this.changed.push(day.getTime());
    }

    if (this.isSelected(day)) {
      this.workdays = this.workdays?.filter((w) => w !== day.getTime());
    } else {
      this.workdays = [...(this.workdays ?? []), day.getTime()];
    }
  }

  save(): void {
    this.mandantService
      .updateWorkdaysForOrganisation(
        this.mandantId,
        this.workday.organisationId,
        this.year,
        this.workdays?.map((w) => new Date(w)) ?? []
      )
      .subscribe({
        next: () => {
          this.messageService.add({
            severity: 'success',
            summary: this.translateService.instant('GLOBAL.SUCCESSFULLY_SAVED'),
          });
          this.dynamicDialogRef.close();
        },
        error: () =>
          this.messageService.add({
            severity: 'error',
            summary: this.translateService.instant('GLOBAL.NOT_SUCCESSFULLY_SAVED'),
          }),
      });
  }

  cancel(): void {
    this.dynamicDialogRef.close();
  }
}

export class Calendar {
  months: Month[] = [];

  constructor(year: number) {
    for (let i = 0; i < 12; i++) {
      this.months.push(new Month(i, year));
    }
  }
}

export class Month {
  weeks: Week[] = [];

  constructor(month: number, year: number) {
    const startOfTheMonth = new Date(year, month, 1);
    startOfTheMonth.setDate(1 - startOfTheMonth.getDay() + 1);
    for (let i = 0; i < 6; i++) {
      const startOfTheWeek = new Date(startOfTheMonth);
      startOfTheWeek.setDate(startOfTheMonth.getDate() + i * 7);
      this.weeks.push(new Week(startOfTheWeek));
    }
  }
}

export class Week {
  days: Date[] = [];

  constructor(startOfTheWeek: Date) {
    for (let i = 0; i < 7; i++) {
      const date = new Date(startOfTheWeek);
      date.setDate(startOfTheWeek.getDate() + i);
      this.days.push(date);
    }
  }
}
