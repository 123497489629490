import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TimeCredit } from '@entity/time-credit';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { FormButtonBarComponent } from '@shared/form-button-bar/form-button-bar.component';

import { FormNumberInputComponent } from '@shared/form-number-input/form-number-input.component';
import { FormDateInputComponent } from '@shared/form-date-input/form-date-input.component';
import { AnyValue } from '@shared/table/table.types';

export const weekDays = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'] as const;

type WeekDaysType = (typeof weekDays)[number];
type ShiftType = 'LateShift' | 'NightShift';
type Shifts<U extends string> = `${U}${ShiftType}`;
type WeekDaysShifts = Shifts<WeekDaysType>;

type TimeCreditFormValues = {
  validFrom: Date;
  validTo: Date | null | undefined;
  timeAllowancePerHourInPercent: number | null;
  timeAllowancePerHourInMinutes: number | null;
  perWeek: number | null;
  perYear: number | null;
  totalPerYear: number | null;
} & { [key in WeekDaysShifts]?: number | null };

type TimeCreditFormControl = {
  [Property in keyof TimeCreditFormValues]: FormControl<TimeCreditFormValues[Property]>;
};

@Component({
  selector: 'app-time-credit-detail',
  templateUrl: './time-credit-detail.component.html',
  styleUrls: ['./time-credit-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, FormDateInputComponent, FormNumberInputComponent, FormButtonBarComponent],
})
export class TimeCreditDetailComponent implements OnInit, OnDestroy {
  form!: FormGroup<TimeCreditFormControl>;
  weekDays = weekDays.map((w) => new WeekDay(w, this.translateService));
  private subscriptions: Subscription[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private translateService: TranslateService,
    private dynamicDialogRef: DynamicDialogRef,
    private dynamicDialogConfig: DynamicDialogConfig
  ) {}

  ngOnInit(): void {
    const timeCredit: TimeCredit = this.dynamicDialogConfig.data;
    const config = {
      validFrom: new FormControl(timeCredit.validFrom, { validators: Validators.required, nonNullable: true }),
      validTo: [timeCredit.validTo],
      timeAllowancePerHourInPercent: [timeCredit.timeAllowancePerHourInPercent, Validators.required],
      timeAllowancePerHourInMinutes: [
        timeCredit.timeAllowancePerHourInPercent ? (timeCredit.timeAllowancePerHourInPercent / 100) * 60 : null,
        Validators.required,
      ],
      perWeek: new FormControl<number | null>({ value: null, disabled: true }),
      perYear: new FormControl<number | null>({ value: null, disabled: true }),
      totalPerYear: new FormControl<number | null>({ value: null, disabled: true }),
    };

    this.form = this.formBuilder.group(config);
    this.weekDays.forEach((weekday) => {
      this.form.controls[weekday.lateShiftId] = new FormControl(
        timeCredit[weekday.lateShiftId] ?? 0,
        Validators.required
      );
      this.form.controls[weekday.nightShiftId] = new FormControl(
        timeCredit[weekday.nightShiftId] ?? 0,
        Validators.required
      );
    });

    this.subscriptions.push(
      this.form.valueChanges.subscribe((value) => {
        this.recalculateValues(value);
      })
    );

    this.subscriptions.push(
      this.form.controls.timeAllowancePerHourInPercent.valueChanges.subscribe((value) => {
        this.form.controls.timeAllowancePerHourInMinutes.setValue(value ? (value / 100) * 60 : null, {
          emitModelToViewChange: true,
          emitEvent: false,
        });
      })
    );

    this.subscriptions.push(
      this.form.controls.timeAllowancePerHourInMinutes.valueChanges.subscribe((value) => {
        this.form.controls.timeAllowancePerHourInPercent.setValue(value ? Math.round((value * 100) / 60) : null, {
          emitModelToViewChange: true,
          emitEvent: false,
        });
      })
    );

    this.recalculateValues(this.form.getRawValue());
  }

  onCancel(): void {
    this.dynamicDialogRef.close(null);
  }

  onSubmit(): void {
    this.dynamicDialogRef.close(this.form.getRawValue());
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  onFocus($event: AnyValue): void {
    $event.target.select();
  }

  private recalculateValues(value: Partial<TimeCreditFormValues>): void {
    let perWeek = 0;
    this.weekDays.forEach((weekday) => {
      perWeek += value[weekday.lateShiftId] ?? 0;
      perWeek += value[weekday.nightShiftId] ?? 0;
    });
    this.form.controls.perWeek.setValue(perWeek, { emitModelToViewChange: true, emitEvent: false });
    this.form.controls.perYear.setValue(perWeek * 52.1429, { emitModelToViewChange: true, emitEvent: false });
    const perHourInPercent = this.form.controls.timeAllowancePerHourInPercent.value;
    this.form.controls.totalPerYear.setValue(perHourInPercent ? (perWeek * 52.1429 * perHourInPercent) / 100.0 : null, {
      emitModelToViewChange: true,
      emitEvent: false,
    });
  }
}

export class WeekDay {
  lateShiftId: WeekDaysShifts;
  nightShiftId: WeekDaysShifts;
  lateShiftLabel: string;
  nightShiftLabel: string;

  constructor(weekDay: WeekDaysType, translateService: TranslateService) {
    this.lateShiftId = (weekDay + 'LateShift') as WeekDaysShifts;
    this.nightShiftId = (weekDay + 'NightShift') as WeekDaysShifts;

    const index = weekDays.indexOf(weekDay);
    const weekDayTranslated = translateService.instant('PRIMENG.dayNames.' + ((index + 1) % 7));
    this.lateShiftLabel = weekDayTranslated + ' ' + translateService.instant('TIME_CREDITS.LATE_SHIFT');
    this.nightShiftLabel = weekDayTranslated + ' ' + translateService.instant('TIME_CREDITS.NIGHT_SHIFT');
  }
}
