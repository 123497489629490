import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import {
  Event as RouterEvent,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
  RouterOutlet,
} from '@angular/router';
import { ScrollPanel, ScrollPanelModule } from 'primeng/scrollpanel';
import { FilterService, PrimeNGConfig } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ToastModule } from 'primeng/toast';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { ProgressBarModule } from 'primeng/progressbar';
import { MenuComponent } from './menu/menu.component';
import { TopBarComponent } from './topbar/top-bar.component';
import { NgClass } from '@angular/common';
import { AnyValue } from '@shared/table/table.types';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgClass,
    TopBarComponent,
    ScrollPanelModule,
    MenuComponent,
    ProgressBarModule,
    BreadcrumbComponent,
    RouterOutlet,
    ToastModule,
    ConfirmDialogModule,
  ],
})
export class AppComponent implements AfterViewInit, OnInit {
  topbarMenuActive = false;
  overlayMenuActive = false;
  staticMenuDesktopInactive = false;
  staticMenuMobileActive = false;
  menuClick = false;
  topbarItemClick = false;
  activeTopbarItem: unknown;
  menuHoverActive = false;
  loading = false;
  @ViewChild('layoutMenuScroller', { static: true }) layoutMenuScrollerViewChild!: ScrollPanel;
  private loadingTimeout = 0;

  constructor(
    private router: Router,
    private changeDetectorRef: ChangeDetectorRef,
    private config: PrimeNGConfig,
    private translateService: TranslateService,
    private httpClient: HttpClient,
    private filterService: FilterService
  ) {}

  ngOnInit(): void {
    this.router.events.subscribe((event: RouterEvent) => {
      switch (event.constructor) {
        case NavigationStart:
          this.loadingTimeout = window.setTimeout(() => {
            this.loading = true;
            this.changeDetectorRef.markForCheck();
          }, 300);
          break;
        case NavigationEnd:
        case NavigationCancel:
        case NavigationError:
          window.clearTimeout(this.loadingTimeout);
          this.loading = false;
          this.changeDetectorRef.markForCheck();
          break;
        default:
        // ignore other cases
      }
    });

    this.setupPrimeNg();
    this.addFiltersToFilterUtils();
    this.sendLoginStatistics();
  }

  ngAfterViewInit(): void {
    window.setTimeout(() => {
      this.layoutMenuScrollerViewChild.moveBar();
    }, 100);
  }

  onLayoutClick(): void {
    if (!this.topbarItemClick) {
      this.activeTopbarItem = null;
      this.topbarMenuActive = false;
    }

    if (!this.menuClick) {
      if (this.overlayMenuActive || this.staticMenuMobileActive) {
        this.hideOverlayMenu();
      }

      this.menuHoverActive = false;
    }

    this.topbarItemClick = false;
    this.menuClick = false;
  }

  onMenuButtonClick(event: Event): void {
    this.menuClick = true;
    this.topbarMenuActive = false;

    if (this.isDesktop()) {
      this.staticMenuDesktopInactive = !this.staticMenuDesktopInactive;
    } else {
      this.staticMenuMobileActive = !this.staticMenuMobileActive;
    }

    event.preventDefault();
  }

  onMenuClick(): void {
    this.menuClick = true;

    window.setTimeout(() => {
      this.layoutMenuScrollerViewChild.moveBar();
    }, 500);
  }

  onTopbarMenuButtonClick(event: Event): void {
    this.topbarItemClick = true;
    this.topbarMenuActive = !this.topbarMenuActive;

    this.hideOverlayMenu();

    event.preventDefault();
  }

  isMobile(): boolean {
    return window.innerWidth < 1025;
  }

  isDesktop(): boolean {
    return window.innerWidth > 1024;
  }

  isTablet(): boolean {
    const width = window.innerWidth;
    return width <= 1024 && width > 640;
  }

  hideOverlayMenu(): void {
    this.overlayMenuActive = false;
    this.staticMenuMobileActive = false;
  }

  private addFiltersToFilterUtils(): void {
    this.filterService.register('date', (value: string | Date, filter: Date | string): boolean => {
      const date = new Date(value);
      return (
        filter instanceof Date &&
        date.getDate() === filter.getDate() &&
        date.getMonth() === filter.getMonth() &&
        date.getFullYear() === filter.getFullYear()
      );
    });
    this.filterService.register('true', (value: AnyValue, filter?: boolean): boolean => {
      if (filter === undefined) {
        return true;
      }
      if (filter && value === true) {
        return true;
      }
      if (filter && value === 'true') {
        return value === true;
      }
      if (!filter && value === false) {
        return true;
      }
      if (!filter && value === 'false') {
        return true;
      }
      if (filter && !value) {
        return false;
      }
      return filter && (value + '').length > 0;
    });
    this.filterService.register('hasValue', (value: AnyValue, filter?: boolean): boolean => {
      if (filter === undefined) {
        return true;
      }
      if (filter) {
        return value?.length > 0;
      }
      if (!filter) {
        return !value || value?.length === 0;
      }
      return false;
    });
  }

  private setupPrimeNg(): void {
    this.config.setTranslation(this.translateService.instant('PRIMENG'));
    this.config.ripple = true;
    this.translateService.onLangChange.subscribe(() => {
      this.config.setTranslation(this.translateService.instant('PRIMENG'));
    });
  }

  private sendLoginStatistics(): void {
    this.httpClient
      .post<unknown>(`/api/1.0/config/login-statistics`, { userAgent: window.navigator.userAgent })
      .subscribe(() => true);
  }
}
