import { ChangeDetectionStrategy, Component, Injector, OnInit } from '@angular/core';
import { AbstractContentComponent } from '../../abstract-content.component';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { TacsCodeKreis } from '@entity/tacs-code-kreis';
import { SelectItem } from 'primeng/api';
import { Organisation, OrganisationalUnitType } from '@entity/organisation';
import { FormTimePeriodComponent, TimePeriodItem } from '@shared/form-time-period/form-time-period.component';
import { KategorieSet } from '@entity/mandant';
import { Context } from '@service/context.service';
import { OrganisationalUnitService } from '@service/organisational-unit.service';
import { skip } from 'rxjs/operators';
import { finalize } from 'rxjs';
import { FormButtonBarComponent } from '@shared/form-button-bar/form-button-bar.component';
import { FormSwitchComponent } from '@shared/form-switch/form-switch.component';
import { FormDropdownComponent } from '@shared/form-dropdown/form-dropdown.component';
import { FormDateInputComponent } from '@shared/form-date-input/form-date-input.component';
import { FormTextInputComponent } from '@shared/form-text-input/form-text-input.component';

import { CardModule } from 'primeng/card';
import { TranslateModule } from '@ngx-translate/core';
import { FormNumberInputComponent } from '@shared/form-number-input/form-number-input.component';

@Component({
  selector: 'app-organisational-unit-detail',
  templateUrl: './organisational-unit-detail.component.html',
  styleUrls: ['./organisational-unit-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CardModule,
    FormsModule,
    ReactiveFormsModule,
    FormTextInputComponent,
    FormDateInputComponent,
    FormDropdownComponent,
    FormSwitchComponent,
    FormTimePeriodComponent,
    FormButtonBarComponent,
    TranslateModule,
    FormNumberInputComponent,
  ],
})
export class OrganisationalUnitDetailComponent extends AbstractContentComponent implements OnInit {
  organisation!: Organisation;
  tacsCodeKreisOptions: SelectItem[] = [];
  form!: FormGroup<{
    organisationId: FormControl<string>;
    bezeichnung: FormControl<string>;
    kostenstelle: FormControl<string>;
    kuerzel: FormControl<string>;
    kategorieSets: FormControl<TimePeriodItem[]>;
    gueltigAb: FormControl<Date>;
    gueltigBis: FormControl<Date | undefined>;
    benchmarkAb: FormControl<Date | undefined>;
    erfassungstyp: FormControl<number>;
    sabEnabled: FormControl<boolean>;
    creationOfCustomerEnabled: FormControl<boolean>;
    organisationalUnitType: FormControl<OrganisationalUnitType>;
    sortierNr: FormControl<number | undefined>;
  }>;
  erfassungstypOptions: SelectItem[] = [];
  title!: string;
  tacsCodeKreis!: Map<number, TacsCodeKreis>;
  submitting = false;

  organisationalUnitTypeOptions: SelectItem[] = [
    {
      label: this.translate('ORGANISATIONAL_UNITS.UNSPECIFIED_TYPE'),
      value: OrganisationalUnitType.UNSPECIFIED,
    },
    {
      label: this.translate('ORGANISATIONAL_UNITS.CUSTOMER_ORGANISATIONAL_UNIT'),
      value: OrganisationalUnitType.CUSTOMER,
    },
    {
      label: this.translate('ORGANISATIONAL_UNITS.EMPLOYEE_ORGANISATIONAL_UNIT'),
      value: OrganisationalUnitType.EMPLOYEE,
    },
  ];

  constructor(
    private fb: FormBuilder,
    private organisationalUnitService: OrganisationalUnitService,
    injector: Injector
  ) {
    super(injector, Context.Global);
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.activatedRoute.data.subscribe((data) => {
      this.organisation = data.organisation;
      this.tacsCodeKreis = data.organizationCategories.reduce(
        (map: Map<number, TacsCodeKreis>, value: TacsCodeKreis) => {
          return map.set(value.id ?? 0, value);
        },
        new Map<number, TacsCodeKreis>()
      );
      this.tacsCodeKreisOptions = data.organizationCategories.map(
        (t: TacsCodeKreis) =>
          ({
            value: t.id,
            label: t.bezeichnung,
          }) as SelectItem
      );
    });

    this.title = this.organisation.id
      ? this.translate('ORGANISATIONAL_UNITS.TITLE_EDIT', this.organisation)
      : this.translate('ORGANISATIONAL_UNITS.TITLE_CREATE');

    this.setBreadcrumbs([
      {
        label: 'MENU.ORGANISATIONAL_UNITS',
        routerLink: ['organisational-units'],
      },
      {
        label: this.title,
      },
    ]);

    this.initializeForm();
    this.setOptions();

    const control = this.form.get('kategorieSets');
    if (control) {
      this.subscriptions.push(
        control.valueChanges
          .pipe(skip(1)) // skip the first one, because this is the initialization
          .subscribe((value: TimePeriodItem[]) => {
            const lastTimePeriodItem = value[value.length - 1];
            if (lastTimePeriodItem) {
              const personalzeitVerschiebung =
                this.tacsCodeKreis.get(lastTimePeriodItem.value)?.kundenPersonalzeitVerschiebung ?? false;
              this.form.get('erfassungstyp')?.setValue(personalzeitVerschiebung ? 1 : 2);
            }
          })
      );
    }
  }

  onSubmit(): void {
    this.submitting = true;
    const formValue = this.form.getRawValue();
    const updatedOrganisation: Organisation = {
      ...this.organisation,
      ...formValue,
      mandantId: this.getMandantId(),
      kategorieSets: formValue.kategorieSets.map(
        (t: TimePeriodItem) =>
          ({
            id: t.id,
            gueltigAb: t.from,
            gueltigBis: t.to,
            kategorieId: t.value,
          }) as KategorieSet
      ),
      organisationalUnitType: formValue.organisationalUnitType,
    };
    const organisationObservable = this.organisation.id
      ? this.organisationalUnitService.updateOrganisationalUnit(this.organisation.id, updatedOrganisation)
      : this.organisationalUnitService.createOrganisationalUnit(updatedOrganisation);
    organisationObservable.pipe(finalize(() => (this.submitting = false))).subscribe({
      next: () => {
        this.showSuccessMessage();
        this.navigate(['/organisational-units']);
      },
      error: () => {
        this.showErrorMessage();
      },
    });
  }

  protected onLanguageChange(): void {
    this.setOptions();
  }

  private initializeForm(): void {
    this.form = this.fb.nonNullable.group({
      organisationId: new FormControl(
        {
          value: this.organisation.organisationId,
          disabled: true,
        },
        { validators: Validators.required, nonNullable: true }
      ),
      bezeichnung: [this.organisation.bezeichnung, Validators.required],
      kostenstelle: [this.organisation.kostenstelle],
      kuerzel: [this.organisation.kuerzel, [Validators.required, Validators.maxLength(10)]],
      kategorieSets: new FormControl(
        this.organisation.kategorieSets?.map(
          (k) =>
            ({
              id: k.id,
              value: k.kategorieId,
              from: k.gueltigAb,
              to: k.gueltigBis,
            }) as TimePeriodItem
        ) || [
          {
            value: this.tacsCodeKreisOptions[0].value,
            from: new Date(),
          },
        ],
        { validators: Validators.required, nonNullable: true }
      ),
      gueltigAb: [this.organisation.gueltigAb ?? new Date(), Validators.required],
      gueltigBis: this.organisation.gueltigBis,
      benchmarkAb: [this.organisation.benchmarkAb ? new Date(this.organisation.benchmarkAb) : undefined],
      erfassungstyp: this.organisation.erfassungstyp,
      sabEnabled: this.organisation.sabEnabled,
      creationOfCustomerEnabled: {
        value: this.organisation.creationOfCustomerEnabled ?? false,
        disabled: this.isNotRodixAdmin(),
      },
      organisationalUnitType: this.organisation.organisationalUnitType,
      sortierNr: this.organisation.sortierNr,
    });
  }

  private setOptions(): void {
    this.erfassungstypOptions = [
      {
        value: 1,
        label: this.translate('ORGANISATIONAL_UNITS.ERFASSUNGSTYP_1'),
      },
      {
        value: 2,
        label: this.translate('ORGANISATIONAL_UNITS.ERFASSUNGSTYP_2'),
      },
      {
        value: 3,
        label: this.translate('ORGANISATIONAL_UNITS.ERFASSUNGSTYP_3'),
      },
    ];
  }
}
