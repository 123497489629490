import { ChangeDetectionStrategy, Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { Market } from '@entity/market';
import { AbstractContentComponent } from '../../abstract-content.component';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MarketService } from '@service/market.service';
import { Context } from '@service/context.service';
import { LanguageFormGroup } from '@entity/language';
import { finalize } from 'rxjs';
import { FormButtonBarComponent } from '@shared/form-button-bar/form-button-bar.component';
import { FormDateInputComponent } from '@shared/form-date-input/form-date-input.component';
import { FormTextInputComponent } from '@shared/form-text-input/form-text-input.component';
import { CardModule } from 'primeng/card';

@Component({
  selector: 'app-market-detail',
  templateUrl: './market-detail.component.html',
  styleUrls: ['./market-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CardModule,
    FormsModule,
    ReactiveFormsModule,
    FormTextInputComponent,
    FormDateInputComponent,
    FormButtonBarComponent,
  ],
})
export class MarketDetailComponent extends AbstractContentComponent implements OnInit, OnDestroy {
  market: Market = {} as Market;
  form!: FormGroup<{
    names: FormGroup<LanguageFormGroup>;
    validFrom: FormControl<Date>;
    validTo: FormControl<Date | undefined>;
  }>;
  title!: string;
  submitting = false;

  constructor(
    private marketService: MarketService,
    private fb: FormBuilder,
    injector: Injector
  ) {
    super(injector, Context.Global);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.activatedRoute.data.subscribe((data) => {
      this.market = data.market || this.market;
    });

    this.initializeForm();
    this.onLanguageChange();
  }

  minDate(): ValidatorFn {
    return (control) => {
      if (!this.form || !control.value) {
        return null;
      }
      const valid = control.value >= this.form.getRawValue().validFrom;
      return valid ? null : { minDate: false };
    };
  }

  onSubmit(): void {
    this.submitting = true;
    const formValue = this.form.getRawValue();
    const updateOrCreateMarket: Market = {
      ...this.market,
      ...formValue,
    };
    const marketObservable = this.market.id
      ? this.marketService.updateMarket(this.market.id, updateOrCreateMarket)
      : this.marketService.createMarket(updateOrCreateMarket);
    marketObservable.pipe(finalize(() => (this.submitting = false))).subscribe({
      next: () => {
        this.showSuccessMessage();
        this.navigate(['/markets']);
      },
      error: () => {
        this.showErrorMessage();
      },
    });
  }

  protected onLanguageChange(): void {
    this.title = this.market.id
      ? this.translate('MARKETS.EDIT_MARKET', this.market)
      : this.translate('MARKETS.CREATE_MARKET');

    this.setBreadcrumbs([
      {
        label: 'MENU.MARKETS',
        routerLink: ['markets'],
      },
      {
        label: this.title,
      },
    ]);
  }

  private initializeForm(): void {
    this.form = this.fb.group({
      names: this.fb.nonNullable.group({
        De: new FormControl(this.market.names?.De, { validators: Validators.required, nonNullable: true }),
        Fr: [this.market.names?.Fr],
        It: [this.market.names?.It],
      }),
      validFrom: new FormControl(this.market.validFrom ?? new Date(), {
        validators: Validators.required,
        nonNullable: true,
      }),
      validTo: new FormControl(this.market.validTo, { validators: this.minDate(), nonNullable: true }),
    });
  }
}
