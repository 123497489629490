import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { AnyValue } from '@shared/table/table.types';

@Directive({
  selector: '[appActionButton]',
  standalone: true,
})
export class ActionButtonDirective {
  @Input()
  width = 200;

  @Input()
  labelKey = '';

  @Input()
  icon = '';

  @Output()
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  onClick = new EventEmitter<{ event: Event; row: AnyValue }>();

  @Input()
  type: 'info' | 'success' | 'secondary' | 'warning' | 'danger' = 'info';

  @Input()
  displayIf?: string;

  @Input()
  displayIfNot?: string;

  @Input()
  disabled = false;
}
