import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit } from '@angular/core';
import { FormControl, FormsModule } from '@angular/forms';
import { SelectItem } from 'primeng/api';
import { AbstractFormElement } from '../abstract-form-element';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { ScrollIntoViewDirective } from '../scroll-into-view.directive';
import { DropdownModule } from 'primeng/dropdown';

import { FormElementComponent } from '../form-element/form-element.component';
import { AnyValue } from '@shared/table/table.types';

export interface TimePeriodItem {
  id: number;
  value: AnyValue;
  from: Date;
  to: Date | null;
  deletable?: boolean;
}

@Component({
  selector: 'app-form-time-period',
  templateUrl: './form-time-period.component.html',
  styleUrls: ['./form-time-period.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    FormElementComponent,
    DropdownModule,
    ScrollIntoViewDirective,
    FormsModule,
    InputTextModule,
    InputNumberModule,
    CalendarModule,
    ButtonModule,
  ],
})
export class FormTimePeriodComponent extends AbstractFormElement<TimePeriodItem[]> implements OnInit, OnChanges {
  @Input()
  options: SelectItem[] = [];

  @Input()
  inputType: 'number' | 'text' | 'select' = 'select';

  @Input()
  maxFractionDigits = 0;

  @Input()
  minFractionDigits = 0;

  @Input()
  withoutGap = false;

  @Input()
  minDate?: Date;

  @Input()
  maxDate?: Date;

  value: TimePeriodItem[] = [];
  minDates: Date[] = [];
  validationState: { from: boolean; to: boolean }[] = [];

  static calculateMinDates(timePeriodItems: TimePeriodItem[], min?: Date): Date[] {
    const minDates: Date[] = min ? [min] : [];
    for (let i = 1; i < timePeriodItems.length; i++) {
      const timePeriodItem = timePeriodItems[i - 1];
      const date = timePeriodItem.to || timePeriodItem.from || new Date(1);
      minDates.push(new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1));
    }
    return minDates;
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.minDate = this.minDate ?? new Date(1000, 0);
    this.maxDate = this.maxDate ?? new Date(2999, 0);
    this.formControl = this.form.get(this.id) as FormControl<TimePeriodItem[]>;
    this.value = this.formControl.value;
    this.value = this.value.sort((a, b) => a.from.getTime() - b.from.getTime());
    this.update();
  }

  ngOnChanges(): void {
    if (this.value && this.formControl) {
      this.update();
    }
  }

  remove(item: TimePeriodItem): void {
    this.value.remove(item);
    this.update();
  }

  add(item?: TimePeriodItem): void {
    if (item) {
      this.value.splice(this.value.indexOf(item) + 1, 0, {
        value: this.options[0]?.value,
        deletable: true,
      } as TimePeriodItem);
    } else {
      this.value.push({ value: this.options[0]?.value, deletable: true } as TimePeriodItem);
    }
    this.update();
  }

  updateMinDates(): void {
    this.minDates = FormTimePeriodComponent.calculateMinDates(this.value, this.minDate);
  }

  update(): void {
    let valid = true;
    const timePeriodItems = this.value;
    this.validationState = [];
    const minDate = this.minDate ?? new Date(1, 0);
    const maxDate = this.maxDate ?? new Date(2999, 0);
    for (let i = 0; i < timePeriodItems.length; i++) {
      const to = timePeriodItems[i].to;
      let toValid = to == null || timePeriodItems[i].from <= to;
      if (toValid && i === timePeriodItems.length - 1 && to != null) {
        toValid = toValid && to < new Date(maxDate.getFullYear(), maxDate.getMonth(), maxDate.getDate() + 1);
      }
      let fromValid = !!timePeriodItems[i].from;
      const previousTo =
        i === 0
          ? new Date(minDate.getFullYear(), minDate.getMonth(), minDate.getDate() - 1)
          : timePeriodItems[i - 1].to;
      fromValid = timePeriodItems[i].from != null && previousTo != null && previousTo < timePeriodItems[i].from;
      if (timePeriodItems[i].from != null && previousTo != null && this.withoutGap && i > 0) {
        const previousToDate = new Date(previousTo);
        previousToDate.setDate(previousToDate.getDate() + 1);
        const from = timePeriodItems[i].from;
        fromValid =
          previousToDate.getFullYear() === from.getFullYear() &&
          previousToDate.getMonth() === from.getMonth() &&
          previousToDate.getDate() === from.getDate();
      }
      valid = valid && toValid && fromValid;
      this.validationState.push({
        from: fromValid,
        to: toValid,
      });
    }
    this.formControl.setValue(timePeriodItems);
    if (valid) {
      this.formControl.setErrors(null);
    } else {
      this.formControl.setErrors({ invalidPeriod: true }, { emitEvent: true });
    }
    this.updateMinDates();
    this.changeDetectorRef.markForCheck();
  }
}
