import { TableService } from '@shared/table/table-service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MandantService } from '@service/mandant.service';
import { ContextService } from '@service/context.service';
import { Workday } from '@entity/workday';
import { map } from 'rxjs/operators';

@Injectable()
export class WorkdayTableService extends TableService<Workday> {
  constructor(
    private mandantService: MandantService,
    private contextService: ContextService
  ) {
    super();
  }

  createEntry(): Workday {
    return {} as Workday;
  }

  getData(focusDate?: Date): Observable<{ entities: Workday[] }> {
    return this.mandantService
      .getWorkdays(this.contextService.getMandantIdInstant(), focusDate ?? new Date())
      .pipe(map((entities) => ({ entities })));
  }

  isBatchUpdateSupported(): boolean {
    return true;
  }

  batchUpdate(value: Workday[], focusDate?: Date): Observable<Workday[]> {
    return this.mandantService.updateWorkdays(
      this.contextService.getMandantIdInstant(),
      focusDate ?? new Date(),
      value
    );
  }
}
