import { ChangeDetectionStrategy, Component, Injector, OnInit } from '@angular/core';
import { AbstractContentComponent } from '../../abstract-content.component';
import { Context } from '@service/context.service';
import { Column } from '@shared/table/column';
import { Variable } from '@entity/variable';
import { TreeNode } from 'primeng/api';
import { VariableService } from '@service/variable.service';
import { TreeTableComponent } from '@shared/table/tree-table.component';

@Component({
  selector: 'app-measures',
  templateUrl: './measures.component.html',
  styleUrls: ['./measures.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TreeTableComponent],
})
export class MeasuresComponent extends AbstractContentComponent implements OnInit {
  columns: Column[] = [
    { field: 'tacsId', headerKey: 'VARIABLES.TACS_ID', width: '250px' },
    {
      field: 'name',
      headerKey: 'GLOBAL.NAME',
      minWidth: '250px',
    },
  ];
  value: TreeNode[] = [];
  private variables: Variable[] = [];

  constructor(
    private variableService: VariableService,
    injector: Injector
  ) {
    super(injector, Context.Mandant);
  }

  private static showVariable(v: Variable): boolean {
    return v.isMeasure || v.tacsId?.split('.').length === 3;
  }

  private static determineParent(variable: Variable, parentNode?: TreeNode): TreeNode | undefined {
    while (parentNode != null && !variable.tacsId?.startsWith(parentNode?.data.tacsId)) {
      parentNode = parentNode?.parent;
    }
    return parentNode;
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.setBreadcrumbs([{ label: 'MENU.VARIABLES' }, { label: 'MENU.MEASURE_VIEW' }]);
  }

  protected onMandantIdChange(id: number | null): void {
    if (!id) {
      this.variables = [];
      this.value = [];
      return;
    }
    this.startLoading();
    this.markForCheck();
    this.variableService.getVariables(id).subscribe((variables) => {
      this.variables = variables;
      this.updateTreeValue(this.variables);
      this.loadingDone();
      this.markForCheck();
    });
  }

  private updateTreeValue(variables: Variable[]): void {
    variables.sort((a, b) => ((a.tacsId ?? '') < (b.tacsId ?? '') ? -1 : 1));
    this.value = [];
    let parentNode: TreeNode | undefined;
    variables
      .filter((v) => MeasuresComponent.showVariable(v))
      .forEach((variable) => {
        const newNode = this.createNode(variable);
        const newParentNode = MeasuresComponent.determineParent(variable, parentNode);
        if (newParentNode) {
          newParentNode.children?.push(newNode);
          newNode.parent = newParentNode;
          if (parentNode !== newParentNode) {
            newParentNode.children?.sort((a, b) => a.data.variable.sortIndex - b.data.variable.sortIndex);
            parentNode = newParentNode;
          }
        } else {
          this.value.push(newNode);
        }
        parentNode = newNode;
      });
    this.remove(this.value);
    this.value = this.value.filter((v) => (v.children?.length ?? 0) > 0);
    this.markForCheck();
  }

  private remove(nodes: TreeNode[]): void {
    for (let i = nodes.length - 1; i >= 0; i--) {
      const node = nodes[i];
      if (!node.data.isMeasure || !node.data.isActive) {
        this.remove(node.children ?? []);
        if (node.children?.length === 0) {
          nodes.splice(i, 1);
        }
      }
    }
  }

  private createNode(variable: Variable): TreeNode {
    let name = (variable.customName ?? {})[this.getLanguageCaseSensitive()];
    if (!name || name.length === 0) {
      name = variable.name;
    }
    const today = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
    let active =
      (variable.entries?.findIndex((e) => e.validFrom <= today && (e.validTo == null || today <= e.validTo)) ?? -1) >=
      0;
    if (variable.validFrom && today < variable.validFrom) {
      active = false;
    }
    if (variable.validTo && variable.validTo < today) {
      active = false;
    }
    return {
      children: [],
      expanded: true,
      data: {
        id: variable.id,
        tacsId: variable.tacsId,
        isMeasure: variable.isMeasure,
        isActive: active,
        name,
        _style_tacsId: {
          'background-color': VariableService.getBackgroundColor(variable.tacsId),
          color: VariableService.getForegroundColor(variable.tacsId),
        },
        variable,
      },
    };
  }
}
