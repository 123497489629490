import { Injectable } from '@angular/core';

import { SaveableDataComponent } from '@shared/saveable-data-component';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class CanDeactivateGuard {
  constructor(private translateService: TranslateService) {}

  canDeactivate(saveableDataComponent: SaveableDataComponent): boolean {
    if (saveableDataComponent.hasUnsavedData()) {
      return confirm(this.translateService.instant('GLOBAL.UNSAVED_CHANGES'));
    }
    return true;
  }
}
