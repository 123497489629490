import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { VarHealthcareDocument, VarHealthcareDocumentSearch } from '@entity/var-healthcare';

@Injectable({
  providedIn: 'root',
})
export class VarHealthcareService {
  private readonly baseUrl = 'api/1.0/varhealthcare';

  constructor(private httpClient: HttpClient) {}

  search(query: string): Observable<VarHealthcareDocumentSearch> {
    return this.httpClient.get<VarHealthcareDocumentSearch>(`${this.baseUrl}/search?query=${query}`);
  }

  getDocument(id: number): Observable<VarHealthcareDocument | null> {
    return this.httpClient.get<VarHealthcareDocument>(`${this.baseUrl}/documents/${id}`);
  }
}
