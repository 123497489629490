<app-table
  filename="MENU.STATISTICS_CODES"
  [actionColumnWidth]="300"
  [columns]="columns"
  [loading]="loading"
  [value]="statisticsCodes">
  @if (isManager()) {
    <ng-template
      appToolbarButton
      icon="fal fa-plus"
      labelKey="STATISTICS_CODES.CREATE_TITLE"
      (onClick)="createNewStatisticsCode()"></ng-template>
  }
  @if (isManager()) {
    <ng-template
      appActionButton
      displayIf="deletable"
      icon="fal fa-trash"
      labelKey="ACTIONS.DELETE"
      type="danger"
      (onClick)="deleteStatisticsCode($event.row)"></ng-template>
  }
</app-table>
