import { ChangeDetectionStrategy, Component, Injector, OnInit } from '@angular/core';
import { AbstractContentComponent } from '../../abstract-content.component';
import { Context } from '@service/context.service';
import { MenuItem, TreeNode } from 'primeng/api';
import { Column } from '@shared/table/column';
import { CompleteVariable } from '@entity/variable';
import { VariableService } from '@service/variable.service';
import { Market } from '@entity/market';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { ActionButtonDirective } from '@shared/table/action-button.directive';
import { ToolbarButtonDirective } from '@shared/table/toolbar-button.directive';
import { TreeTableComponent } from '@shared/table/tree-table.component';

type AdditionalColumns = 'None' | 'Markets' | 'Features' | 'VAR' | 'Leistungsgewicht' | 'Snomed-CT';

@Component({
  selector: 'app-variables',
  templateUrl: './variables.component.html',
  styleUrls: ['./variables.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TreeTableComponent, ToolbarButtonDirective, ActionButtonDirective, TieredMenuModule],
})
export class VariablesComponent extends AbstractContentComponent implements OnInit {
  viewMenuModel: MenuItem[] = [];
  hierarchyMenuModel: MenuItem[] = [
    { label: this.translate('VARIABLES.TYPES.Elementvariable'), command: () => this.expandToHierarchy(0) },
    { label: this.translate('VARIABLES.TYPES.Basisvariable'), command: () => this.expandToHierarchy(1) },
    { label: this.translate('VARIABLES.TYPES.Detailvariable'), command: () => this.expandToHierarchy(2) },
    { label: this.translate('VARIABLES.TYPES.Bereichsvariable'), command: () => this.expandToHierarchy(3) },
    { label: this.translate('VARIABLES.TYPES.Massnahme'), command: () => this.expandToHierarchy(5) },
  ];

  additionalColumnsMenuModel: MenuItem[] = [
    { label: this.translate('VARIABLES.MARKETS'), command: () => this.showColumns('Markets') },
    { label: this.translate('VARIABLES.FEATURES'), command: () => this.showColumns('Features') },
    { label: this.translate('VARIABLES.VAR_HEALTHCARE'), command: () => this.showColumns('VAR') },
    { label: this.translate('VARIABLES.SNOMED_CT'), command: () => this.showColumns('Snomed-CT') },
    { label: this.translate('VARIABLES.LEISTUNGSGEWICHT'), command: () => this.showColumns('Leistungsgewicht') },
    { label: this.translate('VARIABLES.NO_ADDITIONAL_COLUMNS'), command: () => this.showColumns('None') },
  ];

  value: TreeNode[] = [];
  columns: Column[] = [];
  private variables: CompleteVariable[] = [];
  private markets: Market[] = [];
  private additionalColumns: AdditionalColumns = 'None';

  constructor(
    private variableService: VariableService,
    injector: Injector
  ) {
    super(injector, Context.Global, 'MENU.VARIABLES_OVERVIEW');
  }

  private static createNode(variable: CompleteVariable): TreeNode {
    const market: { [key: number]: boolean } = {};
    variable.markets?.forEach((key) => (market[key] = true));
    return {
      children: [],
      expanded: false,
      data: {
        id: variable.id,
        tacsId: variable.tacsId,
        name: variable.name,
        description: variable.description,
        validFrom: variable?.validFrom,
        validTo: variable?.validTo,
        isMeasure: variable.isMeasure,
        isDeletable: variable.isDeletable,
        sortIndex: variable.sortIndex,
        isParent: variable.isDeletable,
        isAssignmentCustomer: variable.isAssignmentCustomer,
        isAssignmentEmployee: variable.isAssignmentEmployee,
        isRebooking: variable.isRebooking,
        isPresence: variable.isPresence,
        isProductivity: variable.isProductivity,
        isCostRelevant: variable.isCostRelevant,
        isTreatmentDuration: variable.isTreatmentDuration,
        market,
        isLastHierarchy: (variable.tacsId?.length ?? 0) >= 15,
        _style_tacsId: {
          'background-color': VariableService.getBackgroundColor(variable.tacsId),
          color: VariableService.getForegroundColor(variable.tacsId),
        },
        varHealthcareDocumentIds: variable.varHealthcareDocumentIds?.join(', '),
        snomedCtIds: variable.snomedCtItems?.map((s) => `${s.identifier} (${s.title})`).join(', '),
        leistungsgewichtKategorie: variable.leistungsgewichtKategorie,
        leistungsgewicht: variable.leistungsgewicht,
        variable,
      },
    };
  }

  onLanguageChange(): void {
    this.setDefaultColumns();

    if (this.additionalColumns === 'Markets') {
      this.addMarketColumns();
    }

    if (this.additionalColumns === 'Features') {
      this.addFeatureColumns();
    }

    if (this.additionalColumns === 'VAR') {
      this.addVarColumn();
    }

    if (this.additionalColumns === 'Snomed-CT') {
      this.addSnomedCtColumn();
    }

    if (this.additionalColumns === 'Leistungsgewicht') {
      this.addleistungsgewichtColumn();
    }
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.activatedRoute.data.subscribe((data) => {
      this.markets = data.markets;
    });
    this.loadData();
  }

  deleteVariable(row: TreeNode): void {
    this.confirm({
      header: this.translate('VARIABLES.DELETE_HEADER'),
      message: this.translate('VARIABLES.DELETE_MESSAGE', row.data),
      accept: () => {
        this.variableService.deleteCompleteVariable(row.data.id).subscribe({
          next: () => {
            this.showSuccessMessage(this.translate('VARIABLES.DELETE_SUCCESSFULLY', row.data));
            this.loadData();
          },
          error: () => {
            this.showErrorMessage(this.translate('VARIABLES.DELETE_FAILED', row.data));
          },
        });
      },
    });
  }

  createVariable(parent?: TreeNode, measure = false): void {
    const data = {
      measure,
      parentId: parent?.data.id,
    };
    this.navigate(['new'], data);
  }

  private addFeatureColumns(): void {
    this.columns.push(
      {
        field: 'isAssignmentCustomer',
        headerKey: 'VARIABLES.ASSIGNMENT_CUSTOMER',
        minWidth: '150px',
        filterType: 'boolean',
      },
      {
        field: 'isAssignmentEmployee',
        headerKey: 'VARIABLES.ASSIGNMENT_EMPLOYEE',
        minWidth: '150px',
        filterType: 'boolean',
      },
      {
        field: 'isRebooking',
        headerKey: 'VARIABLES.REBOOKING',
        minWidth: '150px',
        filterType: 'boolean',
      },
      {
        field: 'isPresence',
        headerKey: 'VARIABLES.PRESENCE',
        minWidth: '150px',
        filterType: 'boolean',
      },
      {
        field: 'isProductivity',
        headerKey: 'VARIABLES.PRODUCTIVITY',
        minWidth: '150px',
        filterType: 'boolean',
      },
      {
        field: 'isCostRelevant',
        headerKey: 'VARIABLES.COST_RELEVANT',
        minWidth: '150px',
        filterType: 'boolean',
      },
      {
        field: 'isTreatmentDuration',
        headerKey: 'VARIABLES.TREATMENT_DURATION',
        minWidth: '150px',
        filterType: 'boolean',
      }
    );
  }

  private addVarColumn(): void {
    this.columns.push({
      field: 'varHealthcareDocumentIds',
      headerKey: 'VARIABLES.VAR_HEALTHCARE_DOCUMENT_ID',
      minWidth: '180px',
    });
  }

  private addSnomedCtColumn(): void {
    this.columns.push({
      field: 'snomedCtIds',
      headerKey: 'VARIABLES.SNOMED_CT_ID',
      minWidth: '180px',
    });
  }

  private addleistungsgewichtColumn(): void {
    this.columns.push(
      {
        field: 'leistungsgewichtKategorie',
        headerKey: 'VARIABLES.LEISTUNGSGEWICHT_KATEGORIE',
        minWidth: '180px',
        filterType: 'number',
      },
      {
        field: 'leistungsgewicht',
        headerKey: 'VARIABLES.LEISTUNGSGEWICHT',
        minWidth: '180px',
        filterType: 'number',
      }
    );
  }

  private addMarketColumns(): void {
    this.markets.forEach((market) => {
      this.columns.push({
        field: 'market.' + market.id,
        header: market.names[this.getLanguageCaseSensitive()],
        minWidth: '150px',
        filterType: 'boolean',
      });
    });
  }

  private setDefaultColumns(): void {
    this.columns = [
      { field: 'tacsId', headerKey: 'VARIABLES.TACS_ID', width: '250px' },
      {
        field: 'name.' + this.getLanguageCaseSensitive(),
        headerKey: 'GLOBAL.NAME',
        width: '350px',
        route: '/main-data/variables/${id}',
      },
      {
        field: 'description.' + this.getLanguageCaseSensitive(),
        headerKey: 'GLOBAL.DESCRIPTION',
        minWidth: '200px',
      },
      {
        field: 'isMeasure',
        headerKey: 'VARIABLES.MEASURE',
        width: '170px',
        filterType: 'boolean',
      },
      {
        field: 'sortIndex',
        headerKey: 'GLOBAL.SORT_NUMBER',
        width: '170px',
        filterType: 'number',
      },
      { field: 'validFrom', headerKey: 'GLOBAL.GUELTIG_AB', width: '130px', filterType: 'date' },
      { field: 'validTo', headerKey: 'GLOBAL.GUELTIG_BIS', width: '130px', filterType: 'date' },
    ];
  }

  private updateTreeValue(variables: CompleteVariable[]): void {
    variables.sort((a, b) => ((a.tacsId ?? '') < (b.tacsId ?? '') ? -1 : 1));
    this.value = [];
    let parentNode: TreeNode | undefined;
    variables.forEach((variable) => {
      const newNode = VariablesComponent.createNode(variable);
      const newParentNode = this.determineParent(variable, parentNode);

      if (newParentNode) {
        if (newParentNode.expanded || newNode.expanded) {
          newParentNode.expanded = true;
          if (newParentNode.parent) {
            newParentNode.parent.expanded = true;
          }
        }
        newParentNode.children?.push(newNode);
        newNode.parent = newParentNode;
        if (parentNode !== newParentNode) {
          newParentNode.children?.sort((a, b) => a.data.variable.sortIndex - b.data.variable.sortIndex);
          parentNode = newParentNode;
        }
      } else {
        this.value.push(newNode);
      }
      parentNode = newNode;
    });
    this.markForCheck();
  }

  // noinspection JSMethodCanBeStatic
  private determineParent(variable: CompleteVariable, parentNode?: TreeNode): TreeNode | undefined {
    while (parentNode != null && !variable.tacsId?.startsWith(parentNode?.data.tacsId)) {
      parentNode = parentNode?.parent;
    }
    return parentNode;
  }

  private expandToHierarchy(n: number): void {
    this.expandToHierarchyRecursively(n, this.value);
    this.value = [...this.value];
  }

  private expandToHierarchyRecursively(n: number, nodes: TreeNode[]): void {
    nodes.forEach((node) => {
      node.expanded = node.data.tacsId.split('.').length - 1 < n;
      if (node.children && node.children.length > 0) {
        this.expandToHierarchyRecursively(n, node.children);
      }
    });
  }

  private loadData(): void {
    this.startLoading();
    this.markForCheck();
    this.onLanguageChange();
    this.variableService.getCompleteVariables().subscribe((variables) => {
      this.variables = variables;
      this.updateTreeValue(this.variables);
      this.loadingDone();
      this.markForCheck();
    });
  }

  private showColumns(additionalColumns: AdditionalColumns): void {
    this.additionalColumns = additionalColumns;
    this.onLanguageChange();
  }
}
