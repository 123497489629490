import { AnyValue } from '@shared/table/table.types';

export const groupFlatTransformation = {
  type: 'tacs:groupFlat',
  transform: (params: AnyValue) => {
    const config = params.config;
    const groupBy = config.groupBy;
    const flatByKey = config.flatByKey;
    const flatByValue = config.flatByValue;
    const result = new Map<string, AnyValue>();
    const rows = params.upstream.cloneRawData();
    const dimensions = new Set<string>();
    dimensions.add(groupBy);
    rows.forEach((row: AnyValue) => {
      const key = row[groupBy];
      if (!result.has(key)) {
        result.set(key, { total: 0 });
      }
      const r = result.get(key);
      r[groupBy] = key;
      r[row[flatByKey]] = row[flatByValue];
      r.total += row[flatByValue];
      dimensions.add(row[flatByKey]);
    });
    dimensions.add('total');
    return {
      data: [...result.values()],
      dimensions: [...dimensions],
    };
  },
};
