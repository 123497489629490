import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Betrieb } from '@entity/betrieb';
import { map } from 'rxjs/operators';
import { GroupedJobLog, ImportJobVerification, Job } from '@entity/job';
import { downloadBlob } from '../download';
import { AnyValue } from '@shared/table/table.types';
import { TableLazyLoadEvent } from 'primeng/table';
import { FilterMetadata } from 'primeng/api';

@Injectable({
  providedIn: 'root',
})
export class JobService {
  constructor(private httpClient: HttpClient) {}

  getJobs(tableLazyLoadEvent: TableLazyLoadEvent): Observable<{ jobs: Job[]; total: number }> {
    const filter = tableLazyLoadEvent.filters || {};

    const newFilter: { [p: string]: FilterMetadata } = {};
    Object.keys(filter).forEach((key) => {
      const filterValue = filter[key];
      if (filterValue) {
        if (filterValue && Array.isArray(filterValue)) {
          newFilter[key] = filterValue[0];
        } else {
          newFilter[key] = filterValue;
        }
      }
    });

    const params = new HttpParams()
      .append('first', tableLazyLoadEvent.first + '')
      .append('rows', tableLazyLoadEvent.rows + '')
      .append('sortField', tableLazyLoadEvent.sortField + '')
      .append('sortOrder', tableLazyLoadEvent.sortOrder + '')
      .append('filters', JSON.stringify(newFilter));

    return this.httpClient.get<Job[]>('api/1.0/jobs', { params, observe: 'response' }).pipe(
      map((response) => {
        return { jobs: response.body || [], total: parseInt(response.headers.get('X-Total-Count') || '0', 10) };
      })
    );
  }

  getGroupedJobLogs(jobId: number): Observable<GroupedJobLog[]> {
    return this.httpClient.get<GroupedJobLog[]>(`api/1.0/jobs/${jobId}/grouped`);
  }

  getXmlEntry(
    jobId: number,
    jobType: string
  ): Observable<{ xmlEntryTypes: string[]; xmlEntries: AnyValue; xmlEntryColumns: AnyValue }> {
    const params = new HttpParams().append('type', jobType);
    return this.httpClient.get<AnyValue[]>(`api/1.0/jobs/${jobId}/xml-entry`, { params }).pipe(
      map((data) => {
        /*
         Data Example 1:
         [
           {
             "LeistungsType": {
               "@xmlns": "http://tacs.ch/export",
               "LeistungGuid": "a4c76fdf-8641-4659-bdc2-016c3a679f2b",
               "PersonalId": "2833",
               "AnstellungsId": "0",
               "Datum": "2020-01-22",
               "VariablenCode": "2.04.05.000.0.000",
               "FallId": "18901",
               "EmpfangendeOrganisation": "11035.001.010",
               "Zeitwert": "0.00"
             }
           }
         ]

         Data Example 2:
         [
           {
             "XML": {
               "Message": "The message"
             }
           }
         ]
         */
        const result = { xmlEntries: {} as AnyValue, xmlEntryTypes: [] as string[], xmlEntryColumns: {} as AnyValue };
        if (data.length === 1 && data[0].XML) {
          result.xmlEntries.XML = [{ message: data[0].XML.Message }];
          result.xmlEntryColumns.XML = [{ field: 'message', header: 'XML' }];
          result.xmlEntryTypes = ['XML'];
        } else {
          data.forEach((d) => {
            const type = Object.keys(d)[0];
            if (!result.xmlEntries[type]) {
              result.xmlEntries[type] = [];
            }
            const element = d[type];
            result.xmlEntries[type].push(element);
          });
          result.xmlEntryTypes = Object.keys(result.xmlEntries);

          result.xmlEntryTypes.forEach((type) => {
            result.xmlEntryColumns[type] = Object.keys(result.xmlEntries[type][0])
              .filter((key) => key !== '@xmlns')
              .map((key) => ({
                field: key,
                header: key,
              }));
          });
        }
        return result;
      })
    );
  }

  downloadXml(id: number): Observable<AnyValue> {
    return this.httpClient.get(`api/1.0/jobs/${id}/download-xml`, { responseType: 'blob', observe: 'response' });
  }

  getBetriebe(): Observable<Betrieb[]> {
    return this.httpClient.get<Betrieb[]>('api/1.0/jobs/betriebe');
  }

  upload(clientId: string, files: FileList): Observable<unknown> {
    const formData = new FormData();
    formData.append('files', files[0]);
    formData.append('clientId', clientId);
    return this.httpClient.post('api/1.0/jobs/upload', formData);
  }

  getCustomer(jobId: number, caseId: string): Observable<GroupedJobLog[]> {
    const params = new HttpParams().append('caseId', caseId);
    return this.httpClient.get<GroupedJobLog[]>(`api/1.0/jobs/${jobId}/customer-case`, { params });
  }

  getEmployee(jobId: number, employeeId: string): Observable<GroupedJobLog[]> {
    const params = new HttpParams().append('employeeId', employeeId);
    return this.httpClient.get<GroupedJobLog[]>(`api/1.0/jobs/${jobId}/employee`, { params });
  }

  downloadCsv(jobId: number, fileName: string): void {
    this.httpClient
      .get(`api/1.0/jobs/${jobId}/csv`, { responseType: 'blob' })
      .subscribe((result: Blob) => downloadBlob(result, fileName));
  }

  getImportJobVerifications(): Observable<ImportJobVerification[]> {
    return this.httpClient.get<ImportJobVerification[]>('api/1.0/jobs/import-job-verifications');
  }

  updateImportJobVerifications(importJobVerifications: ImportJobVerification[]): Observable<ImportJobVerification[]> {
    return this.httpClient.put<ImportJobVerification[]>(
      'api/1.0/jobs/import-job-verifications',
      importJobVerifications
    );
  }

  rerunJob(jobId: number | undefined, rerunAllSubsequentJobs: boolean): Observable<unknown> {
    const params = new HttpParams().append('rerunAllSubsequentJobs', rerunAllSubsequentJobs.toString());
    return this.httpClient.post(`api/1.0/jobs/${jobId}/rerun`, null, { params });
  }
}
