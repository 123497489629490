<p-table
  #dt
  (onFilter)="onFilter($event)"
  (selectionChange)="selectionChange.emit($event)"
  *ngIf="visible"
  [columns]="columns"
  [dataKey]="dataKey"
  [loading]="loading"
  [paginator]="pagination"
  [rowTrackBy]="getTrackByFn(dataKey)"
  [rows]="rowsPerPage"
  [scrollable]="true"
  [selection]="selection"
  [sortField]="sortField"
  [sortOrder]="sortOrder"
  [value]="value"
  [virtualScrollDelay]="0"
  [virtualScrollItemSize]="49"
  [virtualScrollOptions]="{ numToleratedItems: 120 }"
  [virtualScroll]="!pagination"
  scrollHeight="flex"
  styleClass="flex-table p-datatable-sm p-datatable-gridlines p-datatable-striped">
  <ng-template pTemplate="caption">
    <div class="toolbar">
      <p-multiSelect
        (onChange)="frozenColumnsChange()"
        *ngIf="firstColumnFrozen && showFrozenColumnSelection"
        [(ngModel)]="frozenColumns"
        [options]="columns"
        [style]="{ minWidth: '300px' }"
        optionLabel="header"></p-multiSelect>

      <p-calendar
        (ngModelChange)="setFocusDate($event)"
        (onSelect)="setFocusDate($event)"
        *ngIf="hasFocusDate"
        [ngModelOptions]="{ updateOn: 'blur' }"
        [ngModel]="focusDate"
        [showButtonBar]="true"
        [showIcon]="true"
        [style]="{ width: '140px' }"
        [yearNavigator]="true"
        appendTo="body"
        dateFormat="dd.mm.yy"
        yearRange="2000:2030"></p-calendar>

      <p-dropdown
        (ngModelChange)="focusYearChange.emit($event)"
        *ngIf="hasFocusYear"
        [(ngModel)]="focusYear"
        [options]="focusYearRange"
        [style]="{ width: '140px' }"
        appScrollIntoView
        appendTo="body"></p-dropdown>

      <p-button
        (onClick)="button.onClick.emit($event)"
        *ngFor="let button of toolbarButtons"
        [disabled]="button.disabled"
        [icon]="button.icon"
        [label]="button.labelKey | translate"
        type="button"></p-button>

      <ng-container *ngIf="editable">
        <p-button
          (onClick)="save()"
          [disabled]="isSaveDisabled"
          [label]="'ACTIONS.SAVE' | translate"
          icon="fal fa-save"
          type="button"></p-button>
        <p-button
          (onClick)="cancel()"
          [disabled]="isCancelDisabled"
          [label]="'ACTIONS.CANCEL' | translate"
          icon="fal fa-times"
          type="button"></p-button>
        <p-button
          (onClick)="create()"
          *ngIf="isCreateAllowed"
          [disabled]="isCreateDisabled"
          [label]="createLabel | translate"
          icon="fal fa-plus"
          type="button"></p-button>
      </ng-container>

      <p-button
        (onClick)="clearSelection()"
        *ngIf="selectable"
        [disabled]="selection?.length === 0"
        [label]="'ACTIONS.CLEAR_SELECTION' | translate: { count: selection.length }"
        icon="fal fa-square"
        type="button"></p-button>

      <div class="spacer"></div>

      <p-button
        (onClick)="resetFilter()"
        [label]="'GLOBAL.RESET_FILTER' | translate"
        icon="fal fa-undo"
        iconPos="left"
        styleClass="p-button-outlined"
        type="button"></p-button>

      <p-button
        (onClick)="exportCSV(dt.filteredValue || dt.value)"
        icon="fal fa-file-alt"
        iconPos="left"
        label="CSV"
        styleClass="p-button-outlined"
        type="button"></p-button>

      <p-button
        (onClick)="exportExcel(dt.filteredValue || dt.value)"
        icon="fal fa-file-excel"
        iconPos="left"
        label="Excel"
        styleClass="p-button-outlined"
        type="button"></p-button>
    </div>
  </ng-template>
  <ng-template let-columns pTemplate="header">
    <ng-container *ngIf="headerGroups?.length">
      <tr *ngFor="let groups of headerGroups" style="height: 35px">
        <th
          *ngIf="selectable"
          [style.flex-basis]="'50px'"
          [style.min-width]="'50px'"
          pFrozenColumn
          style="flex: 0"></th>
        <th
          *ngFor="let group of groups"
          [colSpan]="group.colSpan"
          [frozen]="group.frozen ?? false"
          [style.flex-basis]="(group.width ?? group.minWidth) + 'px'"
          [style.flex-grow]="group.width ? 0 : group.colSpan"
          [style.min-width]="group.width ? group.width + 'px' : group.minWidth + 'px'"
          [style.position]="group.frozen ? null : 'relative'"
          pFrozenColumn>
          <p-checkbox
            (ngModelChange)="selectedColumnsChange($event, group)"
            *ngIf="columnSelectable && !group.frozen; else text"
            [label]="group.title"
            [ngModel]="columnSelection"
            [value]="group"
            appEllipsisTooltip
            class="header-ellipsis"></p-checkbox>
          <ng-template #text>
            <div appEllipsisTooltip class="header-ellipsis">{{ group.title }}</div>
          </ng-template>
        </th>
        <th
          *ngIf="actionColumnWidthInPx"
          [style.max-width]="actionColumnWidthInPx"
          [style.min-width]="actionColumnWidthInPx"
          [style.width]="actionColumnWidthInPx"></th>
      </tr>
    </ng-container>
    <tr style="height: 35px">
      <th
        *ngIf="selectable"
        [frozen]="frozenColumns.length > 0"
        pFrozenColumn
        style="max-width: 50px; min-width: 50px; width: 50px">
        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
      </th>
      <th
        *ngFor="let col of columns"
        [frozen]="isColumnFrozen(col)"
        [style.max-width]="col.width"
        [style.min-width]="col.minWidth || col.width"
        [style.position]="isColumnFrozen(col) ? null : 'relative'"
        [style.width]="col.width"
        pFrozenColumn>
        <p-checkbox
          (ngModelChange)="selectedColumnsChange($event, col)"
          *ngIf="columnSelectable && !isColumnFrozen(col) && !col.disableSelection; else text"
          [label]="col.header"
          [ngModel]="columnSelection"
          [value]="col"
          appEllipsisTooltip
          class="header-ellipsis"></p-checkbox>
        <ng-template #text>
          <div appEllipsisTooltip class="header-ellipsis">{{ col.header }}</div>
        </ng-template>
        <p-sortIcon
          (click)="dt.sort({ field: col.field })"
          [field]="col.field"
          [style]="{ position: 'absolute', right: '15px', top: '7px' }"></p-sortIcon>
      </th>
      <th
        *ngIf="actionColumnWidthInPx"
        [style.max-width]="actionColumnWidthInPx"
        [style.min-width]="actionColumnWidthInPx"
        [style.width]="actionColumnWidthInPx"></th>
    </tr>
    <tr style="height: 49px">
      <th *ngIf="selectable" [frozen]="frozenColumns.length > 0" pFrozenColumn style="max-width: 50px"></th>
      <th
        *ngFor="let col of columns"
        [frozen]="isColumnFrozen(col)"
        [style.max-width]="col.width"
        [style.min-width]="col.minWidth || col.width"
        [style.width]="col.width"
        pFrozenColumn>
        <ng-container [ngSwitch]="col.filterType">
          <ng-container *ngSwitchCase="'date'">
            <p-calendar
              (ngModelChange)="dt.filter($event, col.field, 'date')"
              [ngModel]="getDateFilter(col.field)"
              [showButtonBar]="true"
              appendTo="body"
              dateFormat="dd.mm.yy"></p-calendar>
          </ng-container>
          <ng-container *ngSwitchCase="'boolean'">
            <div style="width: 100%; text-align: center">
              <p-triStateCheckbox
                (onChange)="dt.filter($event.value, col.field, 'true')"
                [ngModel]="filters[col.field]?.value"></p-triStateCheckbox>
            </div>
          </ng-container>
          <ng-container *ngSwitchCase="'percent'">
            <p-inputNumber
              (onInput)="dt.filter($event.value ? toNumber($event) / 100 : null, col.field, 'equals')"
              [maxFractionDigits]="2"
              [max]="100"
              [minFractionDigits]="2"
              [min]="0"
              [ngModel]="filters[col.field]?.value"
              [showButtons]="false"
              [suffix]="'%'"></p-inputNumber>
          </ng-container>
          <ng-container *ngSwitchCase="'time'">
            <p-calendar
              (ngModelChange)="dt.filter($event, col.field, 'equals')"
              [ngModel]="getDateFilter(col.field)"
              [timeOnly]="true"
              appendTo="body"></p-calendar>
          </ng-container>
          <ng-container *ngSwitchCase="'dateRangeAssignment'">
            <div style="width: 100%; text-align: center">
              <p-triStateCheckbox
                (onChange)="dt.filter($event.value, col.field, 'hasValue')"
                [ngModel]="filters[col.field]?.value"></p-triStateCheckbox>
            </div>
          </ng-container>
          <ng-container *ngSwitchCase="'number'">
            <p-inputNumber
              (onInput)="dt.filter($event.value, col.field, 'equals')"
              [locale]="'de-CH'"
              [minFractionDigits]="1"
              [ngModel]="filters[col.field]?.value"
              [useGrouping]="false"></p-inputNumber>
          </ng-container>
          <ng-container *ngSwitchCase="'integer'">
            <p-inputNumber
              (onInput)="dt.filter($event.value, col.field, 'equals')"
              [locale]="'de-CH'"
              [maxFractionDigits]="0"
              [minFractionDigits]="0"
              [ngModel]="filters[col.field]?.value"
              [useGrouping]="false"></p-inputNumber>
          </ng-container>
          <ng-container *ngSwitchDefault>
            <ng-container *ngIf="col.options; else text">
              <p-dropdown
                (onChange)="dt.filter($event.value, col.field, 'equals')"
                [filterBy]="'label'"
                [filter]="true"
                [ngModel]="filters[col.field]?.value"
                [options]="col.options"
                [placeholder]="'-'"
                [showClear]="true"
                appScrollIntoView
                appendTo="body"></p-dropdown>
            </ng-container>
            <ng-template #text>
              <input
                (input)="dt.filter($any($event.target).value, col.field, 'contains')"
                [ngModel]="getFilterValue(col.field)"
                pInputText
                type="text" />
            </ng-template>
          </ng-container>
        </ng-container>
      </th>
      <th
        *ngIf="actionColumnWidthInPx"
        [style.max-width]="actionColumnWidthInPx"
        [style.min-width]="actionColumnWidthInPx"
        [style.width]="actionColumnWidthInPx"></th>
    </tr>
  </ng-template>
  <ng-template let-columns="columns" let-row let-rowData pTemplate="body">
    <tr style="height: 49px">
      <td
        *ngIf="selectable"
        [frozen]="frozenColumns.length > 0"
        pFrozenColumn
        style="max-width: 50px; min-width: 50px; width: 50px">
        <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
      </td>
      <td
        *ngFor="let col of columns"
        [frozen]="isColumnFrozen(col)"
        [ngClass]="{ selected: col.selected && dt.isSelected(rowData) }"
        [style.max-width]="col.width"
        [style.min-width]="col.minWidth || col.width"
        [style.width]="col.width"
        [style]="row['_style_' + col.field]"
        pFrozenColumn>
        <div
          *ngIf="col.filterType === 'html'"
          [innerHTML]="getData(row, '_html_' + col.field)"
          style="text-overflow: ellipsis; overflow: hidden"></div>
        <app-table-cell
          (valueChange)="update($event, row, col.field)"
          *ngIf="col.filterType !== 'html'"
          [changed]="row._changes && row._changes[col.field] !== undefined"
          [column]="col"
          [editable]="
            col.editable && ((col.onlyInitialModificationAllowed && row._new) || !col.onlyInitialModificationAllowed)
          "
          [error]="row._errors && row._errors[col.field]"
          [options]="col.options"
          [required]="col.required"
          [route]="getRouterLink(col.route, row)"
          [url]="getUrl(row, col.url)"
          [row]="row"
          [type]="col.filterType"
          [value]="getData(row, col.field)"></app-table-cell>
      </td>
      <td
        *ngIf="actionColumnWidthInPx"
        [style.max-width]="actionColumnWidthInPx"
        [style.min-width]="actionColumnWidthInPx"
        [style.width]="actionColumnWidthInPx">
        <ng-container *ngIf="editable">
          <p-button
            (onClick)="delete(row)"
            *ngIf="row.deletable === null || row.deletable === undefined || row.deletable === true"
            [label]="'ACTIONS.DELETE' | translate"
            [style]="{ 'margin-right': '0.5rem' }"
            icon="fal fa-trash"
            styleClass="p-button-danger"
            type="button"></p-button>
        </ng-container>
        <ng-container *ngFor="let button of actionButtons">
          <p-button
            (onClick)="button.onClick.emit({ event: $event, row: row })"
            *ngIf="(!button.displayIf || row[button.displayIf]) && (!button.displayIfNot || !row[button.displayIfNot])"
            [disabled]="button.disabled"
            [icon]="button.icon"
            [label]="button.labelKey | translate"
            [styleClass]="'p-button-' + button.type"
            [style]="{ 'margin-right': '0.5rem' }"
            type="button"></p-button>
        </ng-container>
      </td>
    </tr>
  </ng-template>

  <ng-template *ngIf="footer" let-columns pTemplate="footer">
    <tr>
      <td
        *ngFor="let col of columns"
        [style.max-width]="col.width"
        [style.min-width]="col.minWidth || col.width"
        [style.width]="col.width">
        <ng-container *ngIf="col.footerKey">
          {{ col.footerKey | translate }}
        </ng-container>
        <ng-container *ngIf="col.footer">
          {{ col.footer() }}
        </ng-container>
      </td>
    </tr>
  </ng-template>
</p-table>
