import { ChangeDetectionStrategy, Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { User } from '@entity/user';
import { Context } from '@service/context.service';
import { UserService } from '@service/user.service';
import { AbstractContentComponent } from '../abstract-content.component';
import { Column } from '@shared/table/column';
import { OAuthService } from 'angular-oauth2-oidc';
import { ActionButtonDirective } from '@shared/table/action-button.directive';

import { ToolbarButtonDirective } from '@shared/table/toolbar-button.directive';
import { TableComponent } from '@shared/table/table.component';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TableComponent, ToolbarButtonDirective, ActionButtonDirective],
})
export class UsersComponent extends AbstractContentComponent implements OnInit, OnDestroy {
  columns: Column[] = [];
  users: User[] = [];

  constructor(
    private userService: UserService,
    private oAuthService: OAuthService,
    injector: Injector
  ) {
    super(injector, Context.Anwenderbetrieb, 'MENU.USERS');
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.setColumns();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  onAnwenderbetriebIdChange(): void {
    this.loadUsers();
  }

  onLanguageChange(): void {
    this.setColumns();
  }

  loadUsers(): void {
    const anwenderbetriebId = this.getAnwenderbetriebId();
    if (anwenderbetriebId == null) {
      this.users = [];
      this.markForCheck();
      return;
    }
    this.startLoading();
    this.userService.getUsers(anwenderbetriebId).subscribe((data) => {
      this.users = data;
      this.markForCheck();
      this.loadingDone();
    });
  }

  createUser(): void {
    this.navigate(['/users/new']);
  }

  deleteUser(user: User): void {
    this.confirm({
      header: this.translate('USERS.DELETE_HEADER'),
      message: this.translate('USERS.DELETE_MESSAGE', user),
      accept: () => {
        if (!user.id) return;
        this.userService.delete(user.id).subscribe({
          next: () => {
            this.showSuccessMessage(this.translate('USERS.DELETE_SUCCESSFULLY', user));
            this.loadUsers();
          },
          error: () => {
            this.showErrorMessage(this.translate('USERS.DELETE_FAILED', user));
          },
        });
      },
    });
  }

  impersonate(user: User): void {
    sessionStorage.clear();
    window.location.href =
      this.oAuthService.issuer +
      `/impersonation?username=${encodeURI(user.username ?? '')}&redirect=${encodeURI(window.location.href)}`;
  }

  private setColumns(): void {
    this.columns = [
      { field: 'username', headerKey: 'USERS.USERNAME', width: '300px', route: '/users/${id}' },
      { field: 'firstName', headerKey: 'GLOBAL.FIRST_NAME', width: '200px' },
      { field: 'lastName', headerKey: 'GLOBAL.LAST_NAME', width: '200px' },
      { field: 'email', headerKey: 'GLOBAL.EMAIL', width: '300px' },
      {
        field: 'role.name',
        headerKey: 'USERS.ROLE',
        minWidth: '200px',
        options: [
          { value: 'KIS-partner', label: this.translate('ROLES.KIS-partner') },
          { value: 'RodixAdmin', label: this.translate('ROLES.RodixAdmin') },
          { value: 'Softwarepartner', label: this.translate('ROLES.Softwarepartner') },
          { value: 'SuperUserKomplett', label: this.translate('ROLES.SuperUserKomplett') },
          { value: 'User', label: this.translate('ROLES.User') },
        ],
      },
    ];
    if (this.isRodixAdmin()) {
      this.columns.push({ field: 'betaTester', headerKey: 'USERS.BETA_TESTER', width: '130px', filterType: 'boolean' });
    }
  }
}
