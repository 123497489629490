import { Injectable } from '@angular/core';
import { OAuthResourceServerErrorHandler, OAuthService } from 'angular-oauth2-oidc';
import { HttpResponse } from '@angular/common/http';
import { from, Observable } from 'rxjs';

@Injectable()
export class CustomOAuthResourceServerErrorHandler implements OAuthResourceServerErrorHandler {
  constructor(private oAuthService: OAuthService) {}

  handleError(err: HttpResponse<unknown>): Observable<unknown> {
    if (err.status === 401) {
      return from(this.oAuthService.loadDiscoveryDocumentAndLogin());
    }
    throw err;
  }
}
