import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { User } from '@entity/user';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private httpClient: HttpClient) {}

  updateUser(user: User): Observable<User> {
    return this.httpClient.put<User>(`api/1.0/users/${user.id}`, user);
  }

  createUser(user: User): Observable<User> {
    return this.httpClient.post<User>('api/1.0/users', user);
  }

  createOrUpdateUser(user: User): Observable<User> {
    return user.id ? this.updateUser(user) : this.createUser(user);
  }

  getUser(id: string | number): Observable<User> {
    return this.httpClient.get<User>(`api/1.0/users/${id}`);
  }

  getUsers(anwenderbetriebId: number): Observable<User[]> {
    const params = new HttpParams().set('anwenderbetriebId', anwenderbetriebId + '');
    return this.httpClient.get<User[]>('api/1.0/users', { params });
  }

  delete(id: string): Observable<unknown> {
    return this.httpClient.delete(`api/1.0/users/${id}`);
  }
}
