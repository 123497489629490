import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { AppComponent } from '../app.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { SelectItemGroup } from 'primeng/api';
import { AnwenderbetriebService } from '@service/anwenderbetrieb.service';
import { Anwenderbetrieb } from '@entity/anwenderbetrieb';
import { Mandant } from '@entity/mandant';
import { Context, ContextService } from '@service/context.service';
import { Subscription } from 'rxjs';
import { Language } from '@entity/language';
import { HttpClient } from '@angular/common/http';
import * as Sentry from '@sentry/angular-ivy';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ScrollIntoViewDirective } from '@shared/scroll-into-view.directive';
import { DropdownModule } from 'primeng/dropdown';
import { NgClass } from '@angular/common';
import { RippleModule } from 'primeng/ripple';

@Component({
  selector: 'app-topbar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    RippleModule,
    DropdownModule,
    ScrollIntoViewDirective,
    FormsModule,
    ReactiveFormsModule,
    NgClass,
    TranslateModule,
  ],
})
export class TopBarComponent implements OnInit, OnDestroy {
  languages: string[] = [];
  currentLanguage = '';
  username = '';
  changePasswordUrl = '';
  twoFactorAuthUrl = '';
  anwenderbetriebOptions: SelectItemGroup[] = [];
  selectedTenant = new FormControl<SelectedTenant | null | undefined>(null);
  context: Context = Context.Global;
  private anwenderbetriebe: Anwenderbetrieb[] = [];
  private contextSubscription?: Subscription;

  constructor(
    public app: AppComponent,
    private oAuthService: OAuthService,
    private changeDetectorRef: ChangeDetectorRef,
    private translateService: TranslateService,
    private anwenderbetriebService: AnwenderbetriebService,
    private httpClient: HttpClient,
    private contextService: ContextService
  ) {}

  ngOnInit(): void {
    const identityClaims = this.oAuthService.getIdentityClaims();
    this.username = identityClaims.name;
    this.changePasswordUrl = identityClaims.iss + '/account/password/?referrer=dashboard';
    this.twoFactorAuthUrl = identityClaims.iss + '/account/totp/?referrer=dashboard';
    this.languages = this.translateService.langs;
    this.currentLanguage = this.translateService.currentLang;
    this.anwenderbetriebService.getAllAnwenderbetriebe(true).subscribe((data) => {
      this.anwenderbetriebe = data;
      this.updateTenantDropdown();
      this.changeDetectorRef.markForCheck();
    });

    this.contextSubscription = this.contextService.getContext().subscribe((context) => {
      this.context = context;
      this.updateTenantDropdown();
      this.changeDetectorRef.markForCheck();
    });
  }

  logout(): void {
    Sentry.close().then(() => {
      this.oAuthService.logOut();
    });
  }

  changeLanguage(language: string): void {
    this.currentLanguage = language;
    this.httpClient
      .post('/api/1.0/config/set-language', {
        language: language.substring(0, 1).toUpperCase() + language.substring(1).toLowerCase(),
      })
      .subscribe(() => {
        this.oAuthService.silentRefresh().then(
          () => {
            this.translateService.use(language).subscribe(() => this.changeDetectorRef.markForCheck());
          },
          () => {
            this.logout();
          }
        );
      });
  }

  tenantSelected(): void {
    if (!this.selectedTenant || !this.selectedTenant.value) {
      this.contextService.setAnwenderbetriebId(null);
      this.contextService.setMandantId(undefined);
      return;
    }
    const tenant: SelectedTenant = this.selectedTenant.value;
    this.contextService.setAnwenderbetriebId(tenant.anwenderbetriebId);
    this.contextService.setMandantId(tenant.mandant?.id);
    if (tenant.mandant) {
      this.setLanguage(tenant.mandant);
    } else {
      this.contextService.setLanguages(['De']);
    }
  }

  isGlobal(): boolean {
    return this.context === Context.Global;
  }

  isMandant(): boolean {
    return this.context === Context.Mandant;
  }

  ngOnDestroy(): void {
    this.contextSubscription?.unsubscribe();
  }

  private updateTenantDropdown(): void {
    if (this.context === Context.Mandant) {
      this.anwenderbetriebOptions = this.anwenderbetriebe.map((a) => ({
        label: a.bezeichnung ?? '',
        value: { anwenderbetrieb: a.id, id: a.id },
        items: a.mandant.map((m) => ({
          label: m.bezeichnung,
          value: { mandant: m, anwenderbetriebId: m.anwenderbetriebsId, id: `${m.anwenderbetriebsId}-${m.id}` },
        })),
      }));
      const mandant = this.anwenderbetriebOptions
        .reduce((m: SelectedTenant[], a: SelectItemGroup) => m.concat(a.items.map((i) => i.value)), [])
        .find((m: SelectedTenant) => m.mandant?.id === this.contextService.getMandantIdInstant());
      setTimeout(() => this.selectedTenant.setValue(mandant));
    } else if (this.context === Context.Anwenderbetrieb) {
      this.anwenderbetriebOptions = this.anwenderbetriebe.map((a) => ({
        label: a.bezeichnung ?? '',
        value: {
          mandant: a.mandant.length > 0 ? a.mandant[0] : null,
          anwenderbetriebId: a.id,
          id: `${a.id}-${a.mandant.length > 0 ? a.mandant[0].id : ''}`,
        },
        items: [],
      }));
      const anwenderbetrieb = this.anwenderbetriebOptions.find(
        (s) => s.value.anwenderbetriebId === this.contextService.getAnwenderbetriebIdInstant()
      )?.value;
      setTimeout(() => this.selectedTenant.setValue(anwenderbetrieb));
    }
  }

  private setLanguage(mandant: Mandant): void {
    this.contextService.setLanguages(
      mandant.mandantSprache
        .map((m) => m.sprache.kuerzel.split('-')[0])
        .map((l) => l.substring(0, 1).toUpperCase() + l.substring(1).toLowerCase()) as Language[]
    );
  }
}

export interface SelectedTenant {
  mandant?: Mandant;
  anwenderbetriebId?: number;
}
