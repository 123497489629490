import { ChangeDetectionStrategy, Component, Injector, OnInit } from '@angular/core';
import { Column } from '@shared/table/column';
import { TABLE_SERVICE } from '@shared/table/table-service';
import { JobControllingTableService } from './job-controlling-table.service';
import { AbstractContentComponent } from '../abstract-content.component';
import { Context } from '@service/context.service';
import { SelectItem } from 'primeng/api';
import { TableComponent } from '@shared/table/table.component';

@Component({
  selector: 'app-job-controlling',
  templateUrl: './job-controlling.component.html',
  styleUrls: ['./job-controlling.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: TABLE_SERVICE,
      useClass: JobControllingTableService,
    },
  ],
  standalone: true,
  imports: [TableComponent],
})
export class JobControllingComponent extends AbstractContentComponent implements OnInit {
  columns!: Column[];
  private clientOptions!: SelectItem[];

  constructor(injector: Injector) {
    super(injector, Context.Global, 'MENU.JOB_CONTROLLING');
  }

  ngOnInit(): void {
    this.activatedRoute.data.subscribe((data) => (this.clientOptions = data.clientOptions));
    this.setColumns();
  }

  private setColumns(): void {
    this.columns = [
      {
        field: 'clientId',
        minWidth: '400px',
        headerKey: 'JOB_CONTROLLING.CLIENT',
        options: this.clientOptions,
        editable: true,
        validator: (value) => (!value ? 'required' : undefined),
      },
      {
        field: 'dailyExportWithReplace',
        width: '250px',
        filterType: 'boolean',
        headerKey: 'JOB_CONTROLLING.DAILY_EXPORT_WITH_REPLACE',
        editable: true,
      },
      {
        field: 'dailyExport',
        width: '150px',
        filterType: 'boolean',
        headerKey: 'JOB_CONTROLLING.DAILY_EXPORT',
        editable: true,
      },
      {
        field: 'monthlyExport',
        width: '150px',
        filterType: 'boolean',
        headerKey: 'JOB_CONTROLLING.MONTHLY_EXPORT',
        editable: true,
      },
      {
        field: 'maxMonthlyExportDelay',
        width: '250px',
        filterType: 'number',
        headerKey: 'JOB_CONTROLLING.MAX_MONTHLY_EXPORT_DELAY',
        editable: true,
      },
      {
        field: 'warningThreshold',
        width: '150px',
        filterType: 'number',
        headerKey: 'JOB_CONTROLLING.WARNING_THRESHOLD',
        editable: true,
      },
      {
        field: 'errorThreshold',
        width: '150px',
        filterType: 'number',
        headerKey: 'JOB_CONTROLLING.ERROR_THRESHOLD',
        editable: true,
      },
    ];
  }
}
