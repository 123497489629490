import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';

@Component({
  selector: 'app-form-button-bar',
  templateUrl: './form-button-bar.component.html',
  styleUrls: ['./form-button-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ButtonModule, TranslateModule],
})
export class FormButtonBarComponent implements OnChanges, OnDestroy {
  @Input() form!: FormGroup;
  @Input() cancelRoute = ['..'];
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onCancel = new EventEmitter();
  @Input() offset = true;
  @Input() saveDisabled = false;
  @HostBinding('class.col-12') grid = true;
  private subscription?: Subscription;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.form) {
      this.subscription?.unsubscribe();
      this.subscription = this.form?.statusChanges.subscribe(() => {
        this.changeDetectorRef.markForCheck();
      });
    }
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  cancel(): void {
    if (this.onCancel.observed) {
      this.onCancel.emit();
    } else {
      void this.router.navigate(this.cancelRoute, { relativeTo: this.activatedRoute });
    }
  }
}
