export interface OrganizationCategoryGroup extends Node {
  organizationCategories: OrganizationCategory[];
}

export interface OrganizationCategory extends Node {
  organizations: Organization[];
}

export interface Node {
  deletable: boolean;
  id: number;
  name: string;
  validFrom: Date;
  validTo: Date;
}

export interface Organization extends Node {
  employments: Node[];
  personnelCategories: Node[];
}

export type AssignmentType = 'organizationAssignments' | 'personnelCategoryAssignments' | 'employmentAssignments';
type AssignmentTypeMap = { [K in AssignmentType]: { [id: string]: Assignment[] } };

export interface AssignmentElement extends AssignmentTypeMap {
  isMeasure?: boolean;
  validFrom?: Date;
  validTo?: Date;
  id: number;
  name?: string;
}

export interface Assignment {
  id: number;
  from: Date;
  to?: Date;
}

export enum AssignmentRootType {
  VARIABLE,
  STATISTICS_CODE,
  BENEFICIARY,
}
