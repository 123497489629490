<app-table filename="MENU.USERS" [actionColumnWidth]="400" [columns]="columns" [loading]="loading" [value]="users">
  <ng-template appToolbarButton icon="fal fa-plus" labelKey="USERS.CREATE_USER" (onClick)="createUser()"></ng-template>

  @if (isRodixAdmin()) {
    <ng-template
      appActionButton
      icon="fal fa-people-arrows"
      labelKey="USERS.IMPERSONATE"
      type="warning"
      (onClick)="impersonate($event.row)"></ng-template>
  }

  <ng-template
    appActionButton
    icon="fal fa-trash"
    labelKey="USERS.DELETE_HEADER"
    type="danger"
    (onClick)="deleteUser($event.row)"></ng-template>
</app-table>
