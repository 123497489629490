import { Injectable } from '@angular/core';
import { Customer } from '@entity/customer';
import { ContextService } from '@service/context.service';
import { Observable } from 'rxjs';
import { TreeNode } from 'primeng/api';
import { CustomerCase } from '@entity/customer-case';
import { CustomerCaseMutation } from '@entity/customer-case-mutation';
import { TableService } from '@shared/table/table-service';
import { map } from 'rxjs/operators';
import { CustomerService } from '@service/customer.service';
import { AnyValue } from '@shared/table/table.types';
import { TreeTableLazyLoadEvent } from 'primeng/treetable';

@Injectable()
export class CustomersTreeTableService extends TableService<TreeNode> {
  constructor(
    private customerService: CustomerService,
    private contextService: ContextService
  ) {
    super();
  }

  private static toTreeNode(customer: Customer): TreeNode {
    return {
      data: {
        ...customer,
        customerCases: null,
      },
      children: customer.customerCases.map((customerCase: CustomerCase) => ({
        data: {
          ...customerCase,
          customerCaseMutations: null,
        },
        children: customerCase.customerCaseMutations.map((customerCaseMutation) => ({
          data: customerCaseMutation,
        })),
      })),
    };
  }

  private static toEntity(treeNode: TreeNode): Customer {
    return {
      ...treeNode.data,
      customerCases: treeNode.children?.map(
        (customerCase: AnyValue) =>
          ({
            ...customerCase.data,
            customerCaseMutations: customerCase.children?.map(
              (customerCaseMutation: AnyValue) =>
                ({
                  ...customerCaseMutation.data,
                }) as CustomerCaseMutation
            ),
          }) as CustomerCase
      ),
    } as Customer;
  }

  getData(
    focusDateOrYear?: Date | number,
    treeTableLazyLoadEvent?: TreeTableLazyLoadEvent
  ): Observable<{ entities: TreeNode[]; total: number }> {
    return this.customerService.list(this.contextService.getMandantIdInstant(), undefined, treeTableLazyLoadEvent).pipe(
      map((response) => ({
        entities: response.entities.map(CustomersTreeTableService.toTreeNode),
        total: response.total,
      }))
    );
  }

  create(e: TreeNode): Observable<TreeNode> {
    return this.customerService
      .create(this.contextService.getMandantIdInstant(), CustomersTreeTableService.toEntity(e))
      .pipe(map(CustomersTreeTableService.toTreeNode));
  }

  remove(e: TreeNode): Observable<unknown> {
    const entity = CustomersTreeTableService.toEntity(e);
    return this.customerService.delete(this.contextService.getMandantIdInstant(), entity.customerId);
  }

  update(e: TreeNode): Observable<TreeNode> {
    const customer = CustomersTreeTableService.toEntity(e);
    return this.customerService
      .update(this.contextService.getMandantIdInstant(), customer.customerId, customer)
      .pipe(map(CustomersTreeTableService.toTreeNode));
  }

  createEntry(tree: TreeNode[], parent?: TreeNode | null): TreeNode {
    if (!parent) {
      return {
        data: {
          customerId: this.getNextId(tree, 'customerId'),
        },
        expanded: true,
        children: [
          {
            data: {
              caseId: 1,
            },
          },
        ],
      };
    } else if (parent.data.customerId) {
      return {
        data: {
          caseId: this.getNextId(parent.children ?? [], 'caseId'),
        },
        children: [],
      };
    } else {
      return {
        data: {},
      };
    }
  }
}
