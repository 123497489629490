@for (child of root ? item : $any(item).items; track child; let i = $index) {
  @if (child.visible !== false) {
    <li [class]="child.badgeStyleClass" [ngClass]="{ 'active-menuitem': isActive(i), separator: child.separator }">
      @if (!child.routerLink) {
        <a
          pRipple
          (click)="itemClick($event, child, i)"
          [href]="child.url || '#'"
          [ngClass]="child.styleClass"
          [attr.tabindex]="!visible ? '-1' : null"
          [attr.target]="child.target">
          <i [ngClass]="child.icon"></i>
          <span class="label-max-length" translate>{{ child.label }}</span>
          @if (child.items) {
            <i class="fal fa-fw fa-angle-down layout-menuitem-toggler"></i>
          }
          @if (child.badge) {
            <span class="menuitem-badge">{{ child.badge }}</span>
          }
        </a>
      }
      @if (child.routerLink) {
        <a
          pRipple
          routerLinkActive="active-menuitem-routerlink"
          (click)="itemClick($event, child, i)"
          [routerLinkActiveOptions]="{ exact: false }"
          [routerLink]="child.routerLink"
          [attr.tabindex]="!visible ? '-1' : null"
          [attr.target]="child.target">
          <i [ngClass]="child.icon"></i>
          <span class="label-max-length" translate>{{ child.label }}</span>
          @if (child.items) {
            <i class="fal fa-fw fa-angle-down"></i>
          }
          @if (child.badge) {
            <span class="menuitem-badge">{{ child.badge }}</span>
          }
        </a>
      }
      <div class="layout-menu-tooltip">
        <div class="layout-menu-tooltip-arrow"></div>
        <div class="layout-menu-tooltip-text" translate>{{ child.label }}</div>
      </div>
      @if (child.items) {
        <ul
          [item]="child"
          [parentActive]="isActive(i)"
          [visible]="isActive(i)"
          app-submenu
          [@children]="isActive(i) ? 'visibleAnimated' : 'hiddenAnimated'"></ul>
      }
    </li>
  }
}
