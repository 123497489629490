import { ChangeDetectionStrategy, Component, Injector, OnInit } from '@angular/core';
import { AbstractContentComponent } from '../abstract-content.component';
import { Context } from '@service/context.service';
import { PersonnelCategory, PersonnelCategoryEntry } from '@entity/personnel-category';
import { Column } from '@shared/table/column';
import { MenuItem, TreeNode } from 'primeng/api';
import { PersonnelCategoryEntriesComponent } from './personnel-category-entries/personnel-category-entries.component';
import { formatDate } from '@angular/common';
import { forkJoin } from 'rxjs';
import { tap } from 'rxjs/operators';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { formatTime } from '../util';
import { PersonnelCategoryService } from '@service/personnel-category.service';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { FormDateInputComponent } from '@shared/form-date-input/form-date-input.component';
import { FormNumberInputComponent } from '@shared/form-number-input/form-number-input.component';
import { FormSwitchComponent } from '@shared/form-switch/form-switch.component';
import { DialogModule } from 'primeng/dialog';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { ActionButtonDirective } from '@shared/table/action-button.directive';
import { ToolbarButtonDirective } from '@shared/table/toolbar-button.directive';
import { TreeTableComponent } from '@shared/table/tree-table.component';

@Component({
  selector: 'app-personnel-categories',
  templateUrl: './personnel-categories.component.html',
  styleUrls: ['./personnel-categories.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TreeTableComponent,
    ToolbarButtonDirective,
    ActionButtonDirective,
    TieredMenuModule,
    DialogModule,
    FormsModule,
    ReactiveFormsModule,
    FormSwitchComponent,
    FormNumberInputComponent,
    FormDateInputComponent,
    ButtonModule,
    TranslateModule,
  ],
})
export class PersonnelCategoriesComponent extends AbstractContentComponent implements OnInit {
  value: TreeNode[] = [];
  selection: TreeNode[] = [];
  columns: Column[] = [];
  focusDate: Date = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
  deactivateEnabled = false;
  activateEnabled = false;
  displayActivateDialog = false;
  form?: FormGroup<{
    inOfficialStaffingPlan: FormControl<boolean>;
    weightingStaffingPlan: FormControl<number | null>;
    targetDailyValue: FormControl<Date | null>;
    validFrom: FormControl<Date>;
  }>;
  hierarchyMenuModel: MenuItem[] = [
    { label: this.translate('PERSONNEL_CATEGORY.PERSONNEL_CATEGORY_GROUP'), command: () => this.expandToHierarchy(1) },
    { label: this.translate('PERSONNEL_CATEGORY.PERSONNEL_CATEGORY_TYPE'), command: () => this.expandToHierarchy(2) },
    { label: this.translate('PERSONNEL_CATEGORY.PERSONNEL_CATEGORY'), command: () => this.expandToHierarchy(3) },
  ];

  constructor(
    private personnelCategoryService: PersonnelCategoryService,
    private fb: FormBuilder,
    injector: Injector
  ) {
    super(injector, Context.Mandant, 'MENU.PERSONNEL_CATEGORIES');
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.onLanguageChange();
  }

  onLanguageChange(): void {
    this.columns = [
      { field: 'tacsId', headerKey: 'GLOBAL.ID', width: '150px' },
      {
        field: 'description.' + this.getLanguageCaseSensitive(),
        headerKey: 'GLOBAL.NAME',
        minWidth: '200px',
      },
      {
        field: 'acronym',
        headerKey: 'GLOBAL.ACRONYM',
        width: '130px',
      },
      { field: 'active', headerKey: 'GLOBAL.ACTIVE', width: '130px', filterType: 'boolean' },
      {
        field: 'inOfficialStaffingPlan',
        headerKey: 'PERSONNEL_CATEGORY.IN_OFFICIAL_STAFFING_PLAN',
        width: '150px',
        filterType: 'boolean',
      },
      {
        field: 'weightingStaffingPlan',
        headerKey: 'PERSONNEL_CATEGORY.WEIGHTING_STAFFING_PLAN',
        width: '150px',
        filterType: 'percent',
      },
      {
        field: 'targetDailyValue',
        headerKey: 'PERSONNEL_CATEGORY.TARGET_DAILY_VALUE',
        width: '150px',
        filterType: 'time',
      },
      { field: 'validFrom', headerKey: 'GLOBAL.GUELTIG_AB', width: '130px', filterType: 'date' },
      { field: 'validTo', headerKey: 'GLOBAL.GUELTIG_BIS', width: '130px', filterType: 'date' },
    ];
  }

  showDeactivate(): void {
    const details = `<ul>${this.selection
      .map((d) => `<li>${d.data.tacsId} - ${d.data.description[this.getLanguageCaseSensitive()]}</li>`)
      .join('')}</ul>`;
    const date = formatDate(this.focusDate, 'dd.MM.yyyy', 'en-US');
    this.confirm({
      header: this.translate('PERSONNEL_CATEGORY.DEACTIVATE_SELECTED'),
      message: this.translate('PERSONNEL_CATEGORY.DEACTIVATE_SELECTED_TEXT', { date }) + details,
      accept: () => {
        const observables = [];
        for (const node of this.selection) {
          const entry = node.data.entries.filter((e: PersonnelCategoryEntry) => this.isFocused(e))[0];
          observables.push(
            this.personnelCategoryService.updatePersonnelCategoryEntry(this.getMandantId(), node.data.id, {
              ...entry,
              validTo: this.focusDate,
            })
          );
        }
        forkJoin(observables)
          .pipe(tap(() => this.onMandantIdChange(this.getMandantId())))
          .subscribe({
            next: () => {
              this.showSuccessMessage();
              this.selection = [];
            },
            error: () => this.showErrorMessage(),
          });
      },
    });
  }

  showDialog(row: TreeNode): void {
    const ref = this.dialogService.open(PersonnelCategoryEntriesComponent, {
      data: row.data,
      height: '90%',
      width: '1350px',
    });
    ref.onClose.subscribe((data) => {
      if (data) {
        row.data.entries = data;
        this.updateEntries(this.value);
        this.value = [...this.value];
      }
      this.markForCheck();
    });
  }

  saveActivate(): void {
    const formValue = this.form?.getRawValue();
    if (!formValue || formValue.weightingStaffingPlan == null) {
      return;
    }
    const entry = {
      inOfficialStaffingPlan: formValue.inOfficialStaffingPlan,
      weightingStaffingPlan: formValue.weightingStaffingPlan / 100,
      targetDailyValue: formatTime(formValue.targetDailyValue),
      validFrom: this.focusDate,
    } as PersonnelCategoryEntry;
    const observables = this.selection.map((node) =>
      this.personnelCategoryService.addPersonnelCategoryEntry(this.getMandantId(), node.data.id, entry)
    );
    forkJoin(observables)
      .pipe(tap(() => this.onMandantIdChange(this.getMandantId())))
      .subscribe({
        next: () => {
          this.showSuccessMessage();
          this.displayActivateDialog = false;
          this.selection = [];
        },
        error: () => this.showErrorMessage(),
      });
  }

  showActivate(): void {
    this.displayActivateDialog = true;
    this.form = this.fb.nonNullable.group({
      inOfficialStaffingPlan: [false, Validators.required],
      weightingStaffingPlan: new FormControl<number | null>(null, {
        validators: Validators.compose([Validators.required, Validators.min(0), Validators.max(100)]),
      }),
      targetDailyValue: new FormControl<Date | null>(null, {
        validators: Validators.compose([Validators.required, Validators.min(0), Validators.max(24)]),
      }),
      validFrom: new FormControl(
        { value: this.focusDate, disabled: true },
        { validators: Validators.required, nonNullable: true }
      ),
    });
  }

  focusDateChange($event: Date): void {
    this.focusDate = $event;
    this.updateEntries(this.value);
    this.selectionChange(this.selection);
  }

  updateEntries(tree: TreeNode[]): void {
    tree.forEach((node) => {
      if (node.children && node.children.length > 0) {
        this.updateEntries(node.children);
      }
      const entry = node.data.entries?.filter((e: PersonnelCategoryEntry) => this.isFocused(e))[0];
      node.data.inOfficialStaffingPlan = entry?.inOfficialStaffingPlan;
      node.data.targetDailyValue = entry?.targetDailyValue;
      node.data.weightingStaffingPlan = entry?.weightingStaffingPlan;
      node.data.validFrom = entry?.validFrom;
      node.data.validTo = entry?.validTo;
      node.data.active = !!entry;
    });
  }

  selectionChange(selection: TreeNode[]): void {
    if (selection.length === 0) {
      this.deactivateEnabled = false;
      this.activateEnabled = false;
      return;
    }

    this.deactivateEnabled = true;
    this.activateEnabled = true;

    for (const node of selection) {
      if (!node.data.entries || node.data.entries.length === 0) {
        this.deactivateEnabled = false;
        break;
      }
      const focusedEntry = node.data.entries.filter((e: PersonnelCategoryEntry) => this.isFocused(e))[0];
      if (!focusedEntry || focusedEntry.validTo) {
        this.deactivateEnabled = false;
      }
      if (focusedEntry) {
        this.activateEnabled = false;
      }
    }
  }

  protected onMandantIdChange(id: number | null): void {
    if (!id) {
      this.value = [];
      return;
    }

    this.startLoading();
    this.personnelCategoryService.getPersonnelCategories(id).subscribe((personnelCategories) => {
      this.value = [];
      let parentNode: TreeNode | undefined;
      personnelCategories.sort((a, b) => (a.tacsId < b.tacsId ? -1 : 1));
      personnelCategories.forEach((personnelCategory) => {
        const newNode = this.createNode(personnelCategory);
        parentNode = this.determineParent(personnelCategory, parentNode);

        if (parentNode) {
          parentNode.children?.push(newNode);
          newNode.parent = parentNode;
        } else {
          this.value.push(newNode);
        }
        parentNode = newNode;
      });
      this.markForCheck();
      this.loadingDone();
    });
  }

  // noinspection JSMethodCanBeStatic
  private determineParent(personnelCategory: PersonnelCategory, parentNode?: TreeNode): TreeNode | undefined {
    while (parentNode != null && !personnelCategory.tacsId.startsWith(parentNode?.data.tacsId)) {
      parentNode = parentNode?.parent;
    }
    return parentNode;
  }

  private createNode(d: PersonnelCategory): TreeNode {
    const focusedEntry = d.entries.filter((e) => this.isFocused(e))[0];
    return {
      children: [],
      expanded: true,
      data: {
        id: d.id,
        tacsId: d.tacsId,
        description: d.description,
        acronym: d.acronym,
        inOfficialStaffingPlan: focusedEntry?.inOfficialStaffingPlan,
        targetDailyValue: focusedEntry?.targetDailyValue,
        weightingStaffingPlan: focusedEntry?.weightingStaffingPlan,
        validFrom: focusedEntry?.validFrom,
        validTo: focusedEntry?.validTo,
        active: !!focusedEntry,
        entries: d.entries,
      },
    };
  }

  private isFocused(e: PersonnelCategoryEntry): boolean {
    return e.validFrom <= this.focusDate && (e.validTo == null || this.focusDate <= e.validTo);
  }

  private expandToHierarchy(n: number): void {
    this.expandToHierarchyRecursively(n, this.value);
    this.value = [...this.value];
  }

  private expandToHierarchyRecursively(n: number, nodes: TreeNode[]): void {
    nodes.forEach((node) => {
      node.expanded = node.data.tacsId.split('.').length - 1 < n;
      if (node.children && node.children.length > 0) {
        this.expandToHierarchyRecursively(n, node.children);
      }
    });
  }
}
