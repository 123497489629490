<app-form-element [control]="formControl" [id]="id" [label]="label" [required]="required" [twoColumns]="twoColumns">
  <div class="content">
    @if (prefix) {
      <div class="box-mask" [style.color]="color" [style.background-color]="backgroundColor">
        <span>{{ prefix }}</span>
      </div>
    }
    <p-inputMask
      [formControl]="formControl"
      [mask]="mask"
      [placeholder]="mask"
      [style]="{ width: '100%' }"></p-inputMask>
  </div>
</app-form-element>
