<p-card [header]="title">
  <form (ngSubmit)="onSubmit()" [formGroup]="form" class="grid" novalidate>
    <app-form-text-input [form]="form" id="code" label="STATISTICS_CODES.CODE" />

    <app-form-text-input [form]="form" id="name" label="GLOBAL.NAME" />

    <app-form-text-input [form]="form" id="description" label="GLOBAL.DESCRIPTION" />

    <app-form-text-input [form]="form" id="costCenter" label="GLOBAL.COST_CENTER" />

    <app-form-date-input [form]="form" id="validFrom" label="GLOBAL.GUELTIG_AB" />

    <app-form-date-input [form]="form" id="validTo" label="GLOBAL.GUELTIG_BIS" />

    <app-form-number-input [form]="form" id="sortNumber" label="GLOBAL.SORT_NUMBER" />

    <app-form-button-bar [form]="form" [saveDisabled]="submitting"></app-form-button-bar>
  </form>
</p-card>
