<app-table
  (focusYearChange)="focusYearChanged()"
  [(focusYear)]="focusYear"
  [columns]="columns"
  [editable]="true"
  [focusYearRangeStart]="focusYearRangeStart"
  [hasFocusYear]="true"
  [sortField]="'date'"
  createLabel="HOLIDAYS.ADD_HOLIDAY"
  #table>
  <ng-template
    appToolbarButton
    icon="fal fa-redo"
    labelKey="HOLIDAYS.ADD_PUBLIC_HOLIDAYS"
    (onClick)="addPublicHolidays()"></ng-template>

  <ng-template
    appToolbarButton
    icon="fal fa-calendar-plus"
    labelKey="HOLIDAYS.ADD_LAST_YEARS_HOLIDAYS"
    (onClick)="addLastYearsHolidays()"></ng-template>
</app-table>
