import { Pipe, PipeTransform } from '@angular/core';
import { AnyValue } from '@shared/table/table.types';

@Pipe({
  name: 'filter',
  standalone: true,
})
export class FilterPipe implements PipeTransform {
  transform(input: AnyValue[], key: string, value: unknown): AnyValue[] {
    return input.filter((o) => o[key] === value);
  }
}
