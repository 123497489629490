import { Directive, HostListener, Input } from '@angular/core';

// see https://stackoverflow.com/a/52949299/1977828
@Directive({
  selector: '[appCopyClipboard]',
  standalone: true,
})
export class CopyClipboardDirective {
  @Input()
  appCopyClipboard!: string;

  @HostListener('click', ['$event'])
  onClick(): void {
    if (!this.appCopyClipboard) {
      return;
    }

    navigator?.clipboard.writeText(this.appCopyClipboard).then(
      () => true,
      () => console.debug('could not copy to clipboard')
    );
  }
}
