<div class="grid">
  @if (offset) {
    <div class="col-fixed" style="width: 200px"></div>
  }
  <div class="col">
    <p-button
      icon="fal fa-save"
      type="submit"
      [disabled]="!form.valid || saveDisabled"
      [label]="'ACTIONS.SAVE' | translate"
      [style]="{ 'margin-right': '0.5rem' }"></p-button>
    <p-button
      icon="fal fa-times"
      styleClass="p-button-secondary"
      type="button"
      (onClick)="cancel()"
      [label]="'ACTIONS.CANCEL' | translate"
      [style]="{ 'margin-right': '0.5rem' }"></p-button>
    <ng-content></ng-content>
  </div>
</div>
