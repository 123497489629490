import { TableService } from '@shared/table/table-service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MandantService } from '@service/mandant.service';
import { Holiday } from '@entity/holiday';
import { ContextService } from '@service/context.service';
import { map } from 'rxjs/operators';

@Injectable()
export class HolidayTableService extends TableService<Holiday> {
  constructor(
    private mandantService: MandantService,
    private contextService: ContextService
  ) {
    super();
  }

  create(holiday: Holiday): Observable<Holiday> {
    return this.mandantService.addHoliday(this.contextService.getMandantIdInstant(), holiday);
  }

  createEntry(): Holiday {
    return {} as Holiday;
  }

  getData(focusYear?: number): Observable<{ entities: Holiday[] }> {
    return this.mandantService
      .getHolidays(this.contextService.getMandantIdInstant(), focusYear ?? new Date().getFullYear())
      .pipe(map((entities) => ({ entities })));
  }

  remove(holiday: Holiday): Observable<unknown> {
    return this.mandantService.deleteHoliday(this.contextService.getMandantIdInstant(), holiday);
  }

  update(holiday: Holiday): Observable<Holiday> {
    return this.mandantService.updateHoliday(this.contextService.getMandantIdInstant(), holiday);
  }
}
