@if (columns.length > 0) {
  <app-tree-table
    dataKey="employeeId"
    sortField="employeeId"
    [actionColumnWidth]="350"
    [columns]="columns"
    [createLabels]="['EMPLOYEES.CREATE_EMPLOYEE', 'EMPLOYEES.CREATE_EMPLOYMENT']"
    [editable]="isTableEditable()"
    [firstColumnFrozen]="true"
    [headerGroups]="headerGroupColumns"
    [value]="tree"
    [lazy]="true"
    [strictFilterMode]="false"
    #treeTable></app-tree-table>
}
