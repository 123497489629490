<div class="layout-breadcrumb">
  <ul>
    <li>
      <a routerLink="">
        <i class="fal fa-home"></i>
      </a>
    </li>
    <li class="fal fa-chevron-right" style="padding: 0 0.5rem"></li>
    @for (item of items; track item; let last = $last) {
      <li style="text-transform: none">
        @if (item.routerLink) {
          <a [routerLink]="item.routerLink">{{ item.label! | translate }}</a>
        }
        @if (!item.routerLink) {
          {{ item.label! | translate }}
        }
      </li>
      @if (!last) {
        <li class="fal fa-chevron-right" style="padding: 0 0.5rem"></li>
      }
    }
  </ul>
</div>
