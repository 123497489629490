import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Market } from '@entity/market';

@Injectable({
  providedIn: 'root',
})
export class MarketService {
  private readonly baseUrl = 'api/1.0/markets';

  constructor(private httpClient: HttpClient) {}

  getMarkets(): Observable<Market[]> {
    return this.httpClient.get<Market[]>(this.baseUrl);
  }

  deleteMarket(marketId: number): Observable<unknown> {
    return this.httpClient.delete(`${this.baseUrl}/${marketId}`);
  }

  getMarket(marketId: number): Observable<Market> {
    return this.httpClient.get<Market>(`${this.baseUrl}/${marketId}`);
  }

  createMarket(market: Market): Observable<Market> {
    return this.httpClient.post<Market>(`${this.baseUrl}`, market);
  }

  updateMarket(marketId: number, market: Market): Observable<Market> {
    return this.httpClient.put<Market>(`${this.baseUrl}/${marketId}`, market);
  }
}
