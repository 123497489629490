@if (columns.length > 0) {
  <app-tree-table
    dataKey="customerId"
    sortField="customerId"
    [actionColumnWidth]="350"
    [columns]="columns"
    [createLabels]="[
      'CUSTOMERS.CREATE_CUSTOMER',
      'CUSTOMERS.CREATE_CUSTOMER_CASE',
      'CUSTOMERS.CREATE_CUSTOMER_CASE_MUTATION'
    ]"
    [editable]="isTableEditable()"
    [firstColumnFrozen]="true"
    [headerGroups]="headerGroupColumns"
    [strictFilterMode]="false"
    [lazy]="true"
    [value]="tree"
    #treeTable></app-tree-table>
}
