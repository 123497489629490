import { Injectable } from '@angular/core';
import { Mandant } from '@entity/mandant';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Anwenderbetrieb, AnwenderbetriebPayload } from '@entity/anwenderbetrieb';
import { Organisation } from '@entity/organisation';

@Injectable({
  providedIn: 'root',
})
export class AnwenderbetriebService {
  private readonly baseUrl = `api/1.0/anwenderbetriebe`;

  constructor(private httpClient: HttpClient) {}

  getAllAnwenderbetriebe(
    includeMandant = false,
    includeLicenseCount = false,
    focusYear?: number
  ): Observable<Anwenderbetrieb[]> {
    let params = new HttpParams()
      .append('includeMandant', includeMandant)
      .append('includeLicenseCount', includeLicenseCount);
    if (focusYear) {
      params = params.append('focusYear', focusYear);
    }
    return this.httpClient.get<Anwenderbetrieb[]>(this.baseUrl, { params });
  }

  getAnwenderbetrieb(id: number): Observable<Anwenderbetrieb> {
    return this.httpClient.get<Anwenderbetrieb>(`${this.baseUrl}/${id}`);
  }

  updateAnwenderbetrieb(id: number, anwenderbetrieb: AnwenderbetriebPayload): Observable<Anwenderbetrieb> {
    return this.httpClient.put<Anwenderbetrieb>(`${this.baseUrl}/${id}`, anwenderbetrieb);
  }

  createAnwenderbetrieb(anwenderbetrieb: AnwenderbetriebPayload): Observable<Anwenderbetrieb> {
    return this.httpClient.post<Anwenderbetrieb>(`${this.baseUrl}`, anwenderbetrieb);
  }

  getMandants(anwenderbetriebId: string | number): Observable<Mandant[]> {
    const params = new HttpParams().append('full', 'true');
    return this.httpClient.get<Mandant[]>(`${this.baseUrl}/${anwenderbetriebId}/mandanten`, { params });
  }

  deleteAnwenderbetrieb(id: number, dataOnly = true): Observable<unknown> {
    const params = new HttpParams().append('dataOnly', dataOnly + '');
    return this.httpClient.delete(`${this.baseUrl}/${id}`, { params });
  }

  getOrganisationalUnits(anwenderbetriebId: number): Observable<Organisation[]> {
    return this.httpClient.get<Organisation[]>(`${this.baseUrl}/${anwenderbetriebId}/organisational-units`);
  }

  getActivePersonnelCategories(anwenderbetriebId: number): Observable<{ [tacsId: string]: string }> {
    return this.httpClient.get<{ [tacsId: string]: string }>(
      `${this.baseUrl}/${anwenderbetriebId}/active-personnel-categories`
    );
  }

  downloadLicenseProof(anwenderbetriebId: number, focusYear: number): Observable<HttpResponse<Blob>> {
    const params = new HttpParams().append('focusYear', focusYear);
    return this.httpClient.get(`${this.baseUrl}/${anwenderbetriebId}/license-proof/`, {
      params,
      responseType: 'blob',
      observe: 'response',
    });
  }
}
