import { ChangeDetectionStrategy, Component, Injector, OnInit } from '@angular/core';
import { AbstractContentComponent } from '../abstract-content.component';
import { Column } from '@shared/table/column';
import { MandantService } from '@service/mandant.service';
import { Context } from '@service/context.service';
import { PersonnelCost, PersonnelCostEntry } from '@entity/personnel-cost';
import { DomSanitizer } from '@angular/platform-browser';
import { PersonnelCostDetailComponent } from './personnel-cost-detail/personnel-cost-detail.component';
import { ActionButtonDirective } from '../shared/table/action-button.directive';
import { TableComponent } from '../shared/table/table.component';

@Component({
  selector: 'app-personnel-costs',
  templateUrl: './personnel-costs.component.html',
  styleUrls: ['./personnel-costs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TableComponent, ActionButtonDirective],
})
export class PersonnelCostsComponent extends AbstractContentComponent implements OnInit {
  value: PersonnelCost[] = [];
  columns: Column[] = [];
  focusDate: Date = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());

  constructor(
    private mandantService: MandantService,
    private domSanitizer: DomSanitizer,
    injector: Injector
  ) {
    super(injector, Context.Mandant, 'MENU.PERSONNEL_COSTS');
  }

  static formatTacsId(tacsId: string): string {
    const numberOfIndention = tacsId.split('.').length - 1;
    const indention = new Array(numberOfIndention).fill('&nbsp;&nbsp;&nbsp;&nbsp;').join('');
    return `${indention}${tacsId}`;
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.onLanguageChange();
  }

  onLanguageChange(): void {
    this.columns = [
      { field: 'tacsIdAndName', headerKey: 'GLOBAL.NAME', minWidth: '200px', filterType: 'html' },
      { field: 'focusedEntry.validFrom', headerKey: 'GLOBAL.GUELTIG_AB', width: '130px', filterType: 'date' },
      { field: 'focusedEntry.validTo', headerKey: 'GLOBAL.GUELTIG_BIS', width: '130px', filterType: 'date' },
      { field: 'focusedEntry.value', headerKey: 'GLOBAL.VALUE', width: '130px' },
    ];
  }

  focusDateChange($event: Date): void {
    this.focusDate = $event;
    this.onMandantIdChange(this.getMandantId());
  }

  showDialog(row: PersonnelCost): void {
    const ref = this.dialogService.open(PersonnelCostDetailComponent, {
      modal: true,
      data: row,
      header: this.translate('GLOBAL.DETAILS') + ' - ' + row.tacsIdAndName,
    });

    ref.onClose.subscribe((data) => {
      if (data != null) {
        row.entries = data;
        row.focusedEntry = row.entries.find((p) => this.isFocused(p));
        this.showSuccessMessage();
        this.value = [...this.value];
        this.markForCheck();
      }
    });
  }

  protected onMandantIdChange(id: number | null): void {
    if (!id) {
      this.value = [];
      return;
    }

    this.startLoading();
    this.markForCheck();
    this.mandantService.getPersonnelCosts(id, this.focusDate).subscribe((personnelCosts) => {
      this.value = personnelCosts;
      personnelCosts.sort((a, b) => (a.tacsId < b.tacsId ? -1 : 1));
      personnelCosts.forEach((personnelCost) => {
        const name = personnelCost.name;
        personnelCost.tacsIdAndName = personnelCost.tacsId + ' ' + personnelCost.name;
        personnelCost._html_tacsIdAndName = this.domSanitizer.bypassSecurityTrustHtml(
          `<div style="overflow: hidden; position: relative; display: flex"><div style="white-space: nowrap; text-overflow: ellipsis; flex:1; overflow: hidden" title="${
            personnelCost.tacsId + ' ' + personnelCost.name
          }">${PersonnelCostsComponent.formatTacsId(personnelCost.tacsId) + ' ' + name}</div></div>`
        );
        personnelCost.focusedEntry = personnelCost.entries.find((p) => this.isFocused(p));
      });
      this.markForCheck();
      this.loadingDone();
    });
  }

  private isFocused(e: PersonnelCostEntry): boolean {
    return e.validFrom <= this.focusDate && (e.validTo == null || this.focusDate <= e.validTo);
  }
}
