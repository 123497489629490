import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Category } from '@entity/category';
import { CategoryHierarchy } from '@entity/category-hierarchy';
import { Mandant } from '@entity/mandant';

@Injectable({
  providedIn: 'root',
})
export class CategoryService {
  private readonly baseUrl = 'api/1.0/categories';

  constructor(private httpClient: HttpClient) {}

  getCategories(): Observable<CategoryHierarchy[]> {
    return this.httpClient.get<CategoryHierarchy[]>(this.baseUrl);
  }

  getCategory(categoryId: number): Observable<Category> {
    return this.httpClient.get<Category>(`${this.baseUrl}/${categoryId}`);
  }

  createCategory(category: Category): Observable<Category> {
    return this.httpClient.post<Category>(`${this.baseUrl}`, category);
  }

  updateCategory(categoryId: number, category: Category): Observable<Category> {
    return this.httpClient.put<Category>(`${this.baseUrl}/${categoryId}`, category);
  }

  deleteCategory(categoryId: number): Observable<unknown> {
    return this.httpClient.delete(`${this.baseUrl}/${categoryId}`);
  }

  getMandantenByCategory(categoryId: number): Observable<Mandant[]> {
    return this.httpClient.get<Mandant[]>(`${this.baseUrl}/${categoryId}/mandanten`);
  }
}
