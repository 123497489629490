import { TableService } from '@shared/table/table-service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Catalog } from '@entity/catalog';
import { MainDataService } from '@service/main-data.service';
import { ActivatedRoute } from '@angular/router';
import { ContextService } from '@service/context.service';
import { CatalogService } from '@service/catalog.service';
import { map } from 'rxjs/operators';

@Injectable()
export class MappingCatalogsTableService extends TableService<Catalog> {
  constructor(
    private mainDataService: MainDataService,
    private catalogService: CatalogService,
    private activatedRoute: ActivatedRoute,
    private contextService: ContextService
  ) {
    super();
  }

  createEntry(): Catalog {
    return {
      mappingType: 'Measure',
      validFrom: new Date(),
    };
  }

  getData(): Observable<{ entities: Catalog[] }> {
    if (this.activatedRoute.snapshot.data.isMandantSection) {
      return this.mainDataService.getCatalogs().pipe(map((entities) => ({ entities })));
    }
    return this.catalogService
      .getCatalogs(this.contextService.getMandantIdInstant())
      .pipe(map((entities) => ({ entities })));
  }

  isBatchUpdateSupported(): boolean {
    return true;
  }

  batchUpdate(value: Catalog[]): Observable<Catalog[]> {
    return this.mainDataService.updateCatalogs(value);
  }
}
