import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AbstractFormElement } from '../abstract-form-element';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { InputTextModule } from 'primeng/inputtext';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { FormElementComponent } from '../form-element/form-element.component';

@Component({
  selector: 'app-form-text-input',
  templateUrl: './form-text-input.component.html',
  styleUrls: ['./form-text-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [FormElementComponent, FormsModule, InputTextModule, ReactiveFormsModule, InputTextareaModule],
})
export class FormTextInputComponent extends AbstractFormElement<string> {
  @Input()
  textArea = false;

  @Input()
  maxLength = 9999;
}
