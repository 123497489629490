import { ChangeDetectionStrategy, Component, Injector, OnInit } from '@angular/core';
import { AbstractContentComponent } from '../abstract-content.component';
import { Column } from '@shared/table/column';
import { Context } from '@service/context.service';
import { StatisticsCode } from '@entity/statistics-code';
import { StatisticsCodeService } from '@service/statistics-code.service';
import { map } from 'rxjs/operators';
import { ActionButtonDirective } from '@shared/table/action-button.directive';
import { ToolbarButtonDirective } from '@shared/table/toolbar-button.directive';

import { TableComponent } from '@shared/table/table.component';

@Component({
  selector: 'app-statistics-codes',
  templateUrl: './statistics-codes.component.html',
  styleUrls: ['./statistics-codes.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TableComponent, ToolbarButtonDirective, ActionButtonDirective],
})
export class StatisticsCodesComponent extends AbstractContentComponent implements OnInit {
  statisticsCodes: StatisticsCode[] = [];
  columns: Column[] = [];

  constructor(
    private statisticsCodeService: StatisticsCodeService,
    injector: Injector
  ) {
    super(injector, Context.Mandant);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.setBreadcrumbs([{ label: 'MENU.STATISTICS_CODES' }, { label: 'MENU.STATISTICS_CODES_OVERVIEW' }]);
    this.setColumns();
  }

  loadStatisticsCodes(): void {
    this.startLoading();
    this.statisticsCodeService
      .list(this.getMandantId())
      .pipe(map((r) => r.entities))
      .subscribe((data) => {
        this.statisticsCodes = data;
        this.markForCheck();
        this.loadingDone();
      });
  }

  createNewStatisticsCode(): void {
    this.navigate(['/statistics-codes/overview/new']);
  }

  deleteStatisticsCode(statisticsCode: StatisticsCode): void {
    this.confirm({
      header: this.translate('STATISTICS_CODES.DELETE_HEADER'),
      message: this.translate('STATISTICS_CODES.DELETE_MESSAGE', statisticsCode),
      accept: () => {
        if (!statisticsCode.id) return;
        this.statisticsCodeService.delete(this.getMandantId(), statisticsCode.id).subscribe({
          next: () => {
            this.showSuccessMessage(this.translate('STATISTICS_CODES.DELETE_SUCCESSFULLY', statisticsCode));
            this.loadStatisticsCodes();
          },
          error: () => {
            this.showErrorMessage(this.translate('STATISTICS_CODES.DELETE_FAILED', statisticsCode));
          },
        });
      },
    });
  }

  protected onMandantIdChange(): void {
    this.loadStatisticsCodes();
  }

  private setColumns(): void {
    this.columns = [
      {
        field: 'code',
        headerKey: 'STATISTICS_CODES.CODE',
        width: '300px',
        route: this.isManager() ? '/statistics-codes/overview/${id}' : undefined,
      },
      {
        field: 'name',
        headerKey: 'GLOBAL.NAME',
        width: '350px',
      },
      {
        field: 'description',
        headerKey: 'GLOBAL.DESCRIPTION',
        minWidth: '300px',
      },
      {
        field: 'costCenter',
        headerKey: 'GLOBAL.COST_CENTER',
        width: '300px',
      },
      {
        field: 'sortNumber',
        headerKey: 'GLOBAL.SORT_NUMBER',
        width: '150px',
      },
      { field: 'validFrom', headerKey: 'GLOBAL.GUELTIG_AB', width: '130px', filterType: 'date' },
      { field: 'validTo', headerKey: 'GLOBAL.GUELTIG_BIS', width: '130px', filterType: 'date' },
    ];
  }
}
