import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CompleteVariable, Variable, VariableApproval } from '@entity/variable';
import { AssignmentElement, OrganizationCategoryGroup } from '@entity/assignment';
import { TacsCodeKreis } from '@entity/tacs-code-kreis';
import { AnyValue } from '@shared/table/table.types';

@Injectable({
  providedIn: 'root',
})
export class VariableService {
  private readonly baseUrl = `api/1.0`;

  constructor(private httpClient: HttpClient) {}

  static getBackgroundColor(name?: string): string {
    if (!name) {
      return 'rgba(0,0,0,0)';
    }
    if (name.startsWith('2')) {
      return 'rgba(230,0,60,1)';
    }
    if (name.startsWith('3')) {
      return 'rgba(255,205,10,1)';
    }
    if (name.startsWith('4')) {
      return 'rgba(175,200,15,1)';
    }
    if (name.startsWith('5')) {
      return 'rgba(0,140,210,1)';
    }
    return 'rgba(0,0,0,0)';
  }

  static getForegroundColor(name?: string): string {
    if (!name) {
      return 'rgba(73,89,87,1)';
    }
    if (name.startsWith('2')) {
      return 'rgb(255,255,255)';
    }
    if (name.startsWith('3')) {
      return 'rgba(73,89,87,1)';
    }
    if (name.startsWith('4')) {
      return 'rgba(73,89,87,1)';
    }
    if (name.startsWith('5')) {
      return 'rgb(255,255,255)';
    }
    return 'rgba(73,89,87,1)';
  }

  getApprovals(): Observable<VariableApproval[]> {
    return this.httpClient.get<VariableApproval[]>(`${this.baseUrl}/variables/approvals`);
  }

  getApproval(id: number): Observable<VariableApproval> {
    return this.httpClient.get<VariableApproval>(`${this.baseUrl}/variables/approvals/${id}`);
  }

  accept(id: number, comment: string): Observable<unknown> {
    return this.httpClient.post(`${this.baseUrl}/variables/approvals/${id}/accept`, { comment });
  }

  reject(id: number, comment: string): Observable<unknown> {
    return this.httpClient.post(`${this.baseUrl}/variables/approvals/${id}/reject`, { comment });
  }

  comment(id: number, comment: string): Observable<unknown> {
    return this.httpClient.post(`${this.baseUrl}/variables/approvals/${id}/comment`, { comment });
  }

  getVariableOrganisationalUnitTree(mandantId: number, focusDate: Date): Observable<OrganizationCategoryGroup[]> {
    const params = new HttpParams().append('focusDate', focusDate.toJSON());
    return this.httpClient.get<OrganizationCategoryGroup[]>(
      `${this.baseUrl}/mandanten/${mandantId}/variables/organisational-unit-tree`,
      { params }
    );
  }

  getAssignments(mandantId: number, focusDate: Date, organisationIds: number[]): Observable<AssignmentElement[]> {
    const params = new HttpParams({
      fromObject: {
        focusDate: focusDate.toJSON(),
        ou: organisationIds.map((id) => `${id}`),
      },
    });
    return this.httpClient.get<AssignmentElement[]>(`${this.baseUrl}/mandanten/${mandantId}/variables/assignments`, {
      params,
    });
  }

  updateAssignments(mandantId: number, assignments: AssignmentElement[]): Observable<AssignmentElement[]> {
    return this.httpClient.put<AssignmentElement[]>(
      `${this.baseUrl}/mandanten/${mandantId}/variables/assignments`,
      assignments
    );
  }

  getOrganizationCategories(mandantId: number): Observable<TacsCodeKreis[]> {
    return this.httpClient.get<AssignmentElement[]>(`${this.baseUrl}/mandanten/${mandantId}/organization-categories`);
  }

  generateHandbook(
    mandantId: number,
    type: string,
    level: string,
    id: number | string,
    languageCaseSensitive: string,
    focusDate: Date
  ): Observable<AnyValue> {
    const params = new HttpParams()
      .append('variableLevel', level)
      .append('variableEntityId', id + '')
      .append('language', languageCaseSensitive)
      .append('outputFormat', type)
      .append('focusDate', focusDate.toJSON());
    return this.httpClient.get(`${this.baseUrl}/mandanten/${mandantId}/variables/generate-handbook`, {
      params,
      responseType: 'blob',
      observe: 'response',
    });
  }

  getVariables(mandantId: number): Observable<Variable[]> {
    return this.httpClient.get<Variable[]>(`${this.baseUrl}/mandanten/${mandantId}/variables`);
  }

  createOrUpdate(mandantId: number, variable: Variable): Observable<Variable> {
    if (variable.id) {
      return this.httpClient.put<Variable>(`${this.baseUrl}/mandanten/${mandantId}/variables/${variable.id}`, variable);
    }
    return this.httpClient.post<Variable>(`${this.baseUrl}/mandanten/${mandantId}/variables`, variable);
  }

  updateVariableEntries(mandantId: number, variable: Variable): Observable<Variable> {
    return this.httpClient.put<Variable>(
      `${this.baseUrl}/mandanten/${mandantId}/variables/${variable.id}/entries`,
      variable
    );
  }

  deleteVariable(mandantId: number, variableId: number): Observable<unknown> {
    return this.httpClient.delete(`${this.baseUrl}/mandanten/${mandantId}/variables/${variableId}`);
  }

  activateAllMeasures(mandantId: number, focusDate: Date): Observable<Variable[]> {
    return this.httpClient.post<Variable[]>(`${this.baseUrl}/mandanten/${mandantId}/variables/activate-all-measures`, {
      focusDate,
    });
  }

  deactivateAllMeasures(mandantId: number, focusDate: Date): Observable<Variable[]> {
    return this.httpClient.post<Variable[]>(
      `${this.baseUrl}/mandanten/${mandantId}/variables/deactivate-all-measures`,
      { focusDate }
    );
  }

  getCompleteVariables(): Observable<CompleteVariable[]> {
    return this.httpClient.get<CompleteVariable[]>(`${this.baseUrl}/variables`);
  }

  getCompleteVariable(id: number, calculateNextTacsIdAndSortId = false): Observable<CompleteVariable> {
    let params = new HttpParams();
    if (calculateNextTacsIdAndSortId) {
      params = params.append('calculateNextTacsIdAndSortId', true);
    }
    return this.httpClient.get<CompleteVariable>(`${this.baseUrl}/variables/${id}`, { params });
  }

  updateCompleteVariable(id: number, variable: CompleteVariable): Observable<CompleteVariable> {
    return this.httpClient.put<CompleteVariable>(`${this.baseUrl}/variables/${id}`, variable);
  }

  createCompleteVariable(variable: CompleteVariable): Observable<CompleteVariable> {
    return this.httpClient.post<CompleteVariable>(`${this.baseUrl}/variables`, variable);
  }

  deleteCompleteVariable(id: number): Observable<unknown> {
    return this.httpClient.delete(`${this.baseUrl}/variables/${id}`);
  }
}
