<app-table
  filename="MENU.BENEFICIARY"
  [actionColumnWidth]="300"
  [columns]="columns"
  [loading]="loading"
  [value]="beneficiaries">
  @if (isManager()) {
    <ng-template
      appToolbarButton
      icon="fal fa-plus"
      labelKey="BENEFICIARIES.CREATE_TITLE"
      (onClick)="createNewBeneficiary()"></ng-template>
    <ng-template
      appActionButton
      displayIf="deletable"
      icon="fal fa-trash"
      labelKey="ACTIONS.DELETE"
      type="danger"
      (onClick)="deleteBeneficiary($event.row)"></ng-template>
  }
</app-table>
