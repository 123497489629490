import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { of } from 'rxjs';
import { CompleteVariable } from '@entity/variable';
import { VariableService } from '@service/variable.service';

export const VariableResolve: ResolveFn<CompleteVariable | null> = (route) => {
  const variableService = inject(VariableService);
  if (route.queryParams.parentId) {
    return variableService.getCompleteVariable(route.queryParams.parentId, true);
  }
  if (route.params.id) {
    return variableService.getCompleteVariable(route.params.id);
  }

  return of(null);
};
