import { TableService } from '@shared/table/table-service';
import { PersonnelCategoryEntry } from '@entity/personnel-category';
import { Observable } from 'rxjs';
import { ContextService } from '@service/context.service';
import { Injectable } from '@angular/core';
import { PersonnelCategoryService } from '@service/personnel-category.service';
import { map } from 'rxjs/operators';

@Injectable()
export class PersonnelCategoryEntriesTableService extends TableService<PersonnelCategoryEntry> {
  private personnelCategoryId = -1;

  constructor(
    private personnelCategoryService: PersonnelCategoryService,
    private contextService: ContextService
  ) {
    super();
  }

  setPersonnelCategoryId(personnelCategoryId: number): void {
    this.personnelCategoryId = personnelCategoryId;
  }

  create(e: PersonnelCategoryEntry): Observable<PersonnelCategoryEntry> {
    return this.personnelCategoryService.addPersonnelCategoryEntry(
      this.contextService.getMandantIdInstant(),
      this.personnelCategoryId,
      e
    );
  }

  getData(): Observable<{ entities: PersonnelCategoryEntry[] }> {
    return this.personnelCategoryService
      .getPersonnelCategoryEntries(this.contextService.getMandantIdInstant(), this.personnelCategoryId)
      .pipe(map((entities) => ({ entities })));
  }

  remove(e: PersonnelCategoryEntry): Observable<unknown> {
    return this.personnelCategoryService.deletePersonnelCategoryEntry(
      this.contextService.getMandantIdInstant(),
      this.personnelCategoryId,
      e
    );
  }

  update(e: PersonnelCategoryEntry): Observable<PersonnelCategoryEntry> {
    return this.personnelCategoryService.updatePersonnelCategoryEntry(
      this.contextService.getMandantIdInstant(),
      this.personnelCategoryId,
      e
    );
  }

  createEntry(): PersonnelCategoryEntry {
    return {} as PersonnelCategoryEntry;
  }
}
