<app-table
  (focusYearChange)="focusYearChanged(table)"
  [(focusYear)]="focusYear"
  [columns]="columns"
  [editable]="true"
  [firstColumnFrozen]="true"
  [focusYearRangeStart]="focusYearRangeStart"
  [hasFocusYear]="true"
  [sortField]="'date'"
  #table>
  <ng-template
    appToolbarButton
    icon="fal fa-calendar-plus"
    labelKey="PUBLIC_HOLIDAYS.ADD_LAST_YEARS_HOLIDAYS"
    (onClick)="addLastYearsPublicHoliday()"></ng-template>
</app-table>
