import { ChangeDetectionStrategy, Component, Inject, Injector, OnInit } from '@angular/core';
import { Column } from '@shared/table/column';
import { AbstractContentComponent } from '../abstract-content.component';
import { Context } from '@service/context.service';
import { Catalog } from '@entity/catalog';
import { ActivatedRoute } from '@angular/router';
import { TABLE_SERVICE } from '@shared/table/table-service';
import { MappingCatalogsTableService } from './mapping-catalogs-table.service';
import { ActionButtonDirective } from '@shared/table/action-button.directive';
import { TableComponent } from '@shared/table/table.component';

@Component({
  selector: 'app-mapping-catalogs',
  templateUrl: './mapping-catalogs.component.html',
  styleUrls: ['./mapping-catalogs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: TABLE_SERVICE,
      useClass: MappingCatalogsTableService,
    },
  ],
  standalone: true,
  imports: [TableComponent, ActionButtonDirective],
})
export class MappingCatalogsComponent extends AbstractContentComponent implements OnInit {
  columns: Column[] = [];
  value: Catalog[] = [];
  editable = false;

  constructor(
    injector: Injector,
    activatedRoute: ActivatedRoute,
    @Inject(TABLE_SERVICE) private mappingCatalogsTableService: MappingCatalogsTableService
  ) {
    super(
      injector,
      activatedRoute.snapshot.data.isMandantSection ? Context.Global : Context.Mandant,
      'MENU.MAPPING_CATALOGS'
    );
    this.editable = activatedRoute.snapshot.data.isMandantSection;
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.columns = [
      {
        field: 'name',
        headerKey: 'GLOBAL.NAME',
        minWidth: '200px',
        editable: this.editable,
        required: true,
      },
      {
        field: 'version',
        headerKey: 'MAPPING_CATALOGS.VERSION',
        width: '200px',
        editable: this.editable,
        required: true,
      },
      {
        field: 'mappingType',
        headerKey: 'MAPPING_CATALOGS.TYPE',
        width: '200px',
        options: [
          { label: this.translate('MAPPING_CATALOGS.MEASURE'), value: 'Measure' },
          { label: this.translate('MAPPING_CATALOGS.VARIABLE'), value: 'Variable' },
        ],
        editable: this.editable,
        required: true,
      },
      {
        field: 'validFrom',
        headerKey: 'GLOBAL.GUELTIG_AB',
        width: '200px',
        filterType: 'date',
        editable: this.editable,
        required: true,
      },
      {
        field: 'validTo',
        headerKey: 'GLOBAL.GUELTIG_BIS',
        width: '200px',
        filterType: 'date',
        editable: this.editable,
      },
    ];
  }

  showDetail(row: Catalog): void {
    this.navigate(row.id ?? [], { type: row.mappingType });
  }

  protected onMandantIdChange(): void {
    this.mappingCatalogsTableService.reload();
  }
}
