import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { of } from 'rxjs';
import { Organisation } from '@entity/organisation';
import { OrganisationalUnitService } from '@service/organisational-unit.service';

export const OrganisationResolve: ResolveFn<Organisation> = (route) => {
  if (route.params.id === 'new') {
    return of({ organisationId: 'new', sabEnabled: true } as Organisation);
  }
  return inject(OrganisationalUnitService).getOrganisationalUnit(route.params.id);
};
