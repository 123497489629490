import { AfterViewInit, Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[appEllipsisTooltip]',
  standalone: true,
})
export class EllipsisTooltipDirective implements AfterViewInit {
  constructor(private elementRef: ElementRef) {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      const element = this.elementRef.nativeElement.cloneNode(true);
      element.style.whiteSpace = 'pre';
      element.style.visibility = 'hidden';
      document.body.append(element);
      const addTooltip =
        element.getBoundingClientRect().width > this.elementRef.nativeElement.getBoundingClientRect().width;
      element.remove();
      if (addTooltip) {
        this.elementRef.nativeElement.title = this.elementRef.nativeElement.innerText;
      }
    });
  }
}
