<app-tree-table
  [actionColumnWidth]="600"
  [columns]="columns"
  [firstColumnFrozen]="true"
  [loading]="loading"
  [pagination]="false"
  [value]="value">
  <ng-template
    (onClick)="hierarchyMenu.show($event)"
    [labelKey]="'VARIABLES.FOCUS'"
    appToolbarButton
    icon="fal fa-folder-tree"></ng-template>

  <ng-template
    (onClick)="additionalColumnsMenu.show($event)"
    appToolbarButton
    icon="fal fa-columns"
    labelKey="VARIABLES.ADDITIONAL_COLUMNS"></ng-template>

  <ng-template
    (onClick)="createVariable()"
    appToolbarButton
    icon="fal fa-plus"
    labelKey="VARIABLES.CREATE_VARIABLE"></ng-template>

  <ng-template
    (onClick)="createVariable($event.row)"
    appActionButton
    displayIfNot="node.data.isLastHierarchy"
    icon="fal fa-plus"
    labelKey="VARIABLES.CREATE_VARIABLE"
    type="secondary"></ng-template>

  <ng-template
    (onClick)="createVariable($event.row, true)"
    appActionButton
    displayIfNot="node.data.isLastHierarchy"
    icon="fal fa-plus"
    labelKey="VARIABLES.CREATE_MEASURE_VARIABLE"
    type="secondary"></ng-template>

  <ng-template
    (onClick)="deleteVariable($event.row)"
    appActionButton
    displayIf="node.data.isDeletable"
    icon="fal fa-trash"
    labelKey="VARIABLES.DELETE_HEADER"
    type="danger"></ng-template>
</app-tree-table>

<p-tieredMenu
  #hierarchyMenu
  (click)="hierarchyMenu.hide()"
  [hideTransitionOptions]="'0ms'"
  [model]="hierarchyMenuModel"
  [popup]="true"
  [showTransitionOptions]="'0ms'"
  [style]="{ width: '300px' }"></p-tieredMenu>

<p-tieredMenu
  #additionalColumnsMenu
  (click)="additionalColumnsMenu.hide()"
  [hideTransitionOptions]="'0ms'"
  [model]="additionalColumnsMenuModel"
  [popup]="true"
  [showTransitionOptions]="'0ms'"
  [style]="{ width: '300px' }"></p-tieredMenu>
