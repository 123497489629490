import { ChangeDetectionStrategy, Component, Injector, OnInit } from '@angular/core';
import { AbstractContentComponent } from '../../abstract-content.component';
import { Context } from '@service/context.service';
import { VariableService } from '@service/variable.service';
import { VariableApproval } from '@entity/variable';
import { Column } from '@shared/table/column';
import { SortOrder } from '@shared/table/abstract-table.component';
import { ActionButtonDirective } from '@shared/table/action-button.directive';
import { TableComponent } from '@shared/table/table.component';

@Component({
  selector: 'app-variable-approvals',
  templateUrl: './variable-approvals.component.html',
  styleUrls: ['./variable-approvals.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TableComponent, ActionButtonDirective],
})
export class VariableApprovalsComponent extends AbstractContentComponent implements OnInit {
  approvals: VariableApproval[] = [];
  columns: Column[] = [
    { field: 'mandant', headerKey: 'USERS.MANDANT', width: '300px', options: [] },
    { field: 'tacsId', headerKey: 'GLOBAL.ID', width: '200px' },
    { field: 'name', headerKey: 'GLOBAL.NAME', minWidth: '200px' },
    { field: 'created', headerKey: 'GLOBAL.CREATED', width: '200px', filterType: 'date' },
    { field: 'creatorName', headerKey: 'GLOBAL.NAME', width: '200px' },
    { field: 'creatorEmail', headerKey: 'GLOBAL.EMAIL', width: '200px' },
    {
      field: 'state',
      headerKey: 'VARIABLES.APPROVAL_STATE',
      width: '200px',
      options: [
        { label: this.translate('VARIABLES.APPROVAL_STATES.Accepted'), value: 'Accepted' },
        { label: this.translate('VARIABLES.APPROVAL_STATES.Rejected'), value: 'Rejected' },
        { label: this.translate('VARIABLES.APPROVAL_STATES.Requested'), value: 'Requested' },
      ],
    },
    {
      field: 'type',
      headerKey: 'VARIABLES.APPROVAL_TYPE',
      width: '200px',
      options: [
        { label: this.translate('VARIABLES.APPROVAL_TYPES.NewBetriebsVariable'), value: 'NewBetriebsVariable' },
        { label: this.translate('VARIABLES.APPROVAL_TYPES.RenameBetriebsVariable'), value: 'RenameBetriebsVariable' },
        { label: this.translate('VARIABLES.APPROVAL_TYPES.RenameVariable'), value: 'RenameVariable' },
      ],
    },
  ];
  sortOrder = SortOrder.DESC;

  constructor(
    injector: Injector,
    private variableService: VariableService
  ) {
    super(injector, Context.Global);
    this.setBreadcrumbs([{ label: 'MENU.VARIABLES' }, { label: 'MENU.VARIABLE_APPROVAL' }]);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.startLoading();
    this.variableService.getApprovals().subscribe((approvals) => {
      this.approvals = approvals;
      this.columns[0].options = [...new Set(approvals.map((a) => a.mandant))].map((m) => ({ label: m, value: m }));
      this.loadingDone();
    });
  }

  showDetail(variableApproval: VariableApproval): void {
    this.navigate(variableApproval.id);
  }
}
