export const environment = {
  production: true,
  reportingUrl: 'https://reporting.tacs2.ch',
  variablesOverviewUrl: 'https://administration.tacs2.ch/Web/Element',
  helpUrl: 'https://help.tacs2.ch',
  mappingCatalogsAdminUrl: 'https://administration.tacs2.ch/Web/Katalog?IsAdmin=True',
  variablesFullCatalogUrl: 'https://administration.tacs2.ch/Web/Assign/MandantVariablen',
  variablesAssignmentUrl: 'https://administration.tacs2.ch/Web/Assign/SummaryMandant',
  variablesBetrieb: 'https://administration.tacs2.ch/Web/Betriebsvariable',
  measureViewUrl: 'https://administration.tacs2.ch/Web/VariablenMassnahmen',
  releaseVariablesBetriebUrl: 'https://administration.tacs2.ch/Web/Freigabe/Betriebsvariablen',
  releaseVariablesDescription: 'https://administration.tacs2.ch/Web/Freigabe/VariablenBezeichnungen',
  statisticsCodeUrl: 'https://administration.tacs2.ch/Web/StatistikCode',
  statisticsCodeAssignmentUrl: 'https://administration.tacs2.ch/Web/StatistikCode/SummaryMandant',
  beneficiaryUrl: 'https://administration.tacs2.ch/Web/Nutzniesser',
  beneficiaryAssignmentUrl: 'https://administration.tacs2.ch/Web/Nutzniesser/SummaryMandant',
  timeBudgetUrl: 'https://administration.tacs2.ch/Web/Zeitbudget/Index',
  mappingCatalogsNoAdminUrl: 'https://administration.tacs2.ch/Web/Katalog?IsAdmin=False',
  holidaysUrl: 'https://administration.tacs2.ch/Web/Feiertage',
  workdaysUrl: 'https://administration.tacs2.ch/Web/Betriebsstunde',
  timeCreditUrl: 'https://administration.tacs2.ch/Web/Zeitgutschrift/Index',
  sollStellenPlanUrl: 'https://administration.tacs2.ch/Web/SollStellenplan/Summary',
  personnelCostsUrl: 'https://administration.tacs2.ch/Web/Personalkosten/Summary',
  mandantsUrl: 'https://administration.tacs2.ch/Web/Mandant',
  organisationalUnitsUrl: 'https://administration.tacs2.ch/Web/Organisation',
  personnelCategoriesUrl: 'https://administration.tacs2.ch/Web/Assign/PersonalKategorien',
  customersUrl: 'https://administration.tacs2.ch/Web/PatientOverview/Index',
  employeesUrl: 'https://administration.tacs2.ch/Web/PersonalOverview/Index',
};
