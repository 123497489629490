import { ChangeDetectionStrategy, Component, Injector, OnInit } from '@angular/core';
import { AbstractContentComponent } from '../abstract-content.component';
import { Column } from '@shared/table/column';
import { MenuItem, SelectItem, SharedModule, TreeNode } from 'primeng/api';
import {
  Assignment,
  AssignmentElement,
  AssignmentRootType,
  AssignmentType,
  Node,
  Organization,
  OrganizationCategoryGroup,
} from '@entity/assignment';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { HeaderGroup } from '@shared/table/abstract-table.component';
import { formatDate } from '@angular/common';
import { OverlayPanel, OverlayPanelModule } from 'primeng/overlaypanel';
import { maxDate, minDate } from '../util';
import { DomSanitizer } from '@angular/platform-browser';
import { downloadBlob } from '../download';
import { AssignmentDetailComponent } from './assignment-detail/assignment-detail.component';
import { Context } from '@service/context.service';
import { finalize, Observable } from 'rxjs';
import { EmployeeService } from '@service/employee.service';
import { BeneficiaryService } from '@service/beneficiary.service';
import { StatisticsCodeService } from '@service/statistics-code.service';
import { VariableService } from '@service/variable.service';
import { PersonnelCategoryService } from '@service/personnel-category.service';
import { map } from 'rxjs/operators';
import { TranslateModule } from '@ngx-translate/core';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { FormButtonBarComponent } from '@shared/form-button-bar/form-button-bar.component';
import { FormDateInputComponent } from '@shared/form-date-input/form-date-input.component';
import { DialogModule } from 'primeng/dialog';
import { ScrollIntoViewDirective } from '@shared/scroll-into-view.directive';
import { DropdownModule } from 'primeng/dropdown';
import { MessageModule } from 'primeng/message';
import { TreeModule } from 'primeng/tree';
import { ButtonModule } from 'primeng/button';
import { ToolbarButtonDirective } from '@shared/table/toolbar-button.directive';
import { TableComponent } from '@shared/table/table.component';
import { AnyValue } from '@shared/table/table.types';

const maxColumns = 20;

@Component({
  selector: 'app-assignments',
  templateUrl: './assignments.component.html',
  styleUrls: ['./assignments.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TableComponent,
    ToolbarButtonDirective,
    OverlayPanelModule,
    SharedModule,
    ButtonModule,
    TreeModule,
    MessageModule,
    DropdownModule,
    ScrollIntoViewDirective,
    DialogModule,
    FormsModule,
    ReactiveFormsModule,
    FormDateInputComponent,
    FormButtonBarComponent,
    TieredMenuModule,
    TranslateModule,
  ],
})
export class AssignmentsComponent extends AbstractContentComponent implements OnInit {
  columns: Column[] = [];
  value: AnyValue[] = [];
  headerGroups: HeaderGroup[][] = [];
  visibleColumns: TreeNode[] = [];
  tree: TreeNode[] = [];
  selection: AnyValue[] = [];
  focusDate: Date = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
  displayActivateDialog = false;
  form?: FormGroup<{ from: FormControl<Date>; to: FormControl<Date | null> }>;
  columnSelection: (Column | HeaderGroup)[] = [];
  activeEnabled = false;
  deactivateEnabled = false;
  showPersonnelCategories = false;
  showEmployments = false;
  viewMenuModel: MenuItem[] = [];
  activePersonnelCategories: SelectItem[] = [];
  employments: SelectItem[] = [];
  validFrom?: Date;
  validTo?: Date;
  handbookTree: TreeNode[] = [];
  showMeasure = false;
  maxColumns = maxColumns;
  submitting = false;

  private type!: AssignmentRootType;
  private elements?: AssignmentElement[];
  private organisationalUnitTree?: OrganizationCategoryGroup[];

  constructor(
    private employeeService: EmployeeService,
    private beneficiaryService: BeneficiaryService,
    private variableService: VariableService,
    private personnelCategoryService: PersonnelCategoryService,
    private statisticsCodeService: StatisticsCodeService,
    private fb: FormBuilder,
    private domSanitizer: DomSanitizer,
    injector: Injector
  ) {
    super(injector, Context.Mandant);
  }

  private static calculateValidFrom(organization: Organization, category: Node, group: Node): Date | null {
    const validFromOrganization = organization.validFrom;
    const validFromCategory = category.validFrom;
    const validFromGroup = group.validFrom;
    return maxDate(validFromOrganization, validFromCategory, validFromGroup);
  }

  private static calculateValidTo(organization: Organization, category: Node, group: Node): Date | null {
    const validToOrganization = organization.validTo;
    const validToCategory = category.validTo;
    const validToGroup = group.validTo;
    return minDate(validToOrganization, validToCategory, validToGroup);
  }

  updateView(showPersonnelCategories = false, showEmployments = false): void {
    this.showPersonnelCategories = showPersonnelCategories;
    this.showEmployments = showEmployments;

    if (showPersonnelCategories) {
      this.personnelCategoryService
        .getPersonnelCategories(this.getMandantId(), false, this.focusDate, true)
        .subscribe((data) => {
          this.activePersonnelCategories = data.map(
            (personnelCategory) =>
              ({
                label: personnelCategory.tacsId + ' ' + personnelCategory.description[this.getLanguageCaseSensitive()],
                value: personnelCategory,
              }) as SelectItem
          );
          this.markForCheck();
          this.activePersonnelCategories.sort((a, b) => (a.label ?? '').localeCompare(b.label ?? ''));
        });
    }

    if (showEmployments) {
      this.employments = [];
      this.employeeService
        .list(this.getMandantId(), this.focusDate)
        .pipe(map((r) => r.entities))
        .subscribe((data) => {
          // eslint-disable-next-line max-len
          data.forEach((p) =>
            p.employments
              .filter((e) => e.mandantId === this.getMandantId())
              .filter((e) => e.validFrom <= this.focusDate && (!e.validTo || this.focusDate <= e.validTo))
              .filter((value, index, self) => self.findIndex((e) => e.employmentId === value.employmentId) === index)
              .forEach((e) =>
                this.employments.push({
                  value: {
                    employment: e,
                    label: p.employeeId + '-' + e.employmentId + ' ' + p.firstName + ' ' + p.lastName,
                  },
                  label: p.employeeId + '-' + e.employmentId + ' ' + p.firstName + ' ' + p.lastName,
                } as SelectItem)
              )
          );
          this.employments.sort((a, b) => (a.label ?? '').localeCompare(b.label ?? ''));
          this.markForCheck();
        });
    }

    this.load(true);
  }

  ngOnInit(): void {
    this.activatedRoute.data.subscribe((data) => (this.type = data.type));
    this.setBreadcrumbs([
      {
        label: this.isVariableAssignment()
          ? 'MENU.VARIABLES'
          : this.isStatisticsCodeAssignment()
            ? 'MENU.STATISTICS_CODES'
            : 'MENU.BENEFICIARY',
      },
      {
        label: this.isVariableAssignment()
          ? 'MENU.VARIABLES_ASSIGNMENT'
          : this.isStatisticsCodeAssignment()
            ? 'MENU.STATISTICS_CODES_ASSIGNMENT'
            : 'MENU.BENEFICIARY_ASSIGNMENT',
      },
    ]);
    super.ngOnInit();
    this.initViewMenuModel();
  }

  flattenTree(tree: TreeNode[]): TreeNode[] {
    const nodes: TreeNode[] = [];
    tree.forEach((node) => {
      nodes.push(node);
      if (node.children) {
        nodes.push(...this.flattenTree(node.children));
      }
    });
    return nodes;
  }

  focusDateChange(focusDate?: Date): void {
    if (focusDate != null) {
      this.focusDate = focusDate;
      this.updateView(this.showPersonnelCategories, this.showEmployments);
    }
  }

  showActivate(): void {
    this.displayActivateDialog = true;
    this.updateValidDates();
    this.form = this.fb.group({
      from: this.fb.nonNullable.control(this.focusDate, { validators: Validators.required }),
      to: this.fb.control(null as Date | null, (control) =>
        control.value == null || (this.form?.getRawValue().from ?? this.focusDate) <= control.value
          ? null
          : { invalidPeriod: true }
      ),
    });
  }

  saveActivate(): void {
    const formValue = this.form?.getRawValue();
    if (!formValue || !this.elements) {
      return;
    }
    this.submitting = true;
    const selectedIds = this.selection.map((s) => s.id);
    const selectedElements = this.elements.filter((e) => selectedIds.includes(e.id));
    const elementsToUpdate: AssignmentElement[] = [];
    selectedElements?.forEach((element) => {
      const elementToUpdate: AssignmentElement = {
        id: element.id,
        name: element.name,
        organizationAssignments: {},
        employmentAssignments: {},
        personnelCategoryAssignments: {},
        isMeasure: element.isMeasure,
      };
      this.columnSelection
        .filter((c) => 'field' in c)
        .map((c) => c as Column)
        .forEach((column) => {
          const assignmentType: AssignmentType = column.metaData.type;
          const assignment = (element[assignmentType] ?? {})[column.field] ?? [];
          elementToUpdate[assignmentType][column.field] = [
            ...assignment,
            {
              from: formValue.from,
              to: formValue.to ?? undefined,
            } as Assignment,
          ];
        });
      elementsToUpdate.push(elementToUpdate);
    });
    this.update(elementsToUpdate)
      .pipe(
        finalize(() => {
          this.submitting = false;
          this.markForCheck();
        })
      )
      .subscribe({
        next: (elements) => {
          this.successfullySaved(elements);
          this.displayActivateDialog = false;
          this.markForCheck();
        },
        error: () => this.showErrorMessage(),
      });
  }

  selectionChange(event: AnyValue): void {
    this.selection = event;
    this.updateButtons();
  }

  columnSelectionChange(event: AnyValue): void {
    this.columnSelection = event;
    this.updateButtons();
  }

  updateButtons(): void {
    const enabled = this.columnSelection.length > 0 && this.selection.length > 0;
    this.activeEnabled = enabled;
    this.deactivateEnabled = enabled;
    const selectedIds = this.selection.map((s) => s.id);
    const selectedRows = this.value?.filter((e) => selectedIds.includes(e.id));
    for (const column of this.columnSelection.filter((c) => 'field' in c).map((c) => c as Column)) {
      for (const row of selectedRows) {
        if (row[column.field]?.length > 0) {
          this.activeEnabled = false;
        }
        if (row[column.field]?.length === 0 || row[column.field][0].to) {
          this.deactivateEnabled = false;
        }
        if (!this.activeEnabled && !this.deactivateEnabled) {
          return;
        }
      }
    }
  }

  showDeactivate(): void {
    const details = `<ul>${this.selection.map((d) => `<li>${d.name}</li>`).join('')}</ul>`;
    const date = formatDate(this.focusDate, 'dd.MM.yyyy', 'en-US');
    this.confirm({
      header: this.translate('ASSIGNMENT.DEACTIVATE_SELECTED'),
      message: this.translate('ASSIGNMENT.DEACTIVATE_SELECTED_TEXT', { date }) + details,
      accept: () => {
        const selectedIds = this.selection.map((s) => s.id);
        const selectedElements = this.elements?.filter((e) => selectedIds.includes(e.id));
        const elementsToUpdate: AssignmentElement[] = [];
        selectedElements?.forEach((element) => {
          const elementToUpdate: AssignmentElement = {
            id: element.id,
            name: element.name,
            organizationAssignments: {},
            employmentAssignments: {},
            personnelCategoryAssignments: {},
            isMeasure: element.isMeasure,
          };
          elementsToUpdate.push(elementToUpdate);
          this.columnSelection
            .filter((c) => 'field' in c)
            .map((c) => c as Column)
            .forEach((column) => {
              const assignmentType: AssignmentType = column.metaData.type;
              const assignment = (element[assignmentType] ?? {})[column.field].filter((a) => !a.to)[0];
              assignment.to = this.focusDate;

              elementToUpdate[assignmentType][column.field] = (element[assignmentType] ?? {})[column.field];
            });
        });

        this.update(elementsToUpdate).subscribe({
          next: (elements) => {
            this.successfullySaved(elements);
            this.displayActivateDialog = false;
            this.markForCheck();
          },
          error: () => this.showErrorMessage(),
        });
      },
    });
  }

  loadAssignments(): void {
    this.loading = true;
    const organisationIds = this.visibleColumns.filter((c) => c.data.organizationId).map((c) => c.data.id);
    let sources;
    if (this.isVariableAssignment()) {
      sources = this.variableService.getAssignments(this.getMandantId(), this.focusDate, organisationIds);
    } else if (this.isStatisticsCodeAssignment()) {
      sources = this.statisticsCodeService.getAssignments(this.getMandantId(), this.focusDate, organisationIds);
    } else if (this.isBeneficiaryAssignment()) {
      sources = this.beneficiaryService.getAssignments(this.getMandantId(), this.focusDate, organisationIds);
    }

    sources?.subscribe((elements) => {
      this.loading = false;
      this.elements = elements;
      this.updateColumns();
      this.updateValues();
      this.markForCheck();
    });
  }

  addPersonnelCategory(selection: TreeNode[], value: AnyValue, personnelCategoryOverlayPanel: OverlayPanel): void {
    selection
      .filter((d) => (d.children || []).length === 0)
      .forEach((d) => {
        if (d.data.personnelCategories.filter((e: AnyValue) => e.id === value.id).length > 0) {
          return;
        }
        d.data.personnelCategories.push({
          id: value.id,
          name: value.description[this.getLanguageCaseSensitive()],
          deletable: true,
        });
      });
    personnelCategoryOverlayPanel.hide();
    this.updateColumns();
    this.updateValues();
    this.markForCheck();
  }

  addEmployment(value: AnyValue, employmentOverlayPanel: OverlayPanel): void {
    employmentOverlayPanel.hide();
    const organization = this.flattenTree(this.tree).filter(
      (node) => node.data?.organizationId === value.employment.organisationalUnitId
    )[0];
    if (!organization) {
      return;
    }
    if (organization.data.employments.filter((e: AnyValue) => e.id === value.employment.id).length > 0) {
      return;
    }
    organization.data.employments.push({ id: value.employment.id, name: value.label, deletable: true });
    this.updateColumns();
    this.updateValues();
    this.markForCheck();
  }

  showHandbookDownload($event: AnyValue, overlayPanel: OverlayPanel): void {
    const items: TreeNode[] = [];
    this.organisationalUnitTree?.forEach((group) => {
      const groupNode: TreeNode = {
        label: group.name,
        data: (type: string) => this.download(type, 'GroupLevel', group.id, overlayPanel),
        children: [],
      };
      items.push(groupNode);
      group.organizationCategories.forEach((category) => {
        const categoryNode: TreeNode = {
          label: category.name,
          children: [],
          data: (type: string) => this.download(type, 'CategoryLevel', category.id, overlayPanel),
        };
        groupNode.children?.push(categoryNode);
        category.organizations?.forEach((organisation) => {
          const organisationNode: TreeNode = {
            label: organisation.name,
            children: [],
            data: (type: string) => this.download(type, 'OrganizationLevel', organisation.id, overlayPanel),
          };
          categoryNode.children?.push(organisationNode);
          organisation.employments?.forEach((employment) => {
            organisationNode.children?.push({
              label: employment.name,
              data: (type: string) => this.download(type, 'EmploymentLevel', employment.id, overlayPanel),
            });
          });
          organisation.personnelCategories?.forEach((personnelCategory) => {
            organisationNode.children?.push({
              label: personnelCategory.name,
              data: (type: string) =>
                this.download(
                  type,
                  'PersonnelCategoryLevel',
                  organisation.id + '_' + personnelCategory.id,
                  overlayPanel
                ),
            });
          });
          if (organisationNode.children?.length === 0) {
            organisationNode.children = undefined;
          }
        });
      });
    });
    this.handbookTree = items;
    overlayPanel.show($event);
  }

  toggleMeasures(): void {
    this.showMeasure = !this.showMeasure;
    this.updateValues();
    this.markForCheck();
  }

  isVariableAssignment(): boolean {
    return this.type === AssignmentRootType.VARIABLE;
  }

  isStatisticsCodeAssignment(): boolean {
    return this.type === AssignmentRootType.STATISTICS_CODE;
  }

  isBeneficiaryAssignment(): boolean {
    return this.type === AssignmentRootType.BENEFICIARY;
  }

  protected onMandantIdChange(): void {
    this.load();
  }

  private initViewMenuModel(): void {
    this.viewMenuModel.push({
      label: this.translate('ASSIGNMENT.FILTER.ORGANISATIONAL_UNITS'),
      icon: 'fal fa-sitemap',
      command: () => this.updateView(false, false),
    });
    this.viewMenuModel.push({
      label: this.translate('ASSIGNMENT.FILTER.PERSONNEL_CATEGORIES'),
      icon: 'fal fa-address-card',
      command: () => this.updateView(true, false),
    });
    this.viewMenuModel.push({
      label: this.translate('ASSIGNMENT.FILTER.EMPLOYMENTS'),
      icon: 'fal fa-users',
      command: () => this.updateView(false, true),
    });
  }

  private createOrganizationTree(groups: OrganizationCategoryGroup[]): TreeNode[] {
    const tree: TreeNode[] = [];
    groups.forEach((group) => {
      const groupNode: TreeNode = {
        label: group.name,
        children: [],
        expanded: true,
        data: group,
        key: group.id + '',
      };
      tree.push(groupNode);
      group.organizationCategories.forEach((category) => {
        const categoryNode: TreeNode = {
          label: category.name,
          key: group.id + '-' + category.id,
          children: [],
          expanded: true,
          parent: groupNode,
          data: category,
        };
        groupNode.children?.push(categoryNode);
        category.organizations.forEach((organisation) => {
          const organisationNode: TreeNode = {
            parent: categoryNode,
            label: organisation.name,
            data: organisation,
            key: group.id + '-' + category.id + '-' + organisation.id,
          };
          categoryNode.children?.push(organisationNode);
        });
      });
    });
    return tree;
  }

  private updateColumns(): void {
    const columns: Column[] = [
      {
        field: 'name',
        width: '400px',
        header: this.translate('GLOBAL.NAME'),
        filterType: 'html',
      },
    ];
    const groupHeaderGroup: HeaderGroup[] = [{ colSpan: 1, title: '', width: 400, frozen: true }];
    const categoryHeaderGroup: HeaderGroup[] = [{ colSpan: 1, title: '', width: 400, frozen: true }];
    const organizationHeaderGroup: HeaderGroup[] = [{ colSpan: 1, title: '', width: 400, frozen: true }];
    this.tree?.forEach((group) => {
      let groupColSpan = 0;
      group.children?.forEach((category) => {
        let categoryColSpan = 0;
        category.children
          ?.filter((node) => this.visibleColumns.includes(node))
          .forEach((node) => {
            const organization: Organization = node.data;
            const validFrom = AssignmentsComponent.calculateValidFrom(organization, category.data, group.data);
            const validTo = AssignmentsComponent.calculateValidTo(organization, category.data, group.data);
            const metaData: AssignmentColumnMeta = { validFrom, validTo, type: 'organizationAssignments' };
            if (this.showPersonnelCategories) {
              categoryColSpan += organization.personnelCategories.length + 1;
              organizationHeaderGroup.push({
                title: organization.name,
                colSpan: organization.personnelCategories.length + 1,
                minWidth: (organization.personnelCategories.length + 1) * 200,
              });
              columns.push(
                this.createColumn(organization.id, this.translate('ASSIGNMENT.ORGANISATIONAL_UNIT'), metaData)
              );
              columns.push(
                ...organization.personnelCategories.map((p) =>
                  this.createColumn(
                    organization.id + '_' + p.id,
                    p.name,
                    {
                      validFrom: maxDate(validFrom, p.validFrom),
                      validTo: minDate(validTo, p.validTo),
                      type: 'personnelCategoryAssignments',
                    },
                    p.deletable || this.isRodixAdmin()
                  )
                )
              );
            } else if (this.showEmployments) {
              categoryColSpan += organization.employments.length + 1;
              organizationHeaderGroup.push({
                title: organization.name,
                colSpan: organization.employments.length + 1,
                minWidth: (organization.employments.length + 1) * 200,
              });
              columns.push(
                this.createColumn(
                  organization.id,
                  this.translate('ASSIGNMENT.ORGANISATIONAL_UNIT'),

                  metaData
                )
              );
              columns.push(
                ...organization.employments.map((p) =>
                  this.createColumn(
                    p.id,
                    p.name,
                    {
                      type: 'employmentAssignments',
                      validFrom: maxDate(validFrom, p.validFrom),
                      validTo: minDate(validTo, p.validTo),
                    },
                    p.deletable || this.isRodixAdmin()
                  )
                )
              );
            } else {
              categoryColSpan += 1;
              columns.push(this.createColumn(organization.id, organization.name, metaData));
            }
          });
        if (categoryColSpan > 0) {
          categoryHeaderGroup.push({
            title: category.label ?? '',
            colSpan: categoryColSpan,
            minWidth: categoryColSpan * 200,
          });
          groupColSpan += categoryColSpan;
        }
      });
      if (groupColSpan > 0) {
        groupHeaderGroup.push({ title: group.label ?? '', colSpan: groupColSpan, minWidth: groupColSpan * 200 });
      }
    });
    this.headerGroups = [groupHeaderGroup, categoryHeaderGroup];
    if (this.showPersonnelCategories || this.showEmployments) {
      this.headerGroups.push(organizationHeaderGroup);
    }
    this.columns = columns;
  }

  private createColumn = (
    field: string | number,
    header: string,
    metaData: AssignmentColumnMeta,
    deletable = false
  ): Column => ({
    field: field + '',
    minWidth: '200px',
    header,
    deletable,
    editable: true,
    onEdit: (value, column, row) => this.edit(row, column),
    filterType: 'dateRangeAssignment',
    metaData: metaData,
  });

  private updateValues(): void {
    if (!this.elements) {
      return;
    }
    const organizationIds = this.columns
      .filter((c) => c.metaData?.type === 'organizationAssignments')
      .map((c) => c.field);
    const personnelCategoryIds = this.columns
      .filter((c) => c.metaData?.type === 'personnelCategoryAssignments')
      .map((c) => c.field);
    const employmentIds = this.columns.filter((c) => c.metaData?.type === 'employmentAssignments').map((c) => c.field);
    this.value = this.elements
      .filter((e) => this.showValue(e))
      .map((e: AssignmentElement) => {
        const newAssignment: AnyValue = {};
        organizationIds.forEach(
          (id) => (newAssignment[id] = (e.organizationAssignments ?? {})[id]?.filter((a) => this.isFocused(a)) ?? [])
        );
        // eslint-disable-next-line max-len
        personnelCategoryIds.forEach(
          (id) =>
            (newAssignment[id] = (e.personnelCategoryAssignments ?? {})[id]?.filter((a) => this.isFocused(a)) ?? [])
        );
        employmentIds.forEach(
          (id) => (newAssignment[id] = (e.employmentAssignments ?? {})[id]?.filter((a) => this.isFocused(a)) ?? [])
        );
        return {
          ...newAssignment,
          id: e.id,
          name: e.name,
          _style_name: {
            'background-color': this.getBackgroundColor(e.name),
            color: this.getForegroundColor(e.name),
          },
          _html_name: this.domSanitizer.bypassSecurityTrustHtml(
            `<span style="white-space: pre" title="${e.name}">${this.formatName(e)}</span>`
          ),
          validFrom: e.validFrom,
          validTo: e.validTo,
        };
      });
  }

  private showValue(e: AssignmentElement): boolean {
    if (!this.isVariableAssignment()) {
      return true;
    }
    if (this.showMeasure) {
      return true;
    }
    if (!e.isMeasure) {
      return true;
    }
    if (Object.keys(e.organizationAssignments ?? {}).length) {
      return true;
    }
    if (this.showEmployments && Object.keys(e.employmentAssignments ?? {}).length) {
      return true;
    }
    if (this.showPersonnelCategories && Object.keys(e.personnelCategoryAssignments ?? {}).length) {
      return true;
    }
    return false;
  }

  private formatName(e: AssignmentElement): string {
    if (!this.isVariableAssignment()) {
      return e.name ?? '';
    }
    const numberOfIndention = (e.name ?? '').split('.').length - 1;
    if (numberOfIndention <= 0) {
      return e.name ?? '';
    }
    return new Array(numberOfIndention).fill('&nbsp;&nbsp;&nbsp;&nbsp;').join('') + e.name;
  }

  private isFocused(assignment: Assignment): boolean {
    return assignment.from <= this.focusDate && (!assignment.to || this.focusDate <= assignment.to);
  }

  private edit(row: AnyValue, column: Column): void {
    const assignmentType: AssignmentType = column.metaData.type;
    const elements = this.elements
      ?.filter((e) => e.id === row.id && (e[assignmentType] ?? {})[column.field])
      .map((e) => (e[assignmentType] ?? {})[column.field])[0];
    const ref = this.dialogService.open(AssignmentDetailComponent, {
      header: this.translate('GLOBAL.VALIDITY_PERIOD'),
      contentStyle: { 'max-height': '500px', overflow: 'auto' },
      baseZIndex: 10000,
      modal: true,
      data: {
        mandantId: this.getMandantId(),
        organisationId: column.field,
        variableId: row.id,
        columnValidFrom: column.metaData.validFrom,
        columnValidTo: column.metaData.validTo,
        rowName: row.name,
        columnName: column.header,
        rowValidFrom: row.validFrom,
        rowValidTo: row.validTo,
        type: this.type,
        editable: this.isRodixAdmin(),
        assignmentType,
        elements,
      },
      width: '500px',
    });
    ref.onClose.subscribe((result) => {
      if (result) {
        this.elements
          ?.filter((e) => e.id === row.id)
          .forEach((e) => ((e[assignmentType] ?? {})[column.field] = result));
        this.updateValues();
        this.markForCheck();
      }
    });
  }

  private updateValidDates(): void {
    let validFrom = new Date(1, 1);
    let validTo = new Date(2999, 12);
    this.selection.forEach((selection) => {
      this.columnSelection
        .filter((c) => 'field' in c)
        .map((c) => c as Column)
        .forEach(({ field, metaData }) => {
          const meta: AssignmentColumnMeta = metaData;
          const element = this.elements?.find((e) => e.id === selection.id);
          if (element) {
            const validFroms =
              element[meta.type][field]?.map((a: Assignment) => {
                if (!a.to) {
                  return null;
                }
                const date = new Date(a.to.getTime());
                date.setDate(date.getDate() + 1);
                return date;
              }) ?? [];
            validFrom = maxDate(validFrom, selection.validFrom, meta.validFrom, ...validFroms) ?? validFrom;
            validTo = minDate(validTo, selection.validTo, meta.validTo) ?? validTo;
          }
        });
    });
    this.validFrom = validFrom;
    this.validTo = validTo;
  }

  private successfullySaved(elements: AssignmentElement[]): void {
    elements.forEach((newElement) => {
      const originalElement = this.elements?.filter((o) => o.id === newElement.id)[0];
      if (originalElement) {
        this.updateElement(newElement, originalElement, 'organizationAssignments');
        this.updateElement(newElement, originalElement, 'personnelCategoryAssignments');
        this.updateElement(newElement, originalElement, 'employmentAssignments');
      }
    });
    this.showSuccessMessage();
    this.columnSelection = [];
    this.selection = [];
    this.columns = [...this.columns];
    this.updateValues();
    this.updateButtons();
  }

  private updateElement = (
    newElement: AssignmentElement,
    originalElement: AssignmentElement,
    type: AssignmentType
  ): void => {
    if (!newElement[type]) {
      return;
    }
    if (!originalElement[type]) {
      originalElement[type] = {};
    }
    for (const key of Object.keys(newElement[type])) {
      originalElement[type][key] = newElement[type][key];
    }
  };

  private load(keepVisibleColumns = false): void {
    const oldVisibleColumns = this.visibleColumns.map((c) => c.data.id);
    this.columns = [];
    this.value = [];
    this.headerGroups = [];
    this.visibleColumns = [];
    this.tree = [];
    this.selection = [];
    this.columnSelection = [];
    this.markForCheck();
    let sources;
    if (this.isVariableAssignment()) {
      sources = this.variableService.getVariableOrganisationalUnitTree(this.getMandantId(), this.focusDate);
    } else if (this.isStatisticsCodeAssignment()) {
      sources = this.statisticsCodeService.getOrganisationalUnitTree(this.getMandantId(), this.focusDate);
    } else if (this.isBeneficiaryAssignment()) {
      sources = this.beneficiaryService.getOrganisationalUnitTree(this.getMandantId(), this.focusDate);
    }
    sources?.subscribe((organisationalUnitTree) => {
      this.organisationalUnitTree = organisationalUnitTree;
      this.tree = this.createOrganizationTree(this.organisationalUnitTree);
      this.markForCheck();

      this.visibleColumns = this.flattenTree(this.tree);

      if (keepVisibleColumns) {
        this.visibleColumns = this.visibleColumns.filter((c) => oldVisibleColumns.includes(c.data.id));
      } else {
        if (this.visibleColumns.length > maxColumns) {
          this.visibleColumns = [];
        }
      }

      this.loadAssignments();
    });
  }

  private getBackgroundColor(name?: string): string {
    if (!name || !this.isVariableAssignment()) {
      return 'rgba(255,255,255)';
    }
    if (name.startsWith('2')) {
      return 'rgba(230,0,60,1)';
    }
    if (name.startsWith('3')) {
      return 'rgba(255,205,10,1)';
    }
    if (name.startsWith('4')) {
      return 'rgba(175,200,15,1)';
    }
    if (name.startsWith('5')) {
      return 'rgba(0,140,210,1)';
    }
    return 'rgba(255,255,255)';
  }

  private getForegroundColor(name?: string): string {
    if (!name || !this.isVariableAssignment()) {
      return 'rgba(73,89,87,1)';
    }
    if (name.startsWith('2')) {
      return 'rgb(255,255,255)';
    }
    if (name.startsWith('3')) {
      return 'rgba(73,89,87,1)';
    }
    if (name.startsWith('4')) {
      return 'rgba(73,89,87,1)';
    }
    if (name.startsWith('5')) {
      return 'rgb(255,255,255)';
    }
    return 'rgba(73,89,87,1)';
  }

  private update(elementsToUpdate: AssignmentElement[]): Observable<AssignmentElement[]> {
    let updateOperation: Observable<AssignmentElement[]>;
    if (this.isVariableAssignment()) {
      updateOperation = this.variableService.updateAssignments(this.getMandantId(), elementsToUpdate);
    } else if (this.isStatisticsCodeAssignment()) {
      updateOperation = this.statisticsCodeService.updateAssignments(this.getMandantId(), elementsToUpdate);
    } else if (this.isBeneficiaryAssignment()) {
      updateOperation = this.beneficiaryService.updateAssignments(this.getMandantId(), elementsToUpdate);
    } else {
      throw new Error('unknown update operation in assignments');
    }
    return updateOperation;
  }

  private download(type: string, groupLevel: string, id: number | string, overlayPanel: OverlayPanel): void {
    overlayPanel.hide();
    this.variableService
      .generateHandbook(this.getMandantId(), type, groupLevel, id, this.getLanguageCaseSensitive(), this.focusDate)
      .subscribe((result) => {
        const contentDisposition = result.headers.get('Content-Disposition');
        const regex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/gi;
        let name = contentDisposition.match(regex)[0];
        name = name.substring('filename='.length).replace(/"/g, '');
        downloadBlob(result.body, name);
      });
  }
}

interface AssignmentColumnMeta {
  validFrom: Date | null;
  validTo: Date | null;
  type: AssignmentType;
}
