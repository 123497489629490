import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

class TacsCodeKreis {}

@Injectable({
  providedIn: 'root',
})
export class TacsCodeKreisService {
  private readonly baseUrl = `api/1.0/tacscodekreis`;

  constructor(private httpClient: HttpClient) {}

  getTacsCodeKreis(variablenartId: number): Observable<TacsCodeKreis[]> {
    const params = new HttpParams().append('variablenartId', variablenartId + '');
    return this.httpClient.get<TacsCodeKreis[]>(this.baseUrl, { params });
  }
}
