import { ChangeDetectionStrategy, Component, Injector, OnInit } from '@angular/core';
import { AbstractContentComponent } from '../../abstract-content.component';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { Context } from '@service/context.service';
import { StatisticsCode } from '@entity/statistics-code';
import { StatisticsCodeService } from '@service/statistics-code.service';
import { finalize } from 'rxjs';
import { FormButtonBarComponent } from '@shared/form-button-bar/form-button-bar.component';
import { FormDateInputComponent } from '@shared/form-date-input/form-date-input.component';
import { FormTextInputComponent } from '@shared/form-text-input/form-text-input.component';
import { CardModule } from 'primeng/card';
import { FormNumberInputComponent } from '@shared/form-number-input/form-number-input.component';

@Component({
  selector: 'app-statistics-code-detail',
  templateUrl: './statistics-code-detail.component.html',
  styleUrls: ['./statistics-code-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CardModule,
    FormsModule,
    ReactiveFormsModule,
    FormTextInputComponent,
    FormDateInputComponent,
    FormButtonBarComponent,
    FormNumberInputComponent,
  ],
})
export class StatisticsCodeDetailComponent extends AbstractContentComponent implements OnInit {
  public title!: string;
  public form!: FormGroup<{
    code: FormControl<string>;
    name: FormControl<string>;
    description: FormControl<string | undefined>;
    costCenter: FormControl<string>;
    validFrom: FormControl<Date>;
    validTo: FormControl<Date | undefined>;
    sortNumber: FormControl<number | undefined>;
  }>;
  submitting = false;
  private statisticsCode: StatisticsCode = { deletable: true } as StatisticsCode;

  constructor(
    private statisticsCodeService: StatisticsCodeService,
    private fb: FormBuilder,
    injector: Injector
  ) {
    super(injector, Context.Global);
  }

  ngOnInit(): void {
    this.activatedRoute.data.subscribe((data) => {
      this.statisticsCode = data.statisticsCode ?? this.statisticsCode;
    });
    this.initializeForm();
    this.onLanguageChange();
  }

  minDate(): ValidatorFn {
    return (control) => {
      if (!this.form || !control.value) {
        return null;
      }
      const valid = control.value >= this.form.getRawValue().validFrom;
      return valid ? null : { minDate: false };
    };
  }

  onSubmit(): void {
    this.submitting = true;
    const formValue = this.form.value;
    const updatedStatisticsCode: StatisticsCode = {
      ...this.statisticsCode,
      ...formValue,
    };
    const statisticsCodeObservable = this.statisticsCode.id
      ? this.statisticsCodeService.update(this.getMandantId(), this.statisticsCode.id, updatedStatisticsCode)
      : this.statisticsCodeService.create(this.getMandantId(), updatedStatisticsCode);
    statisticsCodeObservable.pipe(finalize(() => (this.submitting = false))).subscribe({
      next: () => {
        this.showSuccessMessage();
        this.navigate(['/statistics-codes/overview']);
      },
      error: () => {
        this.showErrorMessage();
      },
    });
  }

  protected onLanguageChange(): void {
    this.title = this.statisticsCode.id
      ? this.translate('STATISTICS_CODES.EDIT_TITLE', this.statisticsCode)
      : this.translate('STATISTICS_CODES.CREATE_TITLE');

    this.setBreadcrumbs([
      {
        label: 'MENU.STATISTICS_CODES',
        routerLink: ['/statistics-codes/overview'],
      },
      {
        label: this.title,
      },
    ]);
  }

  private initializeForm(): void {
    this.form = this.fb.nonNullable.group({
      code: new FormControl(
        {
          value: this.statisticsCode.code,
          disabled: !this.statisticsCode.deletable,
        },
        { validators: Validators.required, nonNullable: true }
      ),
      name: [this.statisticsCode.name, Validators.required],
      description: this.statisticsCode.description,
      costCenter: this.statisticsCode.costCenter,
      validFrom: [this.statisticsCode.validFrom ?? new Date(), Validators.required],
      validTo: [this.statisticsCode.validTo, this.minDate()],
      sortNumber: [this.statisticsCode.sortNumber],
    });
  }
}
