import { ChangeDetectionStrategy, Component, Injector, OnInit } from '@angular/core';
import { VariableApproval } from '@entity/variable';
import { AbstractContentComponent } from '../../../abstract-content.component';
import { Context } from '@service/context.service';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { AuthorizationService } from '@service/authorization.service';
import { VariableService } from '@service/variable.service';
import { Language, LanguageFormGroup } from '@entity/language';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { SharedModule } from 'primeng/api';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { DatePipe } from '@angular/common';
import { FormTextInputComponent } from '@shared/form-text-input/form-text-input.component';
import { CardModule } from 'primeng/card';

@Component({
  selector: 'app-variable-approval-detail',
  templateUrl: './variable-approval-detail.component.html',
  styleUrls: ['./variable-approval-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CardModule,
    FormTextInputComponent,
    InputTextareaModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    ButtonModule,
    DatePipe,
    TranslateModule,
  ],
})
export class VariableApprovalDetailComponent extends AbstractContentComponent implements OnInit {
  variableApproval!: VariableApproval;
  commentControl = new FormControl('', { validators: Validators.required, nonNullable: true });
  form!: FormGroup<{
    mandant: FormControl<string | undefined>;
    creatorName: FormControl<string | undefined>;
    creatorEmail: FormControl<string | undefined>;
    variable: FormGroup<{
      parentTacsId: FormControl<string | undefined>;
      parentName: FormControl<string | undefined>;
      tacsId: FormControl<string | undefined>;
      name: FormControl<string | undefined>;
      customName: FormGroup<LanguageFormGroup>;
      customComment: FormGroup<LanguageFormGroup>;
    }>;
  }>;
  name!: string;
  languages: Language[] = [];
  observer = {
    next: () => {
      this.showSuccessMessage();
      this.navigate('..');
    },
    error: () => this.showErrorMessage(),
  };

  constructor(
    injector: Injector,
    private formBuilder: FormBuilder,
    private authService: AuthorizationService,
    private variableService: VariableService
  ) {
    super(injector, Context.Global);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.languages = this.getMandantLanguages();
    this.activatedRoute.data.subscribe((data) => {
      this.variableApproval = data.variableApproval;
      this.form = this.formBuilder.nonNullable.group({
        mandant: this.variableApproval.mandant,
        creatorName: this.variableApproval.creatorName,
        creatorEmail: this.variableApproval.creatorEmail,
        variable: this.formBuilder.nonNullable.group({
          parentTacsId: this.variableApproval?.variable?.parentTacsId,
          parentName: this.variableApproval?.variable?.parentName,
          tacsId: this.variableApproval?.variable?.tacsId,
          name: this.variableApproval?.variable?.name,
          customName: this.formBuilder.nonNullable.group({
            De: this.variableApproval.variable?.customName?.De,
            Fr: this.variableApproval.variable?.customName?.Fr,
            It: this.variableApproval.variable?.customName?.It,
          }),
          customComment: this.formBuilder.nonNullable.group({
            De: this.variableApproval.variable?.customComment?.De,
            Fr: this.variableApproval.variable?.customComment?.Fr,
            It: this.variableApproval.variable?.customComment?.It,
          }),
        }),
      });
      this.form.disable();
    });

    this.name = this.authService.getFullName();

    this.setBreadcrumbs([
      { label: 'MENU.VARIABLES' },
      { label: 'MENU.VARIABLE_APPROVAL', routerLink: ['/variables/approvals'] },
      {
        label:
          this.translate('VARIABLES.APPROVAL_TYPES.' + this.variableApproval.type) +
          ' ' +
          this.translate('VARIABLES.APPROVAL_STATES.' + this.variableApproval.state),
      },
    ]);
  }

  accept(): void {
    this.variableService.accept(this.variableApproval.id, this.commentControl.value).subscribe(this.observer);
  }

  reject(): void {
    this.variableService.reject(this.variableApproval.id, this.commentControl.value).subscribe(this.observer);
  }

  comment(): void {
    this.variableService.comment(this.variableApproval.id, this.commentControl.value).subscribe(this.observer);
  }
}
