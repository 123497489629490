<app-table
  filename="MENU.SERVICE_ACCOUNTS"
  [actionColumnWidth]="1400"
  [columns]="columns"
  [loading]="loading"
  [value]="serviceAccounts">
  <ng-template
    appToolbarButton
    icon="fal fa-plus"
    labelKey="SERVICE_ACCOUNTS.CREATE_SERVICE_ACCOUNT"
    (onClick)="createServiceAccount()"></ng-template>

  <ng-template
    appActionButton
    icon="fal fa-lock-open"
    labelKey="SERVICE_ACCOUNTS.SHOW_CLIENT_SECRET"
    (onClick)="showClientSecret($event.row, $event.event, overlayPanel)"></ng-template>

  <ng-template
    appActionButton
    displayIf="enabled"
    icon="fal fa-pause"
    labelKey="SERVICE_ACCOUNTS.DEACTIVATE_ACCOUNT"
    type="warning"
    (onClick)="deactivateServiceAccount($event.row)"></ng-template>

  <ng-template
    appActionButton
    displayIfNot="enabled"
    icon="fal fa-play"
    labelKey="SERVICE_ACCOUNTS.ACTIVATE_ACCOUNT"
    type="warning"
    (onClick)="activateServiceAccount($event.row)"></ng-template>

  <ng-template
    appActionButton
    icon="fal fa-trash"
    labelKey="SERVICE_ACCOUNTS.DELETE_ACCOUNT"
    type="danger"
    (onClick)="deleteServiceAccount($event.row)"></ng-template>

  <ng-template
    appActionButton
    displayIf="enabled"
    icon="fal fa-undo"
    labelKey="SERVICE_ACCOUNTS.RESET_CLIENT_SECRET"
    type="warning"
    (onClick)="resetClientSecret($event.row)"></ng-template>

  <ng-template
    appActionButton
    icon="fal fa-file-alt"
    labelKey="SERVICE_ACCOUNTS.SHOW_CONFIG"
    (onClick)="showConfiguration($event.row)"></ng-template>
</app-table>

<p-overlayPanel #overlayPanel>
  @if (clientSecret) {
    <pre style="display: inline-block">{{ clientSecret.value }}</pre>
    &nbsp;
    <p-button
      icon="fal fa-copy"
      type="button"
      [appCopyClipboard]="clientSecret.value"
      [title]="'ACTIONS.COPY' | translate"></p-button>
  }
</p-overlayPanel>

<p-dialog
  [(visible)]="showConfigurationModal"
  [baseZIndex]="10000"
  [draggable]="false"
  [header]="'SERVICE_ACCOUNTS.CONFIGURATION' | translate"
  [modal]="true"
  [resizable]="false"
  [style]="{ width: '75vw' }">
  @if (showConfigurationModal) {
    <pre class="configuration">
      <code class="language-yaml"
            pCode>{{ configuration }}</code>
    </pre>
  }
  <p-footer>
    <p-button
      icon="fal fa-copy"
      type="button"
      [appCopyClipboard]="configuration"
      [label]="'ACTIONS.COPY' | translate"></p-button>
    <p-button
      icon="fal fa-download"
      type="button"
      (onClick)="downloadConfiguration()"
      [label]="'ACTIONS.DOWNLOAD' | translate"></p-button>
    <p-button
      icon="fal fa-times"
      type="button"
      (onClick)="showConfigurationModal = false"
      [label]="'ACTIONS.CLOSE' | translate"></p-button>
  </p-footer>
</p-dialog>
