import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Language } from '@entity/language';

export enum Context {
  Global,
  Anwenderbetrieb,
  Mandant,
}

@Injectable({
  providedIn: 'root',
})
export class ContextService {
  private readonly anwenderbetriebId: BehaviorSubject<number | null>;
  private readonly mandantId: BehaviorSubject<number | null>;
  private readonly context = new BehaviorSubject(Context.Global);
  private mandantLanguages: Language[] = ['De'];

  constructor() {
    this.anwenderbetriebId = new BehaviorSubject(ContextService.getValue('anwenderbetriebId'));
    this.mandantId = new BehaviorSubject(ContextService.getValue('mandantId'));
    const mandantLanguages = window.localStorage.getItem('mandantLanguages');
    if (mandantLanguages) {
      this.mandantLanguages = JSON.parse(mandantLanguages);
    }
  }

  private static getValue(key: string): number | null {
    const item = window.localStorage.getItem(key);
    if (item) {
      return parseInt(item, 0);
    } else {
      return null;
    }
  }

  private static setValue(
    value: number | null | undefined,
    key: string,
    subject: BehaviorSubject<number | null>
  ): void {
    if (value) {
      value = parseInt(value + '', 0);
      subject.next(value);
      window.localStorage.setItem(key, value + '');
    } else {
      subject.next(null);
      window.localStorage.removeItem(key);
    }
  }

  setContext(value: Context): void {
    this.context.next(value);
  }

  getContext(): Observable<Context> {
    return this.context.asObservable();
  }

  setAnwenderbetriebId(anwenderbetriebId?: number | null): void {
    ContextService.setValue(anwenderbetriebId, 'anwenderbetriebId', this.anwenderbetriebId);
  }

  getAnwenderbetriebId(): Observable<number | null> {
    return this.anwenderbetriebId.asObservable();
  }

  getAnwenderbetriebIdInstant(): number | null {
    return this.anwenderbetriebId.getValue();
  }

  setMandantId(mandantId: number | undefined): void {
    ContextService.setValue(mandantId, 'mandantId', this.mandantId);
  }

  getMandantId(): Observable<number | null> {
    return this.mandantId.asObservable();
  }

  getMandantLanguages(): Language[] {
    return this.mandantLanguages;
  }

  getMandantIdInstant(): number {
    const id = this.mandantId.getValue();
    if (!id) {
      throw new Error('Mandant id is not set!');
    }
    return id;
  }

  hasMandantId(): boolean {
    return !!this.mandantId.getValue();
  }

  hasAnwenderbetriebId(): boolean {
    return !!this.anwenderbetriebId.getValue();
  }

  setLanguages(languages: Language[]): void {
    this.mandantLanguages = languages;
    window.localStorage.setItem('mandantLanguages', JSON.stringify(languages));
  }
}
