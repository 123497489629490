import { ChangeDetectionStrategy, Component } from '@angular/core';
import { SharedModule } from 'primeng/api';
import { MessagesModule } from 'primeng/messages';
import { PanelModule } from 'primeng/panel';

@Component({
  selector: 'app-version',
  templateUrl: './version.component.html',
  styleUrls: ['./version.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [PanelModule, MessagesModule, SharedModule],
})
export class VersionComponent {}
