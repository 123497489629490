<app-form-element [control]="formControl" [id]="id" [label]="label" [required]="required" [twoColumns]="twoColumns">
  <p-calendar
    [appendTo]="'body'"
    [defaultDate]="defaultDate"
    [formControl]="formControl"
    [id]="id"
    [maxDate]="internalMaxDate"
    [minDate]="internalMinDate"
    [showIcon]="true"
    [style]="{ width: '100%' }"
    [timeOnly]="timeOnly"
    dateFormat="dd.mm.yy"></p-calendar>
</app-form-element>
