import { Injectable } from '@angular/core';
import { Customer } from '@entity/customer';
import { HttpClient } from '@angular/common/http';
import { AbstractCrudMandantService } from '@service/abstract-crud-mandant.service';

@Injectable({
  providedIn: 'root',
})
export class CustomerService extends AbstractCrudMandantService<Customer> {
  constructor(httpClient: HttpClient) {
    super('customers', httpClient);
  }
}
