<p-card [header]="'SERVICE_ACCOUNTS.CREATE_SERVICE_ACCOUNT' | translate">
  <form (ngSubmit)="onSubmit()" [formGroup]="form" class="grid" novalidate>
    @if (anwenderbetriebOrMandantOptions.length > 1) {
      <app-form-dropdown
        [filter]="true"
        [form]="form"
        [label]="('USERS.ANWENDERBETRIEB' | translate) + ' / ' + ('USERS.MANDANT' | translate)"
        [options]="anwenderbetriebOrMandantOptions"
        id="anwenderbetriebOrMandant"></app-form-dropdown>
    }

    <app-form-switch
      [form]="form"
      [label]="'SERVICE_ACCOUNTS.HOSPITAL_INFORMATION_SYSTEM' | translate"
      id="hospitalInformationSystem"></app-form-switch>

    <app-form-button-bar [form]="form" [saveDisabled]="submitting"></app-form-button-bar>
  </form>
</p-card>
