<p-card [header]="'GLOBAL.DETAILS' | translate">
  <form (ngSubmit)="onSubmit()" [formGroup]="form" class="grid">
    @if (variable.parentTacsId) {
      <app-form-text-input
        [form]="form"
        [twoColumns]="false"
        id="parent"
        label="VARIABLES.PARENT_NAME"></app-form-text-input>
    }

    <app-form-text-input [form]="form" [twoColumns]="false" id="tacsId" label="VARIABLES.TACS_ID"></app-form-text-input>

    @for (language of languages; track language) {
      <app-form-text-input
        [form]="form"
        [id]="'name.' + language"
        [label]="'GLOBAL.NAME_' + language"
        [twoColumns]="false"></app-form-text-input>
    }

    @for (language of languages; track language) {
      <app-form-text-input
        [form]="form"
        [id]="'description.' + language"
        [label]="'GLOBAL.DESCRIPTION_' + language"
        [textArea]="true"
        [twoColumns]="false"></app-form-text-input>
    }

    @for (language of languages; track language) {
      <app-form-text-input
        [form]="form"
        [id]="'comment.' + language"
        [label]="'VARIABLES.COMMENT_' + language"
        [textArea]="true"
        [twoColumns]="false"></app-form-text-input>
    }

    <app-form-number-input
      [form]="form"
      [twoColumns]="false"
      id="sortIndex"
      label="GLOBAL.SORT_NUMBER"></app-form-number-input>

    <app-form-number-input
      [form]="form"
      [twoColumns]="false"
      id="leistungsgewichtKategorie"
      label="VARIABLES.LEISTUNGSGEWICHT_KATEGORIE"></app-form-number-input>

    <app-form-number-input
      [form]="form"
      [twoColumns]="false"
      id="leistungsgewicht"
      label="VARIABLES.LEISTUNGSGEWICHT"></app-form-number-input>

    <app-var-healthcare-form-element
      [form]="form"
      [twoColumns]="false"
      id="varHealthcareDocumentIds"
      label="VARIABLES.VAR_HEALTHCARE" />

    <app-snomed-ct-detail-form-element
      [form]="form"
      [twoColumns]="false"
      id="snomedCtItems"
      label="VARIABLES.SNOMED_CT" />

    <app-form-text-input
      [form]="form"
      [twoColumns]="false"
      id="foreignCode"
      label="VARIABLES.FOREIGN_CODE"></app-form-text-input>

    <app-form-multiselect
      [form]="form"
      [options]="marketOptions"
      [twoColumns]="false"
      id="markets"
      label="MENU.MARKETS"></app-form-multiselect>

    <app-form-switch
      [form]="form"
      [twoColumns]="false"
      id="isAssignmentCustomer"
      label="VARIABLES.ASSIGNMENT_CUSTOMER"></app-form-switch>

    <app-form-switch
      [form]="form"
      [twoColumns]="false"
      id="isAssignmentEmployee"
      label="VARIABLES.ASSIGNMENT_EMPLOYEE"></app-form-switch>

    <app-form-switch [form]="form" [twoColumns]="false" id="isRebooking" label="VARIABLES.REBOOKING"></app-form-switch>

    <app-form-switch
      [form]="form"
      [twoColumns]="false"
      id="isTreatmentDuration"
      label="VARIABLES.TREATMENT_DURATION"></app-form-switch>

    <app-form-switch [form]="form" [twoColumns]="false" id="isPresence" label="VARIABLES.PRESENCE"></app-form-switch>

    <app-form-switch
      [form]="form"
      [twoColumns]="false"
      id="isProductivity"
      label="VARIABLES.PRODUCTIVITY"></app-form-switch>

    <app-form-switch
      [form]="form"
      [twoColumns]="false"
      id="isCostRelevant"
      label="VARIABLES.COST_RELEVANT"></app-form-switch>

    <app-form-date-input
      [form]="form"
      [twoColumns]="false"
      id="validFrom"
      label="GLOBAL.GUELTIG_AB"></app-form-date-input>

    <app-form-date-input
      [form]="form"
      [twoColumns]="false"
      id="validTo"
      label="GLOBAL.GUELTIG_BIS"></app-form-date-input>

    <div class="col-12">
      <app-form-element [label]="'MENU.MANDANTS' | translate" [twoColumns]="false">
        <ul>
          @for (mandant of variable.mandants; track mandant) {
            <li>{{ mandant.mandantId }} {{ mandant.bezeichnung }}</li>
          }
        </ul>
      </app-form-element>
    </div>

    <app-form-button-bar (onCancel)="cancel()" [form]="form" [saveDisabled]="submitting"></app-form-button-bar>
  </form>
</p-card>
