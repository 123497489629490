<app-tree-table
  filename="MENU.CATEGORIES"
  sortField="sortNumber"
  [actionColumnWidth]="700"
  [columns]="columns"
  [firstColumnFrozen]="true"
  [loading]="loading"
  [value]="tree">
  <ng-template
    appToolbarButton
    icon="fal fa-plus"
    labelKey="CATEGORIES.ADD_CATEGORY"
    (onClick)="menu.toggle($event)"></ng-template>

  <ng-template
    appActionButton
    icon="fal fa-eye"
    labelKey="CATEGORIES.SHOW_CLIENTS"
    (onClick)="showMandanten($event.row)"></ng-template>
  <ng-template
    appActionButton
    displayIf="node.data._isParent"
    icon="fal fa-plus"
    labelKey="CATEGORIES.CREATE_CATEGORY_BUTTON"
    type="secondary"
    (onClick)="createNewSubCategory($event.row)"></ng-template>
  <ng-template
    appActionButton
    displayIfNot="node.data.isInUse"
    icon="fal fa-trash"
    labelKey="CATEGORIES.DELETE_HEADER"
    type="danger"
    (onClick)="deleteCategory($event.row)"></ng-template>
</app-tree-table>

<p-menu [model]="items" [popup]="true" #menu></p-menu>

<p-dialog
  [(visible)]="displayDialog"
  [closable]="true"
  [closeOnEscape]="true"
  [maximizable]="true"
  [modal]="true"
  [style]="{ height: '90%', width: '900px' }">
  <p-header translate>MENU.MANDANTS</p-header>
  <app-table
    filename="MENU.MANDANTS"
    sortField="mandantId"
    [columns]="mandantenColumns"
    [style]="{ height: '100%' }"
    [value]="mandanten"></app-table>
</p-dialog>
