export function formatDate(date: Date | null | string | undefined): string | null {
  if (date == null || !(date instanceof Date)) {
    return date as string;
  }
  let month = '' + (date.getMonth() + 1);
  let day = '' + date.getDate();
  if (month.length < 2) {
    month = '0' + month;
  }
  if (day.length < 2) {
    day = '0' + day;
  }

  return [date.getFullYear(), month, day].join('-');
}

export function formatTime(date: Date | null): number | null {
  if (!date) {
    return null;
  }
  return date.getHours() + date.getMinutes() / 60;
}

export function minDate(...dates: (Date | null)[]): Date | null {
  return (
    dates
      .filter((d) => d)
      .map((d) => d as Date)
      .sort((a, b) => a.getTime() - b.getTime())[0] ?? null
  );
}

export function maxDate(...dates: (Date | null)[]): Date | null {
  return (
    dates
      .filter((d) => d)
      .map((d) => d as Date)
      .sort((a, b) => b.getTime() - a.getTime())[0] ?? null
  );
}
