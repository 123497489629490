import { SelectItem } from 'primeng/api';
import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { map } from 'rxjs/operators';
import { JobService } from './job.service';

export const BetriebOptionsResolve: ResolveFn<SelectItem[]> = () => {
  return inject(JobService)
    .getBetriebe()
    .pipe(map((betrieb) => betrieb.map((a) => ({ label: `${a.code} - ${a.description}`, value: a.code }))));
};
