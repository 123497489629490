import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Observable, retry, timer } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AnyValue } from '@shared/table/table.types';

export const retryCount = 30;
export const retryWaitMilliSeconds = 1000;

export class AngularDateHttpInterceptor implements HttpInterceptor {
  iso8601 = /^\d{4}-\d\d-\d\dT\d\d:\d\d:\d\d(\.\d+)?(([+-]\d\d:\d\d)|Z)?$/;

  constructor(private router: Router) {}

  public intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(req).pipe(
      tap({
        next: () => true,
        error: (err: unknown) => {
          if (err instanceof HttpErrorResponse) {
            if (err.status !== 403) {
              return;
            }
            return this.router.navigate(['/']);
          }
        },
      }),
      retry({
        delay: (error: unknown, count: number) => {
          if (
            count <= retryCount &&
            error instanceof HttpErrorResponse &&
            (error.status === 503 || error.status === 502)
          ) {
            return timer(retryWaitMilliSeconds);
          }
          throw error;
        },
      }),
      tap((event: HttpEvent<unknown>) => {
        if (event instanceof HttpResponse) {
          const body = event.body;
          this.convertToDate(body);
        }
      })
    );
  }

  convertToDate(body: AnyValue): void {
    if (body === null || body === undefined) {
      return body;
    }

    if (typeof body !== 'object') {
      return body;
    }

    for (const key of Object.keys(body)) {
      const value = body[key];
      if (this.isIso8601(value)) {
        body[key] = new Date(value);
      } else if (typeof value === 'object') {
        this.convertToDate(value);
      }
    }
  }

  isIso8601(value: string): boolean {
    if (value === null || value === undefined) {
      return false;
    }

    return this.iso8601.test(value);
  }
}
