<p-card [header]="title">
  <form (ngSubmit)="onSubmit()" [formGroup]="form" class="grid">
    <app-form-prefixed-input-mask
      [form]="form"
      [mask]="mask"
      [prefix]="prefix"
      [twoColumns]="true"
      id="tacsId"
      label="tacs®Code"></app-form-prefixed-input-mask>

    <app-form-number-input
      [form]="form"
      [twoColumns]="true"
      id="sortNumber"
      label="GLOBAL.SORT_NUMBER"></app-form-number-input>

    <app-form-date-input
      [form]="form"
      [minDate]="minFromDate"
      [maxDate]="maxFromDate || form.value.validTo"
      [twoColumns]="true"
      id="validFrom"
      label="GLOBAL.GUELTIG_AB"></app-form-date-input>

    <app-form-date-input
      [form]="form"
      [minDate]="minToDate || form.value.validFrom"
      [maxDate]="maxToDate"
      [twoColumns]="true"
      id="validTo"
      label="GLOBAL.GUELTIG_BIS"></app-form-date-input>

    <app-form-multiselect
      [form]="form"
      [options]="marketsOptions"
      id="markets"
      label="MANDANTS.MARKTS"></app-form-multiselect>

    <app-form-text-input [form]="form" id="name.De" label="GLOBAL.NAME_De"></app-form-text-input>

    <app-form-text-input [form]="form" id="name.Fr" label="GLOBAL.NAME_Fr"></app-form-text-input>

    <app-form-text-input [form]="form" id="name.It" label="GLOBAL.NAME_It"></app-form-text-input>

    @if (isPersonalkategorie()) {
      <app-form-text-input [form]="form" id="description.De" label="GLOBAL.DESCRIPTION_De"></app-form-text-input>
      <app-form-text-input [form]="form" id="description.Fr" label="GLOBAL.DESCRIPTION_Fr"></app-form-text-input>
      <app-form-text-input [form]="form" id="description.It" label="GLOBAL.DESCRIPTION_It"></app-form-text-input>
    }

    @if (isOrganisationkategorie() || isPersonalkategorie()) {
      <app-form-text-input [form]="form" id="shortCode.De" label="GLOBAL.SHORTCODE_DE"></app-form-text-input>
      <app-form-text-input [form]="form" id="shortCode.Fr" label="GLOBAL.SHORTCODE_FR"></app-form-text-input>
      <app-form-text-input [form]="form" id="shortCode.It" label="GLOBAL.SHORTCODE_IT"></app-form-text-input>
    }

    @if (isOrganisationkategorie()) {
      <app-form-switch
        id="customersPersonnelTimeShifting"
        label="GLOBAL.CUSTOMER_PERSONNEL_TIME_SHIFTING"
        [form]="form"
        [twoColumns]="true"></app-form-switch>
    }

    <app-form-button-bar [form]="form" [saveDisabled]="submitting"></app-form-button-bar>
  </form>
</p-card>
