import { ChangeDetectionStrategy, Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { JobService } from './job.service';
import { GroupedJobLog, Job } from '@entity/job';
import { SelectItem, SharedModule } from 'primeng/api';
import { Context } from '@service/context.service';
import { AbstractContentComponent } from '../abstract-content.component';
import { Table, TableLazyLoadEvent, TableModule } from 'primeng/table';
import { downloadBlob } from '../download';
import { FilterPipe } from '../filter.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { DialogModule } from 'primeng/dialog';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { JobsEntryTableComponent } from './jobs-entry-table/jobs-entry-table.component';
import { PanelModule } from 'primeng/panel';
import { InputTextModule } from 'primeng/inputtext';
import { TriStateCheckboxModule } from 'primeng/tristatecheckbox';
import { ScrollIntoViewDirective } from '@shared/scroll-into-view.directive';
import { DropdownModule } from 'primeng/dropdown';
import { CalendarModule } from 'primeng/calendar';
import { DatePipe, NgClass, NgStyle } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { FormsModule } from '@angular/forms';
import { MultiSelectModule } from 'primeng/multiselect';
import { AnyValue } from '@shared/table/table.types';

@Component({
  selector: 'app-jobs',
  templateUrl: './jobs.component.html',
  styleUrls: ['./jobs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TableModule,
    SharedModule,
    MultiSelectModule,
    FormsModule,
    ButtonModule,
    CalendarModule,
    DropdownModule,
    ScrollIntoViewDirective,
    TriStateCheckboxModule,
    InputTextModule,
    NgStyle,
    NgClass,
    PanelModule,
    JobsEntryTableComponent,
    ProgressSpinnerModule,
    DialogModule,
    TranslateModule,
    DatePipe,
    FilterPipe,
  ],
})
export class JobsComponent extends AbstractContentComponent implements OnInit, OnDestroy {
  columns: unknown[] = [];
  selectedColumns: unknown[] = [];
  jobs: Job[] = [];
  selectedJob?: Job;
  logColumns: unknown[] = [];
  statusOptions: SelectItem[] = [];
  logLevelOptions!: SelectItem[];
  betriebOptions: SelectItem[] = [];
  betriebName: AnyValue = {};
  totalRecords = 0;

  groupedLogs: GroupedJobLog[] = [];
  groupedLogColumns: unknown[] = [];
  xmlEntries: AnyValue = [];
  xmlEntryColumns: AnyValue = {};
  xmlEntryTypes: string[] = [];
  rerunDialogVisible = false;

  constructor(
    private jobService: JobService,
    injector: Injector
  ) {
    super(injector, Context.Global, 'MENU.JOBS');
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.activatedRoute.data.subscribe((data) => {
      this.betriebOptions = data.betriebOptions;
      this.betriebOptions.unshift({
        label: '-',
        value: '',
      });
      data.betriebOptions.forEach((betrieb: SelectItem) => {
        this.betriebName[betrieb.value] = betrieb.label;
      });
    });

    this.updateLanguageDependentFields();
    this.selectedColumns = this.columns;
  }

  onRowSelect(detailTable: Table): void {
    if (!this.selectedJob) {
      return;
    }
    detailTable.expandedRowKeys = {};
    this.jobService.getGroupedJobLogs(this.selectedJob.id).subscribe((result) => {
      let id = 0;
      result.forEach((r) => (r.id = id++));
      this.groupedLogs = result;
      this.markForCheck();
    });
  }

  load($event: TableLazyLoadEvent): void {
    this.startLoading();
    this.jobService.getJobs($event).subscribe((result) => {
      this.jobs = result.jobs;
      this.totalRecords = result.total;
      this.markForCheck();
      this.loadingDone();
    });
  }

  loadXmlEntry(event: AnyValue): void {
    if (!this.selectedJob) {
      return;
    }

    this.xmlEntries = [];
    this.markForCheck();

    this.jobService.getXmlEntry(this.selectedJob.id, event.data.type).subscribe((data) => {
      this.xmlEntryTypes = data.xmlEntryTypes;
      this.xmlEntries = data.xmlEntries;
      this.xmlEntryColumns = data.xmlEntryColumns;
      this.markForCheck();
    });
  }

  downloadCsv(): void {
    if (!this.selectedJob) {
      return;
    }

    this.jobService.downloadCsv(
      this.selectedJob.id,
      `${this.selectedJob.xmlHeaderBetriebId}__${this.selectedJob.xmlHeaderErstellDatum}.csv`
    );
  }

  openRerunDialog(dt: Table): void {
    if (this.selectedJob?.status === 'Failed') {
      this.confirm({
        header: this.translate('JOBS.RERUN_TITLE'),
        message: this.translate('JOBS.RERUN_FAILED_JOB_DETAIL'),
        accept: () => {
          this.jobService.rerunJob(this.selectedJob?.id, false).subscribe(() => {
            dt._filter();
          });
        },
      });
    } else {
      this.rerunDialogVisible = true;
    }
  }

  rerun(rerunAllSubsequentJobs: boolean, dt: Table): void {
    this.rerunDialogVisible = false;
    this.jobService.rerunJob(this.selectedJob?.id, rerunAllSubsequentJobs).subscribe(() => {
      dt._filter();
    });
  }

  downloadXml(): void {
    if (!this.selectedJob) {
      return;
    }
    this.jobService.downloadXml(this.selectedJob.id).subscribe((result) => {
      const contentDisposition = result.headers.get('Content-Disposition');
      let name = contentDisposition.substring(contentDisposition.indexOf('filename=') + 'filename='.length);
      name = name.substring(0, name.indexOf(';'));
      name = name.replace(/"/g, '');
      downloadBlob(result.body, name);
    });
  }

  protected onLanguageChange(): void {
    this.updateLanguageDependentFields();
  }

  private updateLanguageDependentFields(): void {
    this.columns = [
      {
        field: 'startTime',
        headerKey: 'JOBS.START_TIME',
        filterType: 'datetime',
        width: '180px',
      },
      {
        field: 'stopTime',
        headerKey: 'JOBS.STOP_TIME',
        filterType: 'datetime',
        width: '180px',
      },
      {
        field: 'status',
        headerKey: 'JOBS.STATUS_COLUMN_TITLE',
        filterType: 'status',
        width: '220px',
      },
      { field: 'totalEntries', headerKey: 'JOBS.TOTAL_ENTRIES', width: '180px' },
      {
        field: 'processedEntries',
        headerKey: 'JOBS.PROCESSED_ENTRIES',
        width: '210px',
      },
      { field: 'importJobLogWarnings', headerKey: 'JOBS.WARNINGS', width: '150px' },
      { field: 'importJobLogErrors', headerKey: 'JOBS.ERRORS', width: '150px' },
      {
        field: 'xmlHeaderBetriebId',
        headerKey: 'JOBS.XML_HEADER_BETRIEB_ID',
        filterType: 'betrieb',
        width: '400px',
      },
      {
        field: 'xmlHeaderErsetzen',
        headerKey: 'JOBS.XML_HEADER_BETRIEB_ERSETZEN',
        filterType: 'boolean',
        width: '120px',
      },
      {
        field: 'xmlHeaderStartDatum',
        headerKey: 'JOBS.XML_HEADER_START_DATUM',
        filterType: 'date',
        width: '130px',
      },
      {
        field: 'xmlHeaderEndDatum',
        headerKey: 'JOBS.XML_HEADER_END_DATUM',
        filterType: 'date',
        width: '130px',
      },
      {
        field: 'xmlHeaderErstellDatum',
        headerKey: 'JOBS.XML_HEADER_ERSTELL_DATUM',
        filterType: 'datetime',
        width: '180px',
      },
    ];

    this.logColumns = [
      {
        field: 'timestamp',
        headerKey: 'JOBS.TIMESTAMP',
        filterType: 'datetime',
        width: '180px',
        visible: true,
      },
      {
        field: 'type',
        headerKey: 'JOBS.LOG_TYPE',
        filterType: 'type',
        visible: true,
      },
      {
        field: 'level',
        headerKey: 'JOBS.LOG_LEVEL_COLUMN_NAME',
        filterType: 'loglevel',
        width: '150px',
        visible: true,
      },
      {
        field: 'xmlEntry',
        header: this.translate('JOBS.LOG_XML_ENTRY'),
      },
      {
        field: 'message',
        header: this.translate('JOBS.LOG_MESSAGE'),
      },
    ];

    this.statusOptions = [
      { label: this.translate('JOBS.STATUS.Running'), value: 'Running' },
      { label: this.translate('JOBS.STATUS.Failed'), value: 'Failed' },
      { label: this.translate('JOBS.STATUS.Successful'), value: 'Successful' },
      { label: this.translate('JOBS.STATUS.BlobUploaded'), value: 'BlobUploaded' },
      { label: this.translate('JOBS.STATUS.UploadRequested'), value: 'UploadRequested' },
      { label: this.translate('JOBS.STATUS.TenantMismatch'), value: 'TenantMismatch' },
    ];

    this.logLevelOptions = [
      { label: this.translate('JOBS.LOG_LEVEL.Info'), value: 'Info' },
      { label: this.translate('JOBS.LOG_LEVEL.Warning'), value: 'Warning' },
      { label: this.translate('JOBS.LOG_LEVEL.Error'), value: 'Error' },
    ];

    this.groupedLogColumns = [
      {
        field: 'type',
        headerKey: 'JOBS.LOG_TYPE',
        filterType: 'type',
        visible: true,
      },
      {
        field: 'level',
        headerKey: 'JOBS.LOG_LEVEL_COLUMN_NAME',
        filterType: 'loglevel',
        width: '150px',
        visible: true,
      },
      {
        field: 'count',
        filterType: 'number',
        headerKey: 'JOBS.LOG_COUNT',
        visible: true,
      },
    ];

    this.markForCheck();
  }
}
