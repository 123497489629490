import { Observable } from 'rxjs';
import { PersonnelCategory, PersonnelCategoryEntry } from '@entity/personnel-category';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PersonnelCategoryService {
  private readonly baseUrl = `api/1.0/mandanten`;

  constructor(private httpClient: HttpClient) {}

  getPersonnelCategories(
    mandantId: number,
    includeEntities = true,
    focusDate?: Date,
    active = false
  ): Observable<PersonnelCategory[]> {
    let params = new HttpParams().append('includeEntities', includeEntities).append('active', active);
    if (focusDate) {
      params = params.append('focusDate', focusDate.toJSON());
    }
    return this.httpClient.get<PersonnelCategory[]>(`${this.baseUrl}/${mandantId}/personnel-categories`, { params });
  }

  getPersonnelCategoryEntries(mandantId: number, personnelCategoryId: number): Observable<PersonnelCategoryEntry[]> {
    return this.httpClient.get<PersonnelCategoryEntry[]>(
      `${this.baseUrl}/${mandantId}/personnel-categories/${personnelCategoryId}/entries`
    );
  }

  updatePersonnelCategoryEntry(
    mandantId: number,
    personnelCategoryId: number,
    personnelCategoryEntry: PersonnelCategoryEntry
  ): Observable<PersonnelCategoryEntry> {
    return this.httpClient.put<PersonnelCategoryEntry>(
      `${this.baseUrl}/${mandantId}/personnel-categories/${personnelCategoryId}/entries/${personnelCategoryEntry.id}`,
      personnelCategoryEntry
    );
  }

  addPersonnelCategoryEntry(
    mandantId: number,
    personnelCategoryId: number,
    personnelCategoryEntry: PersonnelCategoryEntry
  ): Observable<PersonnelCategoryEntry> {
    return this.httpClient.post<PersonnelCategoryEntry>(
      `${this.baseUrl}/${mandantId}/personnel-categories/${personnelCategoryId}/entries`,
      personnelCategoryEntry
    );
  }

  deletePersonnelCategoryEntry(
    mandantId: number,
    personnelCategoryId: number,
    personnelCategoryEntry: PersonnelCategoryEntry
  ): Observable<unknown> {
    return this.httpClient.delete(
      `${this.baseUrl}/${mandantId}/personnel-categories/${personnelCategoryId}/entries/${personnelCategoryEntry.id}`
    );
  }
}
