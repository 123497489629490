@if (this.columns.length > 0) {
  <app-table
    (focusDateChange)="focusDateChange()"
    [(selection)]="rowSelection"
    [columns]="columns"
    [editable]="true"
    [firstColumnFrozen]="true"
    [focusDate]="focusDate"
    [hasFocusDate]="true"
    [headerGroups]="headerGroups"
    [selectable]="true"
    [showFrozenColumnSelection]="false">
    <ng-template
      appToolbarButton
      icon="fal fa-plus"
      labelKey="ACTIONS.ADD_MISSING_ORGANISATIONS"
      (onClick)="addMissingOrganisations()"></ng-template>
    <ng-template
      appToolbarButton
      icon="fal fa-pencil"
      labelKey="ACTIONS.EDIT"
      (onClick)="editRows()"
      [disabled]="rowSelection.length === 0"></ng-template>
  </app-table>
}
