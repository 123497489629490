import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { AppComponent } from '../app.component';
import { AuthorizationService } from '@service/authorization.service';
import { environment } from '../../environments/environment';
import { AppConfig } from '@entity/app-config';
import { SubMenuComponent } from './sub-menu.component';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [SubMenuComponent],
})
export class MenuComponent implements OnInit {
  model: MenuItem[] = [];

  constructor(
    public app: AppComponent,
    private authorizationService: AuthorizationService,
    private appConfig: AppConfig
  ) {}

  private static addSubMenuEntry(check: () => boolean, menuItem: MenuItem, subMenuItem: MenuItem): void {
    if (check()) {
      menuItem.items?.push(subMenuItem);
    }
  }

  ngOnInit(): void {
    this.addMenuEntry(() => this.authorizationService.isManager(), {
      label: 'MENU.JOBS',
      icon: 'fal fa-cogs',
      routerLink: ['/jobs'],
    });

    this.addMenuEntry(() => this.authorizationService.isRodixAdmin(), {
      label: 'MENU.JOB_CONTROLLING',
      icon: 'fal fa-shield-check',
      routerLink: ['/job-controlling'],
    });

    this.addMenuEntry(() => this.authorizationService.isManager(), {
      label: 'MENU.USERS',
      icon: 'fal fa-user',
      routerLink: ['/users'],
    });

    this.addMenuEntry(() => this.authorizationService.isRodixAdmin(), {
      label: 'MENU.SERVICE_ACCOUNTS',
      icon: 'fal fa-key',
      routerLink: ['/service-accounts'],
    });

    this.addMenuEntry(() => this.authorizationService.isRodixAdmin(), {
      label: 'MENU.ANWENDERBETRIEBE',
      icon: 'fal fa-city',
      routerLink: ['/anwenderbetriebe'],
    });

    this.addMandantsMenu();
    this.addOrganisationalUnitMenu();
    this.addPersonnelCategoriesMenu();

    this.addMasterDataMenu();
    this.addStatisticsCodeMenu();
    this.addBeneficiaryMenu();
    this.addVariablesMenu();

    this.addMenuEntry(() => this.authorizationService.isManager(), {
      label: 'MENU.TIME_BUDGET',
      icon: 'fal fa-stopwatch',
      routerLink: ['time-budgets'],
    });

    this.addMenuEntry(
      () =>
        this.authorizationService.isManager() ||
        this.authorizationService.isSoftwarepartner() ||
        this.authorizationService.isKISPartner(),
      {
        label: 'MENU.MAPPING_CATALOGS',
        icon: 'fal fa-project-diagram',
        routerLink: ['mapping-catalogs'],
      }
    );

    this.addMenuEntry(() => this.authorizationService.isManager(), {
      label: 'MENU.HOLIDAYS',
      icon: 'fal fa-calendar-star',
      routerLink: ['/holidays'],
    });

    this.addMenuEntry(() => this.authorizationService.isManager(), {
      label: 'MENU.WORKDAYS',
      icon: 'fal fa-calendar-alt',
      routerLink: ['workdays'],
    });

    this.addMenuEntry(() => this.authorizationService.isManager(), {
      label: 'MENU.TIME_CREDIT',
      icon: 'fal fa-calendar-edit',
      routerLink: ['/time-credits'],
    });

    this.addMenuEntry(() => this.authorizationService.isManager(), {
      label: 'MENU.TARGET_JOB_PLAN',
      icon: 'fal fa-poll-people',
      routerLink: ['/target-job-plans'],
    });

    this.addMenuEntry(() => this.authorizationService.isManager(), {
      label: 'MENU.PERSONNEL_COSTS',
      icon: 'fal fa-envelope-open-dollar',
      routerLink: ['/personnel-costs'],
      separator: true,
    });

    this.addMenuEntry(() => this.authorizationService.isRodixAdmin(), {
      label: 'MENU.REPORT_STATISTICS',
      icon: 'fal fa-chart-bar',
      routerLink: ['/reporting/report-statistics'],
    });
    this.addMenuEntry(() => this.authorizationService.isRodixAdmin(), {
      label: 'MENU.LOGIN_STATISTICS',
      icon: 'fal fa-user-chart',
      routerLink: ['/reporting/login-statistics'],
      separator: true,
    });

    this.addMenuEntry(() => true, {
      label: 'MENU.REPORTING',
      icon: 'fal fa-chart-line',
      url: environment.reportingUrl,
    });

    this.addMenuEntry(() => true, {
      label: 'MENU.HELP',
      icon: 'fal fa-question',
      url: environment.helpUrl,
      separator: true,
    });

    this.addMenuEntry(() => true, {
      label: `Version ${this.appConfig.version}`,
      icon: 'fal fa-rocket',
      routerLink: ['/version'],
    });
  }

  private addMandantsMenu(): void {
    this.addMenuEntry(() => this.authorizationService.isManager(), {
      label: 'MENU.MANDANTS',
      icon: 'fal fa-building',
      routerLink: ['/mandants'],
    });
  }

  private addOrganisationalUnitMenu(): void {
    this.addMenuEntry(() => this.authorizationService.isManager() || this.authorizationService.isSoftwarepartner(), {
      label: 'MENU.ORGANISATIONAL_UNITS',
      icon: 'fal fa-sitemap',
      routerLink: ['/organisational-units'],
      separator: true,
    });
  }

  private addPersonnelCategoriesMenu(): void {
    this.addMenuEntry(() => this.authorizationService.isManager() || this.authorizationService.isSoftwarepartner(), {
      label: 'MENU.PERSONNEL_CATEGORIES',
      icon: 'fal fa-address-card',
      routerLink: ['/personal-categories'],
    });
  }

  private addMenuEntry(check: () => boolean, menuItem: MenuItem): void {
    if (check()) {
      this.model.push(menuItem);
    }
  }

  private addMasterDataMenu(): void {
    const menuItem: MenuItem = {
      label: 'MENU.MASTER_DATA',
      icon: 'fal fa-database',
      items: [],
    };

    MenuComponent.addSubMenuEntry(() => this.authorizationService.isRodixAdmin(), menuItem, {
      label: 'MENU.MARKETS',
      icon: 'fal fa-shopping-bag',
      routerLink: ['/markets'],
    });

    MenuComponent.addSubMenuEntry(() => this.authorizationService.isRodixAdmin(), menuItem, {
      label: 'MENU.CATEGORIES',
      icon: 'fal fa-layer-group',
      routerLink: ['/categories'],
    });

    MenuComponent.addSubMenuEntry(() => this.authorizationService.isRodixAdmin(), menuItem, {
      label: 'MENU.VARIABLES_OVERVIEW',
      icon: 'fal fa-sort-amount-down',
      routerLink: ['/main-data/variables'],
    });

    MenuComponent.addSubMenuEntry(() => this.authorizationService.isRodixAdmin(), menuItem, {
      label: 'MENU.MAPPING_CATALOGS',
      icon: 'fal fa-chart-network',
      routerLink: ['admin-mapping-catalogs'],
    });

    MenuComponent.addSubMenuEntry(() => this.authorizationService.isRodixAdmin(), menuItem, {
      label: 'MENU.HOLIDAYS',
      icon: 'fal fa-calendar-star',
      routerLink: ['/public-holidays'],
    });

    this.addCustomersSubMenu(menuItem);
    this.addEmployeesSubMenu(menuItem);

    if (menuItem.items?.length) {
      this.model.push(menuItem);
    }
  }

  private addEmployeesSubMenu(menuItem: MenuItem): void {
    MenuComponent.addSubMenuEntry(() => this.authorizationService.isManager(), menuItem, {
      label: 'MENU.EMPLOYEES',
      icon: 'fal fa-users',
      routerLink: ['/employees'],
    });
  }

  private addCustomersSubMenu(menuItem: MenuItem): void {
    MenuComponent.addSubMenuEntry(() => this.authorizationService.isManager(), menuItem, {
      label: 'MENU.CUSTOMERS',
      icon: 'fal fa-hospital-user',
      routerLink: ['/customers'],
    });
  }

  private addVariablesMenu(): void {
    const menuItem: MenuItem = {
      label: 'MENU.VARIABLES',
      icon: 'fal fa-align-justify',
      items: [],
      separator: true,
    };

    MenuComponent.addSubMenuEntry(
      () => this.authorizationService.isManager() || this.authorizationService.isSoftwarepartner(),
      menuItem,
      {
        label: 'MENU.VARIABLES_FULL_CATALOG',
        icon: 'fal fa-list',
        routerLink: ['/variables/overview'],
      }
    );
    MenuComponent.addSubMenuEntry(
      () => this.authorizationService.isManager() || this.authorizationService.isSoftwarepartner(),
      menuItem,
      {
        label: 'MENU.VARIABLES_ASSIGNMENT',
        icon: 'fal fa-tasks',
        routerLink: ['/variables/assignments'],
      }
    );

    MenuComponent.addSubMenuEntry(() => this.authorizationService.isManager(), menuItem, {
      label: 'MENU.VARIABLE_APPROVAL',
      icon: 'fal fa-thumbs-up',
      routerLink: ['/variables/approvals'],
    });

    // eslint-disable-next-line max-len
    MenuComponent.addSubMenuEntry(
      () =>
        this.authorizationService.isManager() ||
        this.authorizationService.isSoftwarepartner() ||
        this.authorizationService.isKISPartner(),
      menuItem,
      {
        label: 'MENU.MEASURE_VIEW',
        icon: 'fal fa-hand-holding-medical',
        routerLink: ['/variables/measures'],
      }
    );

    if (menuItem.items?.length) {
      this.model.push(menuItem);
    }
  }

  private addStatisticsCodeMenu(): void {
    const menuItem: MenuItem = {
      label: 'MENU.STATISTICS_CODES',
      icon: 'fal fa-clipboard-list',
      items: [],
    };

    MenuComponent.addSubMenuEntry(
      () => this.authorizationService.isManager() || this.authorizationService.isSoftwarepartner(),
      menuItem,
      {
        label: 'MENU.STATISTICS_CODES_OVERVIEW',
        icon: 'fal fa-clipboard-list',
        routerLink: ['/statistics-codes/overview'],
      }
    );

    MenuComponent.addSubMenuEntry(
      () => this.authorizationService.isManager() || this.authorizationService.isSoftwarepartner(),
      menuItem,
      {
        label: 'MENU.STATISTICS_CODES_ASSIGNMENT',
        icon: 'fal fa-clipboard-list-check',
        routerLink: ['/statistics-codes/assignments'],
      }
    );

    if (menuItem.items?.length) {
      this.model.push(menuItem);
    }
  }

  private addBeneficiaryMenu(): void {
    const menuItem: MenuItem = {
      label: 'MENU.BENEFICIARY',
      icon: 'fal fa-ballot',
      items: [],
    };

    MenuComponent.addSubMenuEntry(
      () => this.authorizationService.isManager() || this.authorizationService.isSoftwarepartner(),
      menuItem,
      {
        label: 'MENU.BENEFICIARY_OVERVIEW',
        icon: 'fal fa-ballot',
        routerLink: ['/beneficiaries/overview'],
      }
    );
    MenuComponent.addSubMenuEntry(
      () => this.authorizationService.isManager() || this.authorizationService.isSoftwarepartner(),
      menuItem,
      {
        label: 'MENU.BENEFICIARY_ASSIGNMENT',
        icon: 'fal fa-ballot-check',
        routerLink: ['/beneficiaries/assignments'],
      }
    );

    if (menuItem.items?.length) {
      this.model.push(menuItem);
    }
  }
}
