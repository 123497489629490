import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { TreeTableLazyLoadEvent } from 'primeng/treetable';

export abstract class AbstractCrudMandantService<T> {
  protected readonly baseUrl = `api/1.0/mandanten`;

  protected constructor(
    private controller: string,
    protected httpClient: HttpClient
  ) {}

  list(
    mandantId: number,
    focusDate: Date | undefined = undefined,
    treeTableLazyLoadEvent: TreeTableLazyLoadEvent | undefined = undefined
  ): Observable<{ total: number; entities: T[] }> {
    let params = new HttpParams();
    if (focusDate) {
      params = params.append('focusDate', focusDate.toJSON());
    }
    if (treeTableLazyLoadEvent) {
      params = params
        .append('first', treeTableLazyLoadEvent.first ?? 0)
        .append('rows', treeTableLazyLoadEvent.rows ?? 50);
      if (treeTableLazyLoadEvent.sortField) {
        params = params.append('sortOrder', treeTableLazyLoadEvent.sortOrder == 1 ? 'ASC' : 'DESC');
        if (Array.isArray(treeTableLazyLoadEvent.sortField)) {
          console.log(
            "didn't expect treeTableLazyLoadEvent.sortField to be an array (" + treeTableLazyLoadEvent.sortField + ')'
          );
        } else {
          params = params.append('sortField', treeTableLazyLoadEvent.sortField);
        }
      }
      if (treeTableLazyLoadEvent.filters) {
        params = params.append('filters', JSON.stringify(treeTableLazyLoadEvent.filters));
      }
    }

    return this.httpClient
      .get<T[]>(`${this.baseUrl}/${mandantId}/${this.controller}`, { params, observe: 'response' })
      .pipe(
        map((response) => {
          return { entities: response.body || [], total: parseInt(response.headers.get('X-Total-Count') || '0', 10) };
        })
      );
  }

  delete(mandantId: number, id: number | string): Observable<unknown> {
    return this.httpClient.delete(`${this.baseUrl}/${mandantId}/${this.controller}/${id}`);
  }

  get(mandantId: number, id: number | string): Observable<T> {
    return this.httpClient.get<T>(`${this.baseUrl}/${mandantId}/${this.controller}/${id}`);
  }

  update(mandantId: number, id: number | string, entity: T): Observable<T> {
    return this.httpClient.put<T>(`${this.baseUrl}/${mandantId}/${this.controller}/${id}`, entity);
  }

  create(mandantId: number, entity: T): Observable<T> {
    return this.httpClient.post<T>(`${this.baseUrl}/${mandantId}/${this.controller}`, entity);
  }
}
