import { FormControl } from '@angular/forms';

export type Language = 'De' | 'Fr' | 'It';
export type LanguageMap = { [language in Language]: string | undefined };

export const LanguageKeys: Language[] = ['De', 'Fr', 'It'];
export const EmptyLanguageMap: LanguageMap = { De: '', Fr: '', It: '' };

export type LanguageFormGroup = {
  [language in Language]: FormControl<string | undefined>;
};
