import { ChangeDetectionStrategy, Component, Injector, OnInit } from '@angular/core';
import { AbstractContentComponent } from '../../abstract-content.component';
import { Context } from '@service/context.service';
import { EchartComponent, Options } from '@shared/echart/echart.component';
import { ReportingService } from '@service/reporting.service';
import { ReportingStatistics } from '@entity/reporting-statistics';
import { SelectItem } from 'primeng/api';
import { Workbook } from 'exceljs';
import { downloadBlob } from '../../download';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { MultiSelectModule } from 'primeng/multiselect';
import { CardModule } from 'primeng/card';
import { AnyValue } from '@shared/table/table.types';

@Component({
  selector: 'app-report-statistics',
  templateUrl: './report-statistics.component.html',
  styleUrls: ['./report-statistics.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CardModule, MultiSelectModule, FormsModule, EchartComponent, TranslateModule],
})
export class ReportStatisticsComponent extends AbstractContentComponent implements OnInit {
  options?: Options;
  height?: string;
  first = 'reportName';
  second = 'mandantName';
  reportNames: SelectItem[] = [];
  mandantNames: SelectItem[] = [];
  selectedReportNames: string[] = [];
  selectedMandantNames: string[] = [];
  private reportingStatistics: ReportingStatistics[] = [];

  constructor(
    injector: Injector,
    private reportingService: ReportingService
  ) {
    super(injector, Context.Global, 'MENU.REPORT_STATISTICS');
  }

  ngOnInit(): void {
    this.reportingService.getReportingStatistics().subscribe((data) => {
      this.reportNames = [...new Set(data.map((d) => d.reportName))].sort().map((n) => ({ label: n, value: n }));
      this.mandantNames = [...new Set(data.map((d) => d.mandantName))].sort().map((n) => ({ label: n, value: n }));
      this.reportingStatistics = data;
      this.setOptions();
    });
  }

  setOptions(): void {
    const reports = new Map<string, AnyValue>();
    this.reportingStatistics
      .filter((r) => this.selectedMandantNames.length === 0 || this.selectedMandantNames.includes(r.mandantName))
      .filter((r) => this.selectedReportNames.length === 0 || this.selectedReportNames.includes(r.reportName))
      .forEach((d) => {
        let report = reports.get(d[this.first]);
        if (!report) {
          report = {
            name: d[this.first],
            value: 0,
            children: [],
          };
          reports.set(d[this.first], report);
        }

        report.value += d.reportViewCount;
        report.children.push({
          name: d[this.second],
          value: d.reportViewCount,
        });
      });

    this.options = {
      tooltip: {
        show: true,
      },
      toolbox: {
        right: 0,
        itemSize: 20,
        feature: {
          saveAsImage: {
            title: 'Download',
            iconStyle: {
              borderWidth: 0,
              color: 'black',
            },
            icon: '<path fill="currentColor" d="M216 236.07c0-6.63-5.37-12-12-12h-24c-6.63 0-12 5.37-12 12v84.01h-48.88c-10.71 0-16.05 12.97-8.45 20.52l72.31 71.77c4.99 4.95 13.04 4.95 18.03 0l72.31-71.77c7.6-7.54 2.26-20.52-8.45-20.52H216v-84.01zM369.83 97.98L285.94 14.1c-9-9-21.2-14.1-33.89-14.1H47.99C21.5.1 0 21.6 0 48.09v415.92C0 490.5 21.5 512 47.99 512h287.94c26.5 0 48.07-21.5 48.07-47.99V131.97c0-12.69-5.17-24.99-14.17-33.99zM255.95 51.99l76.09 76.08h-76.09V51.99zM336 464.01H47.99V48.09h159.97v103.98c0 13.3 10.7 23.99 24 23.99H336v287.95z"></path>',
          },
          myTool2: {
            show: true,
            title: 'Excel Export',
            iconStyle: {
              borderWidth: 0,
              color: 'black',
            },
            icon: '<path d="M365.3 93.38l-74.63-74.64C278.6 6.742 262.3 0 245.4 0H64C28.65 0 0 28.65 0 64l.0065 384c0 35.34 28.65 64 64 64H320c35.2 0 64-28.8 64-64V138.6C384 121.7 377.3 105.4 365.3 93.38zM336 448c0 8.836-7.164 16-16 16H64.02c-8.838 0-16-7.164-16-16L48 64.13c0-8.836 7.164-16 16-16h160L224 128c0 17.67 14.33 32 32 32h79.1V448zM229.1 233.3L192 280.9L154.9 233.3C146.8 222.8 131.8 220.9 121.3 229.1C110.8 237.2 108.9 252.3 117.1 262.8L161.6 320l-44.53 57.25c-8.156 10.47-6.25 25.56 4.188 33.69C125.7 414.3 130.8 416 135.1 416c7.156 0 14.25-3.188 18.97-9.25L192 359.1l37.06 47.65C233.8 412.8 240.9 416 248 416c5.125 0 10.31-1.656 14.72-5.062c10.44-8.125 12.34-23.22 4.188-33.69L222.4 320l44.53-57.25c8.156-10.47 6.25-25.56-4.188-33.69C252.2 220.9 237.2 222.8 229.1 233.3z"/>',
            onclick: () => this.downloadExcelReport(),
          },
          myTool1: {
            show: true,
            title: '',
            iconStyle: {
              borderWidth: 0,
              color: 'black',
            },
            icon: '<path fill="currentColor" d="M481.162 164.326c19.478 25.678 30.997 57.709 30.836 92.388C511.61 340.638 442.361 408 358.436 408H176v64c-.001 10.683-12.949 16.021-20.485 8.485l-88-87.995c-4.686-4.686-4.687-12.284 0-16.971l88-88.005c7.58-7.58 20.485-2.14 20.485 8.485v64h182.668C415.933 360 464.06 313.154 464 255.889c-.023-22.372-7.149-43.111-19.237-60.082-3.431-4.817-2.962-11.387 1.223-15.564 8.269-8.255 13.592-13.545 17.137-17.104 5.131-5.152 13.645-4.605 18.039 1.187zM48 256.111C47.94 198.846 96.067 152 153.332 152H336v64c0 10.625 12.905 16.066 20.485 8.485l88-88.005c4.687-4.686 4.686-12.285 0-16.971l-88-87.995C348.949 23.979 336.001 29.317 336 40v64H153.564C69.639 104 .389 171.362.002 255.286c-.16 34.679 11.358 66.71 30.836 92.388 4.394 5.792 12.908 6.339 18.039 1.188 3.545-3.559 8.867-8.849 17.137-17.105 4.185-4.178 4.653-10.748 1.223-15.564-12.088-16.971-19.213-37.71-19.237-60.082z"></path>',
            onclick: () => {
              const temp = this.first;
              this.first = this.second;
              this.second = temp;
              this.setOptions();
            },
          },
        },
      },
      series: [
        {
          zoomToNodeRatio: 1,
          name: 'Report Statistics',
          label: {
            show: true,
            formatter: '{b}: {c}',
          },
          upperLabel: {
            show: true,
            backgroundColor: 'transparent',
            fontWeight: 'bold',
          },
          itemStyle: {
            borderWidth: 2,
            gapWidth: 2,
            borderColorSaturation: 0.6,
          },
          type: 'treemap',
          data: [...reports.values()],
        },
      ],
    };
    this.markForCheck();
  }

  private downloadExcelReport(): void {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('All');
    const headerRow = worksheet.addRow(['Mandant', 'Report', 'Anzahl']);
    headerRow.font = { bold: true };
    this.reportingStatistics.forEach((r) => {
      worksheet.addRow([r.mandantName, r.reportName, r.reportViewCount]);
    });
    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      downloadBlob(blob, `reports.xlsx`);
    });
  }
}
