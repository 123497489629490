@if (form) {
  <form class="grid" novalidate (ngSubmit)="onSubmit()" [formGroup]="form">
    <app-form-time-period
      id="entries"
      label="GLOBAL.VALUE"
      inputType="number"
      [form]="form"
      [maxFractionDigits]="1"
      [minFractionDigits]="1"></app-form-time-period>
    <app-form-button-bar (onCancel)="cancel()" [form]="form" [saveDisabled]="submitting"></app-form-button-bar>
  </form>
}
