<app-table
  dataKey="organisationId"
  filename="MENU.ORGANISATIONAL_UNITS"
  sortField="organisationId"
  [columns]="columns"
  [loading]="loading"
  [value]="organisations">
  @if (isManager()) {
    <ng-template
      appToolbarButton
      icon="fal fa-plus"
      labelKey="ORGANISATIONAL_UNITS.TITLE_CREATE"
      (onClick)="addNewOrganisationalUnit()"></ng-template>
  }
  @if (isManager()) {
    <ng-template
      appActionButton
      displayIf="deletable"
      icon="fal fa-trash"
      labelKey="ACTIONS.DELETE"
      type="danger"
      (onClick)="delete($event.row)"
      [width]="150"></ng-template>
  }
</app-table>
