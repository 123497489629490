<p-table
  #dt
  (onLazyLoad)="load($event)"
  (onRowSelect)="onRowSelect(detailTable)"
  [(selection)]="selectedJob"
  [columns]="selectedColumns"
  [exportFilename]="'MENU.JOBS' | translate"
  [lazy]="true"
  [loading]="loading"
  [paginator]="true"
  [rows]="10"
  [scrollable]="true"
  [sortOrder]="-1"
  [totalRecords]="totalRecords"
  [value]="jobs"
  selectionMode="single"
  sortField="startTime"
  styleClass="p-datatable-sm p-datatable-gridlines p-datatable-striped">
  <ng-template pTemplate="caption">
    <div class="toolbar">
      <p-multiSelect
        [(ngModel)]="selectedColumns"
        [placeholder]="'JOBS.SELECTED_COLUMNS_DEFAULT_LABEL' | translate"
        [options]="columns"
        [selectedItemsLabel]="'JOBS.SELECTED_COLUMNS_LABEL' | translate"
        [style]="{ minWidth: '400px', height: '100%' }"
        appendTo="body"
        optionLabel="field">
        <ng-template let-column pTemplate="item">
          <div>{{ column.headerKey | translate }}</div>
        </ng-template>
      </p-multiSelect>
      <p-button
        (onClick)="dt._filter()"
        [label]="'ACTIONS.RELOAD' | translate"
        icon="fal fa-sync-alt"
        iconPos="left"
        type="button"></p-button>

      @if (isRodixAdmin()) {
        <p-button
          (onClick)="openRerunDialog(dt)"
          [disabled]="!selectedJob"
          [label]="'ACTIONS.RERUN' | translate"
          icon="fal fa-play"
          iconPos="left"
          type="button"></p-button>
      }

      @if (isRodixAdmin()) {
        <p-button
          (onClick)="downloadXml()"
          [disabled]="!selectedJob"
          [label]="'JOBS.DOWNLOAD' | translate"
          icon="fal fa-download"
          iconPos="left"
          type="button"></p-button>
      }

      <p-button
        (onClick)="downloadCsv()"
        [disabled]="!selectedJob"
        icon="fal fa-file-alt"
        iconPos="left"
        label="CSV"
        type="button"></p-button>
    </div>
  </ng-template>

  <ng-template let-columns pTemplate="header">
    <tr>
      @for (col of columns; track col) {
        <th [pSortableColumn]="col.field" [style.max-width]="col.width" [style.min-width]="col.width">
          {{ col.headerKey | translate }}
          <p-sortIcon [field]="col.field"></p-sortIcon>
        </th>
      }
    </tr>
    <tr>
      @for (col of columns; track col) {
        <th [style.max-width]="col.width" [style.min-width]="col.width">
          @switch (col.filterType) {
            @case ('date') {
              <p-calendar
                (onInput)="dt.filter($any($event).target.value, col.field, 'date')"
                (onSelect)="dt.filter($event, col.field, 'date')"
                appendTo="body"
                dateFormat="dd.mm.yy"></p-calendar>
            }
            @case ('datetime') {
              <p-calendar
                (onInput)="dt.filter($any($event).target.value, col.field, 'date')"
                (onSelect)="dt.filter($event, col.field, 'date')"
                appendTo="body"
                dateFormat="dd.mm.yy"></p-calendar>
            }
            @case ('status') {
              <p-dropdown
                (onChange)="dt.filter($event.value, col.field, 'equals')"
                [options]="statusOptions"
                [placeholder]="'JOBS.STATUS_COLUMN_TITLE' | translate"
                [showClear]="true"
                appScrollIntoView
                appendTo="body"></p-dropdown>
            }
            @case ('boolean') {
              <div style="width: 100%; text-align: center">
                <p-triStateCheckbox (onChange)="dt.filter($event.value, col.field, 'equals')"></p-triStateCheckbox>
              </div>
            }
            @case ('betrieb') {
              <p-dropdown
                (onChange)="dt.filter($event.value, col.field, 'equals')"
                [filter]="true"
                [filterBy]="'label'"
                [options]="betriebOptions"
                appScrollIntoView
                appendTo="body"></p-dropdown>
            }
            @default {
              <input (input)="dt.filter($any($event.target).value, col.field, 'contains')" pInputText type="text" />
            }
          }
        </th>
      }
    </tr>
  </ng-template>
  <ng-template let-columns="columns" let-job pTemplate="body">
    <tr [pSelectableRow]="job">
      @for (col of columns; track col) {
        @switch (col.filterType) {
          @case ('date') {
            <td [style.max-width]="col.width" [style.min-width]="col.width">
              {{ job[col.field] | date: 'dd.MM.yyyy' }}
            </td>
          }
          @case ('datetime') {
            <td [style.max-width]="col.width" [style.min-width]="col.width">
              {{ job[col.field] | date: 'dd.MM.yyyy HH:mm:ss' }}
            </td>
          }
          @case ('status') {
            <td [style.max-width]="col.width" [style.min-width]="col.width">
              {{ 'JOBS.STATUS.' + job[col.field] | translate }}
            </td>
          }
          @case ('boolean') {
            <td [style.max-width]="col.width" [style.min-width]="col.width" style="text-align: center">
              @if (job[col.field] === true) {
                <i class="fal fa-check"></i>
              }
              @if (job[col.field] === false) {
                <i class="fal fa-minus"></i>
              }
            </td>
          }
          @case ('betrieb') {
            <td [style.max-width]="col.width" [style.min-width]="col.width">
              {{ betriebName[job[col.field]] }}
            </td>
          }
          @default {
            <td [style.max-width]="col.width" [style.min-width]="col.width">
              {{ job[col.field] }}
            </td>
          }
        }
      }
    </tr>
  </ng-template>
</p-table>

<p-table
  #detailTable
  (onRowExpand)="loadXmlEntry($event)"
  [columns]="groupedLogColumns"
  [ngStyle]="{ visibility: groupedLogs.length === 0 ? 'hidden' : 'visible' }"
  [style]="{ 'margin-top': '4rem' }"
  [value]="groupedLogs"
  dataKey="id"
  rowExpandMode="single"
  styleClass="p-datatable-sm p-datatable-gridlines p-datatable-striped">
  <ng-template let-columns pTemplate="header">
    <tr>
      <th style="width: 3em"></th>
      @for (col of columns; track col) {
        <th [pSortableColumn]="col.field" [style.max-width]="col.width" [style.min-width]="col.width">
          {{ col.headerKey | translate }}
          <p-sortIcon [field]="col.field"></p-sortIcon>
        </th>
      }
    </tr>
  </ng-template>
  <ng-template let-columns="columns" let-expanded="expanded" let-log pTemplate="body">
    <tr>
      <td>
        <a [pRowToggler]="log" href="#">
          <i [ngClass]="expanded ? 'fal fa-chevron-down' : 'fal fa-chevron-right'"></i>
        </a>
      </td>
      @for (col of columns | filter: 'visible' : true; track col) {
        <td [style.max-width]="col.width" [style.min-width]="col.width">
          @if (col.filterType === 'number') {
            {{ log[col.field] }}
          }
          @if (col.filterType === 'type') {
            {{ 'JOBS.LOG_ERROR.' + log[col.field] | translate }}
          }
          @if (col.filterType === 'loglevel') {
            {{ 'JOBS.LOG_LEVEL.' + log[col.field] | translate }}
          }
        </td>
      }
    </tr>
  </ng-template>
  <ng-template let-columns="columns" let-rowData pTemplate="rowexpansion">
    <tr>
      <td [attr.colspan]="columns.length + 1">
        @if (xmlEntries) {
          @if (xmlEntryTypes.length > 1) {
            @for (type of xmlEntryTypes; track type) {
              <p-panel [header]="type" [style]="{ margin: '1rem' }">
                <div style="display: flex; width: 100%; flex: 1">
                  <app-jobs-entry-table
                    [columns]="xmlEntryColumns[type]"
                    [entries]="xmlEntries[type]"
                    [jobId]="selectedJob?.id"></app-jobs-entry-table>
                </div>
              </p-panel>
            }
          } @else {
            <div style="display: flex; width: 100%; flex: 1">
              <app-jobs-entry-table
                [columns]="xmlEntryColumns[xmlEntryTypes[0]]"
                [entries]="xmlEntries[xmlEntryTypes[0]]"
                [jobId]="selectedJob?.id"></app-jobs-entry-table>
            </div>
          }
          <ng-template #single>
            <div style="display: flex; width: 100%; flex: 1">
              <app-jobs-entry-table
                [columns]="xmlEntryColumns[xmlEntryTypes[0]]"
                [entries]="xmlEntries[xmlEntryTypes[0]]"
                [jobId]="selectedJob?.id"></app-jobs-entry-table>
            </div>
          </ng-template>
        } @else {
          <div style="overflow: hidden">
            <p-progressSpinner [style]="{ 'margin-left': 'calc(50% - 50px)' }"></p-progressSpinner>
          </div>
        }
        <ng-template #loading>
          <div style="overflow: hidden">
            <p-progressSpinner [style]="{ 'margin-left': 'calc(50% - 50px)' }"></p-progressSpinner>
          </div>
        </ng-template>
      </td>
    </tr>
  </ng-template>
</p-table>

<p-dialog
  [(visible)]="rerunDialogVisible"
  [baseZIndex]="10000"
  [draggable]="false"
  [header]="'JOBS.RERUN_TITLE' | translate"
  [modal]="true"
  [resizable]="false"
  [style]="{ width: '50vw' }">
  <p translate>JOBS.RERUN_DETAIL</p>
  <p-footer>
    <p-button
      (click)="rerun(true, dt)"
      [label]="'JOBS.RERUN_ALL' | translate"
      icon="fal fa-fast-forward"
      styleClass="p-button-danger"></p-button>
    <p-button
      (click)="rerun(false, dt)"
      [label]="'JOBS.RERUN_ONE' | translate"
      icon="fal fa-step-forward"
      styleClass="p-button-warning"></p-button>
    <p-button
      (click)="rerunDialogVisible = false"
      [label]="'ACTIONS.CANCEL' | translate"
      icon="fal fa-stop-circle"
      styleClass="p-button-secondary"></p-button>
  </p-footer>
</p-dialog>
