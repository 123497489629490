import { ChangeDetectionStrategy, Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { User } from '@entity/user';
import { UserService } from '@service/user.service';
import { SelectItem } from 'primeng/api';
import { AbstractContentComponent } from '../../abstract-content.component';
import { Context } from '@service/context.service';
import { Anwenderbetrieb } from '@entity/anwenderbetrieb';
import { Role } from '@entity/role';
import { finalize } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { FormButtonBarComponent } from '@shared/form-button-bar/form-button-bar.component';
import { FormSwitchComponent } from '@shared/form-switch/form-switch.component';
import { FormTextInputComponent } from '@shared/form-text-input/form-text-input.component';
import { FormMultiselectComponent } from '@shared/form-multiselect/form-multiselect.component';
import { FormDropdownComponent } from '@shared/form-dropdown/form-dropdown.component';

import { CardModule } from 'primeng/card';
import { Mandant } from '@entity/mandant';

@Component({
  selector: 'app-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CardModule,
    FormsModule,
    ReactiveFormsModule,
    FormDropdownComponent,
    FormMultiselectComponent,
    FormTextInputComponent,
    FormSwitchComponent,
    FormButtonBarComponent,
    TranslateModule,
  ],
})
export class UserDetailComponent extends AbstractContentComponent implements OnInit, OnDestroy {
  anwenderbetriebOptions: SelectItem[] = [];
  anwenderbetriebe?: Anwenderbetrieb[];
  mandantOptions: SelectItem[] = [];
  form!: FormGroup<{
    anwenderbetriebId: FormControl<number | undefined>;
    mandantIds: FormControl<number[] | undefined>;
    role: FormControl<Role | undefined>;
    username: FormControl<string | undefined>;
    email: FormControl<string | undefined>;
    firstName: FormControl<string | undefined>;
    lastName: FormControl<string | undefined>;
    betaTester: FormControl<boolean | undefined>;
  }>;
  roleOptions: SelectItem[] = [];
  user: User = {} as User;
  submitting = false;

  constructor(
    private userService: UserService,
    private fb: FormBuilder,
    injector: Injector
  ) {
    super(injector, Context.Global);
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.activatedRoute.data.subscribe((data) => {
      this.anwenderbetriebe = data.anwenderbetrieb;
      this.anwenderbetriebOptions = data.anwenderbetrieb.map((a: Anwenderbetrieb) => ({
        label: a.bezeichnung,
        value: a.id,
      }));
      this.roleOptions = data.roleOptions;
      this.user = data.user || this.user;
    });

    this.setBreadcrumbs([
      { label: 'MENU.USERS', routerLink: ['users'] },
      { label: this.user.id ? 'USERS.EDIT_USER' : 'USERS.CREATE_USER' },
    ]);

    this.form = this.fb.nonNullable.group({
      anwenderbetriebId: [this.user.anwenderbetriebId, Validators.required],
      mandantIds: new FormControl(this.user.mandantIds, { validators: Validators.required, nonNullable: true }),
      role: [{ value: this.user.role, disabled: this.isNotRodixAdmin() }, Validators.required],
      username: [this.user.username, Validators.required],
      email: [this.user.email, Validators.required],
      firstName: [this.user.firstName, Validators.required],
      lastName: [this.user.lastName, Validators.required],
      betaTester: [this.user.betaTester],
    });

    const anwenderbetriebIdControl = this.form.controls.anwenderbetriebId;
    const anwenderbetriebId = this.getAnwenderbetriebId();
    if (anwenderbetriebId) {
      anwenderbetriebIdControl.setValue(anwenderbetriebId);
      this.anwenderbetriebChanged(false);
    }

    this.subscriptions.push(
      anwenderbetriebIdControl.valueChanges.subscribe(() => {
        this.anwenderbetriebChanged(true);
        this.markForCheck();
      })
    );
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  anwenderbetriebChanged(resetMandant = true): void {
    const anwenderbetriebId = this.form.getRawValue().anwenderbetriebId;
    this.anwenderbetriebe?.forEach((anwenderbetrieb) => {
      if (anwenderbetrieb.id === anwenderbetriebId) {
        this.mandantOptions = anwenderbetrieb.mandant.map((mandant: Mandant) => ({
          label: mandant.bezeichnung,
          value: mandant.id,
        }));
        if (resetMandant) {
          this.form.controls.mandantIds.setValue(undefined);
        }
        this.markForCheck();
      }
    });
  }

  onSubmit(): void {
    this.submitting = true;
    this.user = { ...this.user, ...this.form.value };
    const action = this.userService.createOrUpdateUser(this.user);

    action.pipe(finalize(() => (this.submitting = false))).subscribe({
      next: () => {
        this.showSuccessMessage(this.translate('USERS.SUCCESSFULLY_SAVED'));
        this.navigate(['/users']);
      },
      error: () => {
        this.showErrorMessage(
          this.translate('USERS.USERNAME_EXISTS_TITLE'),
          this.translate('USERS.USERNAME_EXISTS_DETAIL')
        );
      },
    });
    this.markForCheck();
  }
}
