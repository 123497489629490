<div style="height: calc(100% - 41px)">
  <p-tree
    filterMode="strict"
    scrollHeight="flex"
    selectionMode="checkbox"
    [selection]="selection"
    (selectionChange)="selection = $any($event)"
    [filter]="true"
    [loading]="loading"
    [value]="value"></p-tree>
</div>

<div class="mt-2">
  <p-button
    icon="fal fa-save"
    type="button"
    styleClass="mr-2"
    (onClick)="save()"
    [label]="'ACTIONS.SAVE' | translate"></p-button>
  <p-button
    icon="fal fa-times"
    type="button"
    styleClass="p-button-secondary"
    (onClick)="cancel()"
    [label]="'ACTIONS.CANCEL' | translate"></p-button>
</div>
