<app-form-element [control]="formControl" [id]="id" [label]="label" [required]="required" [twoColumns]="twoColumns">
  <div [class.invalid]="!formControl.valid">
    @for (item of value; track item; let i = $index) {
      <div class="item">
        @if (options.length > 0 && inputType === 'select') {
          <p-dropdown
            appScrollIntoView
            (onChange)="update()"
            [(ngModel)]="item.value"
            [appendTo]="'body'"
            [disabled]="formControl.disabled"
            [filter]="true"
            [filterBy]="'label'"
            [options]="options"
            [style]="{ width: '100%' }"></p-dropdown>
        }
        @if (inputType === 'text') {
          <input pInputText [(ngModel)]="item.value" [disabled]="formControl.disabled" [style]="{ width: '100%' }" />
        }
        @if (inputType === 'number') {
          <p-inputNumber
            [(ngModel)]="item.value"
            [disabled]="formControl.disabled"
            [locale]="'de-CH'"
            [maxFractionDigits]="maxFractionDigits"
            [minFractionDigits]="minFractionDigits"
            [style]="{ width: '100%' }"></p-inputNumber>
        }
        <p-calendar
          appendTo="body"
          dateFormat="dd.mm.yy"
          (onInput)="update()"
          (onSelect)="update()"
          [(ngModel)]="item.from"
          [appendTo]="'body'"
          [disabled]="formControl.disabled"
          [maxDate]="maxDate!"
          [minDate]="minDates[i]"
          [class.invalid]="!validationState[i].from"></p-calendar>
        <span class="minus"><i class="fal fa-minus"></i></span>
        <p-calendar
          appendTo="body"
          dateFormat="dd.mm.yy"
          (onInput)="update()"
          (onSelect)="update()"
          [(ngModel)]="item.to"
          [appendTo]="'body'"
          [disabled]="formControl.disabled"
          [maxDate]="maxDate!"
          [minDate]="item.from"
          [class.invalid]="!validationState[i].to"></p-calendar>
        @if (!formControl.disabled) {
          <p-button
            class="first-button"
            icon="fal fa-plus"
            iconPos="left"
            type="button"
            (onClick)="add(item)"></p-button>
        }
        @if (!formControl.disabled) {
          <p-button
            icon="fal fa-minus"
            iconPos="left"
            type="button"
            (onClick)="remove(item)"
            [disabled]="item.deletable === false"></p-button>
        }
      </div>
    }
    @if (value.length === 0 && !formControl.disabled) {
      <p-button class="first-button" icon="fal fa-plus" iconPos="left" type="button" (onClick)="add()"></p-button>
    }
  </div>
</app-form-element>
