import { Injectable } from '@angular/core';
import { Role } from '@entity/role';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RoleService {
  constructor(private httpClient: HttpClient) {}

  getRoles(): Observable<Role[]> {
    return this.httpClient.get<Role[]>('api/1.0/roles');
  }
}
