import { ChangeDetectionStrategy, Component, Inject, OnInit, Optional } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Column } from '@shared/table/column';
import { TABLE_SERVICE } from '@shared/table/table-service';
import { PersonnelCategoryEntriesTableService } from './personnel-category-entries-table.service';
import { AuthorizationService } from '@service/authorization.service';
import { TableComponent } from '@shared/table/table.component';

@Component({
  selector: 'app-personnel-category-entries',
  templateUrl: './personnel-category-entries.component.html',
  styleUrls: ['./personnel-category-entries.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: TABLE_SERVICE,
      useClass: PersonnelCategoryEntriesTableService,
    },
  ],
  standalone: true,
  imports: [TableComponent],
})
export class PersonnelCategoryEntriesComponent implements OnInit {
  columns: Column[] = [];
  isManager = false;

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private authorizationService: AuthorizationService,
    @Optional() @Inject(TABLE_SERVICE) private tableService: PersonnelCategoryEntriesTableService
  ) {}

  ngOnInit(): void {
    this.tableService.setPersonnelCategoryId(this.config.data.id);
    this.isManager = this.authorizationService.isManager();
    this.columns = [
      {
        field: 'inOfficialStaffingPlan',
        headerKey: 'PERSONNEL_CATEGORY.IN_OFFICIAL_STAFFING_PLAN',
        width: '250px',
        filterType: 'boolean',
        editable: true,
      },
      {
        field: 'weightingStaffingPlan',
        headerKey: 'PERSONNEL_CATEGORY.WEIGHTING_STAFFING_PLAN',
        width: '250px',
        required: true,
        editable: true,
        filterType: 'percent',
        validator: (value) => {
          if (value === undefined) {
            return 'required';
          } else if (value > 1) {
            return 'max';
          } else if (value < 0) {
            return 'min';
          }
        },
      },
      {
        field: 'targetDailyValue',
        headerKey: 'PERSONNEL_CATEGORY.TARGET_DAILY_VALUE',
        width: '200px',
        required: true,
        editable: true,
        filterType: 'time',
        validator: (value) => {
          if (value === undefined) {
            return 'required';
          } else if (value > 24) {
            return 'max';
          } else if (value < 0) {
            return 'min';
          }
        },
      },
      {
        field: 'validFrom',
        headerKey: 'GLOBAL.GUELTIG_AB',
        minWidth: '200px',
        filterType: 'date',
        required: true,
        editable: true,
        validator: (value, row) => {
          if (!value) {
            return 'required';
          }
          if (!!value && value > row.validFrom) {
            return 'Invalid';
          }
        },
      },
      {
        field: 'validTo',
        headerKey: 'GLOBAL.GUELTIG_BIS',
        minWidth: '200px',
        filterType: 'date',
        required: false,
        editable: true,
        validator: (value, row) => {
          if (!!value && value < row.validFrom) {
            return 'Invalid';
          }
        },
      },
    ];
  }

  changesSaved(data: unknown): void {
    this.ref.close(data);
  }
}
