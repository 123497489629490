import { ChangeDetectionStrategy, Component, Injector, OnInit } from '@angular/core';
import { AnwenderbetriebService } from '@service/anwenderbetrieb.service';
import { SelectItem } from 'primeng/api';
import { ServiceAccountService } from '@service/service-account.service';
import { AbstractContentComponent } from '../../abstract-content.component';
import { Context } from '@service/context.service';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { finalize } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { FormButtonBarComponent } from '@shared/form-button-bar/form-button-bar.component';
import { FormSwitchComponent } from '@shared/form-switch/form-switch.component';
import { FormDropdownComponent } from '@shared/form-dropdown/form-dropdown.component';

import { CardModule } from 'primeng/card';

interface AnwenderbetriebOrMandantEntry {
  type: 'Mandant' | 'Anwenderbetrieb';
  id?: string | number;
  name?: string;
}

@Component({
  selector: 'app-service-account-detail',
  templateUrl: './service-account-detail.component.html',
  styleUrls: ['./service-account-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CardModule,
    FormsModule,
    ReactiveFormsModule,
    FormDropdownComponent,
    FormSwitchComponent,
    FormButtonBarComponent,
    TranslateModule,
  ],
})
export class ServiceAccountDetailComponent extends AbstractContentComponent implements OnInit {
  form!: FormGroup<{
    anwenderbetriebOrMandant: FormControl<AnwenderbetriebOrMandantEntry | null>;
    hospitalInformationSystem: FormControl<boolean | null>;
  }>;
  anwenderbetriebOrMandantOptions: SelectItem<AnwenderbetriebOrMandantEntry>[] = [];
  submitting = false;

  constructor(
    private anwenderbetriebService: AnwenderbetriebService,
    private serviceAccountService: ServiceAccountService,
    private fb: FormBuilder,
    injector: Injector
  ) {
    super(injector, Context.Global);
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.setBreadcrumbs([
      { label: 'MENU.SERVICE_ACCOUNTS', routerLink: ['/service-accounts'] },
      { label: 'SERVICE_ACCOUNTS.CREATE_SERVICE_ACCOUNT' },
    ]);

    this.form = this.fb.group({
      anwenderbetriebOrMandant: new FormControl<AnwenderbetriebOrMandantEntry | null>(null, Validators.required),
      hospitalInformationSystem: new FormControl<boolean>(false, Validators.required),
    });

    this.loadData();
  }

  onSubmit(): void {
    const value = this.form.value;
    const anwenderbetriebOrMandant = value.anwenderbetriebOrMandant;
    if (anwenderbetriebOrMandant != null && anwenderbetriebOrMandant.name) {
      this.submitting = true;
      this.serviceAccountService
        .createServiceAccount(
          anwenderbetriebOrMandant.id + '',
          anwenderbetriebOrMandant.name,
          value.hospitalInformationSystem ?? false
        )
        .pipe(finalize(() => (this.submitting = false)))
        .subscribe({
          next: () => {
            this.showSuccessMessage(this.translate('SERVICE_ACCOUNTS.SUCCESSFULLY_SAVED'));
            this.navigate(['/service-accounts']);
          },
          error: (error) => {
            this.showErrorMessage(this.translate('SERVICE_ACCOUNTS.SAVE_FAILED'), error?.error?.title);
            this.markForCheck();
          },
        });
    }
  }

  protected onLanguageChange(): void {
    this.loadData();
  }

  private loadData(): void {
    this.anwenderbetriebService.getAllAnwenderbetriebe(true).subscribe((anwenderbetriebe) => {
      this.anwenderbetriebOrMandantOptions = [];
      anwenderbetriebe.forEach((anwenderbetrieb) => {
        this.anwenderbetriebOrMandantOptions.push({
          label: `${anwenderbetrieb.betriebId}  - ${anwenderbetrieb.bezeichnung} (${this.translate(
            'USERS.ANWENDERBETRIEB'
          )})`,
          value: {
            type: 'Anwenderbetrieb',
            id: anwenderbetrieb.betriebId,
            name: anwenderbetrieb.bezeichnung,
          },
        });
        anwenderbetrieb.mandant.forEach((mandant) => {
          this.anwenderbetriebOrMandantOptions.push({
            label: `${mandant.mandantId} - ${mandant.bezeichnung} (${this.translate('USERS.MANDANT')})`,
            value: {
              type: 'Mandant',
              id: mandant.mandantId,
              name: mandant.bezeichnung,
            },
          });
        });
      });
      this.anwenderbetriebOrMandantOptions.sort((a, b) => (a.label || '').localeCompare(b.label || ''));
      this.markForCheck();
    });
  }
}
