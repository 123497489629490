import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Variable } from '@entity/variable';
import { MessageService, TreeNode } from 'primeng/api';
import { MappedValue } from '@entity/catalog';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MainDataService } from '@service/main-data.service';
import { CatalogService } from '@service/catalog.service';
import { ButtonModule } from 'primeng/button';
import { TreeModule } from 'primeng/tree';

@Component({
  selector: 'app-mapping-catalog-detail-dialog',
  templateUrl: './mapping-catalog-detail-dialog.component.html',
  styleUrls: ['./mapping-catalog-detail-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TreeModule, ButtonModule, TranslateModule],
})
export class MappingCatalogDetailDialogComponent implements OnInit {
  value: TreeNode[] = [];
  selection: TreeNode[] = [];
  loading = true;

  constructor(
    private dynamicDialogRef: DynamicDialogRef,
    private changeDetectorRef: ChangeDetectorRef,
    private dynamicDialogConfig: DynamicDialogConfig,
    private catalogService: CatalogService,
    private mainDataService: MainDataService,
    private messageService: MessageService,
    private translateService: TranslateService
  ) {}

  private static determineParent(variable: Variable, parentNode?: TreeNode): TreeNode | undefined {
    while (parentNode != null && !variable.tacsId?.startsWith(parentNode?.data.tacsId)) {
      parentNode = parentNode?.parent;
    }
    return parentNode;
  }

  private static createNode(value: MappedValue): TreeNode {
    return {
      children: [],
      expanded: !!value.mappedId,
      key: value.id + '|' + value.type,
      data: {
        ...value,
      },
      label: value.tacsId + ' ' + value.name,
    };
  }

  ngOnInit(): void {
    const { mandantId, catalogId, detailId, mappingType, selectedColumns } = this.dynamicDialogConfig.data;
    const action =
      mappingType === 'Measure'
        ? mandantId == null
          ? this.mainDataService.getMeasureMappings(catalogId, detailId)
          : this.catalogService.getMeasureMappings(mandantId, catalogId, detailId)
        : mandantId == null
          ? this.mainDataService.getVariableMappings(catalogId, detailId)
          : this.catalogService.getVariableMappings(mandantId, catalogId, detailId);
    action.subscribe((result) => {
      this.updateTreeValue(result, selectedColumns ?? []);
      this.loading = false;
    });
  }

  save(): void {
    const { mandantId, catalogId, detailId, mappingType } = this.dynamicDialogConfig.data;

    let newMapping = this.selection.map((s) => s.data);
    if (mappingType === 'Measure') {
      newMapping = newMapping.filter((m) => m.tacsId.split('.').length === 6);
    }

    if (!detailId) {
      this.dynamicDialogRef.close(newMapping);
      return;
    }

    const action =
      mappingType === 'Measure'
        ? mandantId == null
          ? this.mainDataService.updateMeasureMappings(catalogId, detailId, newMapping)
          : this.catalogService.updateMeasureMappings(mandantId, catalogId, detailId, newMapping)
        : mandantId == null
          ? this.mainDataService.updateVariableMappings(catalogId, detailId, newMapping)
          : this.catalogService.updateVariableMappings(mandantId, catalogId, detailId, newMapping);

    action.subscribe({
      next: () => {
        this.messageService.add({
          severity: 'success',
          summary: this.translateService.instant('GLOBAL.SUCCESSFULLY_SAVED'),
        });
        this.dynamicDialogRef.close(newMapping);
      },
      error: () =>
        this.messageService.add({
          severity: 'error',
          summary: this.translateService.instant('GLOBAL.NOT_SUCCESSFULLY_SAVED'),
        }),
    });
  }

  cancel(): void {
    this.dynamicDialogRef.close();
  }

  private updateTreeValue(mappedValues: MappedValue[], selectedColumns: MappedValue[]): void {
    mappedValues.sort((a, b) => ((a.tacsId ?? '') < (b.tacsId ?? '') ? -1 : 1));
    this.value = [];
    let parentNode: TreeNode | undefined;
    mappedValues.forEach((mappedValue) => {
      const newNode = MappingCatalogDetailDialogComponent.createNode(mappedValue);
      if (!!mappedValue.mappedId || selectedColumns.find((c) => c.id === mappedValue.id)) {
        this.selection.push(newNode);
        newNode.expanded = true;
      }
      const newParentNode = MappingCatalogDetailDialogComponent.determineParent(mappedValue, parentNode);
      if (newParentNode) {
        if (newParentNode.expanded || newNode.expanded) {
          newParentNode.expanded = true;
          if (newParentNode.parent) {
            newParentNode.parent.expanded = true;
          }
        }
        newParentNode.children?.push(newNode);
        newNode.parent = newParentNode;
        if (parentNode !== newParentNode) {
          parentNode = newParentNode;
        }
      } else {
        this.value.push(newNode);
      }
      parentNode = newNode;
    });
    this.changeDetectorRef.markForCheck();
  }
}
