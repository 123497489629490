@if (columns.length > 0) {
  <app-table
    (columnSelectionChange)="columnSelectionChange($event)"
    (focusDateChange)="focusDateChange($event)"
    (selectionChange)="selectionChange($event)"
    [columnSelectable]="true"
    [columnSelection]="columnSelection"
    [columns]="columns"
    [dataKey]="'id'"
    [firstColumnFrozen]="true"
    [focusDate]="focusDate"
    [hasFocusDate]="true"
    [headerGroups]="headerGroups"
    [loading]="loading"
    [selectable]="true"
    [selection]="selection"
    [sortField]="'name'"
    [value]="value">
    <ng-template
      appToolbarButton
      [disabled]="tree.length === 0"
      icon="fal fa-bars"
      labelKey="GLOBAL.VIEW"
      (onClick)="view.toggle($event)"></ng-template>
    <ng-template
      appToolbarButton
      icon="fal fa-sitemap"
      labelKey="ASSIGNMENT.ORGANISATIONAL_UNIT_FILTER"
      (onClick)="organisationalUnitOverlayPanel.toggle($event)"></ng-template>
    @if (showPersonnelCategories && isManager()) {
      <ng-template
        appToolbarButton
        icon="fal fa-plus"
        labelKey="ASSIGNMENT.ADD_PERSONNEL_CATEGORY"
        (onClick)="personnelCategoryOverlayPanel.toggle($event)"></ng-template>
    }
    @if (isVariableAssignment()) {
      <ng-template
        appToolbarButton
        icon="fal fa-hand-holding-medical"
        (onClick)="toggleMeasures()"
        [labelKey]="showMeasure ? 'ASSIGNMENT.HIDE_MEASURES' : 'ASSIGNMENT.SHOW_MEASURES'"></ng-template>
    }
    @if (showEmployments && isManager()) {
      <ng-template
        appToolbarButton
        icon="fal fa-plus"
        labelKey="ASSIGNMENT.ADD_EMPLOYMENT"
        (onClick)="employmentOverlayPanel.show($event)"></ng-template>
    }
    @if (isManager()) {
      <ng-template
        appToolbarButton
        icon="fal fa-play-circle"
        labelKey="ACTIONS.ACTIVATE"
        (onClick)="showActivate()"
        [disabled]="!activeEnabled"></ng-template>
      <ng-template
        appToolbarButton
        icon="fal fa-stop-circle"
        labelKey="ACTIONS.DEACTIVATE"
        (onClick)="showDeactivate()"
        [disabled]="!deactivateEnabled"></ng-template>
    }
    @if (isVariableAssignment()) {
      <ng-template
        appToolbarButton
        icon="fal fa-book"
        labelKey="ASSIGNMENT.HANDBOOK_DOWNLOAD"
        (onClick)="showHandbookDownload($event, handbookOverlay)"></ng-template>
    }
  </app-table>
}

<p-overlayPanel
  #organisationalUnitOverlayPanel
  (onHide)="loadAssignments()"
  [hideTransitionOptions]="'0ms'"
  [showTransitionOptions]="'0ms'"
  [style]="{ width: '500px' }">
  <ng-template pTemplate>
    <div class="grid">
      <div class="col-12">
        <p-button
          (onClick)="visibleColumns = flattenTree(tree)"
          [label]="'ACTIONS.SELECT_ALL' | translate"
          icon="fal fa-check-square"
          styleClass="p-button-text"></p-button>
        <p-button
          (onClick)="visibleColumns = []"
          [label]="'ACTIONS.DESELECT_ALL' | translate"
          icon="fal fa-square"
          styleClass="p-button-text"></p-button>
        <p-tree
          (selectionChange)="visibleColumns = $any($event)"
          [filter]="true"
          [selection]="visibleColumns"
          [style]="{ width: '100%' }"
          [value]="tree"
          scrollHeight="calc(100vh - 400px)"
          selectionMode="checkbox"></p-tree>
      </div>

      @if (visibleColumns.length > maxColumns) {
        <div class="col-12">
          <p-message severity="warn" [text]="'ASSIGNMENT.TOO_MANY_COLUMNS' | translate"></p-message>
        </div>
      }

      <div class="col-12">
        <p-button
          (onClick)="organisationalUnitOverlayPanel.hide()"
          [label]="'ACTIONS.OK' | translate"
          icon="fal fa-check"></p-button>
      </div>
    </div>
  </ng-template>
</p-overlayPanel>

<p-overlayPanel
  #personnelCategoryOverlayPanel
  [dismissable]="false"
  [hideTransitionOptions]="'0ms'"
  [showTransitionOptions]="'0ms'"
  [style]="{ width: '500px' }">
  <ng-template pTemplate>
    <div class="grid">
      <div class="col-12">
        <p-tree
          #organizations
          [style]="{ width: '100%' }"
          [value]="tree"
          scrollHeight="calc(100vh - 200px)"
          selectionMode="checkbox"></p-tree>
      </div>
      <div class="col-12">
        <p-dropdown
          #personnelCategory
          [filterBy]="'label'"
          [filter]="true"
          [options]="activePersonnelCategories"
          [placeholder]="'ASSIGNMENT.SELECT_PERSONNEL_CATEGORY' | translate"
          [style]="{ width: '100%' }"
          appScrollIntoView
          appendTo="body"></p-dropdown>
      </div>
      <div class="col-12">
        <p-button
          (onClick)="
            addPersonnelCategory(organizations.selection, personnelCategory.value, personnelCategoryOverlayPanel)
          "
          [disabled]="!(organizations.selection?.length > 0 && personnelCategory.value)"
          [label]="'ACTIONS.OK' | translate"
          [style]="{ 'margin-right': '0.5rem' }"
          icon="fal fa-check"></p-button>
        <p-button
          (onClick)="personnelCategoryOverlayPanel.hide()"
          [label]="'ACTIONS.CANCEL' | translate"
          icon="fal fa-times"
          styleClass="p-button-secondary"
          type="button"></p-button>
      </div>
    </div>
  </ng-template>
</p-overlayPanel>

<p-overlayPanel
  #employmentOverlayPanel
  [dismissable]="false"
  [hideTransitionOptions]="'0ms'"
  [showTransitionOptions]="'0ms'">
  <ng-template pTemplate>
    <div class="grid">
      <div class="col-12">
        <p-dropdown
          #employment
          [filter]="true"
          [filterBy]="'label'"
          [options]="employments"
          [placeholder]="'ASSIGNMENT.SELECT_EMPLOYMENT' | translate"
          [style]="{ width: '100%' }"
          appScrollIntoView
          appendTo="body"></p-dropdown>
      </div>
      <div class="col-12">
        <p-button
          (onClick)="addEmployment(employment.value, employmentOverlayPanel)"
          [disabled]="!employment.value"
          [label]="'ACTIONS.OK' | translate"
          [style]="{ 'margin-right': '0.5rem' }"
          icon="fal fa-check"></p-button>
        <p-button
          (onClick)="employmentOverlayPanel.hide()"
          [label]="'ACTIONS.CANCEL' | translate"
          icon="fal fa-times"
          styleClass="p-button-secondary"
          type="button"></p-button>
      </div>
    </div>
  </ng-template>
</p-overlayPanel>

<p-dialog
  [(visible)]="displayActivateDialog"
  [header]="'ASSIGNMENT.ACTIVATE_SELECTED' | translate"
  [modal]="true"
  [style]="{ width: '700px' }">
  @if (form) {
    <form class="grid" novalidate (ngSubmit)="saveActivate()" [formGroup]="form">
      <app-form-date-input
        id="from"
        label="GLOBAL.GUELTIG_AB"
        [form]="form"
        [minDate]="validFrom!"
        [twoColumns]="false"></app-form-date-input>
      <app-form-date-input
        id="to"
        label="GLOBAL.GUELTIG_BIS"
        [form]="form"
        [maxDate]="validTo!"
        [twoColumns]="false"></app-form-date-input>
      <app-form-button-bar
        (onCancel)="displayActivateDialog = false"
        [saveDisabled]="submitting"
        [form]="form"></app-form-button-bar>
    </form>
  }
</p-dialog>

<p-tieredMenu
  #view
  (click)="view.hide()"
  [hideTransitionOptions]="'0ms'"
  [model]="viewMenuModel"
  [popup]="true"
  [showTransitionOptions]="'0ms'"
  [style]="{ width: '300px' }"></p-tieredMenu>

<p-overlayPanel
  #handbookOverlay
  [hideTransitionOptions]="'0ms'"
  [showTransitionOptions]="'0ms'"
  [style]="{ width: '500px' }">
  <ng-template pTemplate>
    <div class="grid">
      <div class="col-12">
        <p-tree [style]="{ width: '100%' }" [value]="handbookTree" scrollHeight="calc(100vh - 200px)">
          <ng-template let-node pTemplate="default">
            <span>{{ node.label }}</span>
            &nbsp;
            <a (click)="node.data('pdf')" class="fal fa-file-pdf"></a>
            &nbsp;
            @if (isRodixAdmin()) {
              <a class="fal fa-file-word" (click)="node.data('word')"></a>
            }
          </ng-template>
        </p-tree>
      </div>
    </div>
  </ng-template>
</p-overlayPanel>
