import { TableService } from '@shared/table/table-service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MandantService } from '@service/mandant.service';
import { ContextService } from '@service/context.service';
import { TimeCredit } from '@entity/time-credit';
import { map } from 'rxjs/operators';

@Injectable()
export class TimeCreditTableService extends TableService<TimeCredit> {
  constructor(
    private mandantService: MandantService,
    private contextService: ContextService
  ) {
    super();
  }

  createEntry(): TimeCredit {
    return {} as TimeCredit;
  }

  getData(focusDate?: Date): Observable<{ entities: TimeCredit[] }> {
    return this.mandantService
      .getTimeCredits(this.contextService.getMandantIdInstant(), focusDate ?? new Date())
      .pipe(map((entities) => ({ entities })));
  }

  isBatchUpdateSupported(): boolean {
    return true;
  }

  batchUpdate(value: TimeCredit[], focusDate?: Date): Observable<TimeCredit[]> {
    return this.mandantService.updateTimeCredits(
      this.contextService.getMandantIdInstant(),
      focusDate ?? new Date(),
      value
    );
  }
}
