import { ChangeDetectionStrategy, Component, Injector, OnInit } from '@angular/core';
import { AbstractContentComponent } from '../abstract-content.component';
import { Context } from '@service/context.service';
import { Variable, VariableEntry } from '@entity/variable';
import { Column } from '@shared/table/column';
import { MenuItem, TreeNode } from 'primeng/api';
import { VariableEntriesComponent } from './variable-entries/variable-entries.component';
import { formatDate } from '@angular/common';
import { forkJoin, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { VariableService } from '@service/variable.service';
import { maxDate, minDate } from '../util';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { ToolbarButtonDirective } from '@shared/table/toolbar-button.directive';
import { ActionButtonDirective } from '@shared/table/action-button.directive';
import { TreeTableComponent } from '@shared/table/tree-table.component';
import { AnyValue } from '@shared/table/table.types';
import { AnwenderbetriebService } from '@service/anwenderbetrieb.service';

@Component({
  selector: 'app-variables',
  templateUrl: './variables.component.html',
  styleUrls: ['./variables.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TreeTableComponent, ActionButtonDirective, ToolbarButtonDirective, TieredMenuModule],
})
export class VariablesComponent extends AbstractContentComponent implements OnInit {
  viewMenuModel: MenuItem[] = [];
  hierarchyMenuModel: MenuItem[] = [
    { label: this.translate('VARIABLES.TYPES.Elementvariable'), command: () => this.expandToHierarchy(0) },
    { label: this.translate('VARIABLES.TYPES.Basisvariable'), command: () => this.expandToHierarchy(1) },
    { label: this.translate('VARIABLES.TYPES.Detailvariable'), command: () => this.expandToHierarchy(2) },
    { label: this.translate('VARIABLES.TYPES.Bereichsvariable'), command: () => this.expandToHierarchy(3) },
    { label: this.translate('VARIABLES.TYPES.Massnahme'), command: () => this.expandToHierarchy(5) },
  ];
  value: TreeNode[] = [];
  selection: TreeNode[] = [];
  columns: Column[] = [];
  focusDate: Date = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
  deactivateEnabled = false;
  activateEnabled = false;
  showMeasure = false;

  private variables: Variable[] = [];
  private varHealthcareEnabled = false;

  constructor(
    private variableService: VariableService,
    private anwenderbetriebService: AnwenderbetriebService,
    injector: Injector
  ) {
    super(injector, Context.Mandant);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.setBreadcrumbs([{ label: 'MENU.VARIABLES' }, { label: 'MENU.VARIABLES_FULL_CATALOG' }]);
    this.onLanguageChange();
  }

  onLanguageChange(): void {
    this.columns = [
      { field: 'tacsId', headerKey: 'VARIABLES.TACS_ID', width: '250px' },
      {
        field: 'name',
        headerKey: 'GLOBAL.NAME',
        width: '350px',
      },
      {
        field: 'description',
        headerKey: 'GLOBAL.DESCRIPTION',
        minWidth: '200px',
      },
      { field: 'active', headerKey: 'GLOBAL.ACTIVE', width: '100px', filterType: 'boolean' },
      {
        field: 'isBetriebsVariable',
        headerKey: 'VARIABLES.BETRIEBSVARIABLE',
        width: '170px',
        filterType: 'boolean',
      },
      {
        field: 'isMeasure',
        headerKey: 'VARIABLES.MEASURE',
        width: '170px',
        filterType: 'boolean',
      },
      { field: 'validFrom', headerKey: 'GLOBAL.GUELTIG_AB', width: '130px', filterType: 'date' },
      { field: 'validTo', headerKey: 'GLOBAL.GUELTIG_BIS', width: '130px', filterType: 'date' },
    ];

    if (this.varHealthcareEnabled) {
      this.columns.push(
        {
          field: 'leistungsgewichtKategorie',
          headerKey: 'VARIABLES.LEISTUNGSGEWICHT_KATEGORIE',
          width: '180px',
          filterType: 'number',
        },
        {
          field: 'leistungsgewicht',
          headerKey: 'VARIABLES.LEISTUNGSGEWICHT',
          width: '180px',
          filterType: 'number',
        },
        {
          field: 'varHealthcareDocumentIds',
          headerKey: 'VARIABLES.VAR_HEALTHCARE_DOCUMENT_ID',
          width: '180px',
        }
      );
    }

    this.initMeasureMenu();
  }

  showDeactivate(): void {
    const details = `<ul>${this.selection.map((d) => `<li>${d.data.tacsId} - ${d.data.name}</li>`).join('')}</ul>`;
    const date = formatDate(this.focusDate, 'dd.MM.yyyy', 'en-US');
    this.confirm({
      header: this.translate('VARIABLES.DEACTIVATE_SELECTED'),
      message: this.translate('VARIABLES.DEACTIVATE_SELECTED_TEXT', { date }) + details,
      accept: () => {
        const observables = [];
        this.loading = true;
        this.markForCheck();
        for (const node of this.selection) {
          const variable = node.data.variable;
          const entry = variable.entries.filter((e: VariableEntry) => this.isFocused(e))[0];
          entry.validTo = minDate(this.focusDate, variable.validTo);
          observables.push(this.variableService.createOrUpdate(this.getMandantId(), variable));
        }
        this.executeUpdate(observables);
      },
    });
  }

  showDialog(row: TreeNode): void {
    const ref = this.dialogService.open(VariableEntriesComponent, {
      data: {
        variable: row.data.variable,
        active: row.data.active,
      },
      header: this.translate('VARIABLES.DETAIL_TITLE', row.data.variable),
      width: '1350px',
    });
    ref.onClose.subscribe((variable?: Variable) => {
      if (variable) {
        if (variable.id) {
          this.variables = [variable, ...this.variables.filter((v) => v.id !== variable.id)];
        } else {
          this.variables = [...this.variables.filter((v) => v.id !== row.data.variable.id)];
        }
        this.updateTreeValue(this.variables);
      }
      this.markForCheck();
    });
  }

  newBetriebsVariable(row: TreeNode): void {
    const parentVariable: Variable = row.data.variable;
    const emptyPattern = '0.00.00.000.2.xxx';
    const newBetriebsVariable: Variable = {
      parentTacsId: parentVariable.tacsId,
      parentName: parentVariable.name,
      parentSortIndex: parentVariable.sortIndex,
      sortIndex: (parentVariable.sortIndex ?? 0) + 1,
      isBetriebsVariable: true,
      tacsId: parentVariable.tacsId + emptyPattern.substring(parentVariable.tacsId?.length ?? 0),
      entries: [{ validFrom: new Date() }],
    };
    const ref = this.dialogService.open(VariableEntriesComponent, {
      data: {
        variable: newBetriebsVariable,
        active: false,
      },
      header: this.translate('VARIABLES.NEW_BETRIEBSVARIABLE'),
      width: '1350px',
    });
    ref.onClose.subscribe((variable?: Variable) => {
      if (variable) {
        this.variables = [variable, ...this.variables];
        this.updateTreeValue(this.variables);
      }
      this.markForCheck();
    });
  }

  showActivate(): void {
    const details = `<ul>${this.selection.map((d) => `<li>${d.data.tacsId} - ${d.data.name}</li>`).join('')}</ul>`;
    const date = formatDate(this.focusDate, 'dd.MM.yyyy', 'en-US');
    this.confirm({
      header: this.translate('VARIABLES.ACTIVATE_SELECTED'),
      message: this.translate('VARIABLES.ACTIVATE_SELECTED_TEXT', { date }) + details,
      accept: () => {
        const observables = [];
        this.loading = true;
        this.markForCheck();
        for (const node of this.selection) {
          const variable = node.data.variable;
          variable.entries.push({ validFrom: maxDate(this.focusDate, variable.validFrom) });
          observables.push(this.variableService.updateVariableEntries(this.getMandantId(), variable));
        }
        this.executeUpdate(observables);
      },
    });
  }

  focusDateChange($event: Date): void {
    this.focusDate = $event;
    this.updateTreeValue(this.variables);
    this.selection = [];
    this.selectionChange(this.selection);
  }

  selectionChange(selection: TreeNode[]): void {
    if (selection.length === 0) {
      this.deactivateEnabled = false;
      this.activateEnabled = false;
      return;
    }

    this.deactivateEnabled = true;
    this.activateEnabled = true;

    for (const node of selection) {
      if (node.data.variable.isLocked) {
        this.activateEnabled = false;
        this.deactivateEnabled = false;
      }
      if (node.data.variable.validTo && node.data.variable.validTo < this.focusDate) {
        this.activateEnabled = false;
      }
      if (!node.data.entries || node.data.entries.length === 0) {
        this.deactivateEnabled = false;
        continue;
      }
      const focusedEntry = node.data.entries.filter((e: VariableEntry) => this.isFocused(e))[0];
      if (!focusedEntry || focusedEntry.validTo) {
        this.deactivateEnabled = false;
      }
      if (focusedEntry) {
        this.activateEnabled = false;
      }
    }
  }

  toggleMeasures(): void {
    this.showMeasure = !this.showMeasure;
    this.initMeasureMenu();
    this.updateTreeValue(this.variables);
  }

  protected onAnwenderbetriebIdChange(id: number | null): void {
    if (id == null) {
      this.varHealthcareEnabled = false;
      return;
    }
    this.anwenderbetriebService.getAnwenderbetrieb(id).subscribe((data) => {
      this.varHealthcareEnabled = data.varHealthcareEnabled;
      this.onLanguageChange();
    });
  }

  protected onMandantIdChange(id: number | null): void {
    if (!id) {
      this.value = [];
      return;
    }
    this.selection = [];
    this.selectionChange([]);
    this.startLoading();
    this.markForCheck();
    this.variableService.getVariables(id).subscribe((variables) => {
      this.variables = variables;
      this.updateTreeValue(this.variables);
      this.loadingDone();
      this.markForCheck();
    });
  }

  private updateTreeValue(variables: Variable[]): void {
    variables.sort((a, b) => ((a.tacsId ?? '') < (b.tacsId ?? '') ? -1 : 1));
    this.value = [];
    let parentNode: TreeNode | undefined;
    variables
      .filter(
        (v) =>
          (this.showMeasure || !v.isMeasure || v.entries?.find((e) => this.isFocused(e))) &&
          (v.validTo == null || this.focusDate < v.validTo)
      )
      .forEach((variable) => {
        const newNode = this.createNode(variable);
        let newParentNode = this.determineParent(variable, parentNode);

        const expectedParentTacsId = variable.tacsId?.substring(0, variable.tacsId?.lastIndexOf('.'));
        if ((expectedParentTacsId?.length ?? 0) > 0 && expectedParentTacsId != newParentNode?.data.tacsId) {
          const oldParentNode = newParentNode;
          newParentNode = {
            expanded: true,
            selectable: false,
            children: [],
            parent: oldParentNode,
            data: {
              fake: true,
              tacsId: expectedParentTacsId,
              _style_tacsId: {
                'background-color': VariableService.getBackgroundColor(variable.tacsId),
                color: VariableService.getForegroundColor(variable.tacsId),
              },
              variable: {
                sortIndex: -1,
                tacsId: expectedParentTacsId,
              },
              id: -1,
            },
          };
          oldParentNode?.children?.push(newParentNode);
        }

        if (newParentNode) {
          if (newParentNode.expanded || newNode.expanded) {
            newParentNode.expanded = true;
            if (newParentNode.parent) {
              newParentNode.parent.expanded = true;
            }
          }
          newParentNode.children?.push(newNode);
          newNode.parent = newParentNode;
          if (parentNode !== newParentNode) {
            newParentNode.children?.sort((a, b) => a.data.variable.sortIndex - b.data.variable.sortIndex);
            parentNode = newParentNode;
          }
        } else {
          this.value.push(newNode);
        }
        parentNode = newNode;
      });
    this.markForCheck();
  }

  // noinspection JSMethodCanBeStatic
  private determineParent(variable: Variable, parentNode?: TreeNode): TreeNode | undefined {
    while (parentNode != null && !variable.tacsId?.startsWith(parentNode?.data.tacsId)) {
      parentNode = parentNode?.parent;
    }
    return parentNode;
  }

  private createNode(variable: Variable): TreeNode {
    const focusedEntry = variable.entries?.filter((e) => this.isFocused(e))[0];
    let name = (variable.customName ?? {})[this.getLanguageCaseSensitive()];
    let customNameNotApproved = variable.isBetriebsVariable && !variable.isCustomNameApproved;
    if (!name || name.length === 0) {
      name = variable.name;
    } else {
      customNameNotApproved = !variable.isCustomNameApproved;
    }
    return {
      children: [],
      expanded: (variable.entries?.length ?? 0) > 0,
      data: {
        id: variable.id,
        tacsId: variable.tacsId,
        name,
        description: variable.description,
        validFrom: focusedEntry?.validFrom,
        validTo: focusedEntry?.validTo,
        active: !!focusedEntry,
        isMeasure: variable.isMeasure,
        entries: variable.entries,
        isBetriebsVariable: variable.isBetriebsVariable,
        canAddBetriebsVariable: !variable.isBetriebsVariable && !variable.isMeasure,
        leistungsgewicht: variable.leistungsgewicht,
        leistungsgewichtKategorie: variable.leistungsgewichtKategorie,
        varHealthcareDocumentIds: variable.varHealthcareDocumentIds?.join(', '),
        _style_tacsId: {
          'background-color': VariableService.getBackgroundColor(variable.tacsId),
          color: VariableService.getForegroundColor(variable.tacsId),
        },
        _style_name: {
          color: customNameNotApproved ? VariableService.getBackgroundColor(variable.tacsId) : undefined,
          'font-style': customNameNotApproved ? 'italic' : undefined,
        },
        variable,
      },
    };
  }

  private isFocused(e: VariableEntry): boolean {
    return e.validFrom <= this.focusDate && (e.validTo == null || this.focusDate <= e.validTo);
  }

  private executeUpdate(observables: Observable<AnyValue>[]): void {
    forkJoin(observables)
      .pipe(tap(() => this.onMandantIdChange(this.getMandantId())))
      .subscribe({
        next: () => {
          this.showSuccessMessage();
          this.selection = [];
        },
        error: () => {
          this.showErrorMessage();
          this.loading = false;
          this.markForCheck();
        },
      });
  }

  private initMeasureMenu(): void {
    this.viewMenuModel = [
      {
        label: this.translate(this.showMeasure ? 'ASSIGNMENT.HIDE_MEASURES' : 'ASSIGNMENT.SHOW_MEASURES'),
        command: () => this.toggleMeasures(),
      },
      {
        label: this.translate('VARIABLES.ACTIVATE_ALL_MEASURES'),
        command: () => this.activateAllMeasures(),
      },
      {
        label: this.translate('VARIABLES.DEACTIVATE_ALL_MEASURES'),
        command: () => this.deactivateAllMeasures(),
      },
    ];
  }

  private activateAllMeasures(): void {
    const date = formatDate(this.focusDate, 'dd.MM.yyyy', 'en-US');
    this.confirm({
      header: this.translate('VARIABLES.ACTIVATE_ALL_MEASURES'),
      message: this.translate('VARIABLES.ACTIVATE_ALL_MEASURES_TEXT', { date }),
      accept: () => {
        this.startLoading();
        this.variableService.activateAllMeasures(this.getMandantId(), this.focusDate).subscribe({
          next: (variables) => {
            this.variables = variables;
            this.updateTreeValue(variables);
            this.loadingDone();
            this.showSuccessMessage();
          },
          error: () => {
            this.loadingDone();
            this.showErrorMessage();
          },
        });
      },
    });
  }

  private deactivateAllMeasures(): void {
    const date = formatDate(this.focusDate, 'dd.MM.yyyy', 'en-US');
    this.confirm({
      header: this.translate('VARIABLES.DEACTIVATE_ALL_MEASURES'),
      message: this.translate('VARIABLES.DEACTIVATE_ALL_MEASURES_TEXT', { date }),
      accept: () => {
        this.startLoading();
        this.variableService.deactivateAllMeasures(this.getMandantId(), this.focusDate).subscribe({
          next: (variables) => {
            this.variables = variables;
            this.updateTreeValue(variables);
            this.loadingDone();
            this.showSuccessMessage();
          },
          error: () => {
            this.loadingDone();
            this.showErrorMessage();
          },
        });
      },
    });
  }

  private expandToHierarchy(n: number): void {
    this.expandToHierarchyRecursively(n, this.value);
    this.value = [...this.value];
  }

  private expandToHierarchyRecursively(n: number, nodes: TreeNode[]): void {
    nodes.forEach((node) => {
      node.expanded = node.data.tacsId.split('.').length - 1 < n;
      if (node.children && node.children.length > 0) {
        this.expandToHierarchyRecursively(n, node.children);
      }
    });
  }
}
