import { ChangeDetectionStrategy, Component, Inject, Injector, OnInit, ViewChild } from '@angular/core';
import { AbstractContentComponent } from '../abstract-content.component';
import { MandantService } from '@service/mandant.service';
import { Context } from '@service/context.service';
import { Column } from '@shared/table/column';
import { Workday } from '@entity/workday';
import { SelectItem } from 'primeng/api';
import { TABLE_SERVICE } from '@shared/table/table-service';
import { WorkdayTableService } from './workday-table.service';
import { TableComponent } from '@shared/table/table.component';
import { WorkdayOverviewComponent } from './workday-overview/workday-overview.component';
import { ActionButtonDirective } from '@shared/table/action-button.directive';
import { ToolbarButtonDirective } from '@shared/table/toolbar-button.directive';

@Component({
  selector: 'app-workdays',
  templateUrl: './workdays.component.html',
  styleUrls: ['./workdays.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: TABLE_SERVICE,
      useClass: WorkdayTableService,
    },
  ],
  standalone: true,
  imports: [TableComponent, ToolbarButtonDirective, ActionButtonDirective],
})
export class WorkdaysComponent extends AbstractContentComponent implements OnInit {
  focusDate = new Date();
  columns: Column[] = [];
  columnSelection: Column[] = [];
  rowSelection: Workday[] = [];
  @ViewChild(TableComponent) table?: TableComponent;
  private organisationalUnits: SelectItem[] = [];

  constructor(
    injector: Injector,
    private mandantService: MandantService,
    @Inject(TABLE_SERVICE) private workdayTableService: WorkdayTableService
  ) {
    super(injector, Context.Mandant, 'MENU.WORKDAYS');
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  focusDateChange(): void {
    setTimeout(() => {
      this.workdayTableService.reload();
    }, 0);
  }

  activateAllSelected(): void {
    this.updateBooleanValues(true);
  }

  deactivateAllSelected(): void {
    this.updateBooleanValues(false);
  }

  showDialog(row: Workday): void {
    this.dialogService.open(WorkdayOverviewComponent, {
      data: {
        workday: row,
        year: this.focusDate.getFullYear(),
        mandantId: this.getMandantId(),
      },
      header: this.translate('GLOBAL.DETAILS'),
    });
  }

  addMissingOrganisations(): void {
    const organisationIds = this?.table?.value.map((v) => v.organisationId) ?? [];
    this.organisationalUnits
      .filter((o) => !organisationIds.includes(o.value))
      .forEach((o) => {
        this.table?.addItem({ organisationId: o.value });
      });
  }

  protected onMandantIdChange(id: number | null): void {
    if (!id) {
      return;
    }

    this.mandantService.getOrganisationalUnits(id).subscribe((data) => {
      this.organisationalUnits = data.map((o) => ({ value: o.id, label: o.bezeichnung }) as SelectItem);
      this.columns = [
        {
          field: 'organisationId',
          headerKey: 'MENU.ORGANISATIONAL_UNITS',
          minWidth: '300px',
          options: this.organisationalUnits,
          editable: true,
        },
        {
          field: 'validFrom',
          headerKey: 'GLOBAL.GUELTIG_AB',
          width: '150px',
          filterType: 'date',
          editable: true,
          disableSelection: true,
        },
        {
          field: 'validTo',
          headerKey: 'GLOBAL.GUELTIG_BIS',
          width: '150px',
          filterType: 'date',
          editable: true,
          disableSelection: true,
        },
        {
          field: 'holidaysAreWorkdays',
          headerKey: 'HOLIDAYS.HOLIDAYS_ARE_WORKDAYS',
          width: '150px',
          filterType: 'boolean',
          editable: true,
          fastEdit: true,
        },
        {
          field: 'monday',
          headerKey: 'PRIMENG.dayNames.1',
          width: '150px',
          filterType: 'boolean',
          editable: true,
          fastEdit: true,
        },
        {
          field: 'tuesday',
          headerKey: 'PRIMENG.dayNames.2',
          width: '150px',
          filterType: 'boolean',
          editable: true,
          fastEdit: true,
        },
        {
          field: 'wednesday',
          headerKey: 'PRIMENG.dayNames.3',
          width: '150px',
          filterType: 'boolean',
          editable: true,
          fastEdit: true,
        },
        {
          field: 'thursday',
          headerKey: 'PRIMENG.dayNames.4',
          width: '150px',
          filterType: 'boolean',
          editable: true,
          fastEdit: true,
        },
        {
          field: 'friday',
          headerKey: 'PRIMENG.dayNames.5',
          width: '150px',
          filterType: 'boolean',
          editable: true,
          fastEdit: true,
        },
        {
          field: 'saturday',
          headerKey: 'PRIMENG.dayNames.6',
          width: '150px',
          filterType: 'boolean',
          editable: true,
          fastEdit: true,
        },
        {
          field: 'sunday',
          headerKey: 'PRIMENG.dayNames.0',
          width: '150px',
          filterType: 'boolean',
          editable: true,
          fastEdit: true,
        },
      ];
      this.markForCheck();
      this?.workdayTableService.reload();
    });
  }

  private updateBooleanValues(value: boolean): void {
    if (this.table) {
      this.columnSelection
        .filter((c) => c.filterType === 'boolean')
        .forEach((c) => {
          this.rowSelection.forEach((r) => this.table?.update(value, r, c.field));
        });
    }
  }
}
