import { ChangeDetectionStrategy, Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { Category } from '@entity/category';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { CategoryService } from '@service/category.service';
import { AbstractContentComponent } from '../../abstract-content.component';
import { Context } from '@service/context.service';
import { SelectItem } from 'primeng/api';
import { Market } from '@entity/market';
import { LanguageFormGroup } from '@entity/language';
import { finalize } from 'rxjs';
import { FormButtonBarComponent } from '@shared/form-button-bar/form-button-bar.component';
import { FormSwitchComponent } from '@shared/form-switch/form-switch.component';
import { FormTextInputComponent } from '@shared/form-text-input/form-text-input.component';
import { FormMultiselectComponent } from '@shared/form-multiselect/form-multiselect.component';
import { FormDateInputComponent } from '@shared/form-date-input/form-date-input.component';
import { FormNumberInputComponent } from '@shared/form-number-input/form-number-input.component';
import { FormPrefixedInputMaskComponent } from '@shared/form-prefixed-input-mask/form-prefixed-input-mask.component';
import { CardModule } from 'primeng/card';
import { CategoryHierarchy } from '@entity/category-hierarchy';

@Component({
  selector: 'app-category-detail',
  templateUrl: './category-detail.component.html',
  styleUrls: ['./category-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CardModule,
    FormsModule,
    ReactiveFormsModule,
    FormPrefixedInputMaskComponent,
    FormNumberInputComponent,
    FormDateInputComponent,
    FormMultiselectComponent,
    FormTextInputComponent,
    FormSwitchComponent,
    FormButtonBarComponent,
  ],
})
export class CategoryDetailComponent extends AbstractContentComponent implements OnInit, OnDestroy {
  category: Category = {} as Category;
  form!: FormGroup<{
    description: FormGroup<LanguageFormGroup>;
    name: FormGroup<LanguageFormGroup>;
    shortCode: FormGroup<LanguageFormGroup>;
    customersPersonnelTimeShifting: FormControl<boolean>;
    validFrom: FormControl<Date>;
    validTo: FormControl<Date | undefined>;
    tacsId: FormControl<string>;
    sortNumber: FormControl<number>;
    markets: FormControl<number[]>;
  }>;
  title!: string;
  marketsOptions: SelectItem[] = [];
  mask!: string;
  minFromDate?: Date;
  maxFromDate?: Date;
  minToDate?: Date;
  maxToDate?: Date;
  prefix!: string;
  submitting = false;
  private static readonly personalKategoriePrefix = '9.';

  private categories: CategoryHierarchy[] = [];

  constructor(
    private categoryService: CategoryService,
    private fb: FormBuilder,
    injector: Injector
  ) {
    super(injector, Context.Global);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.activatedRoute.data.subscribe((data) => {
      this.category = data.category || this.category;
      this.marketsOptions = data.markets.map(
        (m: Market) =>
          ({
            value: m.id,
            label: m.names[this.getLanguageCaseSensitive()],
          }) as SelectItem
      );
    });

    this.loadCategories();

    this.activatedRoute.params.subscribe((params) => {
      this.prefix = this.category.tacsId ? this.getPrefix(this.category.tacsId) : params.tacsId;
      this.category.tacsId = this.category.tacsId ? this.getLastPart(this.category.tacsId) : '';
      this.category.parentId = this.category.parentId ?? params.parentId;
      this.category.minValidFrom =
        this.category.minValidFrom ?? (params.minValidFrom ? new Date(params.minValidFrom) : undefined);
      this.category.maxValidTo =
        this.category.maxValidTo ?? (params.maxValidTo ? new Date(params.maxValidTo) : undefined);
    });
    this.setMask();
    this.minFromDate = this.category.minValidFrom;
    this.maxFromDate = this.category.maxValidFrom;
    this.minToDate = this.category.minValidTo;
    this.maxToDate = this.category.maxValidTo;

    this.initializeForm();
    this.onLanguageChange();
  }

  private loadCategories(): void {
    this.startLoading();
    this.categoryService.getCategories().subscribe((data) => {
      this.categories = data.filter((categoryHierarchy: CategoryHierarchy) =>
        categoryHierarchy.data.tacsId.startsWith(CategoryDetailComponent.personalKategoriePrefix)
      );
      this.loadingDone();
    });
  }

  setMask(): void {
    if (this.isBetriebskategorie() || this.isOrganisationkategorie()) {
      this.mask = '99';
    } else {
      if (this.prefix.split('.').length < 3) {
        this.mask = '999';
      } else {
        this.mask = '99';
      }
    }
  }

  getLastPart(tacsId: string): string {
    const tacsIdParts = tacsId.split('.');
    return tacsIdParts.pop() ?? '0';
  }

  getPrefix(tacsId: string): string {
    const tacsIdParts = tacsId.split('.');
    tacsIdParts.pop();
    return tacsIdParts.join('.') + '.';
  }

  minDateValidator(): ValidatorFn {
    return (control) => {
      if (!this.form || !control.value) {
        return null;
      }
      const valid = control.value >= this.form.getRawValue().validFrom;
      return valid ? null : { minDate: false };
    };
  }

  onSubmit(): void {
    this.submitting = true;
    const formValue = this.form.getRawValue();
    const category: Category = {
      ...this.category,
      ...formValue,
      tacsId: this.prefix + formValue.tacsId,
      parentId: this.category.parentId,
    };
    const categoryObservable = this.category.id
      ? this.categoryService.updateCategory(this.category.id, category)
      : this.categoryService.createCategory(category);
    categoryObservable.pipe(finalize(() => (this.submitting = false))).subscribe({
      next: () => {
        this.showSuccessMessage();
        this.navigate(['/categories']);
      },
      error: () => {
        this.showErrorMessage();
      },
    });
  }

  isBetriebskategorie(): boolean {
    return this.prefix.startsWith('7.');
  }

  isOrganisationkategorie(): boolean {
    return this.prefix.startsWith('8.');
  }

  isPersonalkategorie(): boolean {
    return this.prefix.startsWith(CategoryDetailComponent.personalKategoriePrefix);
  }

  protected onLanguageChange(): void {
    const cat = this.translate('CATEGORIES.CATEGORY_' + this.prefix.substring(0, 1));
    if (this.category.id) {
      this.title = this.translate('CATEGORIES.EDIT_CATEGORY', { ...this.category, category: cat });
    } else {
      this.title = this.translate('CATEGORIES.CREATE_CATEGORY', { category: cat });
    }

    this.setBreadcrumbs([
      {
        label: 'MENU.CATEGORIES',
        routerLink: ['categories'],
      },
      {
        label: this.title,
      },
    ]);
  }

  private initializeForm(): void {
    this.form = this.fb.nonNullable.group({
      description: this.fb.nonNullable.group({
        De: [this.category.description?.De],
        Fr: [this.category.description?.Fr],
        It: [this.category.description?.It],
      }),
      name: this.fb.nonNullable.group({
        De: [this.category.name?.De, Validators.required],
        Fr: [this.category.name?.Fr],
        It: [this.category.name?.It],
      }),
      shortCode: this.fb.nonNullable.group({
        De: [this.category.shortCode?.De, this.createValidators('De')],
        Fr: [this.category.shortCode?.Fr, this.createValidators('Fr')],
        It: [this.category.shortCode?.It, this.createValidators('It')],
      }),
      customersPersonnelTimeShifting: [this.category.customersPersonnelTimeShifting],
      validFrom: [this.category.validFrom ?? this.minFromDate ?? new Date(), Validators.required],
      validTo: [this.category.validTo ?? this.maxToDate, this.minDateValidator()],
      tacsId: [this.category.tacsId, Validators.required],
      sortNumber: [this.category.sortNumber, Validators.required],
      markets: new FormControl(
        {
          value: this.category.markets
            ? this.category.markets
            : this.activatedRoute.snapshot.params.markets
              ? this.activatedRoute.snapshot.params.markets.split(',').map((x: string | number) => +x)
              : [],
          disabled: this.isNotRodixAdmin(),
        },
        { validators: Validators.required, nonNullable: true }
      ),
    });
  }

  uniqueShortCodeValidator(language: 'De' | 'Fr' | 'It'): ValidatorFn {
    return (control) => {
      if (!this.form || !control.value) {
        return null;
      }
      const existingCategories = this.getExistingCategories(this.categories, []);
      const existingShortCodesDe = existingCategories.map((category: Category) => category.shortCode[language]);
      const valid = !existingShortCodesDe.includes(this.form.getRawValue().shortCode[language]);
      return valid ? null : { NotUnique: false };
    };
  }

  private getExistingCategories(categories: CategoryHierarchy[], flatExistingCategories: Category[]): Category[] {
    categories.forEach((categoryHierarchy: CategoryHierarchy) => {
      // add parent category
      flatExistingCategories.push(categoryHierarchy.data);
      // add children categories
      categoryHierarchy.children.forEach((child: CategoryHierarchy) =>
        this.getExistingCategories([child], flatExistingCategories)
      );
    });
    return flatExistingCategories;
  }

  private createValidators(language: 'De' | 'Fr' | 'It'): ValidatorFn[] {
    const result: ValidatorFn[] = [];

    if (language === 'De' && (this.isOrganisationkategorie() || this.isPersonalkategorie())) {
      result.push(Validators.required);
    }

    if (this.isPersonalkategorie()) {
      result.push(this.uniqueShortCodeValidator(language));
    }

    return result;
  }
}
