<app-table
  (focusDateChange)="focusDateChange($event)"
  [actionColumnWidth]="150"
  [columns]="columns"
  [focusDate]="focusDate"
  [hasFocusDate]="true"
  [loading]="loading"
  [value]="value">
  <ng-template
    (onClick)="showDialog($event.row)"
    appActionButton
    icon="fal fa-search"
    labelKey="GLOBAL.DETAILS"></ng-template>
</app-table>
