import { ChangeDetectionStrategy, Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { AbstractContentComponent } from '../abstract-content.component';
import { Context } from '@service/context.service';
import { Organisation } from '@entity/organisation';
import { Column } from '@shared/table/column';
import { MandantService } from '@service/mandant.service';
import { OrganisationalUnitService } from '@service/organisational-unit.service';
import { ActionButtonDirective } from '@shared/table/action-button.directive';
import { ToolbarButtonDirective } from '@shared/table/toolbar-button.directive';

import { TableComponent } from '@shared/table/table.component';

@Component({
  selector: 'app-organisational-units',
  templateUrl: './organisational-units.component.html',
  styleUrls: ['./organisational-units.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TableComponent, ToolbarButtonDirective, ActionButtonDirective],
})
export class OrganisationalUnitsComponent extends AbstractContentComponent implements OnInit, OnDestroy {
  columns: Column[] = [];
  organisations: Organisation[] = [];

  constructor(
    private mandantService: MandantService,
    private organisationalUnitService: OrganisationalUnitService,
    injector: Injector
  ) {
    super(injector, Context.Mandant, 'MENU.ORGANISATIONAL_UNITS');
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.setColumns();
  }

  addNewOrganisationalUnit(): void {
    this.navigate(['new']);
  }

  delete(organisation: Organisation): void {
    this.confirm({
      header: this.translate('ORGANISATIONAL_UNITS.DELETE_HEADER'),
      message: this.translate('ORGANISATIONAL_UNITS.DELETE_MESSAGE', organisation),
      accept: () => {
        this.organisationalUnitService.deleteOrganisation(organisation.id).subscribe(() => {
          this.onMandantIdChange(this.getMandantId());
          this.showSuccessMessage(this.translate('ORGANISATIONAL_UNITS.DELETE_SUCCESSFULLY', organisation));
        });
      },
    });
  }

  protected onLanguageChange(): void {
    this.setColumns();
  }

  protected onMandantIdChange(id: number | null): void {
    if (id == null) {
      this.organisations = [];
      this.markForCheck();
      return;
    }
    this.startLoading();
    this.mandantService.getOrganisationalUnits(id).subscribe((data) => {
      this.organisations = data;
      this.markForCheck();
      this.loadingDone();
    });
  }

  private setColumns(): void {
    const erfassungstypOptions = [
      {
        value: 1,
        label: this.translate('ORGANISATIONAL_UNITS.ERFASSUNGSTYP_1'),
      },
      {
        value: 2,
        label: this.translate('ORGANISATIONAL_UNITS.ERFASSUNGSTYP_2'),
      },
      {
        value: 3,
        label: this.translate('ORGANISATIONAL_UNITS.ERFASSUNGSTYP_3'),
      },
    ];
    this.columns = [
      {
        field: 'organisationId',
        headerKey: 'GLOBAL.ID',
        width: '180px',
        route: this.isManager() ? '/organisational-units/${id}' : undefined,
      },
      {
        field: 'bezeichnung',
        headerKey: 'GLOBAL.NAME',
        minWidth: '250px',
      },
      {
        field: 'kuerzel',
        headerKey: 'GLOBAL.ACRONYM',
        width: '150px',
      },
      {
        field: 'kostenstelle',
        headerKey: 'ORGANISATIONAL_UNITS.COST_CENTER',
        width: '150px',
      },
      {
        field: 'gueltigAb',
        headerKey: 'GLOBAL.GUELTIG_AB',
        width: '130px',
        filterType: 'date',
      },
      {
        field: 'gueltigBis',
        headerKey: 'GLOBAL.GUELTIG_BIS',
        width: '130px',
        filterType: 'date',
      },
      {
        field: 'sortierNr',
        headerKey: 'GLOBAL.SORT_NUMBER',
        width: '130px',
        filterType: 'number',
      },
      {
        field: 'benchmarkFreigabe',
        headerKey: 'ORGANISATIONAL_UNITS.BENCHMARK_AVAILABLE',
        width: '130px',
        filterType: 'boolean',
      },
      {
        field: 'organisationCategoryDescription',
        headerKey: 'ORGANISATIONAL_UNITS.LAST_CATEGORY',
        width: '350px',
      },
      {
        field: 'erfassungstyp',
        headerKey: 'ORGANISATIONAL_UNITS.ERFASSUNGSTYP',
        options: erfassungstypOptions,
        width: '300px',
      },
      {
        field: 'hasEmployees',
        headerKey: 'ORGANISATIONAL_UNITS.HAS_EMPLOYEE',
        width: '130px',
        filterType: 'boolean',
      },
      {
        field: 'hasCustomers',
        headerKey: 'ORGANISATIONAL_UNITS.HAS_CUSTOMERS',
        width: '130px',
        filterType: 'boolean',
      },
      {
        field: 'sabEnabled',
        headerKey: 'ORGANISATIONAL_UNITS.SAB_ENABLED',
        width: '150px',
        filterType: 'boolean',
      },
    ];
  }
}
