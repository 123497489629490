import { ChangeDetectionStrategy, Component, Injector, OnInit, ViewChild } from '@angular/core';
import { AbstractContentComponent } from '../../abstract-content.component';
import { KategorieSet, MandantEdit } from '@entity/mandant';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { SelectItem } from 'primeng/api';
import { FileUpload, FileUploadModule } from 'primeng/fileupload';
import { Kanton } from '@entity/kanton';
import { TacsCodeKreis } from '@entity/tacs-code-kreis';
import { FormTimePeriodComponent, TimePeriodItem } from '@shared/form-time-period/form-time-period.component';
import { Context } from '@service/context.service';
import { MandantService } from '@service/mandant.service';
import { Market } from '@entity/market';
import { OAuthService } from 'angular-oauth2-oidc';
import * as Sentry from '@sentry/angular-ivy';
import { finalize } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { FormButtonBarComponent } from '@shared/form-button-bar/form-button-bar.component';

import { FormElementComponent } from '@shared/form-element/form-element.component';
import { FormMultiselectComponent } from '@shared/form-multiselect/form-multiselect.component';
import { FormDateInputComponent } from '@shared/form-date-input/form-date-input.component';
import { FormDropdownComponent } from '@shared/form-dropdown/form-dropdown.component';
import { FormTextInputComponent } from '@shared/form-text-input/form-text-input.component';
import { CardModule } from 'primeng/card';

@Component({
  selector: 'app-mandant-detail',
  templateUrl: './mandant-detail.component.html',
  styleUrls: ['./mandant-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CardModule,
    FormsModule,
    ReactiveFormsModule,
    FormTextInputComponent,
    FormDropdownComponent,
    FormDateInputComponent,
    FormMultiselectComponent,
    FormTimePeriodComponent,
    FormElementComponent,
    FileUploadModule,
    FormButtonBarComponent,
    TranslateModule,
  ],
})
export class MandantDetailComponent extends AbstractContentComponent implements OnInit {
  mandant!: MandantEdit;
  kantone: Kanton[] = [];
  form!: FormGroup<{
    bezeichnung: FormControl<string | undefined>;
    acronym: FormControl<string | undefined>;
    strasse: FormControl<string | undefined>;
    adressZusatz: FormControl<string | undefined>;
    plz: FormControl<string | undefined>;
    ort: FormControl<string | undefined>;
    land: FormControl<string | undefined>;
    kantonId: FormControl<number | undefined>;
    telefon: FormControl<string | undefined>;
    auswertungsbasis: FormControl<string | undefined>;
    marktIds: FormControl<number[] | undefined>;
    kategorieSets: FormControl<TimePeriodItem[]>;
    gueltigAb: FormControl<Date | undefined>;
    gueltigBis: FormControl<Date | undefined>;
    logo: FormControl<string | undefined>;
    languageCodes: FormControl<string[] | undefined>;
    benchmarkAb: FormControl<Date | undefined>;
    tacsRecordingUrl: FormControl<string | undefined>;
  }>;
  logo?: string;
  kantonOptions: SelectItem[] = [];
  countryOptions: SelectItem[] = [];
  auswertungsbasisOptions: SelectItem[] = [];
  tacsCodeKreisOptions: SelectItem[] = [];
  marktOptions: SelectItem[] = [];
  languageOptions: SelectItem[] = [];
  submitting = false;
  allTacsCodeKreises: TacsCodeKreis[] = [];
  @ViewChild('fileUpload') private fileUpload!: FileUpload;

  constructor(
    private fb: FormBuilder,
    private mandantService: MandantService,
    private oAuthService: OAuthService,
    injector: Injector
  ) {
    super(injector, Context.Global);
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.activatedRoute.data.subscribe((data) => {
      this.mandant = data.mandant ?? { anwenderbetriebsId: this.getAnwenderbetriebId() };
      this.kantone = data.kantone;
      this.allTacsCodeKreises = data.tacsCodeKreis;
      this.setMappedTacsCodeKreises(this.allTacsCodeKreises);
      this.marktOptions = data.markets.map(
        (m: Market) =>
          ({
            value: m.id,
            label: m.names[this.getLanguageCaseSensitive()],
          }) as SelectItem
      );
    });
    this.initializeForm();
    this.setFilteredTacsCodeKreisOptions();

    this.logo = this.mandant.logo;

    this.updateOptions();

    this.onLanguageChange();

    const control = this.form.get('land');
    if (control) {
      this.subscriptions.push(
        control.valueChanges.subscribe((land: string | undefined) => {
          this.kantonOptions = this.kantone
            .filter((k) => k.landCode === land)
            .map(
              (kanton: Kanton) =>
                ({
                  value: kanton.id,
                  label: kanton.bezeichnung,
                }) as SelectItem
            );
        })
      );
    }
  }

  private setMappedTacsCodeKreises(tacsCodeKreises: TacsCodeKreis[]): void {
    this.tacsCodeKreisOptions = tacsCodeKreises.map(
      (t: TacsCodeKreis) =>
        ({
          value: t.id,
          label: t.bezeichnung,
        }) as SelectItem
    );
  }

  onSubmit(): void {
    this.submitting = true;
    const formValue = this.form.value;
    const updatedMandant: MandantEdit = {
      ...formValue,
      anwenderbetriebsId: this.getAnwenderbetriebId() ?? -1,
      kategorieSets: formValue.kategorieSets?.map(
        (t: TimePeriodItem) =>
          ({
            id: t.id,
            gueltigAb: t.from,
            gueltigBis: t.to,
            kategorieId: t.value,
          }) as KategorieSet
      ),
    };
    if (this.isNotRodixAdmin()) {
      updatedMandant.land = this.mandant.land;
      updatedMandant.kantonId = this.mandant.kantonId;
      updatedMandant.auswertungsbasis = this.mandant.auswertungsbasis;
      updatedMandant.marktIds = this.mandant.marktIds;
      updatedMandant.kategorieSets = this.mandant.kategorieSets;
      updatedMandant.languageCodes = this.mandant.languageCodes;
      updatedMandant.benchmarkAb = this.mandant.benchmarkAb;
    }
    (this.mandant.id
      ? this.mandantService.updateMandant(this.mandant.id, updatedMandant)
      : this.mandantService.createMandant(updatedMandant)
    )
      .pipe(finalize(() => (this.submitting = false)))
      .subscribe({
        next: () => {
          this.showSuccessMessage();
          this.oAuthService.silentRefresh().then(
            () => {
              this.navigate(['/mandants']);
            },
            () => {
              Sentry.close().then(() => {
                this.oAuthService.logOut();
              });
            }
          );
        },
        error: () => {
          this.showErrorMessage();
        },
      });
  }

  upload(event: { files: File[] }): void {
    const fileReader = new FileReader();
    for (const file of event.files) {
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        const base64DataUrl = fileReader.result as string;
        const base64 = base64DataUrl.substring(base64DataUrl.indexOf(',') + 1);
        this.logo = base64;
        this.form.get('logo')?.setValue(base64);
        this.fileUpload.clear();
        this.markForCheck();
      };
    }
  }

  protected onLanguageChange(): void {
    this.setBreadcrumbs([
      {
        label: 'MENU.MANDANTS',
        routerLink: ['mandants'],
      },
      {
        label: this.mandant.id
          ? this.translate('MANDANTS.EDIT_MANDANT', this.mandant)
          : this.translate('MANDANTS.ADD_MANDANT'),
      },
    ]);
    this.updateOptions();
  }

  private initializeForm(): void {
    this.form = this.fb.nonNullable.group({
      bezeichnung: new FormControl(this.mandant.bezeichnung, { validators: Validators.required, nonNullable: true }),
      acronym: this.mandant.acronym,
      tacsRecordingUrl: this.mandant.tacsRecordingUrl,
      strasse: this.mandant.strasse,
      adressZusatz: this.mandant.adressZusatz,
      plz: this.mandant.plz,
      ort: this.mandant.ort,
      land: new FormControl(
        {
          value: this.mandant.land,
          disabled: this.isNotRodixAdmin(),
        },
        { validators: Validators.required, nonNullable: true }
      ),
      kantonId: new FormControl(
        {
          value: this.mandant.kantonId,
          disabled: this.isNotRodixAdmin(),
        },
        { validators: Validators.required, nonNullable: true }
      ),
      telefon: this.mandant.telefon,
      auswertungsbasis: new FormControl(
        {
          value: this.mandant.auswertungsbasis,
          disabled: this.isNotRodixAdmin(),
        },
        { validators: Validators.required, nonNullable: true }
      ),
      marktIds: new FormControl(
        {
          value: this.mandant.marktIds,
          disabled: this.isNotRodixAdmin(),
        },
        { validators: Validators.required, nonNullable: true }
      ),
      kategorieSets: new FormControl(
        {
          value:
            this.mandant.kategorieSets?.map(
              (k) =>
                ({
                  id: k.id,
                  value: k.kategorieId,
                  from: k.gueltigAb,
                  to: k.gueltigBis,
                }) as TimePeriodItem
            ) ?? [],
          disabled: this.isNotRodixAdmin(),
        },
        { validators: Validators.required, nonNullable: true }
      ),
      gueltigAb: new FormControl(this.mandant.gueltigAb, { validators: Validators.required, nonNullable: true }),
      gueltigBis: this.mandant.gueltigBis,
      logo: this.mandant.logo,
      languageCodes: new FormControl(
        {
          value: this.mandant.languageCodes,
          disabled: this.isNotRodixAdmin(),
        },
        { validators: Validators.required, nonNullable: true }
      ),
      benchmarkAb: new FormControl(
        {
          value: this.mandant.benchmarkAb ? new Date(this.mandant.benchmarkAb) : undefined,
          disabled: this.isNotRodixAdmin(),
        },
        { nonNullable: true }
      ),
    });
  }

  private updateOptions(): void {
    this.auswertungsbasisOptions = [
      {
        value: 1,
        label: this.translate('MANDANTS.AUSWERTUNGSBASIS_PENSUM'),
      },
      {
        value: 2,
        label: this.translate('MANDANTS.AUSWERTUNGSBASIS_EINSATZPLANUNG'),
      },
      {
        value: 3,
        label: this.translate('MANDANTS.AUSWERTUNGSBASIS_TACS'),
      },
    ];

    this.countryOptions = [
      {
        value: 'CHE',
        label: this.translate('COUNTRIES.CHE'),
      },
      {
        value: 'LIE',
        label: this.translate('COUNTRIES.LIE'),
      },
    ];

    this.languageOptions = [
      {
        value: 'de-CH',
        label: 'Deutsch',
      },
      {
        value: 'fr-CH',
        label: 'Français',
      },
      {
        value: 'it-CH',
        label: 'Italiano',
      },
    ];

    this.kantonOptions = this.kantone
      .filter((k) => k.landCode === this.form.get('land')?.value)
      .map(
        (kanton: Kanton) =>
          ({
            value: kanton.id,
            label: kanton.bezeichnung,
          }) as SelectItem
      );
  }

  setFilteredTacsCodeKreisOptions(): void {
    const filteredTacsCodeKreises = this.allTacsCodeKreises.filter((tck) => {
      const matchingMarktTacsCodeKreis = tck.marktTacsCodeKreis?.find((value) => {
        return this.form.value.marktIds?.find((formMarktId) => {
          return formMarktId === value.marktId;
        });
      });
      return matchingMarktTacsCodeKreis != undefined;
    });
    this.setMappedTacsCodeKreises(filteredTacsCodeKreises);
  }

  onChange(): void {
    this.setFilteredTacsCodeKreisOptions();
  }
}
