<p-card [header]="title">
  <form class="grid" novalidate (ngSubmit)="onSubmit()" [formGroup]="form">
    <app-form-text-input id="names.De" label="GLOBAL.NAME_De" [form]="form"></app-form-text-input>

    <app-form-text-input id="names.Fr" label="GLOBAL.NAME_Fr" [form]="form"></app-form-text-input>

    <app-form-text-input id="names.It" label="GLOBAL.NAME_It" [form]="form"></app-form-text-input>

    <app-form-date-input id="validFrom" label="GLOBAL.GUELTIG_AB" [form]="form"></app-form-date-input>

    <app-form-date-input id="validTo" label="GLOBAL.GUELTIG_BIS" [form]="form"></app-form-date-input>

    <app-form-button-bar [form]="form" [saveDisabled]="submitting"></app-form-button-bar>
  </form>
</p-card>
