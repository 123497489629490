<form class="grid" novalidate style="max-width: 1200px" (ngSubmit)="onSubmit()" [formGroup]="form">
  <app-form-date-input id="validFrom" label="GLOBAL.GUELTIG_AB" [form]="form"></app-form-date-input>

  <app-form-date-input id="validTo" label="GLOBAL.GUELTIG_BIS" [form]="form"></app-form-date-input>

  <app-form-number-input
    id="timeAllowancePerHourInPercent"
    label="TIME_CREDITS.TIME_CREDIT_PER_HOUR_IN_PERCENT"
    [form]="form"
    [maxFractionDigits]="0"
    [max]="100"
    [minFractionDigits]="0"
    [min]="0"
    (onFocus)="onFocus($event)"
    [twoColumns]="true"></app-form-number-input>

  <app-form-number-input
    id="timeAllowancePerHourInMinutes"
    label="TIME_CREDITS.TIME_CREDIT_PER_HOUR_IN_MINUTES"
    [form]="form"
    [maxFractionDigits]="0"
    [max]="60"
    [minFractionDigits]="0"
    [min]="0"
    (onFocus)="onFocus($event)"
    [twoColumns]="true"></app-form-number-input>

  @for (weekDay of weekDays; track weekDay; let i = $index) {
    <app-form-number-input
      [form]="form"
      [id]="weekDay.lateShiftId"
      [label]="weekDay.lateShiftLabel"
      [maxFractionDigits]="2"
      [minFractionDigits]="1"
      (onFocus)="onFocus($event)"
      [twoColumns]="true"></app-form-number-input>
    <app-form-number-input
      [form]="form"
      [id]="weekDay.nightShiftId"
      [label]="weekDay.nightShiftLabel"
      [maxFractionDigits]="2"
      [minFractionDigits]="1"
      (onFocus)="onFocus($event)"
      [twoColumns]="true"></app-form-number-input>
  }

  <app-form-number-input
    id="perWeek"
    label="TIME_CREDITS.PER_WEEK"
    [form]="form"
    [maxFractionDigits]="2"
    [minFractionDigits]="1"></app-form-number-input>
  <app-form-number-input
    id="perYear"
    label="TIME_CREDITS.PER_YEAR"
    [form]="form"
    [maxFractionDigits]="2"
    [minFractionDigits]="1"></app-form-number-input>
  <app-form-number-input
    id="totalPerYear"
    label="TIME_CREDITS.TOTAL_PER_YEAR"
    [form]="form"
    [maxFractionDigits]="2"
    [minFractionDigits]="1"></app-form-number-input>

  <app-form-button-bar (onCancel)="onCancel()" [form]="form"></app-form-button-bar>
</form>
