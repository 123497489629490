import { ChangeDetectionStrategy, Component, Injector, OnInit, ViewChild } from '@angular/core';
import { AbstractContentComponent } from '../../abstract-content.component';
import { Context } from '@service/context.service';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { Anwenderbetrieb, AnwenderbetriebPayload, License } from '@entity/anwenderbetrieb';
import { FileUpload, FileUploadModule } from 'primeng/fileupload';
import { Catalog } from '@entity/catalog';
import { SelectItem } from 'primeng/api';
import { AnwenderbetriebService } from '@service/anwenderbetrieb.service';
import { finalize, Observable } from 'rxjs';
import { FormTimePeriodComponent, TimePeriodItem } from '@shared/form-time-period/form-time-period.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormButtonBarComponent } from '@shared/form-button-bar/form-button-bar.component';

import { FormElementComponent } from '@shared/form-element/form-element.component';
import { FormMultiselectComponent } from '@shared/form-multiselect/form-multiselect.component';
import { FormDateInputComponent } from '@shared/form-date-input/form-date-input.component';
import { FormSwitchComponent } from '@shared/form-switch/form-switch.component';
import { FormTextInputComponent } from '@shared/form-text-input/form-text-input.component';
import { FormNumberInputComponent } from '@shared/form-number-input/form-number-input.component';
import { CardModule } from 'primeng/card';

@Component({
  selector: 'app-anwenderbetrieb-detail',
  templateUrl: './anwenderbetrieb-detail.component.html',
  styleUrls: ['./anwenderbetrieb-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CardModule,
    FormsModule,
    ReactiveFormsModule,
    FormNumberInputComponent,
    FormTextInputComponent,
    FormSwitchComponent,
    FormDateInputComponent,
    FormTimePeriodComponent,
    FormMultiselectComponent,
    FormElementComponent,
    FileUploadModule,
    FormButtonBarComponent,
    TranslateModule,
  ],
})
export class AnwenderbetriebDetailComponent extends AbstractContentComponent implements OnInit {
  anwenderbetrieb?: Anwenderbetrieb;
  form!: FormGroup<{
    betriebId: FormControl<number | undefined>;
    tacsRecordingUrl: FormControl<string | undefined>;
    bezeichnung: FormControl<string | undefined>;
    gueltigAb: FormControl<Date>;
    gueltigBis: FormControl<Date | undefined>;
    synchronizeMandantData: FormControl<boolean>;
    logoData: FormControl<string | undefined>;
    catalogMappingIds: FormControl<number[]>;
    lizenzierte: FormControl<TimePeriodItem[]>;
    varHealthcareEnabled: FormControl<boolean>;
  }>;
  logo?: string;
  catalogOptions: SelectItem[] = [];
  submitting = false;

  @ViewChild('fileUpload') private fileUpload!: FileUpload;

  constructor(
    injector: Injector,
    private formBuilder: FormBuilder,
    private anwenderbetriebService: AnwenderbetriebService
  ) {
    super(injector, Context.Global, 'GLOBAL.DETAILS');
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.activatedRoute.data.subscribe((data) => {
      this.anwenderbetrieb = data.anwenderbetrieb ?? {};
      this.catalogOptions = (data.catalogs ?? []).map(
        (c: Catalog) =>
          ({
            value: c.id,
            label: `${c.name} (${c.version})`,
          }) as SelectItem
      );
    });

    this.form = this.formBuilder.nonNullable.group({
      betriebId: new FormControl(
        {
          value: this.anwenderbetrieb?.betriebId,
          disabled: !!this.anwenderbetrieb?.id,
        },
        { validators: Validators.required, nonNullable: true }
      ),
      tacsRecordingUrl: new FormControl(this.anwenderbetrieb?.tacsRecordingUrl, { nonNullable: true }),
      bezeichnung: new FormControl(this.anwenderbetrieb?.bezeichnung, {
        validators: Validators.required,
        nonNullable: true,
      }),
      gueltigAb: this.anwenderbetrieb?.gueltigAb ?? new Date(),
      gueltigBis: this.anwenderbetrieb?.gueltigBis,
      synchronizeMandantData: this.anwenderbetrieb?.synchronizeMandantData ?? false,
      logoData: this.anwenderbetrieb?.logoData,
      catalogMappingIds: new FormControl(this.anwenderbetrieb?.catalogMappingIds ?? [], { nonNullable: true }),
      varHealthcareEnabled: this.anwenderbetrieb?.varHealthcareEnabled ?? false,
      lizenzierte: new FormControl(
        (this.anwenderbetrieb?.lizenzierte ?? []).map(
          (l) =>
            ({
              id: l.id,
              value: l.lizenzierteBenutzer,
              from: l.gueltigAb,
              to: l.gueltigBis,
            }) as TimePeriodItem
        ),
        { nonNullable: true }
      ),
    });

    this.logo = this.anwenderbetrieb?.logoData;
  }

  onSubmit(): void {
    this.submitting = true;
    const value = this.form.getRawValue();
    const licenses: License[] =
      value.lizenzierte?.map(
        (t: TimePeriodItem) =>
          ({
            id: t.id,
            lizenzierteBenutzer: t.value,
            gueltigAb: t.from,
            gueltigBis: t.to,
          }) as License
      ) ?? [];
    const betrieb: AnwenderbetriebPayload = {
      ...value,
      lizenzierte: licenses,
    };

    let action: Observable<Anwenderbetrieb>;
    const updateAction = !!this.anwenderbetrieb?.id;
    if (updateAction && this.anwenderbetrieb) {
      action = this.anwenderbetriebService.updateAnwenderbetrieb(this.anwenderbetrieb.id, betrieb);
    } else {
      action = this.anwenderbetriebService.createAnwenderbetrieb(betrieb);
    }
    action.pipe(finalize(() => (this.submitting = false))).subscribe({
      next: (data) => {
        this.showSuccessMessage();
        if (updateAction) {
          this.navigate('..');
        } else {
          this.setAnwenderbetriebId(data.id);
          window.location.href = window.location.protocol + '//' + window.location.host + '/mandants/new';
        }
      },
      error: (error) => {
        if (error.error.field === 'BetriebId') {
          this.form.get('betriebId')?.setErrors({ notUnique: true });
        }
        this.showErrorMessage();
      },
    });
  }

  upload(event: { files: File[] }): void {
    const fileReader = new FileReader();
    for (const file of event.files) {
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        const base64DataUrl = fileReader.result as string;
        const base64 = base64DataUrl.substr(base64DataUrl.indexOf(',') + 1);
        this.logo = base64;
        this.form.get('logoData')?.setValue(base64);
        this.fileUpload.clear();
        this.markForCheck();
      };
    }
  }
}
