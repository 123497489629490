import { ChangeDetectionStrategy, Component, computed, Injector, OnInit, signal } from '@angular/core';
import { AbstractContentComponent } from '../abstract-content.component';
import { Context } from '@service/context.service';
import { MandantService } from '@service/mandant.service';
import { Organisation } from '@entity/organisation';
import { finalize, forkJoin } from 'rxjs';
import { Column } from '@shared/table/column';
import { TargetJobPlan, TargetTime } from '@entity/target-job-plan';
import { DomSanitizer } from '@angular/platform-browser';
import { PersonnelCostsComponent } from '../personnel-costs/personnel-costs.component';
import { HeaderGroup } from '@shared/table/abstract-table.component';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { TimePeriodItem } from '@shared/form-time-period/form-time-period.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormButtonBarComponent } from '@shared/form-button-bar/form-button-bar.component';
import { FormTimePeriodComponent } from '@shared/form-time-period/form-time-period.component';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { MessageModule } from 'primeng/message';
import { ListboxModule } from 'primeng/listbox';
import { SharedModule } from 'primeng/api';
import { OverlayPanelModule } from 'primeng/overlaypanel';

import { ToolbarButtonDirective } from '@shared/table/toolbar-button.directive';
import { TableComponent } from '@shared/table/table.component';

@Component({
  selector: 'app-target-job-plans',
  templateUrl: './target-job-plans.component.html',
  styleUrls: ['./target-job-plans.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TableComponent,
    ToolbarButtonDirective,
    OverlayPanelModule,
    SharedModule,
    ListboxModule,
    FormsModule,
    MessageModule,
    ButtonModule,
    DialogModule,
    ReactiveFormsModule,
    FormTimePeriodComponent,
    FormButtonBarComponent,
    TranslateModule,
  ],
})
export class TargetJobPlansComponent extends AbstractContentComponent implements OnInit {
  focusDate = new Date();
  organisations: Organisation[] = [];
  value: TargetJobPlan[] = [];
  columns: Column[] = [];
  headerGroups: HeaderGroup[][] = [];
  dialogVisible = false;
  form = new FormGroup({
    value: new FormControl<TimePeriodItem[]>([], { validators: Validators.required, nonNullable: true }),
  });
  selectedOrganisations: number[] = [];
  maxOrganisations = 10;
  emptyRowsHidden = false;
  submitting = false;
  private organisationId?: number;
  private personnelCategoryId?: number;
  private lastMandantId?: number;
  private targetJobPlans = signal<TargetJobPlan[]>([]);

  constructor(
    injector: Injector,
    private mandantService: MandantService,
    private domSanitizer: DomSanitizer
  ) {
    super(injector, Context.Mandant, 'MENU.TARGET_JOB_PLAN');
  }

  private static formatFooter(numbers?: number[]): string {
    if (numbers) {
      return new Intl.NumberFormat('de-CH', {
        style: 'decimal',
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      }).format(numbers.reduce((a, b) => a + b));
    }
    return '0';
  }

  updateColumns(): void {
    this.columns = [
      {
        field: 'tacsIdAndName',
        headerKey: 'VARIABLES.TACS_ID',
        filterType: 'html',
        width: '400px',
        footerKey: 'GLOBAL.TOTAL',
      },
    ];
    const headerGroups: HeaderGroup[] = [
      {
        title: this.translate('VARIABLES.TACS_ID'),
        colSpan: 1,
        width: 400,
        frozen: true,
      },
    ];
    this.organisations
      .filter((o) => this.selectedOrganisations.includes(o.id))
      .forEach((o) => {
        this.columns.push({
          field: `timeEntriesMap.${o.id}.actualTime`,
          header: this.translate('TARGET_JOB_PLANS.ACTUAL'),
          width: '80px',
          filterType: 'number',
          footer: computed(() =>
            TargetJobPlansComponent.formatFooter(
              this.targetJobPlans().map((a) => a.timeEntriesMap[o.id].actualTime ?? 0)
            )
          ),
          color: '#adb5bd',
        });
        this.columns.push({
          field: `timeEntriesMap.${o.id}.focusedTargetTime.value`,
          header: this.translate('TARGET_JOB_PLANS.TARGET'),
          width: '80px',
          filterType: 'number',
          footer: computed(() =>
            TargetJobPlansComponent.formatFooter(
              this.targetJobPlans().map((a) => a.timeEntriesMap[o.id].focusedTargetTime?.value ?? 0)
            )
          ),
          editable: true,
          onEdit: (value, column, row) => this.showEditDialog(o.id, row),
        });
        headerGroups.push({ title: o.bezeichnung, colSpan: 2, width: 160 });
      });
    this.headerGroups = [headerGroups];
    this.updateValue();
  }

  save(): void {
    if (!(this.organisationId && this.personnelCategoryId && this.form.valid)) {
      return;
    }
    this.submitting = true;
    const targetTimes = this.form.getRawValue().value.map(
      (timePeriodItem: TimePeriodItem) =>
        ({
          id: timePeriodItem.id,
          value: timePeriodItem.value,
          validFrom: timePeriodItem.from,
          validTo: timePeriodItem.to,
        }) as TargetTime
    );
    this.mandantService
      .updateTargetTimes(this.getMandantId(), this.organisationId, this.personnelCategoryId, targetTimes)
      .pipe(finalize(() => (this.submitting = false)))
      .subscribe({
        next: (result) => {
          this.dialogVisible = false;
          this.showSuccessMessage();
          const targetJobPlans = [...this.targetJobPlans()];
          const targetJobPlan = targetJobPlans.find((v) => v.id === this.personnelCategoryId);
          if (targetJobPlan) {
            const targetJobPlanTimeEntry = targetJobPlan.timeEntries.find(
              (t) => t.organisationId === this.organisationId
            );
            if (targetJobPlanTimeEntry) {
              targetJobPlanTimeEntry.targetTimes = result;
              targetJobPlanTimeEntry.focusedTargetTime = this.getFocusedTargetTime(result);
            }
          }
          this.targetJobPlans.update(() => targetJobPlans);

          const value = this.value.find((v) => v.id === this.personnelCategoryId);
          if (value) {
            const timeEntry = value.timeEntries.find((t) => t.organisationId === this.organisationId);
            if (timeEntry) {
              timeEntry.targetTimes = result;
              timeEntry.focusedTargetTime = this.getFocusedTargetTime(result);
            }
          }
          this.value = [...this.value];
          this.markForCheck();
        },
        error: () => this.showErrorMessage(),
      });
  }

  focusDateChange(focusDate: Date): void {
    this.focusDate = focusDate;
    this.onMandantIdChange(this.getMandantId());
  }

  hideEmptyRows(): void {
    this.emptyRowsHidden = true;
    this.updateValue();
  }

  showEmptyRows(): void {
    this.emptyRowsHidden = false;
    this.updateValue();
  }

  protected onMandantIdChange(id: number | null): void {
    if (!id) {
      this.value = [];
      return;
    }
    this.loading = true;
    this.markForCheck();
    forkJoin([
      this.mandantService.getOrganisationalUnits(id),
      this.mandantService.getTargetJobPlans(id, this.focusDate),
    ]).subscribe(([organisations, targetJobPlans]) => {
      this.organisations = organisations.filter(
        (o) => o.gueltigAb <= this.focusDate && (!o.gueltigBis || this.focusDate <= o.gueltigBis)
      );
      if (this.lastMandantId !== id) {
        this.lastMandantId = id;
        this.selectedOrganisations = this.organisations.slice(0, 10).map((o) => o.id);
      }
      targetJobPlans.forEach((targetJobPlan) => {
        targetJobPlan.timeEntriesMap = [];
        targetJobPlan.tacsIdAndName = targetJobPlan.tacsId + ' ' + targetJobPlan.name;
        targetJobPlan._html_tacsIdAndName = this.domSanitizer.bypassSecurityTrustHtml(
          `<div style="overflow: hidden; position: relative; display: flex"><div style="white-space: nowrap; text-overflow: ellipsis; flex:1; overflow: hidden" title="${
            targetJobPlan.tacsId + ' ' + targetJobPlan.name
          }">${PersonnelCostsComponent.formatTacsId(targetJobPlan.tacsId) + ' ' + targetJobPlan.name}</div></div>`
        );

        targetJobPlan.timeEntries.forEach((timeEntry) => {
          targetJobPlan.timeEntriesMap[timeEntry.organisationId] = timeEntry;
          timeEntry.focusedTargetTime = this.getFocusedTargetTime(timeEntry.targetTimes);
        });
      });
      this.targetJobPlans.set(targetJobPlans);
      this.updateColumns();
      this.markForCheck();
      this.loading = false;
    });
  }

  private updateValue(): void {
    if (this.emptyRowsHidden) {
      this.value =
        this.targetJobPlans().filter(
          (t) =>
            t.timeEntries.filter(
              (te) =>
                this.selectedOrganisations.includes(te.organisationId) && (te.actualTime || te.focusedTargetTime?.value)
            ).length > 0
        ) ?? [];
    } else {
      this.value = this.targetJobPlans() ?? [];
    }
  }

  private showEditDialog(organisationId: number, row: TargetJobPlan): void {
    this.organisationId = organisationId;
    this.personnelCategoryId = row.id;
    this.form.controls.value.setValue(
      row.timeEntriesMap[organisationId].targetTimes.map(
        (t) =>
          ({
            value: t.value,
            id: t.id,
            from: t.validFrom,
            to: t.validTo,
          }) as TimePeriodItem
      ) ?? []
    );
    this.dialogVisible = true;
  }

  private getFocusedTargetTime(targetTimes: TargetTime[]): TargetTime | undefined {
    return targetTimes.find((t) => t.validFrom <= this.focusDate && (!t.validTo || this.focusDate <= t.validTo));
  }
}
