<app-table
  filename="MENU.MANDANTS"
  [actionColumnWidth]="300"
  [columns]="columns"
  [loading]="loading"
  [value]="mandants">
  @if (isRodixAdmin()) {
    <ng-template appToolbarButton icon="fal fa-plus" labelKey="MANDANTS.ADD_MANDANT" (onClick)="add()"></ng-template>
  }

  <ng-template
    appActionButton
    icon="fal fa-trash"
    labelKey="ACTIONS.DELETE"
    type="danger"
    (onClick)="delete($event.row)"></ng-template>
  <ng-template
    appActionButton
    icon="fal fa-undo"
    labelKey="ANWENDERBETRIEBE.DELETE_DATA_ONLY"
    type="warning"
    (onClick)="deleteDataOnly($event.row)"></ng-template>
</app-table>

<p-dialog [closable]="false" [contentStyle]="{ width: '135px', height: '135px' }" [modal]="true" [visible]="deleting">
  <p-header translate>GLOBAL.DELETING</p-header>
  <p-progressSpinner></p-progressSpinner>
</p-dialog>
