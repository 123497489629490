<app-form-element [control]="formControl" [id]="id" [label]="label" [required]="required" [twoColumns]="twoColumns">
  <div [class.invalid]="!formControl.valid">
    @for (item of values(); track item; let i = $index) {
      <div class="flex flex-wrap md:flex-row w-full mb-4 gap-2 align-items-center">
        <div class="flex flex-column gap-1">
          <label for="snomedIdentifier">{{ 'VARIABLES.SNOMED_CT_ID' | translate }}</label>
          <p-inputNumber
            inputId="snomedIdentifier"
            [format]="false"
            [ngModel]="item.identifier"
            (ngModelChange)="updateItemId(i, $event)"
            [disabled]="formControl.disabled" />
        </div>

        <div class="flex flex-column gap-1 flex-1">
          <label for="snomedTitle">{{ 'VARIABLES.SNOMED_CT_TITLE' | translate }}</label>
          <div class="flex gap-1">
            <input
              id="snomedTitle"
              pInputText
              [ngModel]="item.title"
              (ngModelChange)="updateTitle(i, $event)"
              class="flex-grow-1"
              maxlength="100" />
            @if (!formControl.disabled) {
              <p-button
                class="first-button"
                icon="fal fa-plus"
                iconPos="left"
                type="button"
                (onClick)="add(i)"></p-button>
            }
            @if (!formControl.disabled) {
              <p-button icon="fal fa-minus" iconPos="left" type="button" (onClick)="remove(i)"></p-button>
            }
          </div>
        </div>
      </div>
      @if (values().length > 1 && i !== values().length - 1) {
        <hr />
      }
    }
    @if (!values().length && !formControl.disabled) {
      <p-button class="first-button" icon="fal fa-plus" iconPos="left" type="button" (onClick)="add()"></p-button>
    }
  </div>
</app-form-element>
