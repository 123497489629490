import { ChangeDetectionStrategy, Component, Injector, OnInit } from '@angular/core';
import { AbstractContentComponent } from '../abstract-content.component';
import { Column } from '@shared/table/column';
import { Context } from '@service/context.service';
import { Beneficiary } from '@entity/beneficiary';
import { BeneficiaryService } from '@service/beneficiary.service';
import { map } from 'rxjs/operators';
import { ActionButtonDirective } from '@shared/table/action-button.directive';
import { ToolbarButtonDirective } from '@shared/table/toolbar-button.directive';

import { TableComponent } from '@shared/table/table.component';

@Component({
  selector: 'app-beneficiaries',
  templateUrl: './beneficiaries.component.html',
  styleUrls: ['./beneficiaries.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TableComponent, ToolbarButtonDirective, ActionButtonDirective],
})
export class BeneficiariesComponent extends AbstractContentComponent implements OnInit {
  beneficiaries: Beneficiary[] = [];
  columns: Column[] = [];

  constructor(
    private beneficiaryService: BeneficiaryService,
    injector: Injector
  ) {
    super(injector, Context.Mandant);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.setColumns();
    this.setBreadcrumbs([{ label: 'MENU.BENEFICIARY' }, { label: 'MENU.BENEFICIARY_OVERVIEW' }]);
  }

  createNewBeneficiary(): void {
    this.navigate(['/beneficiaries/overview/new']);
  }

  deleteBeneficiary(beneficiary: Beneficiary): void {
    this.confirm({
      header: this.translate('BENEFICIARIES.DELETE_HEADER'),
      message: this.translate('BENEFICIARIES.DELETE_MESSAGE', beneficiary),
      accept: () => {
        const id = beneficiary.id;
        if (!id) return;
        this.beneficiaryService.delete(this.getMandantId(), id).subscribe({
          next: () => {
            this.showSuccessMessage(this.translate('BENEFICIARIES.DELETE_SUCCESSFULLY', beneficiary));
            this.loadBeneficiaries();
          },
          error: () => {
            this.showErrorMessage(this.translate('BENEFICIARIES.DELETE_FAILED', beneficiary));
          },
        });
      },
    });
  }

  protected onMandantIdChange(): void {
    this.loadBeneficiaries();
  }

  private setColumns(): void {
    this.columns = [
      {
        field: 'code',
        headerKey: 'BENEFICIARIES.CODE',
        width: '300px',
        route: this.isManager() ? '/beneficiaries/overview/${id}' : undefined,
      },
      {
        field: 'name',
        headerKey: 'GLOBAL.NAME',
        width: '350px',
      },
      {
        field: 'description',
        headerKey: 'GLOBAL.DESCRIPTION',
        minWidth: '300px',
      },
      {
        field: 'costCenter',
        headerKey: 'GLOBAL.COST_CENTER',
        width: '300px',
      },
      {
        field: 'sortNumber',
        headerKey: 'GLOBAL.SORT_NUMBER',
        width: '150px',
      },
      { field: 'validFrom', headerKey: 'GLOBAL.GUELTIG_AB', width: '130px', filterType: 'date' },
      { field: 'validTo', headerKey: 'GLOBAL.GUELTIG_BIS', width: '130px', filterType: 'date' },
    ];
  }

  private loadBeneficiaries(): void {
    this.startLoading();
    this.beneficiaryService
      .list(this.getMandantId())
      .pipe(map((r) => r.entities))
      .subscribe((data) => {
        this.beneficiaries = data;
        this.loadingDone();
        this.markForCheck();
      });
  }
}
