import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Injector,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { AbstractTableComponent } from './abstract-table.component';
import { SharedModule, TreeNode } from 'primeng/api';
import { Table, TableModule } from 'primeng/table';
import { Column } from '@shared/table/column';
import { TranslateModule } from '@ngx-translate/core';
import { TableCellComponent } from './table-cell/table-cell.component';
import { InputTextModule } from 'primeng/inputtext';
import { InputNumberModule } from 'primeng/inputnumber';
import { TriStateCheckboxModule } from 'primeng/tristatecheckbox';
import { EllipsisTooltipDirective } from '../ellipsis-tooltip.directive';
import { CheckboxModule } from 'primeng/checkbox';
import { ButtonModule } from 'primeng/button';
import { ScrollIntoViewDirective } from '../scroll-into-view.directive';
import { DropdownModule } from 'primeng/dropdown';
import { CalendarModule } from 'primeng/calendar';
import { FormsModule } from '@angular/forms';
import { MultiSelectModule } from 'primeng/multiselect';
import { NgClass, NgFor, NgIf, NgSwitch, NgSwitchCase, NgSwitchDefault } from '@angular/common';
import { AnyValue } from '@shared/table/table.types';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgIf,
    TableModule,
    SharedModule,
    MultiSelectModule,
    FormsModule,
    CalendarModule,
    DropdownModule,
    ScrollIntoViewDirective,
    NgFor,
    ButtonModule,
    CheckboxModule,
    EllipsisTooltipDirective,
    NgSwitch,
    NgSwitchCase,
    TriStateCheckboxModule,
    InputNumberModule,
    NgSwitchDefault,
    InputTextModule,
    NgClass,
    TableCellComponent,
    TranslateModule,
  ],
})
export class TableComponent extends AbstractTableComponent implements OnChanges, AfterViewInit {
  @Input()
  createLabel = '';

  flatTree: TreeNode[] = [];
  visible = true;
  @Input()
  rowsPerPage = 100;
  @ViewChild('dt')
  private table?: Table;

  constructor(injector: Injector) {
    super(injector);
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
    if (changes.columns?.currentValue) {
      this.updateSelectedColumns();
      this.visible = false;
      setTimeout(() => {
        this.visible = true;
        this.markForCheck();
      });
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.table) this.table.filters = this.filters;
    });
  }

  getTrackByFn(dataKey: string): (index: number, item: AnyValue) => string | number {
    return (index, item) => {
      return item[dataKey] ?? item.id ?? item;
    };
  }

  create(): void {
    const item = this.tableService?.createEntry(this.value);
    this.addItem(item);
    this.updateButtons();
  }

  addItem(item: AnyValue): void {
    this.markAsNew(item);
    this.value = [...this.value, item];
    this.changes.added.push(item);
  }

  update(event: AnyValue, row: AnyValue, field: AnyValue): void {
    const changes = `_changes`;
    if (row[changes] === undefined) {
      row[changes] = {};
    }

    if (row[changes][field] === event) {
      delete row[changes][field];
    } else if (row[field] !== event) {
      row[changes][field] = this.getData(row, field) ?? null;
    }
    const oldValue = this.getData(row, field);
    this.setData(row, field, event);
    this.tableService?.onDataChange(event, oldValue, field, row, this.value);
    if (!this.changes.added.includes(row)) {
      if (!this.changes.updated.includes(row)) {
        this.changes.updated.push(row);
      } else if (Object.keys(row[changes]).length === 0) {
        this.changes.updated.remove(row);
      }
    }
    this.updateButtons();
    this.markForCheck();
  }

  delete(row: AnyValue): void {
    if (!this.changes.added.includes(row)) {
      this.changes.deleted.push(row);
    }
    this.changes.added.remove(row);
    this.changes.updated.remove(row);
    this.value.remove(row);
    this.value = [...this.value];
    this.updateButtons();
  }

  selectedColumnsChange($event: AnyValue, value: AnyValue): void {
    const node = this.flatTree.filter((n) => n.data === value)[0];
    if (!node) {
      return;
    }
    const add = $event.length > (this.columnSelection?.length ?? 0);
    this.columnSelection = $event;
    if (add) {
      if (node.parent && this.allSelected(node.parent.children ?? [])) {
        const v = node.parent.data;
        this.selectedColumnsChange([...(this.columnSelection ?? []), v], v);
      }
      const children = this.getAllChildren(node).map((c) => c.data);
      this.columnSelection?.push(...children.filter((c) => !this.columnSelection?.includes(c)));
    } else {
      const unselect = this.getAllChildren(node).map((c) => c.data);
      unselect.push(...this.getAllParents(node).map((c) => c.data));
      this.columnSelection = this.columnSelection?.filter((c) => !unselect.includes(c));
    }

    this.columns.forEach((c) => (c.selected = this.columnSelection?.includes(c) ?? false));
    this.columnSelectionChange.emit(this.columnSelection);
  }

  getAllChildren(node: TreeNode): TreeNode[] {
    const children = [...(node.children ?? [])];
    children.forEach((c) => children.push(...this.getAllChildren(c)));
    return [...new Set(children)];
  }

  getAllParents(node: TreeNode): TreeNode[] {
    if (!node.parent) {
      return [];
    }
    const parents = [node.parent];
    parents.push(...this.getAllParents(node.parent));
    return parents;
  }

  allSelected(siblings: TreeNode[]): boolean {
    for (const sibling of siblings) {
      if (!this.columnSelection?.includes(sibling.data)) {
        return false;
      }
    }
    return true;
  }

  resetFilter(): void {
    super.resetFilter();
    if (this.table) this.table.filters = this.filters;
    this.table?._filter();
  }

  isColumnFrozen(column: Column): boolean {
    return this.frozenColumns.includes(column);
  }

  getFilterValue(field: string): string | undefined {
    const filter = this.filters[field];
    if (Array.isArray(filter)) {
      return filter[0]?.value;
    }
    return filter?.value;
  }

  protected getMetaStore(row: AnyValue): AnyValue {
    return row;
  }

  private updateSelectedColumns(): void {
    this.columnSelection = [];
    this.columnSelectionChange.emit(this.columnSelection);
    this.columns.forEach((c) => (c.selected = this.columnSelection?.includes(c) ?? false));
    this.flatTree = [];

    const nodeMatrix: TreeNode[][] = [];
    for (const group of this.headerGroups) {
      const row: TreeNode[] = [];
      for (const header of group) {
        const node: TreeNode = { data: header, children: [] };
        this.flatTree.push(node);
        for (let i = 0; i < header.colSpan; i++) {
          row.push(node);
        }
      }
      nodeMatrix.push(row);
    }
    nodeMatrix.push(this.columns.map((c) => ({ data: c }) as TreeNode));
    this.flatTree.push(...nodeMatrix[nodeMatrix.length - 1]);

    for (let i = nodeMatrix.length - 1; i >= 1; i--) {
      for (let a = 0; a < nodeMatrix[i].length; a++) {
        const node = nodeMatrix[i][a];
        const parent = nodeMatrix[i - 1][a];
        node.parent = parent;
        parent.children?.push(node);
      }
    }
  }
}
