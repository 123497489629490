import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import * as echarts from 'echarts/core';
import { EChartsType } from 'echarts/core';
import {
  DatasetComponent,
  DatasetComponentOption,
  DataZoomComponent,
  DataZoomComponentOption,
  GridComponent,
  GridComponentOption,
  LegendComponent,
  LegendComponentOption,
  TitleComponent,
  TitleComponentOption,
  ToolboxComponent,
  ToolboxComponentOption,
  TooltipComponent,
  TooltipComponentOption,
  TransformComponent,
} from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';
import {
  BarChart,
  BarSeriesOption,
  LineSeriesOption,
  SunburstChart,
  SunburstSeriesOption,
  TreemapChart,
  TreemapSeriesOption,
} from 'echarts/charts';
import { groupFlatTransformation } from '@shared/echart/transformations';

export type Options = echarts.ComposeOption<
  | BarSeriesOption
  | LineSeriesOption
  | TitleComponentOption
  | GridComponentOption
  | DatasetComponentOption
  | LegendComponentOption
  | DataZoomComponentOption
  | TooltipComponentOption
  | ToolboxComponentOption
  | TreemapSeriesOption
  | SunburstSeriesOption
>;

@Component({
  selector: 'app-echart',
  templateUrl: './echart.component.html',
  styleUrls: ['./echart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class EchartComponent implements AfterViewInit, OnChanges {
  @ViewChild('chart') chart?: ElementRef;
  private echart?: EChartsType;

  @Input()
  private options?: Options;

  constructor() {
    echarts.use([
      TitleComponent,
      TooltipComponent,
      GridComponent,
      BarChart,
      CanvasRenderer,
      LegendComponent,
      DatasetComponent,
      TransformComponent,
      DataZoomComponent,
      ToolboxComponent,
      TreemapChart,
      SunburstChart,
    ]);
  }

  ngAfterViewInit(): void {
    this.echart = echarts.init(this.chart?.nativeElement);
    echarts.registerTransform(groupFlatTransformation);
    if (this.options) {
      this.options.color = [
        '#008CD2',
        '#FFCD0A',
        '#AFC80F',
        '#E6003C',
        '#0B29DE',
        '#FEFF03',
        '#27D404',
        '#F23D0C',
        '#0BDEB2',
        '#FF9A03',
        '#D4B204',
        '#D10CF2',
        '#0C65E8',
        '#E8D102',
        '#75DE04',
        '#FC1D0D',
        '#0CD9E8',
        '#E8A302',
        '#DED004',
        '#FC0DC4',
        '#2d2d2d',
        '#575757',
        '#9a9a9a',
        '#b4b4b4',
      ];

      this.echart?.setOption(this.options);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.options && this.options) {
      this.echart?.setOption(this.options);
    }
  }
}
