<p-card [header]="mandant.id ? ('MANDANTS.EDIT_MANDANT' | translate: mandant) : ('MANDANTS.ADD_MANDANT' | translate)">
  <form novalidate (ngSubmit)="onSubmit()" [formGroup]="form">
    <div class="grid">
      <app-form-text-input id="bezeichnung" label="GLOBAL.NAME" [form]="form" [twoColumns]="true"></app-form-text-input>
      <app-form-text-input id="acronym" label="GLOBAL.ACRONYM" [form]="form" [twoColumns]="true"></app-form-text-input>
      <app-form-text-input
        id="strasse"
        label="MANDANTS.STRASSE"
        [form]="form"
        [twoColumns]="true"></app-form-text-input>
      <app-form-text-input
        id="adressZusatz"
        label="MANDANTS.ADRESSZUSATZ"
        [form]="form"
        [twoColumns]="true"></app-form-text-input>
      <app-form-text-input id="plz" label="MANDANTS.PLZ" [form]="form" [twoColumns]="true"></app-form-text-input>
      <app-form-text-input id="ort" label="MANDANTS.ORT" [form]="form" [twoColumns]="true"></app-form-text-input>
      <app-form-dropdown
        id="land"
        label="MANDANTS.LAND"
        [form]="form"
        [options]="countryOptions"
        [twoColumns]="true"></app-form-dropdown>
      <app-form-dropdown
        id="kantonId"
        label="MANDANTS.KANTON"
        [form]="form"
        [options]="kantonOptions"
        [twoColumns]="true"></app-form-dropdown>
      <app-form-text-input
        id="telefon"
        label="MANDANTS.TELEFON"
        [form]="form"
        [twoColumns]="true"></app-form-text-input>
      <app-form-dropdown
        id="auswertungsbasis"
        label="MANDANTS.AUSWERTUNGSBASIS"
        [form]="form"
        [options]="auswertungsbasisOptions"
        [twoColumns]="true"></app-form-dropdown>
      <app-form-date-input
        id="benchmarkAb"
        label="MANDANTS.BENCHMARK_AB"
        [form]="form"
        [twoColumns]="true"></app-form-date-input>
      <app-form-multiselect
        id="languageCodes"
        label="MANDANTS.LANGUAGES"
        [form]="form"
        [options]="languageOptions"
        [twoColumns]="true"></app-form-multiselect>
      <app-form-date-input
        id="gueltigAb"
        label="GLOBAL.GUELTIG_AB"
        [form]="form"
        [twoColumns]="true"></app-form-date-input>
      <app-form-date-input
        id="gueltigBis"
        label="GLOBAL.GUELTIG_BIS"
        [form]="form"
        [twoColumns]="true"></app-form-date-input>

      <app-form-multiselect
        id="marktIds"
        label="MANDANTS.MARKTS"
        [form]="form"
        [options]="marktOptions"
        (onChange)="onChange()"
        [twoColumns]="true"></app-form-multiselect>

      <app-form-time-period
        id="kategorieSets"
        label="MANDANTS.KATEGORIE_SETS"
        [form]="form"
        [options]="tacsCodeKreisOptions"
        [withoutGap]="true"></app-form-time-period>

      <div class="col-12">
        <app-form-element id="logo" label="MANDANTS.LOGO">
          <p-fileUpload
            mode="basic"
            (uploadHandler)="upload($event)"
            [auto]="true"
            [chooseLabel]="'MANDANTS.CHOOSE_LOGO' | translate"
            [customUpload]="true"
            #fileUpload></p-fileUpload>
          @if (logo) {
            <img class="logo" src="data:image/jpg;base64,{{ logo }}" />
          }
        </app-form-element>
      </div>

      <app-form-text-input
        [form]="form"
        id="tacsRecordingUrl"
        label="ANWENDERBETRIEBE.TACS_RECORDING_URL"></app-form-text-input>

      <app-form-button-bar [form]="form" [saveDisabled]="submitting"></app-form-button-bar>
    </div>
  </form>
</p-card>
