import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { AbstractFormElement } from '../abstract-form-element';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ScrollIntoViewDirective } from '../scroll-into-view.directive';
import { DropdownModule } from 'primeng/dropdown';
import { FormElementComponent } from '../form-element/form-element.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-form-dropdown',
  templateUrl: './form-dropdown.component.html',
  styleUrls: ['./form-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    FormElementComponent,
    DropdownModule,
    ScrollIntoViewDirective,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
  ],
})
export class FormDropdownComponent extends AbstractFormElement<SelectItem[]> implements OnInit {
  @Input()
  options: SelectItem[] = [];

  @Input()
  filter = false;

  @Input()
  placeholder?: string;

  ngOnInit(): void {
    super.ngOnInit();
    if (!this.formControl.value && this.options.length > 0 && !this.placeholder) {
      this.formControl.setValue(this.options[0].value);
    }
  }
}
