<div class="topbar clearfix">
  <h1 style="display: inline-block; font-weight: bold; color: #ffffff; width: 120px; font-size: 24px">tacs®</h1>

  <a (click)="app.onMenuButtonClick($event)" href="#" id="menu-button" pRipple>
    <i class="fal fa-align-left"></i>
  </a>

  <a (click)="app.onTopbarMenuButtonClick($event)" class="profile" href="#" pRipple style="display: flex">
    <span class="username" style="align-self: center; padding-left: 8px">{{ username }}</span>
    <i class="fal fa-user-circle" style="font-size: 30px; padding: 5px; color: white"></i>
    <i class="fal fa-angle-down" style="color: white; align-self: center"></i>
  </a>

  <span class="topbar-language">
    @for (language of languages; track language; let last = $last) {
      <span (click)="changeLanguage(language)" [class.active]="language === currentLanguage">
        {{ language.toUpperCase() }}
      </span>
      @if (!last) {
        &nbsp;|&nbsp;
      }
    }
  </span>

  <span class="topbar-context">
    <p-dropdown
      (onChange)="tenantSelected()"
      [dataKey]="'id'"
      [filterFields]="['label']"
      [filter]="true"
      [formControl]="selectedTenant"
      [group]="isMandant()"
      [options]="anwenderbetriebOptions"
      [style]="{ width: '100%', visibility: this.isGlobal() ? 'hidden' : 'visible' }"
      appScrollIntoView></p-dropdown>
  </span>

  <ul [ngClass]="{ 'topbar-menu-visible': app.topbarMenuActive }" class="topbar-menu fadeInDown">
    <li>
      <a [href]="this.changePasswordUrl" pRipple>
        <i class="topbar-icon fal fa-key"></i>
        <span class="topbar-item-name" translate>GLOBAL.CHANGE_PASSWORD</span>
      </a>
      <a [href]="this.twoFactorAuthUrl" pRipple>
        <i class="topbar-icon fal fa-qrcode"></i>
        <span class="topbar-item-name" translate>GLOBAL.TWO_FACTOR_AUTHENTICATION</span>
      </a>
      <a (click)="logout()" href="#" pRipple>
        <i class="topbar-icon fal fa-sign-out"></i>
        <span class="topbar-item-name" translate>GLOBAL.SIGN_OUT</span>
      </a>
    </li>
  </ul>
</div>
