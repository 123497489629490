import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Organisation } from '@entity/organisation';

@Injectable({
  providedIn: 'root',
})
export class OrganisationalUnitService {
  private readonly baseUrl = `api/1.0/organisational-units`;

  constructor(private httpClient: HttpClient) {}

  getOrganisationalUnit(organisationId: number): Observable<Organisation> {
    return this.httpClient.get<Organisation>(`${this.baseUrl}/${organisationId}`);
  }

  updateOrganisationalUnit(organisationId: number, organisation: Organisation): Observable<Organisation> {
    return this.httpClient.put<Organisation>(`${this.baseUrl}/${organisationId}`, organisation);
  }

  createOrganisationalUnit(organisation: Organisation): Observable<Organisation> {
    return this.httpClient.post<Organisation>(`${this.baseUrl}`, organisation);
  }

  deleteOrganisation(organisationId: number): Observable<unknown> {
    return this.httpClient.delete(`${this.baseUrl}/${organisationId}`);
  }
}
