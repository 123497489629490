import { SelectItem } from 'primeng/api';
import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { map } from 'rxjs/operators';
import { RoleService } from '@service/role.service';
import { TranslateService } from '@ngx-translate/core';

export const RoleOptionsResolve: ResolveFn<SelectItem[]> = () => {
  const translateService = inject(TranslateService);
  return inject(RoleService)
    .getRoles()
    .pipe(
      map((roles) =>
        roles.map((role) => ({
          label: translateService.instant(`ROLES.${role.name}`),
          value: role,
        }))
      )
    );
};
