<app-tree-table
  [actionColumnWidth]="isCreateAllowed ? 350 : 200"
  [columns]="columns"
  [createLabels]="['MAPPING_CATALOGS.ADD_GROUP', 'MAPPING_CATALOGS.ADD_DETAIL']"
  [editable]="true"
  [firstColumnFrozen]="true"
  [isCreateAllowed]="isCreateAllowed"
  [showFrozenColumnSelection]="false"
  [sortField]="'id'">
  <ng-template
    appToolbarButton
    icon="fal fa-columns"
    labelKey="MAPPING_CATALOGS.COLUMNS"
    (onClick)="showColumnSelection()"></ng-template>

  @if (!isCreateAllowed && isRodixAdmin()) {
    <ng-template
      appToolbarButton
      icon="fal fa-undo"
      labelKey="MAPPING_CATALOGS.RESET_TO_DEFAULT"
      (onClick)="resetToDefault()"></ng-template>
  }

  @if (isRodixAdmin()) {
    <ng-template
      appToolbarButton
      icon="fal fa-download"
      labelKey="MAPPING_CATALOGS.DOWNLOAD_XLSX_MAPPING"
      (onClick)="downloadMappingCatalog()"></ng-template>
  }

  <ng-template
    appActionButton
    icon="fal fa-search"
    labelKey="GLOBAL.DETAILS"
    (onClick)="showDetailForRow($event.row)"
    [displayIf]="'node.data.detail'"></ng-template>
</app-tree-table>
