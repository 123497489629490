import { ChangeDetectionStrategy, Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { Context } from '@service/context.service';
import { Mandant } from '@entity/mandant';
import { AnwenderbetriebService } from '@service/anwenderbetrieb.service';
import { AbstractContentComponent } from '../abstract-content.component';
import { Observable } from 'rxjs';
import { Column } from '@shared/table/column';
import { MandantService } from '@service/mandant.service';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from 'primeng/api';
import { DialogModule } from 'primeng/dialog';
import { ActionButtonDirective } from '@shared/table/action-button.directive';
import { ToolbarButtonDirective } from '@shared/table/toolbar-button.directive';

import { TableComponent } from '@shared/table/table.component';

@Component({
  selector: 'app-mandants',
  templateUrl: './mandants.component.html',
  styleUrls: ['./mandants.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TableComponent,
    ToolbarButtonDirective,
    ActionButtonDirective,
    DialogModule,
    SharedModule,
    TranslateModule,
    ProgressSpinnerModule,
  ],
})
export class MandantsComponent extends AbstractContentComponent implements OnInit, OnDestroy {
  columns: Column[] = [];
  mandants: Mandant[] = [];
  deleting = false;
  canDelete = false;

  constructor(
    private mandantService: MandantService,
    private anwenderbetriebService: AnwenderbetriebService,
    injector: Injector
  ) {
    super(injector, Context.Anwenderbetrieb, 'MENU.MANDANTS');
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.setColumns();
    this.canDelete = this.isRodixAdmin();
  }

  delete(mandant: Mandant): void {
    this.confirm({
      header: this.translate('ANWENDERBETRIEBE.DELETE_HEADER'),
      message: this.translate('ANWENDERBETRIEBE.DELETE_MESSAGE', {
        id: mandant.mandantId,
        name: mandant.bezeichnung,
      }),
      accept: () => {
        this.deleteMandant(mandant, false);
      },
    });
  }

  deleteDataOnly(mandant: Mandant): void {
    this.confirm({
      header: this.translate('ANWENDERBETRIEBE.DELETE_DATA_ONLY_HEADER'),
      message: this.translate('ANWENDERBETRIEBE.DELETE_DATA_ONLY_MESSAGE', {
        id: mandant.mandantId,
        name: mandant.bezeichnung,
      }),
      accept: () => {
        this.deleteMandant(mandant, true);
      },
    });
  }

  add(): void {
    this.navigate(['new']);
  }

  protected onLanguageChange(): void {
    this.setColumns();
  }

  protected onAnwenderbetriebIdChange(id: number | null): void {
    if (id == null) {
      this.mandants = [];
      this.markForCheck();
      return;
    }
    this.startLoading();
    this.anwenderbetriebService.getMandants(id).subscribe((data) => {
      this.mandants = data;
      this.markForCheck();
      this.loadingDone();
    });
  }

  private setColumns(): void {
    this.columns = [
      {
        field: 'mandantId',
        headerKey: 'GLOBAL.ID',
        width: '120px',
        route: '/mandants/${id}',
      },
      {
        field: 'acronym',
        headerKey: 'GLOBAL.ACRONYM',
        width: '150px',
      },
      {
        field: 'bezeichnung',
        headerKey: 'GLOBAL.NAME',
        minWidth: '250px',
      },
      {
        field: 'tacsRecordingUrl',
        headerKey: 'ANWENDERBETRIEBE.TACS_RECORDING_URL',
        minWidth: '250px',
        url: 'tacsRecordingUrl',
      },
      {
        field: 'gueltigAb',
        headerKey: 'GLOBAL.GUELTIG_AB',
        width: '130px',
        filterType: 'date',
      },
      {
        field: 'gueltigBis',
        headerKey: 'GLOBAL.GUELTIG_BIS',
        width: '130px',
        filterType: 'date',
      },
      {
        field: 'benchmarkFreigabe',
        headerKey: 'MANDANTS.BENCHMARK',
        width: '130px',
        filterType: 'boolean',
      },
      {
        field: 'logoWeb',
        headerKey: 'MANDANTS.LOGO',
        width: '120px',
        filterType: 'boolean',
      },
      {
        field: 'superUsers',
        headerKey: 'MANDANTS.SUPERUSERS',
        width: '400px',
      },
    ];
  }

  private deleteMandant(mandant: Mandant, dataOnly: boolean): void {
    this.deleting = true;
    this.markForCheck();
    this.handleDelete(this.mandantService.deleteMandant(mandant.id, dataOnly), mandant, dataOnly);
  }

  private handleDelete(deleteOperationObservable: Observable<unknown>, mandant: Mandant, dataOnly: boolean): void {
    deleteOperationObservable.subscribe({
      next: () => {
        this.deleting = false;
        const key = dataOnly
          ? 'ANWENDERBETRIEBE.DELETE_DATA_ONLY_SUCCESSFULLY'
          : 'ANWENDERBETRIEBE.DELETE_SUCCESSFULLY';
        this.showSuccessMessage(
          this.translate(key, {
            id: mandant.mandantId,
            name: mandant.bezeichnung,
          })
        );
        this.onAnwenderbetriebIdChange(mandant.anwenderbetriebsId);
      },
      error: () => {
        this.deleting = false;
        const key = dataOnly ? 'ANWENDERBETRIEBE.DELETE_DATA_ONLY_FAILED' : 'ANWENDERBETRIEBE.DELETE_FAILED';
        this.showErrorMessage(
          this.translate(key, {
            id: mandant.mandantId,
            name: mandant.bezeichnung,
          })
        );
      },
    });
  }
}
