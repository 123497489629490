import { TableService } from '@shared/table/table-service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { JobService } from '../jobs/job.service';
import { ImportJobVerification } from '@entity/job';
import { map } from 'rxjs/operators';

@Injectable()
export class JobControllingTableService extends TableService<ImportJobVerification> {
  constructor(private jobService: JobService) {
    super();
  }

  getData(): Observable<{ entities: ImportJobVerification[] }> {
    return this.jobService.getImportJobVerifications().pipe(map((entities) => ({ entities })));
  }

  batchUpdate(value: ImportJobVerification[]): Observable<ImportJobVerification[]> {
    return this.jobService.updateImportJobVerifications(value);
  }

  isBatchUpdateSupported(): boolean {
    return true;
  }

  createEntry(): ImportJobVerification {
    return {
      clientId: '',
      dailyExport: true,
      monthlyExport: true,
      dailyExportWithReplace: false,
      errorThreshold: 0,
      warningThreshold: 0,
    } as ImportJobVerification;
  }
}
