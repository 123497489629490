import { ChangeDetectionStrategy, Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { AbstractContentComponent } from '../abstract-content.component';
import { AnwenderbetriebService } from '@service/anwenderbetrieb.service';
import { Context } from '@service/context.service';
import { SharedModule, TreeNode } from 'primeng/api';
import { Observable } from 'rxjs';
import { Column } from '@shared/table/column';
import { MandantService } from '@service/mandant.service';
import { Anwenderbetrieb } from '@entity/anwenderbetrieb';
import { downloadBlob } from '../download';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TranslateModule } from '@ngx-translate/core';
import { DialogModule } from 'primeng/dialog';
import { ActionButtonDirective } from '@shared/table/action-button.directive';
import { ToolbarButtonDirective } from '@shared/table/toolbar-button.directive';
import { TreeTableComponent } from '@shared/table/tree-table.component';

@Component({
  selector: 'app-anwenderbetriebe',
  templateUrl: './anwenderbetriebe.component.html',
  styleUrls: ['./anwenderbetriebe.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TreeTableComponent,
    ToolbarButtonDirective,
    ActionButtonDirective,
    DialogModule,
    SharedModule,
    TranslateModule,
    ProgressSpinnerModule,
  ],
})
export class AnwenderbetriebeComponent extends AbstractContentComponent implements OnInit, OnDestroy {
  tree: TreeNode[] = [];
  columns: Column[] = [];
  deleting = false;
  focusYear = new Date().getFullYear();

  constructor(
    private anwenderbetriebService: AnwenderbetriebService,
    private mandantService: MandantService,
    injector: Injector
  ) {
    super(injector, Context.Global, 'MENU.ANWENDERBETRIEBE');
  }

  private static isLicenseAgreementMet(anwenderbetrieb: Anwenderbetrieb): boolean {
    return (anwenderbetrieb.licenseCount ?? 0) >= (anwenderbetrieb.employeeCount ?? 0);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.setColumns();
    this.loadData();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  focusYearChange(focusYear: number): void {
    this.focusYear = focusYear;
    this.loadData();
  }

  downloadLicenseProof(row: TreeNode): void {
    this.showLoadingDialog();
    this.anwenderbetriebService.downloadLicenseProof(row.data.dbId, this.focusYear).subscribe((result) => {
      if (result.body) {
        downloadBlob(result.body, 'license_proof.xlsx');
      }
      this.hideLoadingDialog();
    });
  }

  delete(node: TreeNode): void {
    this.confirm({
      header: this.translate('ANWENDERBETRIEBE.DELETE_HEADER'),
      message: this.translate('ANWENDERBETRIEBE.DELETE_MESSAGE', node.data),
      accept: () => this.deleteTenant(node, false),
    });
  }

  deleteDataOnly(node: TreeNode): void {
    this.confirm({
      header: this.translate('ANWENDERBETRIEBE.DELETE_DATA_ONLY_HEADER'),
      message: this.translate('ANWENDERBETRIEBE.DELETE_DATA_ONLY_MESSAGE', node.data),
      accept: () => this.deleteTenant(node, true),
    });
  }

  create(): void {
    this.navigate('new');
  }

  protected onLanguageChange(): void {
    this.setColumns();
  }

  private deleteTenant(node: TreeNode, dataOnly: boolean): void {
    this.deleting = true;
    this.markForCheck();
    const mandantId = node.data.dbId;
    if (node.leaf && node.parent) {
      this.handleDelete(this.mandantService.deleteMandant(mandantId, dataOnly), node, dataOnly);
    } else {
      this.handleDelete(this.anwenderbetriebService.deleteAnwenderbetrieb(mandantId, dataOnly), node, dataOnly);
    }
  }

  private handleDelete(observable: Observable<unknown>, node: TreeNode, dataOnly: boolean): void {
    observable.subscribe({
      next: () => {
        this.deleting = false;
        const key = dataOnly
          ? 'ANWENDERBETRIEBE.DELETE_DATA_ONLY_SUCCESSFULLY'
          : 'ANWENDERBETRIEBE.DELETE_SUCCESSFULLY';
        this.showSuccessMessage(this.translate(key, node.data));
        this.loadData();
      },
      error: () => {
        this.deleting = false;
        const key = dataOnly ? 'ANWENDERBETRIEBE.DELETE_DATA_ONLY_FAILED' : 'ANWENDERBETRIEBE.DELETE_FAILED';
        this.showErrorMessage(this.translate(key, node.data));
      },
    });
  }

  private setColumns(): void {
    this.columns = [
      { field: 'id', headerKey: 'GLOBAL.ID', width: '200px', route: '${route}' },
      { field: 'name', headerKey: 'GLOBAL.NAME', minWidth: '250px', route: '${route}' },
      {
        field: 'tacsRecordingUrl',
        headerKey: 'ANWENDERBETRIEBE.TACS_RECORDING_URL',
        minWidth: '250px',
        url: 'tacsRecordingUrl',
      },
      { field: 'licenseCount', headerKey: 'ANWENDERBETRIEBE.LICENSE_COUNT', width: '150px', filterType: 'number' },
      { field: 'employeeCount', headerKey: 'ANWENDERBETRIEBE.EMPLOYEE_COUNT', width: '150px', filterType: 'number' },
      { field: 'userCount', headerKey: 'ANWENDERBETRIEBE.USER_COUNT', width: '150px', filterType: 'number' },
      {
        field: 'licenseAgreementMet',
        headerKey: 'ANWENDERBETRIEBE.LICENSE_AGREEMENT_MET',
        width: '200px',
        filterType: 'boolean',
      },
      {
        field: 'from',
        headerKey: 'GLOBAL.GUELTIG_AB',
        width: '150px',
        filterType: 'date',
      },
      {
        field: 'to',
        headerKey: 'GLOBAL.GUELTIG_BIS',
        width: '150px',
        filterType: 'date',
      },
    ];
  }

  private loadData(): void {
    this.startLoading();
    this.anwenderbetriebService.getAllAnwenderbetriebe(true, true, this.focusYear).subscribe((anwenderbetriebe) => {
      this.tree = anwenderbetriebe.map(
        (anwenderbetrieb) =>
          ({
            data: {
              route: '/anwenderbetriebe/' + anwenderbetrieb.id,
              isAnwenderbetrieb: true,
              dbId: anwenderbetrieb.id,
              name: anwenderbetrieb.bezeichnung,
              id: anwenderbetrieb.betriebId,
              from: anwenderbetrieb.gueltigAb,
              to: anwenderbetrieb.gueltigBis,
              licenseCount: anwenderbetrieb.licenseCount,
              employeeCount: anwenderbetrieb.employeeCount,
              userCount: anwenderbetrieb.userCount,
              licenseAgreementMet: AnwenderbetriebeComponent.isLicenseAgreementMet(anwenderbetrieb),
              tacsRecordingUrl: anwenderbetrieb.tacsRecordingUrl,
            },
            leaf: false,
            children: anwenderbetrieb.mandant.map(
              (mandant) =>
                ({
                  data: {
                    route: '/mandants/' + mandant.id,
                    dbId: mandant.id,
                    name: mandant.bezeichnung,
                    id: mandant.mandantId,
                    from: mandant.gueltigAb,
                    to: mandant.gueltigBis,
                    licenseAgreementMet: AnwenderbetriebeComponent.isLicenseAgreementMet(anwenderbetrieb),
                    tacsRecordingUrl: mandant.tacsRecordingUrl,
                  },
                  leaf: true,
                }) as TreeNode
            ),
          }) as TreeNode
      );
      this.markForCheck();
      this.loadingDone();
    });
  }
}
