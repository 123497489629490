import { ChangeDetectionStrategy, Component, Injector, OnInit } from '@angular/core';
import { CategoryService } from '@service/category.service';
import { Context } from '@service/context.service';
import { Column } from '@shared/table/column';
import { AbstractContentComponent } from '../abstract-content.component';
import { MenuItem, SharedModule, TreeNode } from 'primeng/api';
import { CategoryHierarchy } from '@entity/category-hierarchy';
import { Mandant } from '@entity/mandant';
import { Category } from '@entity/category';
import { Market } from '@entity/market';
import { TableComponent } from '@shared/table/table.component';
import { TranslateModule } from '@ngx-translate/core';
import { DialogModule } from 'primeng/dialog';
import { MenuModule } from 'primeng/menu';
import { ActionButtonDirective } from '@shared/table/action-button.directive';
import { ToolbarButtonDirective } from '@shared/table/toolbar-button.directive';
import { TreeTableComponent } from '@shared/table/tree-table.component';

@Component({
  selector: 'app-category',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TreeTableComponent,
    ToolbarButtonDirective,
    ActionButtonDirective,
    MenuModule,
    DialogModule,
    SharedModule,
    TranslateModule,
    TableComponent,
  ],
})
export class CategoriesComponent extends AbstractContentComponent implements OnInit {
  columns: Column[] = [];
  tree: TreeNode[] = [];
  mandanten: Mandant[] = [];
  displayDialog = false;
  mandantenColumns: Column[] = [];
  items: MenuItem[] = [];
  private markets: Market[] = [];

  constructor(
    private categoryService: CategoryService,
    injector: Injector
  ) {
    super(injector, Context.Global, 'MENU.CATEGORIES');
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.activatedRoute.data.subscribe((data) => {
      this.markets = data.markets;
    });
    this.setColumns();
    this.setMandantenColumns();
    this.createCategoryMenuButton();
    this.loadCategories();
  }

  createTreeNode(categoryHierarchy: CategoryHierarchy, parent = true): TreeNode {
    const market: { [key: number]: boolean } = {};
    categoryHierarchy.data.markets.forEach((key) => (market[key] = true));
    return {
      data: { ...categoryHierarchy.data, _isParent: parent, market },
      leaf: categoryHierarchy.children.length === 0,
      children: categoryHierarchy.children.map((child) => {
        return this.createTreeNode(child, this.hasSubCategorySubCategories(child));
      }),
    } as TreeNode;
  }

  createCategoryMenuButton(): void {
    this.items = [
      {
        label: this.translate('CATEGORIES.CATEGORY_7'),
        command: () => this.createNewCategory('7.'),
      },
      {
        label: this.translate('CATEGORIES.CATEGORY_8'),
        command: () => this.createNewCategory('8.'),
      },
      {
        label: this.translate('CATEGORIES.CATEGORY_9'),
        command: () => this.createNewCategory('9.'),
      },
    ];
  }

  loadCategories(): void {
    this.startLoading();
    this.categoryService.getCategories().subscribe((data) => {
      this.tree = data.map((category: CategoryHierarchy) => this.createTreeNode(category));
      this.markForCheck();
      this.loadingDone();
    });
  }

  createNewCategory(categoryTacsId: string): void {
    this.navigate(['/categories/new', { tacsId: categoryTacsId } as Category]);
  }

  createNewSubCategory(node: TreeNode): void {
    const category: Category = {} as Category;
    category.tacsId = node.data.tacsId + '.';
    category.parentId = node.data.id;
    category.markets = node.data.markets;
    category.minValidFrom = node.data.validFrom?.toJSON();
    if (node.data.validTo) {
      category.maxValidTo = node.data.validTo?.toJSON();
    }
    this.navigate(['/categories/new', category]);
  }

  deleteCategory(category: TreeNode): void {
    this.confirm({
      header: this.translate('CATEGORIES.DELETE_HEADER'),
      message: this.translate('CATEGORIES.DELETE_MESSAGE', category),
      accept: () => {
        this.categoryService.deleteCategory(category.data.id).subscribe({
          next: () => {
            this.showSuccessMessage(this.translate('CATEGORIES.DELETE_SUCCESSFULLY', category));
            this.loadCategories();
          },
          error: () => {
            this.showErrorMessage(this.translate('CATEGORIES.DELETE_FAILED', category));
          },
        });
      },
    });
  }

  showMandanten(category: TreeNode): void {
    this.categoryService.getMandantenByCategory(category.data.id).subscribe((mandanten) => {
      this.mandanten = mandanten;
      this.displayDialog = true;
      this.markForCheck();
    });
  }

  hasSubCategorySubCategories(category: CategoryHierarchy): boolean {
    return category.data.tacsId.startsWith('9.') && category.data.tacsId.split('.').length === 3;
  }

  protected onLanguageChange(): void {
    this.setColumns();
    this.setMandantenColumns();
    this.createCategoryMenuButton();
  }

  private setColumns(): void {
    this.columns = [
      {
        field: 'tacsId',
        headerKey: 'GLOBAL.ID',
        width: '180px',
      },
      {
        field: 'name.' + this.getLanguageCaseSensitive(),
        headerKey: 'GLOBAL.NAME',
        minWidth: '300px',
        route: '/categories/${id}',
      },
      {
        field: 'sortNumber',
        headerKey: 'GLOBAL.SORT_NUMBER',
        width: '120px',
      },
      {
        field: 'validFrom',
        headerKey: 'GLOBAL.GUELTIG_AB',
        width: '130px',
        filterType: 'date',
      },
      {
        field: 'validTo',
        headerKey: 'GLOBAL.GUELTIG_BIS',
        width: '130px',
        filterType: 'date',
      },
    ];

    this.markets.forEach((market) => {
      this.columns.push({
        field: 'market.' + market.id,
        header: market.names[this.getLanguageCaseSensitive()],
        minWidth: '150px',
        filterType: 'boolean',
      });
    });
  }

  private setMandantenColumns(): void {
    this.mandantenColumns = [
      {
        field: 'mandantId',
        headerKey: 'GLOBAL.ID',
        width: '120px',
        route: '/mandants/${id}',
      },
      {
        field: 'acronym',
        headerKey: 'GLOBAL.ACRONYM',
        width: '150px',
      },
      {
        field: 'bezeichnung',
        headerKey: 'GLOBAL.NAME',
        minWidth: '250px',
      },
      {
        field: 'gueltigAb',
        headerKey: 'GLOBAL.GUELTIG_AB',
        width: '130px',
        filterType: 'date',
      },
      {
        field: 'gueltigBis',
        headerKey: 'GLOBAL.GUELTIG_BIS',
        width: '130px',
        filterType: 'date',
      },
    ];
  }
}
