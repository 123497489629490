import { Observable, Subject } from 'rxjs';
import { InjectionToken } from '@angular/core';
import { TreeNode } from 'primeng/api';
import { Column } from '@shared/table/column';
import { AnyValue } from '@shared/table/table.types';
import { TreeTableLazyLoadEvent } from 'primeng/treetable';

export const TABLE_SERVICE = new InjectionToken<Storage>('Table Service');

export abstract class TableService<E> {
  private loadDataSubject = new Subject<AnyValue>();

  getLoadDataObservable(): Observable<AnyValue> {
    return this.loadDataSubject.asObservable();
  }

  reload(): void {
    this.loadDataSubject.next(null);
  }

  getNextId(tree: TreeNode[], property: string): number {
    const collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' });
    const ids = tree.map((t) => t.data[property]).sort(collator.compare);
    if (ids.length > 0) {
      return parseInt(ids[ids.length - 1], 10) + 1;
    }
    return 1;
  }

  isBatchUpdateSupported(): boolean {
    return false;
  }

  abstract getData(
    focusDateOrYear?: Date | number,
    treeTableLazyLoadEvent?: TreeTableLazyLoadEvent
  ): Observable<{ entities: E[]; total?: number }>;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  create(e: E): Observable<E> {
    throw new Error('not implemented');
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  update(e: E): Observable<E> {
    throw new Error('not implemented');
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  remove(e: E): Observable<AnyValue> {
    throw new Error('not implemented');
  }

  abstract createEntry(value: E[], parent?: E): E;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  isEditable(e: E, column: Column): boolean {
    return true;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  batchUpdate(value: E[], focusDateOrYear?: Date | number): Observable<E[]> {
    throw new Error('not implemented');
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onDataChange(data: AnyValue, oldData: AnyValue, field: string, row: E, value: E[]): void {
    // do nothing
  }
}

export interface DataChange {
  field: string;
  row: AnyValue;
  data: AnyValue;
  value: AnyValue;
}
