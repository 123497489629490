import { ChangeDetectorRef, Directive, HostBinding, Input, OnChanges, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Directive()
export abstract class AbstractFormElement<T> implements OnInit, OnChanges {
  required = false;
  @HostBinding('class.sm:col-12') sm12 = true;
  @Input()
  twoColumns = false;
  @Input()
  id!: string;
  @Input()
  label?: string;
  @Input()
  form!: FormGroup;
  formControl!: FormControl<T>;

  constructor(protected changeDetectorRef: ChangeDetectorRef) {}

  @HostBinding('class.lg:col-6')
  get lg6(): boolean {
    return this.twoColumns;
  }

  ngOnInit(): void {
    this.formControl = this.form.get(this.id) as FormControl<T>;
    const validator = this.formControl.validator;
    if (validator) {
      const result = validator(new FormControl());
      this.required = result && result.required && this.formControl.enabled;
    }
  }

  ngOnChanges(): void {
    this.formControl = this.form.get(this.id) as FormControl;
  }
}
