<app-table
  [actionColumnWidth]="editable ? 300 : 200"
  [columns]="columns"
  [editable]="editable"
  [loading]="loading"
  [value]="value">
  <ng-template
    appActionButton
    icon="fal fa-search"
    labelKey="GLOBAL.DETAILS"
    [displayIf]="'id'"
    (onClick)="showDetail($event.row)"></ng-template>
</app-table>
