<p-card
  [header]="
    anwenderbetrieb?.id
      ? ('ANWENDERBETRIEBE.EDIT_ANWENDERBETRIEB' | translate: anwenderbetrieb)
      : ('ANWENDERBETRIEBE.CREATE_NEW' | translate)
  ">
  <form (ngSubmit)="onSubmit()" [formGroup]="form" novalidate>
    <div class="grid">
      <app-form-number-input
        [form]="form"
        [twoColumns]="false"
        id="betriebId"
        label="GLOBAL.ID"></app-form-number-input>
      <app-form-text-input [form]="form" [twoColumns]="true" id="bezeichnung" label="GLOBAL.NAME"></app-form-text-input>
      <app-form-switch
        [form]="form"
        [twoColumns]="true"
        id="synchronizeMandantData"
        label="ANWENDERBETRIEBE.SYNCHRONIZED_MANDANTS"></app-form-switch>
      <app-form-date-input
        [form]="form"
        [twoColumns]="true"
        id="gueltigAb"
        label="GLOBAL.GUELTIG_AB"></app-form-date-input>
      <app-form-date-input
        [form]="form"
        [twoColumns]="true"
        id="gueltigBis"
        label="GLOBAL.GUELTIG_BIS"></app-form-date-input>

      <app-form-time-period
        [form]="form"
        [twoColumns]="false"
        id="lizenzierte"
        inputType="number"
        label="ANWENDERBETRIEBE.LICENSE_COUNT"></app-form-time-period>

      <app-form-multiselect
        [form]="form"
        [options]="catalogOptions"
        [twoColumns]="false"
        id="catalogMappingIds"
        label="ANWENDERBETRIEBE.CATALOG_MAPPINGS"></app-form-multiselect>

      <app-form-switch
        [form]="form"
        [twoColumns]="true"
        id="varHealthcareEnabled"
        label="ANWENDERBETRIEBE.VAR_HEALTHCARE_ENABLED"></app-form-switch>

      <div class="col-12">
        <app-form-element id="logo" label="ANWENDERBETRIEBE.LOGO">
          <p-fileUpload
            #fileUpload
            (uploadHandler)="upload($event)"
            [auto]="true"
            [chooseLabel]="'ANWENDERBETRIEBE.CHOOSE_LOGO' | translate"
            [customUpload]="true"
            mode="basic"></p-fileUpload>
          @if (logo) {
            <img class="logo" src="data:image/jpg;base64,{{ logo }}" />
          }
        </app-form-element>
      </div>

      <app-form-text-input
        [form]="form"
        id="tacsRecordingUrl"
        label="ANWENDERBETRIEBE.TACS_RECORDING_URL"></app-form-text-input>

      <app-form-button-bar [form]="form" [saveDisabled]="submitting"></app-form-button-bar>
    </div>
  </form>
</p-card>
