<p-card [header]="(user.id ? 'USERS.EDIT_USER' : 'USERS.CREATE_USER') | translate">
  <form (ngSubmit)="onSubmit()" [formGroup]="form" class="grid" novalidate>
    @if (anwenderbetriebOptions.length > 1) {
      <app-form-dropdown
        [filter]="true"
        [form]="form"
        [options]="anwenderbetriebOptions"
        id="anwenderbetriebId"
        label="USERS.ANWENDERBETRIEB"></app-form-dropdown>
    }

    <app-form-multiselect
      [form]="form"
      [options]="mandantOptions"
      id="mandantIds"
      label="USERS.MANDANT"></app-form-multiselect>

    @if (isRodixAdmin()) {
      <app-form-dropdown
        [form]="form"
        [options]="roleOptions"
        id="role"
        label="USERS.ROLE"
        placeholder="GLOBAL.CHOOSE"></app-form-dropdown>
    }

    <app-form-text-input [form]="form" id="username" label="USERS.USERNAME"></app-form-text-input>

    <app-form-text-input [form]="form" id="email" label="GLOBAL.EMAIL"></app-form-text-input>

    <app-form-text-input [form]="form" id="firstName" label="GLOBAL.FIRST_NAME"></app-form-text-input>

    <app-form-text-input [form]="form" id="lastName" label="GLOBAL.LAST_NAME"></app-form-text-input>

    @if (isRodixAdmin()) {
      <app-form-switch [form]="form" id="betaTester" label="USERS.BETA_TESTER"></app-form-switch>
    }

    <app-form-button-bar [form]="form" [saveDisabled]="submitting"></app-form-button-bar>
  </form>
</p-card>
