import { ChangeDetectionStrategy, Component, Injector, OnInit, ViewChild } from '@angular/core';
import { AbstractContentComponent } from '../abstract-content.component';
import { Context } from '@service/context.service';
import { TABLE_SERVICE } from '@shared/table/table-service';
import { TableComponent } from '@shared/table/table.component';
import { Column } from '@shared/table/column';
import { Holiday } from '@entity/holiday';
import { HolidayTableService } from './holiday-table.service';
import { MandantService } from '@service/mandant.service';
import { ToolbarButtonDirective } from '@shared/table/toolbar-button.directive';

@Component({
  selector: 'app-holidays',
  templateUrl: './holidays.component.html',
  styleUrls: ['./holidays.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: TABLE_SERVICE,
      useClass: HolidayTableService,
    },
  ],
  standalone: true,
  imports: [TableComponent, ToolbarButtonDirective],
})
export class HolidaysComponent extends AbstractContentComponent implements OnInit {
  holidays: Holiday[] = [];
  columns: Column[] = [];
  focusYearRangeStart = new Date().getFullYear() - 2;
  focusYear = new Date().getFullYear();
  private dateColumn?: Column;

  @ViewChild(TableComponent)
  private table?: TableComponent;

  constructor(
    injector: Injector,
    private mandantService: MandantService
  ) {
    super(injector, Context.Mandant, 'MENU.HOLIDAYS');
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.initColumns();
  }

  initColumns(): void {
    this.dateColumn = {
      field: 'date',
      headerKey: 'GLOBAL.DATE',
      filterType: 'date',
      width: '200px',
      editable: true,
      minDate: new Date(this.focusYear, 0, 1),
      maxDate: new Date(this.focusYear, 11, 31),
    };
    this.columns = [
      {
        field: 'name',
        headerKey: 'GLOBAL.NAME',
        minWidth: '200px',
        editable: true,
      },
      this.dateColumn,
      {
        field: 'isHalf',
        headerKey: 'HOLIDAYS.HALF_DAY',
        width: '200px',
        filterType: 'boolean',
        editable: true,
        fastEdit: true,
      },
      {
        field: 'isRepeating',
        headerKey: 'HOLIDAYS.REPEATING',
        width: '200px',
        filterType: 'boolean',
        editable: true,
        fastEdit: true,
      },
      {
        field: 'isPublicHoliday',
        headerKey: 'HOLIDAYS.PUBLIC_HOLIDAY',
        width: '200px',
        filterType: 'boolean',
        editable: false,
      },
    ];
  }

  focusYearChanged(): void {
    setTimeout(() => {
      if (this.dateColumn) {
        this.dateColumn.minDate = new Date(this.focusYear, 0, 1);
        this.dateColumn.maxDate = new Date(this.focusYear, 11, 31);
      }
      this.table?.loadData();
    }, 250);
  }

  addPublicHolidays(): void {
    this.mandantService.addPublicHolidays(this.getMandantId(), this.focusYear).subscribe(() => this.table?.loadData());
  }

  addLastYearsHolidays(): void {
    this.mandantService
      .addLastYearsHolidays(this.getMandantId(), this.focusYear)
      .subscribe(() => this.table?.loadData());
  }

  protected onMandantIdChange(): void {
    this.table?.loadData();
  }
}
