import { ChangeDetectionStrategy, Component, Injector } from '@angular/core';
import { AbstractContentComponent } from '../abstract-content.component';
import { Context } from '@service/context.service';
import { TranslateModule } from '@ngx-translate/core';

import { CardModule } from 'primeng/card';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CardModule, TranslateModule],
})
export class DashboardComponent extends AbstractContentComponent {
  constructor(injector: Injector) {
    super(injector, Context.Global);
    this.setBreadcrumbs([{ label: 'MENU.DASHBOARD' }]);
  }

  hasOnlyUserRole(): boolean {
    return this.authorizationService.hasOnlyUserRole();
  }
}
