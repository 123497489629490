<p-card [header]="'MENU.REPORT_STATISTICS' | translate">
  <div class="filter">
    <p-multiSelect
      [options]="reportNames"
      [(ngModel)]="selectedReportNames"
      [placeholder]="'REPORT_STATISTICS.SELECT_REPORT' | translate"
      [style]="{ width: '100%' }"
      (onChange)="setOptions()"
      [filter]="true"></p-multiSelect>
    <p-multiSelect
      [options]="mandantNames"
      [(ngModel)]="selectedMandantNames"
      [placeholder]="'REPORT_STATISTICS.SELECT_MANDANT' | translate"
      [style]="{ width: '100%' }"
      (onChange)="setOptions()"
      [filter]="true"></p-multiSelect>
  </div>
  <div style="width: 100%; height: calc(100vh - 300px); min-height: 100px">
    <app-echart [options]="options"></app-echart>
  </div>
</p-card>
