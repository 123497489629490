import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormTimePeriodComponent, TimePeriodItem } from '@shared/form-time-period/form-time-period.component';
import { maxDate, minDate } from '../../util';
import { finalize, Observable } from 'rxjs';
import { Assignment, AssignmentElement, AssignmentRootType, AssignmentType } from '@entity/assignment';
import { BeneficiaryService } from '@service/beneficiary.service';
import { StatisticsCodeService } from '@service/statistics-code.service';
import { VariableService } from '@service/variable.service';
import { FormButtonBarComponent } from '@shared/form-button-bar/form-button-bar.component';
import { DatePipe } from '@angular/common';
import { AnyValue } from '@shared/table/table.types';

@Component({
  selector: 'app-assignment-detail',
  templateUrl: './assignment-detail.component.html',
  styleUrls: ['./assignment-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, FormTimePeriodComponent, FormButtonBarComponent, DatePipe],
})
export class AssignmentDetailComponent implements OnInit {
  form?: FormGroup<{ assignments: FormControl<TimePeriodItem[]> }>;
  columnValidFrom: Date | null = null;
  columnValidTo: Date | null = null;
  rowValidFrom: Date | null = null;
  rowValidTo: Date | null = null;
  minDate?: Date;
  maxDate?: Date;
  rowName = '';
  columnName = '';
  editable = false;
  submitting = false;
  private type!: AssignmentRootType;

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private variableService: VariableService,
    private beneficiaryService: BeneficiaryService,
    private statisticsCodeService: StatisticsCodeService,
    private changeDetectorRef: ChangeDetectorRef,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    const data = this.config.data;
    this.columnValidFrom = data.columnValidFrom;
    this.columnValidTo = data.columnValidTo;
    this.rowValidFrom = data.rowValidFrom;
    this.rowValidTo = data.rowValidTo;
    this.minDate = maxDate(this.columnValidFrom, this.rowValidFrom) ?? undefined;
    this.maxDate = minDate(this.columnValidTo, this.rowValidTo) ?? undefined;
    this.rowName = data.rowName;
    this.columnName = data.columnName;
    this.editable = data.editable ?? false;
    this.type = data.type;
    this.form = this.fb.group({
      assignments: this.fb.control({
        disabled: !this.editable,
        value:
          data.elements?.map(
            (a: Assignment) =>
              ({
                id: a.id,
                from: a.from,
                to: a.to,
                deletable: true,
              }) as TimePeriodItem
          ) ?? [],
      }),
    });
  }

  onSubmit(): void {
    this.submitting = true;
    const data = this.config.data;
    const assignments: { [organisationId: number]: Assignment[] } = {};
    assignments[data.organisationId] =
      this.form?.getRawValue().assignments.map((a: TimePeriodItem) => ({
        id: a.id,
        from: a.from,
        to: a.to ?? undefined,
      })) ?? [];

    const element: AnyValue = {
      id: data.variableId,
      name: '',
    };
    element[data.assignmentType] = assignments;
    this.update(element)
      .pipe(
        finalize(() => {
          this.changeDetectorRef.markForCheck();
          this.submitting = false;
        })
      )
      .subscribe((result) => this.ref.close(result[0][data.assignmentType as AssignmentType]?.[data.organisationId]));
  }

  cancel(): void {
    this.ref.close();
  }

  private update(element: AssignmentElement): Observable<AssignmentElement[]> {
    let updateOperation: Observable<AssignmentElement[]>;
    if (this.type === AssignmentRootType.VARIABLE) {
      updateOperation = this.variableService.updateAssignments(this.config.data.mandantId, [element]);
    } else if (this.type === AssignmentRootType.STATISTICS_CODE) {
      updateOperation = this.statisticsCodeService.updateAssignments(this.config.data.mandantId, [element]);
    } else if (this.type === AssignmentRootType.BENEFICIARY) {
      updateOperation = this.beneficiaryService.updateAssignments(this.config.data.mandantId, [element]);
    } else {
      throw new Error('Unknown operation in assignment detail');
    }

    return updateOperation;
  }
}
