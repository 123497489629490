import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { JobService } from '../job.service';
import { formatDate } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MessageService, SharedModule, TreeNode } from 'primeng/api';
import { TreeTableModule } from 'primeng/treetable';
import { DialogModule } from 'primeng/dialog';
import { InputTextModule } from 'primeng/inputtext';
import { TableModule } from 'primeng/table';
import { AnyValue } from '@shared/table/table.types';

@Component({
  selector: 'app-jobs-entry-table',
  templateUrl: './jobs-entry-table.component.html',
  styleUrls: ['./jobs-entry-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TableModule, SharedModule, InputTextModule, DialogModule, TreeTableModule, TranslateModule],
})
export class JobsEntryTableComponent {
  static TRANSLATIONS: { [key: string]: string } = {
    employeeId: 'EMPLOYEES.EMPLOYEE_ID',
    firstName: 'GLOBAL.FIRST_NAME',
    lastName: 'GLOBAL.LAST_NAME',
    dateOfBirth: 'GLOBAL.DATE_OF_BIRTH',
    employments: 'EMPLOYEES.EMPLOYMENT',
    employmentId: 'EMPLOYEES.EMPLOYMENT_ID',
    entryDate: 'EMPLOYEES.ENTRY_DATE',
    validFrom: 'GLOBAL.GUELTIG_AB',
    validTo: 'GLOBAL.GUELTIG_BIS',
    organisationalUnitId: 'EMPLOYEES.ORGANISATIONAL_UNIT_ID',
    salaryBase: 'EMPLOYEES.SALARY_BASE',
    costCenter: 'EMPLOYEES.COST_CENTER',
    jobTitle: 'EMPLOYEES.JOB_TITLE',
    jobTitleTacsId: 'EMPLOYEES.JOB_TITLE',
    workloadPercentage: 'EMPLOYEES.WORKLOAD_PERCENTAGE',
    workloadHours: 'EMPLOYEES.WORKLOAD_HOURS',
    customerId: 'CUSTOMERS.CUSTOMER_ID',
    gender: 'GLOBAL.GENDER',
    customerCases: 'CUSTOMERS.CUSTOMER_CASE',
    caseId: 'CUSTOMERS.CASE_ID',
    leaveDate: 'CUSTOMERS.LEAVE_DATE',
    customerCaseMutations: 'CUSTOMERS.CUSTOMER_CASE_MUTATION',
    discipline: 'CUSTOMERS.DISCIPLINE',
    insuranceCategory: 'CUSTOMERS.INSURANCE_CATEGORY',
    payScaleLevel: 'CUSTOMERS.PAY_SCALE_LEVEL',
    typeOfStay: 'CUSTOMERS.TYPE_OF_STAY',
  };
  @Input() jobId?: number;
  @Input() columns: unknown[] = [];
  @Input() entries: unknown[] = [];
  detail: TreeNode[] = [];
  showDetail = false;

  constructor(
    private jobService: JobService,
    private changeDetectorRef: ChangeDetectorRef,
    private translateService: TranslateService,
    private messageService: MessageService
  ) {}

  private static formatDate(value?: string): string {
    return value ? formatDate(value, 'dd.MM.yyyy', 'en-US') : '-';
  }

  showCustomer(rowData: unknown, caseId: string): void {
    if (!this.jobId) {
      return;
    }

    this.jobService.getCustomer(this.jobId, caseId).subscribe({
      next: (customerCase) => {
        if (customerCase) {
          this.detail = [];
          this.fill(customerCase, this.detail);
          this.showDetail = true;
          this.changeDetectorRef.markForCheck();
        } else {
          this.messageService.add({
            severity: 'warn',
            summary: this.translateService.instant('JOBS.LOG_ERROR.KundenFallNotFound'),
          });
        }
      },
      error: () =>
        this.messageService.add({
          severity: 'warn',
          summary: this.translateService.instant('JOBS.LOG_ERROR.KundenFallNotFound'),
        }),
    });
  }

  showEmployee(rowData: unknown, employeeId: string): void {
    if (!this.jobId) {
      return;
    }

    this.jobService.getEmployee(this.jobId, employeeId).subscribe({
      next: (employee) => {
        if (employee) {
          this.detail = [];
          this.fill(employee, this.detail);
          this.showDetail = true;
          this.changeDetectorRef.markForCheck();
        } else {
          this.messageService.add({
            severity: 'warn',
            summary: this.translateService.instant('JOBS.LOG_ERROR.AnstellungNotFound'),
          });
        }
      },
      error: () =>
        this.messageService.add({
          severity: 'warn',
          summary: this.translateService.instant('JOBS.LOG_ERROR.AnstellungNotFound'),
        }),
    });
  }

  isArray(object: unknown): boolean {
    return Array.isArray(object);
  }

  private fill(object: AnyValue, parent: unknown[]): void {
    const keys = Object.keys(object);
    keys
      .filter((k) => k !== 'id')
      .forEach((key) => {
        const value = object[key];
        if (value) {
          const name = this.getTranslation(key);
          const node = {
            data: { name, value: '' },
            children: [],
            expanded: true,
          };
          if (Array.isArray(value) && value.length > 0) {
            if (value.length > 1) {
              value.forEach((v) => {
                this.fill({ ' ': v }, node.children);
              });
            } else {
              this.fill(value[0], node.children);
            }
            parent.push(node);
          } else if (typeof value === 'object' && Object.keys(value).length > 0) {
            this.fill(value, node.children);
            parent.push(node);
          } else if ((value + '').length > 0) {
            if (value instanceof Date || /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/.test(value)) {
              node.data.value = JobsEntryTableComponent.formatDate(value);
            } else {
              node.data.value = value;
            }
            parent.push(node);
          }
        }
      });
  }

  private getTranslation(key: string): string {
    return this.translateService.instant(JobsEntryTableComponent.TRANSLATIONS[key] ?? key);
  }
}
