<app-form-element [control]="formControl" [id]="id" [label]="label" [required]="required" [twoColumns]="twoColumns">
  <p-dropdown
    [appendTo]="'body'"
    [filter]="filter"
    [filterBy]="'label'"
    [formControl]="formControl"
    [id]="id"
    [options]="options"
    [placeholder]="placeholder ?? '' | translate"
    [style]="{ width: '100%' }"
    appScrollIntoView></p-dropdown>
</app-form-element>
