import { StatisticsCode } from '@entity/statistics-code';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AbstractCrudMandantService } from '@service/abstract-crud-mandant.service';
import { Observable } from 'rxjs';
import { AssignmentElement, OrganizationCategoryGroup } from '@entity/assignment';

@Injectable({
  providedIn: 'root',
})
export class StatisticsCodeService extends AbstractCrudMandantService<StatisticsCode> {
  constructor(httpClient: HttpClient) {
    super('statistics-codes', httpClient);
  }

  getOrganisationalUnitTree(mandantId: number, focusDate: Date): Observable<OrganizationCategoryGroup[]> {
    const params = new HttpParams().append('focusDate', focusDate.toJSON());
    return this.httpClient.get<OrganizationCategoryGroup[]>(
      `${this.baseUrl}/${mandantId}/statistics-codes/organisational-unit-tree`,
      { params }
    );
  }

  getAssignments(mandantId: number, focusDate: Date, organisationIds: number[]): Observable<AssignmentElement[]> {
    const params = new HttpParams({
      fromObject: {
        focusDate: focusDate.toJSON(),
        ou: organisationIds.map((id) => `${id}`),
      },
    });
    return this.httpClient.get<AssignmentElement[]>(`${this.baseUrl}/${mandantId}/statistics-codes/assignments`, {
      params,
    });
  }

  updateAssignments(mandantId: number, assignments: AssignmentElement[]): Observable<AssignmentElement[]> {
    return this.httpClient.put<AssignmentElement[]>(
      `${this.baseUrl}/${mandantId}/statistics-codes/assignments`,
      assignments
    );
  }
}
