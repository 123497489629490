import { ChangeDetectionStrategy, Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { ServiceAccountService } from '@service/service-account.service';
import { ServiceAccount } from '@entity/service-account';
import { ClientSecret } from '@entity/client-secret';
import { configTemplate } from './config-template';
import { Context } from '@service/context.service';
import { AbstractContentComponent } from '../abstract-content.component';
import { Observable } from 'rxjs';
import { OverlayPanel, OverlayPanelModule } from 'primeng/overlaypanel';
import { Column } from '@shared/table/column';
import { downloadBlob } from '../download';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from 'primeng/api';
import { DialogModule } from 'primeng/dialog';
import { CopyClipboardDirective } from '../copy-clipboard.directive';
import { ButtonModule } from 'primeng/button';

import { ActionButtonDirective } from '@shared/table/action-button.directive';
import { ToolbarButtonDirective } from '@shared/table/toolbar-button.directive';
import { TableComponent } from '@shared/table/table.component';

@Component({
  selector: 'app-service-accounts',
  templateUrl: './service-accounts.component.html',
  styleUrls: ['./service-accounts.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TableComponent,
    ToolbarButtonDirective,
    ActionButtonDirective,
    OverlayPanelModule,
    ButtonModule,
    CopyClipboardDirective,
    DialogModule,
    SharedModule,
    TranslateModule,
  ],
})
export class ServiceAccountsComponent extends AbstractContentComponent implements OnInit, OnDestroy {
  serviceAccounts: ServiceAccount[] = [];
  columns: Column[] = [];
  clientSecret?: ClientSecret;
  options: unknown[] = [];
  showConfigurationModal = false;
  configuration = '';

  constructor(
    private serviceAccountService: ServiceAccountService,
    injector: Injector
  ) {
    super(injector, Context.Global, 'MENU.SERVICE_ACCOUNTS');
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.setColumns();
    this.setOptions();
    this.loadData();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  createServiceAccount(): void {
    this.navigate(['/service-accounts/new']);
  }

  deleteServiceAccount(serviceAccount: ServiceAccount): void {
    this.showConfirmDialog(
      'SERVICE_ACCOUNTS.DELETE_HEADER',
      'SERVICE_ACCOUNTS.DELETE_MESSAGE',
      'SERVICE_ACCOUNTS.DELETE_SUCCESSFULLY',
      'SERVICE_ACCOUNTS.DELETE_FAILED',
      serviceAccount,
      () => this.serviceAccountService.deleteServiceAccount(serviceAccount)
    );
  }

  resetClientSecret(serviceAccount: ServiceAccount): void {
    this.showConfirmDialog(
      'SERVICE_ACCOUNTS.RESET_CLIENT_SECRET',
      'SERVICE_ACCOUNTS.RESET_CLIENT_SECRET_MESSAGE',
      'GLOBAL.SUCCESSFULLY_SAVED',
      'GLOBAL.NOT_SUCCESSFULLY_SAVED',
      serviceAccount,
      () => this.serviceAccountService.resetClientSecret(serviceAccount)
    );
  }

  deactivateServiceAccount(serviceAccount: ServiceAccount): void {
    this.showConfirmDialog(
      'SERVICE_ACCOUNTS.DEACTIVATE_HEADER',
      'SERVICE_ACCOUNTS.DEACTIVATE_MESSAGE',
      'SERVICE_ACCOUNTS.DEACTIVATE_SUCCESSFULLY',
      'SERVICE_ACCOUNTS.DEACTIVATE_FAILED',
      serviceAccount,
      () => this.serviceAccountService.deactivateServiceAccount(serviceAccount)
    );
  }

  activateServiceAccount(serviceAccount: ServiceAccount): void {
    this.showConfirmDialog(
      'SERVICE_ACCOUNTS.ACTIVATE_HEADER',
      'SERVICE_ACCOUNTS.ACTIVATE_MESSAGE',
      'SERVICE_ACCOUNTS.ACTIVATE_SUCCESSFULLY',
      'SERVICE_ACCOUNTS.ACTIVATE_FAILED',
      serviceAccount,
      () => this.serviceAccountService.activateServiceAccount(serviceAccount)
    );
  }

  showClientSecret(serviceAccount: ServiceAccount, event: unknown, overlayPanel: OverlayPanel): void {
    this.serviceAccountService.getServiceAccountClientSecret(serviceAccount.id).subscribe((clientSecret) => {
      this.clientSecret = clientSecret;
      overlayPanel.toggle(event);
      this.markForCheck();
    });
  }

  showConfiguration(serviceAccount: ServiceAccount): void {
    this.serviceAccountService.getServiceAccountClientSecret(serviceAccount.id).subscribe((clientSecret) => {
      this.configuration = configTemplate
        .replace('{{clientId}}', serviceAccount.clientId)
        .replace('{{clientSecret}}', clientSecret.value);
      this.showConfigurationModal = true;
      this.markForCheck();
    });
  }

  downloadConfiguration(): void {
    downloadBlob(new Blob([this.configuration], { type: 'text/plain' }), 'config.yml');
  }

  protected onLanguageChange(): void {
    this.setColumns();
    this.setOptions();
  }

  private loadData(): void {
    this.startLoading();
    this.serviceAccountService.getServiceAccounts().subscribe((data) => {
      this.serviceAccounts = data;
      this.markForCheck();
      this.loadingDone();
    });
  }

  private setColumns(): void {
    this.columns = [
      { field: 'clientId', headerKey: 'SERVICE_ACCOUNTS.CLIENT_ID', width: '150px' },
      { field: 'name', headerKey: 'SERVICE_ACCOUNTS.NAME', minWidth: '200px' },
      { field: 'enabled', headerKey: 'SERVICE_ACCOUNTS.ACTIVATED', width: '100px', filterType: 'boolean' },
      {
        field: 'hospitalInformationSystem',
        headerKey: 'SERVICE_ACCOUNTS.HOSPITAL_INFORMATION_SYSTEM',
        width: '100px',
        filterType: 'boolean',
      },
    ];
  }

  private setOptions(): void {
    this.options = [
      {
        label: this.translate('SERVICE_ACCOUNTS.TRUE_OR_FALSE'),
        value: null,
      },
      {
        label: this.translate('SERVICE_ACCOUNTS.TRUE'),
        value: true,
      },
      {
        label: this.translate('SERVICE_ACCOUNTS.FALSE'),
        value: false,
      },
    ];
  }

  private showConfirmDialog(
    headerKey: string,
    messageKey: string,
    successKey: string,
    errorKey: string,
    serviceAccount: ServiceAccount,
    acceptAction: () => Observable<unknown>
  ): void {
    this.confirm({
      header: this.translate(headerKey),
      message: this.translate(messageKey, serviceAccount),
      accept: () => {
        acceptAction().subscribe({
          next: () => {
            this.showSuccessMessage(this.translate(successKey, serviceAccount));
            this.loadData();
          },
          error: () => {
            this.showErrorMessage(this.translate(errorKey, serviceAccount));
          },
        });
      },
    });
  }
}
