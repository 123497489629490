<app-table filename="MENU.MARKETS" [columns]="columns" [loading]="loading" [value]="markets">
  <ng-template
    appToolbarButton
    icon="fal fa-plus"
    labelKey="MARKETS.CREATE_MARKET"
    (onClick)="createNewMarket()"></ng-template>
  <ng-template
    appActionButton
    displayIf="deletable"
    icon="fal fa-trash"
    labelKey="MARKETS.DELETE_HEADER"
    type="danger"
    (onClick)="deleteMarket($event.row)"></ng-template>
</app-table>
