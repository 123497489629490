import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AbstractFormElement } from '@shared/abstract-form-element';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputMaskModule } from 'primeng/inputmask';

import { FormElementComponent } from '../form-element/form-element.component';

@Component({
  selector: 'app-form-prefixed-input-mask',
  templateUrl: './form-prefixed-input-mask.component.html',
  styleUrls: ['./form-prefixed-input-mask.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [FormElementComponent, InputMaskModule, FormsModule, ReactiveFormsModule],
})
export class FormPrefixedInputMaskComponent extends AbstractFormElement<string> {
  @Input()
  mask = '';

  @Input()
  prefix = '';

  @Input()
  backgroundColor?: string = undefined;

  @Input()
  color?: string = undefined;
}
