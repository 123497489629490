import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { AbstractFormElement } from '@shared/abstract-form-element';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputNumberModule } from 'primeng/inputnumber';
import { FormElementComponent } from '../form-element/form-element.component';
import { AnyValue } from '@shared/table/table.types';

@Component({
  selector: 'app-form-number-input',
  templateUrl: './form-number-input.component.html',
  styleUrls: ['./form-number-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [FormElementComponent, InputNumberModule, FormsModule, ReactiveFormsModule],
})
export class FormNumberInputComponent extends AbstractFormElement<number> {
  @Input()
  suffix = '';

  @Input()
  maxFractionDigits = 0;

  @Input()
  minFractionDigits = 0;

  @Input()
  min = -1 * Number.MAX_VALUE;

  @Input()
  max = Number.MAX_VALUE;

  @Output()
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  onFocus = new EventEmitter();

  onInput($event: AnyValue): void {
    this.formControl.setValue($event.value, { emitViewToModelChange: true });
  }
}
