import { APP_ID, APP_INITIALIZER, enableProdMode, ErrorHandler, importProvidersFrom } from '@angular/core';

import { environment } from './environments/environment';
import { AppComponent } from './app/app.component';
import { ListboxModule } from 'primeng/listbox';
import { MessageModule } from 'primeng/message';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { RippleModule } from 'primeng/ripple';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { TreeModule } from 'primeng/tree';
import { CardModule } from 'primeng/card';
import { KeyFilterModule } from 'primeng/keyfilter';
import { MenuModule } from 'primeng/menu';
import { SplitButtonModule } from 'primeng/splitbutton';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputMaskModule } from 'primeng/inputmask';
import { TooltipModule } from 'primeng/tooltip';
import { FocusTrapModule } from 'primeng/focustrap';
import { InplaceModule } from 'primeng/inplace';
import { InputSwitchModule } from 'primeng/inputswitch';
import { CheckboxModule } from 'primeng/checkbox';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { DialogModule } from 'primeng/dialog';
import { TreeTableModule } from 'primeng/treetable';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { FileUploadModule } from 'primeng/fileupload';
import { TriStateCheckboxModule } from 'primeng/tristatecheckbox';
import { CalendarModule } from 'primeng/calendar';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ProgressBarModule } from 'primeng/progressbar';
import { ToastModule } from 'primeng/toast';
import { MultiSelectModule } from 'primeng/multiselect';
import { PanelModule } from 'primeng/panel';
import { InputTextModule } from 'primeng/inputtext';
import { DropdownModule } from 'primeng/dropdown';
import { ButtonModule } from 'primeng/button';
import { ToolbarModule } from 'primeng/toolbar';
import { TableModule } from 'primeng/table';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { provideAnimations } from '@angular/platform-browser/animations';
import { AppRoutes } from './app/app.routes';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { bootstrapApplication } from '@angular/platform-browser';
import { AngularDateHttpInterceptor } from './app/http.interceptor';
import { Router } from '@angular/router';
import { AppConfig } from '@entity/app-config';
import { AnwenderbetriebService } from '@service/anwenderbetrieb.service';
import { ContextService } from '@service/context.service';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { OAuthModule, OAuthResourceServerErrorHandler, OAuthService } from 'angular-oauth2-oidc';
import { DialogService, DynamicDialogModule } from 'primeng/dynamicdialog';
import { ConfirmationService, MessageService } from 'primeng/api';
import { CanDeactivateGuard } from './app/can-deactivate.guard';
import * as Sentry from '@sentry/angular-ivy';
import { Config } from '@entity/config';
import { forkJoin } from 'rxjs';
import { Language } from '@entity/language';
import { CustomOAuthResourceServerErrorHandler } from './app/custom-o-auth-resource-server-error-handler.service';
import { LazyTranslateLoader } from './app/lazyTranslateLoader';

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(
      FormsModule,
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useClass: LazyTranslateLoader,
        },
      }),
      OAuthModule.forRoot({
        resourceServer: {
          sendAccessToken: true,
        },
      }),
      AppRoutes,
      ScrollPanelModule,
      BreadcrumbModule,
      TableModule,
      ToolbarModule,
      ButtonModule,
      DropdownModule,
      InputTextModule,
      PanelModule,
      MultiSelectModule,
      ReactiveFormsModule,
      ToastModule,
      ProgressBarModule,
      ConfirmDialogModule,
      CalendarModule,
      TriStateCheckboxModule,
      FileUploadModule,
      OverlayPanelModule,
      TreeTableModule,
      DialogModule,
      ProgressSpinnerModule,
      CheckboxModule,
      InputSwitchModule,
      InputSwitchModule,
      InplaceModule,
      FocusTrapModule,
      TooltipModule,
      InputMaskModule,
      InputNumberModule,
      SplitButtonModule,
      MenuModule,
      KeyFilterModule,
      InputSwitchModule,
      DynamicDialogModule,
      CardModule,
      TreeModule,
      TieredMenuModule,
      RippleModule,
      InputTextareaModule,
      MessageModule,
      ListboxModule
    ),
    CanDeactivateGuard,
    MessageService,
    ConfirmationService,
    DialogService,
    {
      provide: APP_INITIALIZER,
      useFactory: initApp,
      multi: true,
      deps: [
        OAuthService,
        HttpClient,
        TranslateService,
        AppConfig,
        ContextService,
        AnwenderbetriebService,
        Sentry.TraceService,
      ],
    },
    {
      provide: APP_ID,
      useValue: 'web-administration-universal',
    },
    { provide: OAuthResourceServerErrorHandler, useClass: CustomOAuthResourceServerErrorHandler },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: true,
        dialogOptions: {
          lang: 'de',
        },
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: AppConfig,
      useValue: new AppConfig(),
    },
    { provide: HTTP_INTERCEPTORS, useClass: AngularDateHttpInterceptor, multi: true, deps: [Router] },
    provideHttpClient(withInterceptorsFromDi()),
    provideAnimations(),
  ],
}).catch((err) => console.log(err));

export function initApp(
  oauthService: OAuthService,
  httpClient: HttpClient,
  translateService: TranslateService,
  appConfig: AppConfig,
  contextService: ContextService,
  anwenderbetriebService: AnwenderbetriebService
): () => Promise<boolean> {
  return () => {
    return new Promise((resolve, rejects) => {
      httpClient.get<Config>('api/1.0/config').subscribe((config) => {
        appConfig.version = config.version;
        oauthService.configure({
          ...config.oAuthConfig,
          redirectUri: window.location.href,
          silentRefreshRedirectUri: window.location.origin + '/silent-refresh.html',
          silentRefreshShowIFrame: false,
          showDebugInformation: false,
          sessionChecksEnabled: false,
          postLogoutRedirectUri: window.location.origin,
        });
        oauthService.setupAutomaticSilentRefresh();
        oauthService.loadDiscoveryDocumentAndLogin().then((res) => {
          if (res) {
            Sentry.init({
              tracePropagationTargets: ['localhost', 'https://dashboard.tacs2.ch'],
              dsn: config.dsn,
              integrations: [
                new Sentry.BrowserTracing({
                  routingInstrumentation: Sentry.routingInstrumentation,
                }),
              ],
              tracesSampleRate: 0.25,
            });
            const { preferred_username, email, locale } = oauthService.getIdentityClaims();
            Sentry.setUser({
              email,
              username: preferred_username,
            });
            // this language will be used as a fallback when a translation isn't found in the current language
            translateService.setDefaultLang(locale ?? 'de');
            translateService.addLangs(['de', 'fr']);
            const translateServiceObservable = translateService.use(translateService.defaultLang);

            if (!contextService.hasAnwenderbetriebId()) {
              forkJoin([anwenderbetriebService.getAllAnwenderbetriebe(true), translateServiceObservable]).subscribe(
                (data) => {
                  const [anwenderbetriebe] = data;
                  if (anwenderbetriebe && anwenderbetriebe.length > 0) {
                    contextService.setAnwenderbetriebId(anwenderbetriebe[0].id);
                    if (anwenderbetriebe[0].mandant?.length) {
                      contextService.setMandantId(anwenderbetriebe[0].mandant[0].id);
                      contextService.setLanguages(
                        anwenderbetriebe[0].mandant[0].mandantSprache
                          .map((m) => m.sprache.kuerzel.split('-')[0])
                          .map((l) => l.substring(0, 1).toUpperCase() + l.substring(1).toLowerCase()) as Language[]
                      );
                    } else {
                      contextService.setMandantId(undefined);
                      contextService.setLanguages(['De']);
                    }
                  }
                  resolve(true);
                }
              );
            } else {
              translateServiceObservable.subscribe(() => resolve(res));
            }
          } else {
            rejects(res);
          }
        });
      });
    });
  };
}
