import { TableService } from '@shared/table/table-service';
import { Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { ContextService } from '@service/context.service';
import { TreeNode } from 'primeng/api';
import { map } from 'rxjs/operators';
import {
  TimeBudget,
  TimeBudgetNode,
  TimeBudgetOrganizationCategoryGroup,
  TimeBudgetTree,
  TimeBudgetVariable,
} from '@entity/time-budget';
import { TimeBudgetService } from '@service/time-budget.service';
import { VariableService } from '@service/variable.service';
import { AnyValue } from '@shared/table/table.types';

export const OrganizationKey = 'organization';
export const OrganizationCategoryGroupKey = 'organizationCategoryGroup';
export const OrganizationCategoryKey = 'organizationCategory';
export const MandantKey = 'mandant';
export type TimeBudgetType = 'mandant' | 'organizationCategoryGroup' | 'organizationCategory' | 'organization';
export const CalculatedValueKey = 'CalculatedValue';
export const HoursKey = 'hours';
export const PercentageKey = 'percentage';
export type TimeBudgetUnit = 'hours' | 'percentage';

const PersonalTacsId = '1';

@Injectable()
export class TimeBudgetTableService extends TableService<TreeNode> {
  private dataLoadSubject = new Subject<DataLoad>();
  private internalValue?: TimeBudgetTree;

  constructor(
    private timeBudgetService: TimeBudgetService,
    private contextService: ContextService
  ) {
    super();
  }

  private static calculateValue(
    budget: TimeBudget | undefined,
    node: TimeBudgetNode,
    parent: Map<string, CalculatedValue>,
    v: TimeBudgetVariable
  ): CalculatedValue {
    let hours = 0;
    let percentage = 0;
    let hoursUserInput = false;
    let percentageUserInput = false;
    let parentUserInput = false;
    if (budget) {
      if (budget.hours) {
        hoursUserInput = true;
        hours = budget.hours;
        percentage = node.availableTotalHours > 0 ? budget.hours / node.availableTotalHours : 0;
      } else if (budget.percentage) {
        percentageUserInput = true;
        hours = node.availableTotalHours * budget.percentage;
        percentage = budget.percentage;
      } else {
        percentageUserInput = true;
      }
    } else {
      const parentCalculation = parent.get(v.tacsId);
      if (
        parentCalculation &&
        (parentCalculation.hoursUserInput || parentCalculation.percentageUserInput || parentCalculation.parentUserInput)
      ) {
        parentUserInput =
          parentCalculation.hoursUserInput ||
          parentCalculation.percentageUserInput ||
          parentCalculation.parentUserInput ||
          false;
        const parentPercentageValue = parentCalculation?.percentage ?? 0;
        hours = node.availableTotalHours * parentPercentageValue;
        percentage = parentPercentageValue;
      }
    }
    return { hours, percentage, hoursUserInput, percentageUserInput, parentUserInput };
  }

  createEntry(): TreeNode {
    return {} as TreeNode;
  }

  getData(focusYear?: number): Observable<{ entities: TreeNode[] }> {
    return this.timeBudgetService
      .getTimeBudgets(this.contextService.getMandantIdInstant(), focusYear ?? new Date().getFullYear())
      .pipe(
        map((timeBudgetTree) => {
          this.internalValue = { ...timeBudgetTree };
          timeBudgetTree.variables.push({ id: -1, tacsId: PersonalTacsId, name: 'Personal' });
          const calculatedValues = this.calculateValues(timeBudgetTree);
          this.dataLoadSubject.next({ timeBudgetTree, calculatedValues });
          return this.buildTree(timeBudgetTree, calculatedValues);
        })
      )
      .pipe(map((entities) => ({ entities })));
  }

  isBatchUpdateSupported(): boolean {
    return true;
  }

  batchUpdate(value: TreeNode[], focusYear?: number): Observable<TreeNode[]> {
    if (!this.internalValue) {
      return of(value);
    }
    return this.timeBudgetService
      .updateTimeBudgets(
        this.contextService.getMandantIdInstant(),
        focusYear ?? new Date().getFullYear(),
        this.internalValue
      )
      .pipe(
        map((timeBudgetTree) => {
          this.internalValue = { ...timeBudgetTree };
          timeBudgetTree.variables.push({ id: -1, tacsId: PersonalTacsId, name: 'Personal' });
          const calculatedValues = this.calculateValues(timeBudgetTree);
          this.dataLoadSubject.next({ timeBudgetTree, calculatedValues });
          return this.buildTree(timeBudgetTree, calculatedValues);
        })
      );
  }

  onDataLoaded(): Observable<DataLoad> {
    return this.dataLoadSubject.asObservable();
  }

  onDataChange(data: AnyValue, oldData: AnyValue, field: string, row: TreeNode): void {
    if (!this.internalValue) {
      return;
    }
    const tacsId: string = row.data.tacsId;

    if (field.startsWith(MandantKey)) {
      this.onDataChangeMandant(field, tacsId, data, this.internalValue.budgets);
    } else if (field.startsWith(OrganizationCategoryGroupKey)) {
      this.onDataChangeOrganizationCategoryGroup(field, tacsId, data, this.internalValue.organizationCategoryGroups);
    } else if (field.startsWith(OrganizationCategoryKey)) {
      this.onDataChangeOrganizationCategory(field, tacsId, data, this.internalValue.organizationCategoryGroups);
    } else if (field.startsWith(OrganizationKey)) {
      this.onDataChangeOrganization(field, tacsId, data, this.internalValue.organizationCategoryGroups);
    }
    const calculatedValues = this.calculateValues(this.internalValue);
    this.recursiveSetData(calculatedValues, row);
    this.dataLoadSubject.next({ timeBudgetTree: this.internalValue, calculatedValues });
  }

  recursiveSetData(calculatedValues: CalculatedValues, row: TreeNode): void {
    this.setData(calculatedValues, row, row.data.tacsId);
    if (row.parent) {
      this.recursiveSetData(calculatedValues, row.parent);
    }
  }

  isEditable(e: TreeNode): boolean {
    return e.data.tacsId !== PersonalTacsId;
  }

  private buildTree(timeBudgetTree: TimeBudgetTree, calculatedValues: CalculatedValues): TreeNode[] {
    const result: TreeNode[] = [];
    timeBudgetTree.variables.sort((a, b) => ((a.tacsId ?? '') < (b.tacsId ?? '') ? -1 : 1));
    let parentNode: TreeNode | undefined;
    timeBudgetTree.variables.forEach((variable) => {
      const newNode = this.createNode(variable, calculatedValues);
      const newParentNode = this.determineParent(variable, parentNode);

      if (newParentNode) {
        if (newParentNode.expanded || newNode.expanded || newNode.data.hasUserInput) {
          newParentNode.expanded = true;
          if (newParentNode.parent) {
            newParentNode.parent.expanded = true;
          }
        }
        newParentNode.children?.push(newNode);
        newNode.parent = newParentNode;
        if (parentNode !== newParentNode) {
          parentNode = newParentNode;
        }
      } else {
        result.push(newNode);
      }
      parentNode = newNode;
    });
    return result;
  }

  // noinspection JSMethodCanBeStatic
  private determineParent(variable: TimeBudgetVariable, parentNode?: TreeNode): TreeNode | undefined {
    while (parentNode != null && !variable.tacsId?.startsWith(parentNode?.data.tacsIdAndName.split(' ')[0])) {
      parentNode = parentNode?.parent;
    }
    return parentNode;
  }

  private createNode(variable: TimeBudgetVariable, calculateValues: CalculatedValues): TreeNode {
    const node: TreeNode = {
      children: [],
      expanded: false,
      data: {
        deletable: false,
        tacsIdAndName: variable.tacsId + ' ' + variable.name,
        tacsId: variable.tacsId,
        _style_tacsIdAndName: {
          'background-color': VariableService.getBackgroundColor(variable.tacsId),
          color: VariableService.getForegroundColor(variable.tacsId),
        },
      },
    };

    this.setData(calculateValues, node, variable.tacsId);
    return node;
  }

  private setData(calculateValues: CalculatedValues, treeNode: TreeNode, tacsId: string): void {
    let hasUserInput = false;
    const setData = (type: string, calculatedValueMap: Map<number, Map<string, CalculatedValue>>): void =>
      calculatedValueMap.forEach((value, key) => {
        const calculatedValue = value.get(tacsId);
        treeNode.data[`${type}_${CalculatedValueKey}_${key}`] = calculatedValue;
        treeNode.data[`${type}_${HoursKey}_${key}`] = calculatedValue?.hours;
        treeNode.data[`${type}_${PercentageKey}_${key}`] = calculatedValue?.percentage;
        treeNode.data[`_style_${type}_${HoursKey}_${key}`] = {
          'background-color': calculatedValue?.hoursUserInput ? 'rgba(0,139,210, 0.3)' : 'transparent',
        };
        treeNode.data[`_style_${type}_${PercentageKey}_${key}`] = {
          'background-color': calculatedValue?.percentageUserInput ? 'rgba(0,139,210, 0.3)' : 'transparent',
        };
        hasUserInput = hasUserInput || !!calculatedValue?.hoursUserInput || !!calculatedValue?.percentageUserInput;
      });

    setData(OrganizationKey, calculateValues.organization);
    setData(OrganizationCategoryKey, calculateValues.organizationCategory);
    setData(OrganizationCategoryGroupKey, calculateValues.organizationCategoryGroup);
    setData(MandantKey, calculateValues.mandant);
    treeNode.data.hasUserInput = hasUserInput;
  }

  private calculateValues(timeBudgetTree: TimeBudgetTree): CalculatedValues {
    const result = new CalculatedValues();

    const variables = timeBudgetTree.variables;
    variables.sort((a, b) => ((a.tacsId ?? '') < (b.tacsId ?? '') ? 1 : -1));
    const mandantCalculations = this.calculateHoursAndPercentage(variables, timeBudgetTree);
    result.mandant.set(timeBudgetTree.id, mandantCalculations);

    const organizationCategoryGroupsHoursSum = new Map<string, number>();
    timeBudgetTree.organizationCategoryGroups.forEach((organizationCategoryGroup) => {
      const organizationCategoryGroupCalculations = this.calculateHoursAndPercentage(
        variables,
        organizationCategoryGroup,
        mandantCalculations
      );
      result.organizationCategoryGroup.set(organizationCategoryGroup.id, organizationCategoryGroupCalculations);

      const organizationCategoryHoursSum = new Map<string, number>();
      organizationCategoryGroup.organizationCategories.forEach((organizationCategory) => {
        const organizationCategoryCalculations = this.calculateHoursAndPercentage(
          variables,
          organizationCategory,
          organizationCategoryGroupCalculations
        );
        result.organizationCategory.set(organizationCategory.id, organizationCategoryCalculations);

        const organizationHoursSum = new Map<string, number>();
        organizationCategory.organizations.forEach((organization) => {
          const organizationCalculations = this.calculateHoursAndPercentage(
            variables,
            organization,
            organizationCategoryCalculations
          );
          result.organization.set(organization.id, organizationCalculations);
          organizationCalculations.forEach((value, key) => {
            const hours = value.hours ?? value.percentage * organization.availableTotalHours;
            organizationHoursSum.set(key, hours + (organizationHoursSum.get(key) ?? 0));
          });
        });
        this.updateHoursAndPercentage(
          organizationCategoryCalculations,
          organizationHoursSum,
          organizationCategory,
          organizationCategoryHoursSum
        );
      });
      this.updateHoursAndPercentage(
        organizationCategoryGroupCalculations,
        organizationCategoryHoursSum,
        organizationCategoryGroup,
        organizationCategoryGroupsHoursSum
      );
    });

    this.updateHoursAndPercentage(mandantCalculations, organizationCategoryGroupsHoursSum, timeBudgetTree);
    return result;
  }

  private updateHoursAndPercentage(
    calculations: Map<string, CalculatedValue>,
    childSums: Map<string, number>,
    node: TimeBudgetNode,
    nodeSum?: Map<string, number>
  ): void {
    calculations.forEach((calculatedValue, key) => {
      const hours = childSums.get(key) ?? 0;
      if (!calculatedValue.hoursUserInput && !calculatedValue.percentageUserInput) {
        calculations.set(key, {
          hours,
          percentage: node.availableTotalHours > 0 ? hours / node.availableTotalHours : 0,
          percentageUserInput: false,
          hoursUserInput: false,
        });
      }
    });
    if (nodeSum) {
      calculations.forEach((calculatedValue, key) => nodeSum.set(key, calculatedValue.hours + (nodeSum.get(key) ?? 0)));
    }
  }

  private calculateHoursAndPercentage(
    variables: TimeBudgetVariable[],
    node: TimeBudgetNode,
    parent: Map<string, CalculatedValue> = new Map<string, CalculatedValue>()
  ): Map<string, CalculatedValue> {
    const result = new Map<string, CalculatedValue>();

    const parentMap = new Map<string, CalculatedValue>();

    variables.forEach((v) => {
      const budget = node.budgets.find((b) => b.tacsId === v.tacsId);

      let parentTacsId = v.tacsId;
      do {
        parentTacsId = parentTacsId.substring(0, parentTacsId.lastIndexOf('.'));
      } while (parentTacsId.length > 0 && !variables.find((v) => v.tacsId === parentTacsId));

      let parentCalculation = parentMap.get(parentTacsId);
      if (!parentCalculation) {
        parentCalculation = { hours: 0, percentage: 0, percentageUserInput: false, hoursUserInput: false };
        parentMap.set(parentTacsId, parentCalculation);
      }

      const calculatedValue = TimeBudgetTableService.calculateValue(budget, node, parent, v);

      const self = parentMap.get(v.tacsId);

      if (self && (self.hours ?? 0) > 0 && !calculatedValue.hoursUserInput) {
        calculatedValue.hours = Math.max(self.hours, calculatedValue.hours);
      }
      if (self && (self.percentage ?? 0) > 0 && !calculatedValue.percentageUserInput) {
        calculatedValue.percentage = Math.max(self.percentage, calculatedValue.percentage);
      }
      if (
        self &&
        ((calculatedValue.hours > 0 && self.hours > calculatedValue.hours + Number.EPSILON) ||
          (calculatedValue.percentage > 0 && self.percentage > calculatedValue.percentage + Number.EPSILON))
      ) {
        calculatedValue.invalid = true;
      }

      parentCalculation.hours = parentCalculation.hours + calculatedValue.hours;
      parentCalculation.percentage = parentCalculation.percentage + calculatedValue.percentage;

      result.set(v.tacsId, calculatedValue);
    });
    result.set(PersonalTacsId, {
      hours: node.availableTotalHours,
      percentage: 1,
      hoursUserInput: false,
      percentageUserInput: false,
    });
    return result;
  }

  private updateHours(tacsId: string, data: number | null, budgets: TimeBudget[]): void {
    const budget = budgets.find((b) => b.tacsId === tacsId);
    if (budget) {
      if (data === null) {
        if (!budget.percentage) {
          budgets.remove(budget);
        }
      } else {
        budget.hours = data ?? undefined;
        budget.percentage = 0;
      }
    } else if (data != null) {
      budgets.push({ tacsId, hours: data });
    }
  }

  private updatePercentage(tacsId: string, data: number | null, budgets: TimeBudget[]): void {
    const budget = budgets.find((b) => b.tacsId === tacsId);
    if (budget) {
      if (data === null) {
        if (!budget.hours) {
          budgets.remove(budget);
        }
      } else {
        budget.percentage = data ?? undefined;
        budget.hours = 0;
      }
    } else if (data != null) {
      budgets.push({ tacsId, percentage: data });
    }
  }

  private onDataChangeMandant(field: string, tacsId: string, data: AnyValue, budgets: TimeBudget[]): void {
    if (field.includes(`_${HoursKey}_`)) {
      this.updateHours(tacsId, data, budgets);
    } else {
      this.updatePercentage(tacsId, data, budgets);
    }
  }

  private onDataChangeOrganizationCategoryGroup(
    field: string,
    tacsId: string,
    data: AnyValue,
    timeBudgetOrganizationCategoryGroups: TimeBudgetOrganizationCategoryGroup[]
  ): void {
    if (field.includes(`_${HoursKey}_`)) {
      const key = field.substring(`${OrganizationCategoryGroupKey}_${HoursKey}_`.length);
      const organizationCategoryGroup = timeBudgetOrganizationCategoryGroups.find((o) => o.id === parseInt(key, 10));
      if (organizationCategoryGroup) {
        this.updateHours(tacsId, data, organizationCategoryGroup.budgets);
      }
    } else {
      const key = field.substring(`${OrganizationCategoryGroupKey}_${PercentageKey}_`.length);
      const organizationCategoryGroup = timeBudgetOrganizationCategoryGroups.find((o) => o.id === parseInt(key, 10));
      if (organizationCategoryGroup) {
        this.updatePercentage(tacsId, data, organizationCategoryGroup.budgets);
      }
    }
  }

  private onDataChangeOrganizationCategory(
    field: string,
    tacsId: string,
    data: AnyValue,
    timeBudgetOrganizationCategoryGroups: TimeBudgetOrganizationCategoryGroup[]
  ): void {
    if (field.includes(`_${HoursKey}_`)) {
      const key = field.substring(`${OrganizationCategoryKey}_${HoursKey}_`.length);
      const organizationCategory = timeBudgetOrganizationCategoryGroups
        .flatMap((x) => x.organizationCategories)
        .find((o) => o.id === parseInt(key, 10));
      if (organizationCategory) {
        this.updateHours(tacsId, data, organizationCategory.budgets);
      }
    } else {
      const key = field.substring(`${OrganizationCategoryKey}_${PercentageKey}_`.length);
      const organizationCategory = timeBudgetOrganizationCategoryGroups
        .flatMap((x) => x.organizationCategories)
        .find((o) => o.id === parseInt(key, 10));
      if (organizationCategory) {
        this.updatePercentage(tacsId, data, organizationCategory.budgets);
      }
    }
  }

  private onDataChangeOrganization(
    field: string,
    tacsId: string,
    data: AnyValue,
    timeBudgetOrganizationCategoryGroups: TimeBudgetOrganizationCategoryGroup[]
  ): void {
    if (field.includes(`_${HoursKey}_`)) {
      const key = field.substring(`${OrganizationKey}_${HoursKey}_`.length);
      const organization = timeBudgetOrganizationCategoryGroups
        .flatMap((x) => x.organizationCategories)
        .flatMap((x) => x.organizations)
        .find((o) => o.id === parseInt(key, 10));
      if (organization) {
        this.updateHours(tacsId, data, organization.budgets);
      }
    } else {
      const key = field.substring(`${OrganizationKey}_${PercentageKey}_`.length);
      const organization = timeBudgetOrganizationCategoryGroups
        .flatMap((x) => x.organizationCategories)
        .flatMap((x) => x.organizations)
        .find((o) => o.id === parseInt(key, 10));
      if (organization) {
        this.updatePercentage(tacsId, data, organization.budgets);
      }
    }
  }
}

export class CalculatedValues {
  mandant = new Map<number, Map<string, CalculatedValue>>();
  organizationCategoryGroup = new Map<number, Map<string, CalculatedValue>>();
  organizationCategory = new Map<number, Map<string, CalculatedValue>>();
  organization = new Map<number, Map<string, CalculatedValue>>();
}

export interface CalculatedValue {
  hours: number;
  percentage: number;
  hoursUserInput: boolean;
  percentageUserInput: boolean;
  invalid?: boolean;
  parentUserInput?: boolean;
}

export interface DataLoad {
  timeBudgetTree: TimeBudgetTree;
  calculatedValues: CalculatedValues;
}
