import { ChangeDetectionStrategy, Component, Injector, OnInit } from '@angular/core';
import { AbstractContentComponent } from '../../../abstract-content.component';
import { Context } from '@service/context.service';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { CompleteVariable } from '@entity/variable';
import { Market } from '@entity/market';
import { SelectItem } from 'primeng/api';
import { EmptyLanguageMap, LanguageFormGroup, LanguageKeys } from '@entity/language';
import { VariableService } from '@service/variable.service';
import { finalize } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { FormButtonBarComponent } from '@shared/form-button-bar/form-button-bar.component';
import { FormElementComponent } from '@shared/form-element/form-element.component';
import { FormDateInputComponent } from '@shared/form-date-input/form-date-input.component';
import { FormSwitchComponent } from '@shared/form-switch/form-switch.component';
import { FormMultiselectComponent } from '@shared/form-multiselect/form-multiselect.component';
import { FormNumberInputComponent } from '@shared/form-number-input/form-number-input.component';
import { FormTextInputComponent } from '@shared/form-text-input/form-text-input.component';

import { CardModule } from 'primeng/card';
import { InputNumberModule } from 'primeng/inputnumber';
import { VarHealthcareFormElementComponent } from './var-healthcare-form-element/var-healthcare-form-element.component';
import {
  SnomedCtDetailFormElementComponent,
  SnomedCtItem,
} from './snomed-ct-detail-form-element/snomed-ct-detail-form-element.component';

@Component({
  selector: 'app-variable-detail',
  templateUrl: './variable-detail.component.html',
  styleUrls: ['./variable-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CardModule,
    FormsModule,
    ReactiveFormsModule,
    FormTextInputComponent,
    FormNumberInputComponent,
    FormMultiselectComponent,
    FormSwitchComponent,
    FormDateInputComponent,
    FormElementComponent,
    FormButtonBarComponent,
    TranslateModule,
    InputNumberModule,
    VarHealthcareFormElementComponent,
    SnomedCtDetailFormElementComponent,
  ],
})
export class VariableDetailComponent extends AbstractContentComponent implements OnInit {
  form!: FormGroup<{
    parent?: FormControl<string>;
    tacsId: FormControl<string>;
    name: FormGroup<LanguageFormGroup>;
    description: FormGroup<LanguageFormGroup>;
    comment: FormGroup<LanguageFormGroup>;
    sortIndex: FormControl<number>;
    foreignCode: FormControl<string>;
    markets: FormControl<number[]>;
    isAssignmentCustomer: FormControl<boolean>;
    isAssignmentEmployee: FormControl<boolean>;
    isRebooking: FormControl<boolean>;
    isTreatmentDuration: FormControl<boolean>;
    isPresence: FormControl<boolean>;
    isProductivity: FormControl<boolean>;
    isCostRelevant: FormControl<boolean>;
    validFrom: FormControl<Date>;
    validTo: FormControl<Date | undefined>;
    leistungsgewicht: FormControl<number | undefined>;
    leistungsgewichtKategorie: FormControl<number | undefined>;
    varHealthcareDocumentIds: FormControl<number[]>;
    snomedCtItems: FormControl<SnomedCtItem[]>;
  }>;
  languages = LanguageKeys;
  marketOptions!: SelectItem[];
  variable: CompleteVariable = { isDeletable: false };
  submitting = false;

  constructor(
    injector: Injector,
    private variableService: VariableService,
    private formBuilder: FormBuilder
  ) {
    super(injector, Context.Global);
    this.setBreadcrumbs([
      { label: 'MENU.VARIABLES_OVERVIEW', routerLink: ['/main-data/variables'] },
      { label: 'GLOBAL.DETAILS' },
    ]);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.activatedRoute.data.subscribe((data) => {
      this.variable = data.variable ?? {};
      this.marketOptions = data.markets.map(
        (m: Market) =>
          ({
            value: m.id,
            label: m.names[this.getLanguageCaseSensitive()],
          }) as SelectItem
      );
      if (data.parent) {
        this.variable.parentId = data.parent.id;
        this.variable.parentTacsId = data.parent.tacsId;
        this.variable.tacsId = data.parent.tacsId;
        this.variable.parentName = data.parent.name[this.getLanguageCaseSensitive()];
        this.variable.sortIndex = data.parent.sortIndex;
        this.variable.markets = data.parent.markets;
        this.variable.isAssignmentCustomer = data.parent.isAssignmentCustomer;
        this.variable.isRebooking = data.parent.isRebooking;
        this.variable.isTreatmentDuration = data.parent.isTreatmentDuration;
        this.variable.isPresence = data.parent.isPresence;
        this.variable.isProductivity = data.parent.isProductivity;
        this.variable.isCostRelevant = data.parent.isCostRelevant;
        this.variable.validFrom = data.parent.validFrom;
        this.variable.validTo = data.parent.validTo;
      }
      this.activatedRoute.queryParams.subscribe((params) => {
        if (params.measure && data.parent) {
          this.variable.tacsId = data.parent.nextMeasureTacsId;
          this.variable.sortIndex = data.parent.nextMeasureSortIndex;
        }
      });
    });

    this.form = this.formBuilder.nonNullable.group({
      tacsId: [
        this.variable.tacsId ?? '',
        [Validators.required, Validators.pattern(/^[1-6](\.\d{2}(\.\d{2}(\.\d{3}(\.\d(\.\d{3})?)?)?)?)?$/)],
      ],
      name: this.formBuilder.nonNullable.group({ ...EmptyLanguageMap, ...this.variable.name }),
      description: this.formBuilder.nonNullable.group({ ...EmptyLanguageMap, ...this.variable.description }),
      comment: this.formBuilder.nonNullable.group({ ...EmptyLanguageMap, ...this.variable.comment }),
      sortIndex: [this.variable.sortIndex ?? 0, Validators.required],
      foreignCode: [this.variable.foreignCode ?? ''],
      markets: new FormControl(this.variable.markets ?? [], { nonNullable: true }),
      isAssignmentCustomer: [this.variable.isAssignmentCustomer ?? false],
      isAssignmentEmployee: [this.variable.isAssignmentEmployee ?? false],
      isRebooking: [this.variable.isRebooking ?? false],
      isTreatmentDuration: [this.variable.isTreatmentDuration ?? false],
      isPresence: [this.variable.isPresence ?? false],
      isProductivity: [this.variable.isProductivity ?? false],
      isCostRelevant: [this.variable.isCostRelevant ?? false],
      validFrom: [this.variable.validFrom ?? new Date()],
      validTo: [this.variable.validTo ?? undefined],
      leistungsgewicht: [this.variable.leistungsgewicht],
      leistungsgewichtKategorie: [this.variable.leistungsgewichtKategorie],
      varHealthcareDocumentIds: [this.variable.varHealthcareDocumentIds ?? []],
      snomedCtItems: [this.variable.snomedCtItems ?? []],
    });
    this.form.controls.parent = new FormControl(
      {
        value: this.variable.parentTacsId + ' ' + this.variable.parentName,
        disabled: true,
      },
      { nonNullable: true }
    );

    this.form.controls.name.controls.De.setValidators(Validators.required);

    if (this.variable.mandants?.length) {
      this.form.controls.tacsId.disable();
    }
  }

  onSubmit(): void {
    this.submitting = true;
    const formValues = this.form.getRawValue();
    delete formValues.parent;
    const newVariable: CompleteVariable = { ...this.variable, ...formValues };
    const action = this.variable.id
      ? this.variableService.updateCompleteVariable(this.variable.id, newVariable)
      : this.variableService.createCompleteVariable(newVariable);
    action.pipe(finalize(() => (this.submitting = false))).subscribe({
      next: () => {
        this.showSuccessMessage();
        this.navigate(['..']);
      },
      error: () => this.showErrorMessage(),
    });
  }

  cancel(): void {
    this.navigate(['..']);
  }
}
