<app-tree-table
  (focusDateChange)="focusDateChange($event)"
  (selectionChange)="selectionChange($event)"
  [(selection)]="selection"
  [actionColumnWidth]="450"
  [columns]="columns"
  [firstColumnFrozen]="true"
  [focusDate]="focusDate"
  [hasFocusDate]="true"
  [loading]="loading"
  [selectable]="true"
  [value]="value"
  [pagination]="false"
  dataKey="tacsId">
  <ng-template
    appActionButton
    displayIfNot="node.data.fake"
    icon="fal fa-search"
    labelKey="GLOBAL.DETAILS"
    (onClick)="showDialog($event.row)"></ng-template>

  @if (isManager()) {
    <ng-template
      appActionButton
      displayIf="node.data.canAddBetriebsVariable"
      icon="fal fa-plus"
      labelKey="VARIABLES.NEW_BETRIEBSVARIABLE"
      (onClick)="newBetriebsVariable($event.row)"></ng-template>
  }

  <ng-template
    appToolbarButton
    icon="fal fa-folder-tree"
    (onClick)="hierarchyMenu.show($event)"
    [labelKey]="'VARIABLES.FOCUS'"></ng-template>

  @if (isManager()) {
    <ng-template
      appToolbarButton
      icon="fal fa-hand-holding-medical"
      (onClick)="measuresMenu.show($event)"
      [labelKey]="'GLOBAL.MEASURES'"></ng-template>
  }

  @if (isManager()) {
    <ng-template
      appToolbarButton
      icon="fal fa-stop-circle"
      labelKey="VARIABLES.DEACTIVATE_SELECTED"
      (onClick)="showDeactivate()"
      [disabled]="!deactivateEnabled"></ng-template>
  }

  @if (isManager()) {
    <ng-template
      appToolbarButton
      icon="fal fa-play-circle"
      labelKey="VARIABLES.ACTIVATE_SELECTED"
      (onClick)="showActivate()"
      [disabled]="!activateEnabled"></ng-template>
  }
</app-tree-table>

<p-tieredMenu
  (click)="measuresMenu.hide()"
  [hideTransitionOptions]="'0ms'"
  [model]="viewMenuModel"
  [popup]="true"
  [showTransitionOptions]="'0ms'"
  [style]="{ width: '300px' }"
  #measuresMenu></p-tieredMenu>

<p-tieredMenu
  (click)="hierarchyMenu.hide()"
  [hideTransitionOptions]="'0ms'"
  [model]="hierarchyMenuModel"
  [popup]="true"
  [showTransitionOptions]="'0ms'"
  [style]="{ width: '300px' }"
  #hierarchyMenu></p-tieredMenu>
