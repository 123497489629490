<div
  class="layout-wrapper layout-static"
  (click)="onLayoutClick()"
  [ngClass]="{
    'layout-static-inactive': staticMenuDesktopInactive,
    'layout-mobile-active': staticMenuMobileActive,
    'layout-overlay-active': overlayMenuActive
  }">
  <app-topbar></app-topbar>
  <div class="layout-menu-container" (click)="onMenuClick()">
    <p-scrollPanel [style]="{ height: '100%' }" #layoutMenuScroller>
      <div class="layout-menu-content">
        <app-menu></app-menu>
        <img alt="by Rodix" src="assets/logo/by_rodix.svg" />
      </div>
    </p-scrollPanel>
  </div>

  <div class="layout-content">
    <div class="progress-bar">
      @if (loading) {
        <p-progressBar
          mode="indeterminate"
          [style]="{ 'background-color': 'transparent', height: '5px' }"></p-progressBar>
      }
    </div>
    <app-breadcrumb></app-breadcrumb>

    <div class="layout-content-container">
      <div class="content">
        <router-outlet></router-outlet>
      </div>
    </div>

    @if (staticMenuMobileActive) {
      <div class="layout-mask"></div>
    }
  </div>
</div>

<p-toast></p-toast>
<p-confirmDialog></p-confirmDialog>
