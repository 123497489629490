import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { PersonnelCost, PersonnelCostEntry } from '@entity/personnel-cost';
import { TimePeriodItem } from '@shared/form-time-period/form-time-period.component';
import { MandantService } from '@service/mandant.service';
import { ContextService } from '@service/context.service';
import { finalize } from 'rxjs';
import { FormButtonBarComponent } from '../../shared/form-button-bar/form-button-bar.component';
import { FormTimePeriodComponent } from '../../shared/form-time-period/form-time-period.component';

@Component({
  selector: 'app-personnel-cost-detail',
  templateUrl: './personnel-cost-detail.component.html',
  styleUrls: ['./personnel-cost-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, FormTimePeriodComponent, FormButtonBarComponent],
})
export class PersonnelCostDetailComponent implements OnInit {
  form!: FormGroup<{
    entries: FormControl<TimePeriodItem[]>;
  }>;
  submitting = false;
  private personnelCost!: PersonnelCost;

  constructor(
    private formBuilder: FormBuilder,
    private dynamicDialogConfig: DynamicDialogConfig,
    private mandantService: MandantService,
    private contextService: ContextService,
    private dynamicDialogRef: DynamicDialogRef
  ) {}

  ngOnInit(): void {
    this.personnelCost = this.dynamicDialogConfig.data;
    const entries: TimePeriodItem[] = this.personnelCost.entries.map(
      (e) =>
        ({
          id: e.id,
          value: e.value,
          from: e.validFrom,
          to: e.validTo,
        }) as TimePeriodItem
    );
    this.form = this.formBuilder.group({
      entries: new FormControl(entries ?? [], { nonNullable: true }),
    });
  }

  onSubmit(): void {
    this.submitting = true;
    const personnelCostEntries = this.form.getRawValue().entries.map(
      (e: TimePeriodItem) =>
        ({
          id: e.id,
          value: e.value,
          validFrom: e.from,
          validTo: e.to,
        }) as PersonnelCostEntry
    );
    this.mandantService
      .updatePersonnelCostEntries(
        this.contextService.getMandantIdInstant(),
        this.personnelCost.id,
        personnelCostEntries
      )
      .pipe(finalize(() => (this.submitting = false)))
      .subscribe((result) => {
        this.dynamicDialogRef.close(result);
      });
  }

  cancel(): void {
    this.dynamicDialogRef.close();
  }
}
