import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Input, OnInit } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MenuItem } from 'primeng/api';
import { AppComponent } from '../app.component';
import { NavigationEnd, Router, RouterLink, RouterLinkActive } from '@angular/router';
import { filter, take } from 'rxjs/operators';
import { TranslateModule } from '@ngx-translate/core';
import { RippleModule } from 'primeng/ripple';
import { NgClass } from '@angular/common';

@Component({
  /* eslint-disable @angular-eslint/component-selector */
  selector: '[app-submenu]',
  /* eslint-enable @angular-eslint/component-selector */
  templateUrl: './sub-menu.component.html',
  styleUrls: ['./sub-menu.component.scss'],
  animations: [
    trigger('children', [
      state(
        'hiddenAnimated',
        style({
          height: '0px',
        })
      ),
      state(
        'visibleAnimated',
        style({
          height: '*',
        })
      ),
      state(
        'visible',
        style({
          height: '*',
          'z-index': 100,
        })
      ),
      state(
        'hidden',
        style({
          height: '0px',
          'z-index': '*',
        })
      ),
      transition('visibleAnimated => hiddenAnimated', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
      transition('hiddenAnimated => visibleAnimated', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass, RippleModule, TranslateModule, RouterLinkActive, RouterLink, forwardRef(() => SubMenuComponent)],
})
export class SubMenuComponent implements OnInit {
  @Input() item!: MenuItem | MenuItem[];
  @Input() root = false;
  @Input() visible = false;
  private activeIndex?: null | number;

  constructor(
    public app: AppComponent,
    private router: Router,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  private _parentActive?: boolean;

  @Input() get parentActive(): boolean | undefined {
    return this._parentActive;
  }

  set parentActive(val: boolean | undefined) {
    this._parentActive = val;

    if (!this._parentActive) {
      this.activeIndex = null;
    }
  }

  ngOnInit(): void {
    if (Array.isArray(this.item)) {
      this.router.events
        .pipe(
          filter((event) => event instanceof NavigationEnd),
          take(1)
        )
        .subscribe(() => {
          const index = this.findActiveItemIndex(this.item as MenuItem[]);
          if (index >= 0) {
            this.activeIndex = index;
            this.changeDetectorRef.markForCheck();
          }
        });
    }
  }

  itemClick(event: Event, item: MenuItem, index: number): void {
    if (this.root) {
      this.app.menuHoverActive = !this.app.menuHoverActive;
    }
    // avoid processing disabled items
    if (item.disabled) {
      event.preventDefault();
      return;
    }

    // activate current item and deactivate active sibling if any
    this.activeIndex = this.activeIndex === index ? null : index;

    // execute command
    if (item.command) {
      item.command({ originalEvent: event, item });
    }

    // prevent hash change
    if (item.items || (!item.url && !item.routerLink)) {
      window.setTimeout(() => {
        this.app.layoutMenuScrollerViewChild.moveBar();
      }, 450);
      event.preventDefault();
    }

    // hide menu
    if (!item.items) {
      this.app.overlayMenuActive = false;
      this.app.staticMenuMobileActive = false;
      this.app.menuHoverActive = !this.app.menuHoverActive;
    }
  }

  isActive(index: number): boolean {
    return this.activeIndex === index;
  }

  private findActiveItemIndex(items: MenuItem[]): number {
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      if (item.items) {
        if (this.findActiveItemIndex(item.items) >= 0) {
          return i;
        }
      } else if (item.routerLink) {
        if (this.router.isActive(this.router.createUrlTree(item.routerLink), true)) {
          return i;
        }
      }
    }
    return -1;
  }
}
