import { Employee } from '@entity/employee';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractCrudMandantService } from '@service/abstract-crud-mandant.service';

@Injectable({
  providedIn: 'root',
})
export class EmployeeService extends AbstractCrudMandantService<Employee> {
  constructor(httpClient: HttpClient) {
    super('employees', httpClient);
  }
}
