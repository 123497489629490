import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AbstractFormElement } from '../abstract-form-element';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputSwitchModule } from 'primeng/inputswitch';
import { FormElementComponent } from '../form-element/form-element.component';

@Component({
  selector: 'app-form-switch',
  templateUrl: './form-switch.component.html',
  styleUrls: ['./form-switch.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [FormElementComponent, InputSwitchModule, FormsModule, ReactiveFormsModule],
})
export class FormSwitchComponent extends AbstractFormElement<boolean> {}
