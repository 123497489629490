<app-tree-table
  [actionColumnWidth]="0"
  [columns]="columns"
  [editable]="true"
  [firstColumnFrozen]="true"
  [(focusYear)]="focusYear"
  [footer]="true"
  [hasFocusYear]="true"
  [headerGroups]="headerGroups"
  [isCreateAllowed]="false"
  (focusYearChange)="focusYearChange($event)"
  [newHeaderGroupMode]="true"
  [showFrozenColumnSelection]="false">
  <ng-template
    appToolbarButton
    icon="fal fa-sitemap"
    labelKey="ASSIGNMENT.ORGANISATIONAL_UNIT_FILTER"
    (onClick)="organisationalUnitOverlayPanel.toggle($event)"></ng-template>

  <ng-template
    appToolbarButton
    icon="fal fa-history"
    labelKey="TIME_BUDGETS.IMPORT_FROM_PREVIOUS_YEAR"
    (onClick)="importFromPreviousYear()"></ng-template>

  <ng-template
    appToolbarButton
    icon="fal fa-file-csv"
    labelKey="TIME_BUDGETS.OLD_CSV_EXPORT"
    (onClick)="csvExport()"></ng-template>
</app-tree-table>

<p-overlayPanel
  (onHide)="load()"
  [hideTransitionOptions]="'0ms'"
  [showTransitionOptions]="'0ms'"
  [style]="{ width: '500px' }"
  #organisationalUnitOverlayPanel>
  <ng-template pTemplate>
    <div class="grid">
      <div class="col-12">
        <p-button
          icon="fal fa-check-square"
          styleClass="p-button-text"
          (onClick)="visibleColumns = flattenTree(columnTree)"
          [label]="'ACTIONS.SELECT_ALL' | translate"></p-button>
        <p-button
          icon="fal fa-square"
          styleClass="p-button-text"
          (onClick)="visibleColumns = []"
          [label]="'ACTIONS.DESELECT_ALL' | translate"></p-button>
        <p-tree
          scrollHeight="calc(100vh - 200px)"
          selectionMode="checkbox"
          [selection]="visibleColumns"
          (selectionChange)="visibleColumns = $any($event)"
          [propagateSelectionDown]="false"
          [style]="{ width: '100%' }"
          [value]="columnTree ?? []"></p-tree>
      </div>

      @if (visibleColumns.length > maxColumns) {
        <div class="col-12">
          <p-message severity="warn" [text]="'ASSIGNMENT.TOO_MANY_COLUMNS' | translate"></p-message>
        </div>
      }

      <div class="col-12">
        <p-button
          icon="fal fa-check"
          (onClick)="organisationalUnitOverlayPanel.hide()"
          [label]="'ACTIONS.OK' | translate"></p-button>
      </div>
    </div>
  </ng-template>
</p-overlayPanel>
