import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ReportingStatistics } from '@entity/reporting-statistics';
import { Observable } from 'rxjs';
import { LoginStatistics } from '@entity/login-statistics';

@Injectable({
  providedIn: 'root',
})
export class ReportingService {
  private readonly baseUrl = `api/1.0/reporting`;

  constructor(private httpClient: HttpClient) {}

  getReportingStatistics(): Observable<ReportingStatistics[]> {
    return this.httpClient.get<ReportingStatistics[]>(`${this.baseUrl}/report-statistics`);
  }

  getLoginStatistics(): Observable<LoginStatistics[]> {
    return this.httpClient.get<LoginStatistics[]>(`${this.baseUrl}/login-statistics`);
  }
}
