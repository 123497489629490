<app-table
  (focusDateChange)="focusDateChange($event)"
  [columns]="columns"
  [firstColumnFrozen]="true"
  [focusDate]="focusDate"
  [footer]="true"
  [hasFocusDate]="true"
  [headerGroups]="headerGroups"
  [loading]="loading"
  [showFrozenColumnSelection]="false"
  [sortField]="'tacsIdAndName'"
  [value]="value">
  <ng-template
    appToolbarButton
    icon="fal fa-sitemap"
    labelKey="ASSIGNMENT.ORGANISATIONAL_UNIT_FILTER"
    (onClick)="organisationalUnitOverlayPanel.toggle($event)"></ng-template>

  @if (!emptyRowsHidden) {
    <ng-template
      appToolbarButton
      icon="fal fa-align-slash"
      labelKey="GLOBAL.HIDE_EMPTY_ROWS"
      (onClick)="hideEmptyRows()"></ng-template>
  }

  @if (emptyRowsHidden) {
    <ng-template
      appToolbarButton
      icon="fal fa-filter"
      labelKey="GLOBAL.SHOW_ALL"
      (onClick)="showEmptyRows()"></ng-template>
  }
</app-table>

<p-overlayPanel
  (onHide)="updateColumns()"
  [hideTransitionOptions]="'0ms'"
  [showTransitionOptions]="'0ms'"
  #organisationalUnitOverlayPanel>
  <ng-template pTemplate>
    <div class="grid">
      <div class="col-12">
        <p-listbox
          optionLabel="bezeichnung"
          optionValue="id"
          [(ngModel)]="selectedOrganisations"
          [checkbox]="true"
          [filter]="true"
          [listStyle]="{ 'max-height': '250px' }"
          [multiple]="true"
          [options]="organisations"
          [showToggleAll]="true"
          [style]="{ width: '100%' }"></p-listbox>
      </div>

      @if (selectedOrganisations.length > maxOrganisations) {
        <div class="col-12">
          <p-message severity="warn" [text]="'ASSIGNMENT.TOO_MANY_COLUMNS' | translate"></p-message>
        </div>
      }

      <div class="col-12">
        <p-button
          icon="fal fa-check"
          (onClick)="organisationalUnitOverlayPanel.hide()"
          [label]="'ACTIONS.OK' | translate"></p-button>
      </div>
    </div>
  </ng-template>
</p-overlayPanel>

<p-dialog [header]="'GLOBAL.DETAILS' | translate" [modal]="true" [(visible)]="dialogVisible">
  @if (dialogVisible) {
    <form novalidate style="width: 560px" (ngSubmit)="save()" [formGroup]="form">
      <app-form-time-period
        id="value"
        [form]="form"
        [inputType]="'number'"
        [maxFractionDigits]="2"
        [minFractionDigits]="0"></app-form-time-period>
      <app-form-button-bar
        (onCancel)="dialogVisible = false"
        [saveDisabled]="submitting"
        [form]="form"
        [offset]="false"></app-form-button-bar>
    </form>
  }
</p-dialog>
