<app-tree-table
  (focusDateChange)="focusDateChange($event)"
  (selectionChange)="selectionChange($event)"
  [(selection)]="selection"
  [actionColumnWidth]="150"
  [columns]="columns"
  [firstColumnFrozen]="true"
  [focusDate]="focusDate"
  [hasFocusDate]="true"
  [loading]="loading"
  [selectable]="true"
  [value]="value">
  <ng-template
    appToolbarButton
    icon="fal fa-folder-tree"
    (onClick)="hierarchyMenu.show($event)"
    [labelKey]="'PERSONNEL_CATEGORY.FOCUS'"></ng-template>

  <ng-template
    appActionButton
    icon="fal fa-search"
    labelKey="GLOBAL.DETAILS"
    (onClick)="showDialog($event.row)"></ng-template>

  @if (isManager()) {
    <ng-template
      appToolbarButton
      icon="fal fa-stop-circle"
      labelKey="PERSONNEL_CATEGORY.DEACTIVATE_SELECTED"
      (onClick)="showDeactivate()"
      [disabled]="!deactivateEnabled"></ng-template>
  }

  @if (isManager()) {
    <ng-template
      appToolbarButton
      icon="fal fa-play-circle"
      labelKey="PERSONNEL_CATEGORY.ACTIVATE_SELECTED"
      (onClick)="showActivate()"
      [disabled]="!activateEnabled"></ng-template>
  }
</app-tree-table>

<p-tieredMenu
  (click)="hierarchyMenu.hide()"
  [hideTransitionOptions]="'0ms'"
  [model]="hierarchyMenuModel"
  [popup]="true"
  [showTransitionOptions]="'0ms'"
  [style]="{ width: '300px' }"
  #hierarchyMenu></p-tieredMenu>

<p-dialog
  [(visible)]="displayActivateDialog"
  [header]="'PERSONNEL_CATEGORY.ACTIVATE_SELECTED' | translate"
  [modal]="true"
  [style]="{ width: '700px' }">
  @if (form) {
    <form novalidate (ngSubmit)="saveActivate()" [formGroup]="form">
      <div class="grid">
        <app-form-switch
          id="inOfficialStaffingPlan"
          label="PERSONNEL_CATEGORY.IN_OFFICIAL_STAFFING_PLAN"
          [form]="form"
          [twoColumns]="false"></app-form-switch>
        <app-form-number-input
          id="weightingStaffingPlan"
          label="PERSONNEL_CATEGORY.WEIGHTING_STAFFING_PLAN"
          suffix="%"
          [form]="form"
          [maxFractionDigits]="2"
          [max]="100"
          [minFractionDigits]="2"
          [min]="0"
          [twoColumns]="false"></app-form-number-input>
        <app-form-date-input
          id="targetDailyValue"
          label="PERSONNEL_CATEGORY.TARGET_DAILY_VALUE"
          [form]="form"
          [timeOnly]="true"
          [twoColumns]="false"></app-form-date-input>
        <app-form-date-input
          id="validFrom"
          label="GLOBAL.GUELTIG_AB"
          [form]="form"
          [twoColumns]="false"></app-form-date-input>
        <div class="col-12">
          <p-button
            icon="fal fa-save"
            type="submit"
            [disabled]="!form.valid"
            [label]="'ACTIONS.SAVE' | translate"
            [style]="{ 'margin-right': '0.5rem' }"></p-button>
          <p-button
            icon="fal fa-times"
            styleClass="ui-button-secondary"
            type="button"
            (onClick)="displayActivateDialog = false"
            [label]="'ACTIONS.CANCEL' | translate"></p-button>
        </div>
      </div>
    </form>
  }
</p-dialog>
