@if (this.columns.length > 0) {
  <app-table
    (focusDateChange)="focusDateChange()"
    [(columnSelection)]="columnSelection"
    [(focusDate)]="focusDate"
    [(selection)]="rowSelection"
    [columnSelectable]="true"
    [columns]="columns"
    [editable]="true"
    [firstColumnFrozen]="true"
    [hasFocusDate]="true"
    [selectable]="true"
    [actionColumnWidth]="400">
    <ng-template
      appToolbarButton
      icon="fal fa-check"
      labelKey="ACTIONS.ACTIVATE"
      (onClick)="activateAllSelected()"></ng-template>
    <ng-template
      appToolbarButton
      icon="fal fa-minus"
      labelKey="ACTIONS.DEACTIVATE"
      (onClick)="deactivateAllSelected()"></ng-template>
    <ng-template
      appToolbarButton
      icon="fal fa-plus"
      labelKey="ACTIONS.ADD_MISSING_ORGANISATIONS"
      (onClick)="addMissingOrganisations()"></ng-template>
    <ng-template
      appActionButton
      icon="fal fa-search"
      labelKey="GLOBAL.DETAILS"
      displayIf="organisationId"
      (onClick)="showDialog($event.row)"></ng-template>
  </app-table>
}
