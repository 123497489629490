import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { AuthorizationService } from '@service/authorization.service';

export class Permission {
  constructor(
    public role: 'RodixAdmin' | 'Manager' | 'ManagerAndSoftwarepartner' | 'ManagerAndSoftwarepartnerAndKISPartner'
  ) {}
}

export class AuthRole {
  public static RODIX_ADMIN = new Permission('RodixAdmin');
  public static MANAGER = new Permission('Manager');
  public static MANAGER_AND_SOFTWAREPARTNER = new Permission('ManagerAndSoftwarepartner');
  public static MANAGER_AND_SOFTWAREPARTNER_AND_KIS_PARTNER = new Permission('ManagerAndSoftwarepartnerAndKISPartner');
}

export const AuthGuard: CanActivateFn = (route) => {
  const router = inject(Router);
  const authorizationService = inject(AuthorizationService);
  if (route.data.role === AuthRole.RODIX_ADMIN.role && authorizationService.isRodixAdmin()) {
    return true;
  }
  if (route.data.role === AuthRole.MANAGER.role && authorizationService.isManager()) {
    return true;
  }
  if (
    route.data.role === AuthRole.MANAGER_AND_SOFTWAREPARTNER.role &&
    (authorizationService.isManager() || authorizationService.isSoftwarepartner())
  ) {
    return true;
  }
  if (
    route.data.role === AuthRole.MANAGER_AND_SOFTWAREPARTNER_AND_KIS_PARTNER.role &&
    (authorizationService.isManager() ||
      authorizationService.isSoftwarepartner() ||
      authorizationService.isKISPartner())
  ) {
    return true;
  }

  void router.navigate(['/dashboard']);
  return false;
};
