export const configTemplate = `
$schema: https://tacs2.ch/.well-known/json-schema/exporter/v2.json
# Currently only version 2 is supported
version: 2
# The configuration section
config:
  # If set to \`true\` error reporting to sentry is disabled
  DisableErrorReporting: false
  # The directory in which files are put after they have been uploaded
  DoneDirectoryPath: C:\\work\\done
  # How long files are kept in the done directory before they are removed
  DoneDirectoryRetentionPeriod: 30:00:00:00
  # How ofter the service should check for tiles to be removed
  DoneDirectoryCleanupTimeout: 01:00:00
  # The directory to which log files are written
  LogDirectoryPath: C:\\work\\log
  # The directory in which the services looks for new changes
  WatchDirectoryPath: C:\\work\\watch
  # How often the service looks for changes.
  # This is a backup method, because the service should detect
  # file changes immediately.
  WatchDirectoryPollTimeout: 00:10:00
  # In what order to read the files.
  # Supported values are "Filename" and "CreationTime"
  WatchDirectorySortAttribute: Filename
  # The directory to which files are moved which are currently
  # being uploaded to the server.
  WorkDirectoryPath: C:\\work\\work
  # This section configures the credentials to authenticate to
  # the remote server.
  OAuth:
    # The URL of the server to authenticate against
    AccessTokenEndpointUrl: https://login.tacs2.ch/auth/realms/tacs/protocol/openid-connect/token
    # The ClientId as provided by Rodix
    ClientId: {{clientId}}
    # The ClientSecret as provided by Rodix
    ClientSecret: {{clientSecret}}
    # In case a proxy server must be used, it can be configured
    # in this following section
    Http: &http
      # Whether to use a proxy server or not.
      # Supported values are "true" and "false".
      UseProxy: false
      # The url of the proxy server.
      # E.g. "https://proxy.corp.ch:8433"
      ProxyUrl: ''
      # The username to use when authenticating with the proxy.
      # Leave it empty ('') if it is not required.
      ProxyUserName: ''
      # The password to use when authenticating with the proxy.
      # Leave it empty ('') if it is not required.
      ProxyPassword: ''
  # This section configures the connection to the upload endpoint.
  Upload:
    # The URL of the service that accepts the uploaded files.
    TacsUploadEndpointBaseUrl: https://upload.tacs2.ch/api/
    # In case a proxy server must be used, it can be configured
    # in this following section.
    Http: *http`;
