<p-dropdown
  appScrollIntoView
  (onChange)="loadYear()"
  [(ngModel)]="year"
  [options]="years"
  [style]="{ width: '100%', 'margin-bottom': '1rem' }"></p-dropdown>

<div class="calendar">
  @for (month of calendar.months; track month; let i = $index) {
    <div class="month">
      <h5>{{ 'PRIMENG.monthNames.' + i | translate }}</h5>
      <table>
        <tr>
          @for (day of month.weeks[0].days; track day) {
            <th>{{ 'PRIMENG.dayNamesMin.' + day.getDay() | translate }}</th>
          }
        </tr>
        @for (week of month.weeks; track week) {
          <tr>
            @for (day of week.days; track day) {
              <td
                (click)="clickDay(day)"
                [class.out]="day.getMonth() !== i"
                [class.selected]="isSelected(day)"
                [class.changed]="isChanged(day)">
                {{ day.getDate() }}
              </td>
            }
          </tr>
        }
      </table>
    </div>
  }
</div>

<p-button
  icon="fal fa-save"
  (onClick)="save()"
  [disabled]="changed.length === 0"
  [label]="'ACTIONS.SAVE' | translate"
  [style]="{ 'margin-right': '0.5rem' }"></p-button>
<p-button
  icon="fal fa-times"
  styleClass="p-button-secondary"
  (onClick)="cancel()"
  [label]="'ACTIONS.CANCEL' | translate"></p-button>
