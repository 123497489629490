<app-form-element [control]="formControl" [id]="id" [label]="label" [required]="required" [twoColumns]="twoColumns">
  @if (!textArea) {
    <input pInputText type="text" [formControl]="formControl" [id]="id" [maxLength]="maxLength" />
  }

  @if (textArea) {
    <textarea
      pInputTextarea
      [autoResize]="true"
      [formControl]="formControl"
      [id]="id"
      [maxLength]="maxLength"
      [rows]="1"
      [style]="{ width: '100%' }"></textarea>
  }
</app-form-element>
