import { Directive, EventEmitter, Input, Output } from '@angular/core';

@Directive({
  selector: '[appToolbarButton]',
  standalone: true,
})
export class ToolbarButtonDirective {
  @Input()
  labelKey = '';

  @Input()
  icon = '';

  @Input()
  disabled = false;

  @Output()
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  onClick = new EventEmitter();
}
