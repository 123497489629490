import { Injectable } from '@angular/core';
import { Employee } from '@entity/employee';
import { ContextService } from '@service/context.service';
import { Observable } from 'rxjs';
import { TreeNode } from 'primeng/api';
import { Employment } from '@entity/employment';
import { TableService } from '@shared/table/table-service';
import { map } from 'rxjs/operators';
import { EmployeeService } from '@service/employee.service';
import { AnyValue } from '@shared/table/table.types';
import { TreeTableLazyLoadEvent } from 'primeng/treetable';

@Injectable()
export class EmployeesTreeTableService extends TableService<TreeNode> {
  constructor(
    private employeeService: EmployeeService,
    private contextService: ContextService
  ) {
    super();
  }

  private static toTreeNode(employee: Employee): TreeNode {
    return {
      data: {
        ...employee,
        employments: null,
      },
      children: employee.employments.map((employment) => ({
        data: {
          ...employment,
        },
      })),
    };
  }

  private static toEntity(treeNode: TreeNode): Employee {
    return {
      ...treeNode.data,
      employments: treeNode.children?.map(
        (employment: AnyValue) =>
          ({
            ...employment.data,
          }) as Employment
      ),
    } as Employee;
  }

  getData(
    focusDateOrYear?: Date | number,
    treeTableLazyLoadEvent?: TreeTableLazyLoadEvent
  ): Observable<{
    entities: TreeNode[];
  }> {
    return this.employeeService
      .list(this.contextService.getMandantIdInstant(), undefined, treeTableLazyLoadEvent)
      .pipe(
        map((result) => ({ entities: result.entities.map(EmployeesTreeTableService.toTreeNode), total: result.total }))
      );
  }

  create(e: TreeNode): Observable<TreeNode> {
    return this.employeeService
      .create(this.contextService.getMandantIdInstant(), EmployeesTreeTableService.toEntity(e))
      .pipe(map(EmployeesTreeTableService.toTreeNode));
  }

  remove(e: TreeNode): Observable<unknown> {
    const employee = EmployeesTreeTableService.toEntity(e);
    return this.employeeService.delete(this.contextService.getMandantIdInstant(), employee.employeeId);
  }

  update(e: TreeNode): Observable<TreeNode> {
    const employee = EmployeesTreeTableService.toEntity(e);
    return this.employeeService
      .update(this.contextService.getMandantIdInstant(), employee.employeeId, employee)
      .pipe(map(EmployeesTreeTableService.toTreeNode));
  }

  createEntry(tree: TreeNode[], parent?: TreeNode): TreeNode {
    if (!parent) {
      const employeeId = this.getNextId(tree, 'employeeId');
      return {
        data: {
          employeeId,
        },
        expanded: true,
        children: [
          {
            data: {
              employmentId: 1,
            },
          },
        ],
      };
    } else {
      return {
        data: {
          employmentId: this.getNextId(parent.children ?? [], 'employmentId'),
        },
        children: [],
      };
    }
  }
}
