<app-form-element [control]="formControl" [id]="id" [label]="label" [required]="required" [twoColumns]="twoColumns">
  <p-inputNumber
    (onFocus)="onFocus.emit($event)"
    (onInput)="onInput($event)"
    [formControl]="formControl"
    [locale]="'de-CH'"
    [maxFractionDigits]="maxFractionDigits"
    [max]="max"
    [minFractionDigits]="minFractionDigits"
    [min]="min"
    [mode]="'decimal'"
    [showButtons]="false"
    [suffix]="suffix"
    [useGrouping]="false"></p-inputNumber>
</app-form-element>
