import { ChangeDetectionStrategy, ChangeDetectorRef, Component, effect, signal } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { DatePipe, NgClass, NgStyle } from '@angular/common';
import { DialogModule } from 'primeng/dialog';
import { FormElementComponent } from '@shared/form-element/form-element.component';
import { InputGroupAddonModule } from 'primeng/inputgroupaddon';
import { InputGroupModule } from 'primeng/inputgroup';
import { InputTextModule } from 'primeng/inputtext';
import { TableModule } from 'primeng/table';
import { TranslateModule } from '@ngx-translate/core';
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { PaginatorModule } from 'primeng/paginator';
import { ScrollIntoViewDirective } from '@shared/scroll-into-view.directive';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { AbstractFormElement } from '@shared/abstract-form-element';

@Component({
  selector: 'app-snomed-ct-detail-form-element',
  standalone: true,
  imports: [
    ButtonModule,
    CalendarModule,
    DropdownModule,
    FormElementComponent,
    InputTextModule,
    PaginatorModule,
    ScrollIntoViewDirective,
    OverlayPanelModule,
    TableModule,
    DialogModule,
    TranslateModule,
    DatePipe,
    InputGroupAddonModule,
    InputGroupModule,
    NgClass,
    NgStyle,
  ],
  templateUrl: './snomed-ct-detail-form-element.component.html',
  styleUrl: './snomed-ct-detail-form-element.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SnomedCtDetailFormElementComponent extends AbstractFormElement<SnomedCtItem[]> {
  values = signal<SnomedCtItem[]>([]);

  constructor(c: ChangeDetectorRef) {
    super(c);
    effect(() => {
      const values = this.values();
      const invalid = values.some((v) => v.identifier == undefined);
      if (invalid) {
        this.formControl.setErrors({ Invalid: true });
      } else {
        this.formControl.setErrors({});
        this.formControl.setValue(values);
      }
    });
  }

  // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
  ngOnChanges(): void {
    super.ngOnChanges();
    if (this.formControl) {
      this.values.set(this.formControl.value);
      this.values().forEach((v, i) => {
        this.updateItemId(i, v.identifier);
        this.updateTitle(i, v.title);
      });
    }
  }

  add(index: number | undefined = undefined): void {
    this.values.update((values) => {
      const newValues = [...values];
      newValues.splice((index ?? values.length - 1) + 1, 0, {});
      return newValues;
    });
  }

  remove(index: number): void {
    this.values.update((values) => {
      const newValues = [...values];
      newValues.splice(index, 1);
      return newValues;
    });
  }

  updateItemId(index: number, value?: number): void {
    this.values.update((values) => {
      values[index].identifier = value;
      return [...values];
    });
  }

  updateTitle(index: number, value?: string): void {
    this.values.update((values) => {
      values[index].title = value;
      return [...values];
    });
  }
}

export interface SnomedCtItem {
  id?: number;
  identifier?: number;
  title?: string;
}
