import { ChangeDetectionStrategy, Component, computed, Inject, Injector, OnInit } from '@angular/core';
import { AbstractContentComponent } from '../abstract-content.component';
import { Context } from '@service/context.service';
import { Column } from '@shared/table/column';
import { TABLE_SERVICE } from '@shared/table/table-service';
import {
  CalculatedValue,
  CalculatedValueKey,
  CalculatedValues,
  HoursKey,
  MandantKey,
  OrganizationCategoryGroupKey,
  OrganizationCategoryKey,
  OrganizationKey,
  PercentageKey,
  TimeBudgetTableService,
  TimeBudgetType,
  TimeBudgetUnit,
} from './time-budget-table.service';
import { HeaderGroup } from '@shared/table/abstract-table.component';
import { TimeBudgetNode, TimeBudgetOrganizationCategoryGroup, TimeBudgetTree } from '@entity/time-budget';
import { SharedModule, TreeNode } from 'primeng/api';
import { TimeBudgetService } from '@service/time-budget.service';
import { Workbook, Worksheet } from 'exceljs';
import { downloadBlob } from '../download';
import { TranslateModule } from '@ngx-translate/core';
import { MessageModule } from 'primeng/message';

import { TreeModule } from 'primeng/tree';
import { ButtonModule } from 'primeng/button';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ToolbarButtonDirective } from '@shared/table/toolbar-button.directive';
import { TreeTableComponent } from '@shared/table/tree-table.component';
import { AnyValue } from '@shared/table/table.types';

@Component({
  selector: 'app-time-budgets',
  templateUrl: './time-budgets.component.html',
  styleUrls: ['./time-budgets.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: TABLE_SERVICE,
      useClass: TimeBudgetTableService,
    },
  ],
  standalone: true,
  imports: [
    TreeTableComponent,
    ToolbarButtonDirective,
    OverlayPanelModule,
    SharedModule,
    ButtonModule,
    TreeModule,
    MessageModule,
    TranslateModule,
  ],
})
export class TimeBudgetsComponent extends AbstractContentComponent implements OnInit {
  focusYear = new Date().getFullYear();
  columns: Column[] = [];
  headerGroups: HeaderGroup[][] = [];
  visibleColumns: TreeNode[] = [];
  maxColumns = 10;
  columnTree?: TreeNode[];

  private timeBudgetTree?: TimeBudgetTree;
  private calculatedValues?: CalculatedValues;

  constructor(
    injector: Injector,
    @Inject(TABLE_SERVICE) private timeBudgetTableService: TimeBudgetTableService,
    private timeBudgetService: TimeBudgetService
  ) {
    super(injector, Context.Mandant, 'MENU.TIME_BUDGET');
  }

  private static determineParentTacsId(tacsId: string, sortedTacsIds: string[]): string {
    let parentTacsId = tacsId;
    do {
      parentTacsId = parentTacsId.substring(0, parentTacsId.lastIndexOf('.'));
    } while (parentTacsId.length > 0 && !sortedTacsIds.includes(parentTacsId));
    return parentTacsId;
  }

  private static sum(field: TimeBudgetUnit, numbers?: Map<string, CalculatedValue> | undefined): number {
    if (!numbers) {
      return 0;
    }
    const sortedTacsIds = [...numbers.keys()].sort((a, b) => b.split('.').length - a.split('.').length);

    const map = new Map<string, number>();
    sortedTacsIds.forEach((tacsId) => {
      if (tacsId !== '1') {
        const value = (numbers.get(tacsId) ?? {})[field] ?? 0;
        const parentTacsId = this.determineParentTacsId(tacsId, sortedTacsIds);
        map.set(parentTacsId, (map.get(parentTacsId) ?? 0) + Math.max(map.get(tacsId) ?? 0, value));
      }
    });

    return map.get('') ?? 0;
  }

  private static round(percentage: number): number {
    const roundedPercentage = Math.round(percentage * 100) / 100;
    if (Object.is(roundedPercentage, -0)) {
      return 0;
    }
    return roundedPercentage;
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.subscriptions.push(
      this.timeBudgetTableService.onDataLoaded().subscribe((data) => {
        this.timeBudgetTree = data.timeBudgetTree;
        this.calculatedValues = data.calculatedValues;
        if (!this.columnTree) {
          this.columnTree = this.createColumnTree();
          this.visibleColumns = this.flattenTree(this.columnTree);
        } else {
          this.visibleColumns = this.flattenTree(this.columnTree).filter((c) =>
            this.visibleColumns.find((vc) => vc.key === c.key)
          );
        }
        this.updateColumns();
        this.markForCheck();
      })
    );
  }

  load(): void {
    this.updateColumns();
  }

  flattenTree(tree?: TreeNode[]): TreeNode[] {
    const nodes: TreeNode[] = [];
    tree?.forEach((node) => {
      nodes.push(node);
      if (node.children) {
        nodes.push(...this.flattenTree(node.children));
      }
    });
    return nodes;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  focusYearChange(event: AnyValue): void {
    this.columnTree = undefined;
    setTimeout(() => this.timeBudgetTableService.reload());
  }

  importFromPreviousYear(): void {
    this.showLoadingDialog();
    this.timeBudgetService.importTimeBudgetFromPreviousYear(this.getMandantId(), this.focusYear).subscribe(() => {
      this.hideLoadingDialog();
      this.timeBudgetTableService.reload();
    });
  }

  csvExport(): void {
    const organisations =
      this.timeBudgetTree?.organizationCategoryGroups.flatMap((g) =>
        g.organizationCategories.flatMap((c) => c.organizations)
      ) ?? [];
    const organisationIds: { [id: number]: string } = {};
    organisations.forEach((oe) => (organisationIds[oe.id] = oe.code));

    const workbook: Workbook = new Workbook();
    const worksheet: Worksheet = workbook.addWorksheet('Data');
    worksheet.addRow(['OrganisationId', 'TacsId', 'ZeitwertMin', 'ZeitwertProzent']);
    this.calculatedValues?.organization.forEach((value, oeId) => {
      const organisationId = organisationIds[oeId];
      for (const tacsId of [...value.keys()].reverse()) {
        const precision = 1000000;
        const v = value.get(tacsId);
        if (v?.hours) {
          worksheet.addRow([
            organisationId,
            tacsId,
            Math.round(v.hours * 60 * precision) / precision,
            Math.round(v.percentage * 100 * precision) / precision,
          ]);
        }
      }
    });

    workbook.csv.writeBuffer({ formatterOptions: { delimiter: ';' } }).then((data) => {
      const blob = new Blob([data], { type: 'text/csv' });
      downloadBlob(blob, `Zeitbudget.csv`);
    });
  }

  protected onMandantIdChange(id: number | null): void {
    if (id) {
      this.columnTree = undefined;
      this.timeBudgetTableService.reload();
    }
  }

  private createColumnTree(): TreeNode[] {
    return (
      this.timeBudgetTree?.organizationCategoryGroups.map((organizationCategoryGroup) => ({
        label: organizationCategoryGroup.name,
        key: `${OrganizationCategoryGroupKey}_${organizationCategoryGroup.id}`,
        children: organizationCategoryGroup.organizationCategories.map((organizationCategory) => ({
          label: organizationCategory.name,
          key: `${OrganizationCategoryKey}_${organizationCategory.id}`,
          children: organizationCategory.organizations.map((organization) => ({
            label: organization.name,
            key: `${OrganizationKey}_${organization.id}`,
          })),
        })),
      })) ?? []
    );
  }

  private updateColumns(): void {
    if (!this.timeBudgetTree || !this.calculatedValues) {
      return;
    }

    const calculatedValues = this.calculatedValues;
    this.columns = [
      {
        field: 'tacsIdAndName',
        minWidth: '350px',
        headerKey: 'GLOBAL.ID',
        editable: false,
        footerKey: 'GLOBAL.AVAILABLE',
      },
    ];
    this.headerGroups = [];
    const organizationCategoryGroupHeaders: HeaderGroup[] = [];
    const organizationCategoryHeaders: HeaderGroup[] = [];
    const organisationHeaders: HeaderGroup[] = [];
    let mandantColSpan = 2;

    this.addColumn(this.timeBudgetTree, calculatedValues, MandantKey);
    organizationCategoryGroupHeaders.push({ title: '', colSpan: 1 }, { title: '', colSpan: 2 });
    organizationCategoryHeaders.push({ title: '', colSpan: 1 }, { title: '', colSpan: 2 });
    organisationHeaders.push({ title: '', colSpan: 1 }, { title: '', colSpan: 2 });

    const filtered = this.getFilteredOrganizationCategoryGroups();

    filtered.forEach((organizationCategoryGroup) => {
      let organizationCategoryGroupColSpan = 0;

      if (!organizationCategoryGroup.hidden) {
        organizationCategoryGroupColSpan = 2;
        this.addColumn(organizationCategoryGroup, calculatedValues, OrganizationCategoryGroupKey);
        organizationCategoryHeaders.push({ title: '', colSpan: 2 });
        organisationHeaders.push({ title: '', colSpan: 2 });
      }

      organizationCategoryGroup.organizationCategories.forEach((organizationCategory) => {
        if (!organizationCategory.hidden) {
          this.addColumn(organizationCategory, calculatedValues, OrganizationCategoryKey);
          organisationHeaders.push({ title: '', colSpan: 2 });
        }

        organizationCategoryHeaders.push({
          title: organizationCategory.name,
          colSpan: (organizationCategory.hidden ? 0 : 2) + 2 * organizationCategory.organizations.length,
        });
        organizationCategoryGroupColSpan +=
          (organizationCategory.hidden ? 0 : 2) + 2 * organizationCategory.organizations.length;

        organizationCategory.organizations.forEach((organization) => {
          organisationHeaders.push({ title: organization.name, colSpan: 2 });

          this.addColumn(organization, calculatedValues, OrganizationKey);
        });
      });
      organizationCategoryGroupHeaders.push({
        title: organizationCategoryGroup.name,
        colSpan: organizationCategoryGroupColSpan,
      });
      mandantColSpan += organizationCategoryGroupColSpan;
    });
    this.headerGroups.push([
      { title: '', colSpan: 1 },
      { title: this.timeBudgetTree.name, colSpan: mandantColSpan },
    ]);
    if (organizationCategoryGroupHeaders.filter((o) => o.title !== '').length > 0) {
      this.headerGroups.push(organizationCategoryGroupHeaders);
    }
    if (organizationCategoryHeaders.filter((o) => o.title !== '').length > 0) {
      this.headerGroups.push(organizationCategoryHeaders);
    }
    if (organisationHeaders.filter((o) => o.title !== '').length > 0) {
      this.headerGroups.push(organisationHeaders);
    }
  }

  private addColumn(timeBudgetTree: TimeBudgetNode, calculatedValues: CalculatedValues, type: TimeBudgetType): void {
    let hours =
      timeBudgetTree.availableTotalHours -
      TimeBudgetsComponent.sum(HoursKey, calculatedValues[type].get(timeBudgetTree.id));
    hours = TimeBudgetsComponent.round(hours);
    this.columns.push({
      field: `${type}_${HoursKey}_${timeBudgetTree.id}`,
      headerKey: 'TIME_BUDGETS.HOURS',
      minWidth: '120px',
      filterType: 'number',
      editable: true,
      fastEdit: true,
      footer: computed(() =>
        new Intl.NumberFormat('de-CH', {
          style: 'decimal',
          minimumFractionDigits: 0,
          maximumFractionDigits: 2,
        }).format(hours)
      ),
      footerColor: hours < 0 ? 'rgba(230,0,60,1)' : undefined,
      validator: (value, row) => {
        if (row.data[`${type}_${CalculatedValueKey}_${timeBudgetTree.id}`]?.invalid) {
          return 'Invalid';
        }
        return undefined;
      },
    });

    let percentage = 1 - TimeBudgetsComponent.sum(PercentageKey, calculatedValues[type].get(timeBudgetTree.id));
    percentage = TimeBudgetsComponent.round(percentage);
    this.columns.push({
      field: `${type}_${PercentageKey}_${timeBudgetTree.id}`,
      header: '%',
      minWidth: '120px',
      filterType: 'percent',
      editable: true,
      fastEdit: true,
      footer: computed(() =>
        new Intl.NumberFormat('de-CH', {
          style: 'percent',
          minimumFractionDigits: 0,
          maximumFractionDigits: 2,
        }).format(percentage)
      ),
      footerColor: percentage < 0 ? 'rgba(230,0,60,1)' : undefined,
      validator: (value, row) => {
        if (row.data[`${type}_${CalculatedValueKey}_${timeBudgetTree.id}`]?.invalid) {
          return 'Invalid';
        }
        return undefined;
      },
    });
  }

  private getFilteredOrganizationCategoryGroups(): TimeBudgetOrganizationCategoryGroup[] {
    const visibleColumnKeys = this.visibleColumns.map((v) => v.key);

    return (
      this.timeBudgetTree?.organizationCategoryGroups
        .map((ocg) => ({
          ...ocg,
          hidden: !visibleColumnKeys.includes(`${OrganizationCategoryGroupKey}_${ocg.id}`),
          organizationCategories: ocg.organizationCategories
            .map((oc) => ({
              ...oc,
              organizations: oc.organizations.filter((o) => visibleColumnKeys.includes(`${OrganizationKey}_${o.id}`)),
              hidden: !visibleColumnKeys.includes(`${OrganizationCategoryKey}_${oc.id}`),
            }))
            .filter(
              (oc) => oc.organizations.length > 0 || visibleColumnKeys.includes(`${OrganizationCategoryKey}_${oc.id}`)
            ),
        }))
        .filter(
          (ocg) =>
            ocg.organizationCategories.length > 0 ||
            visibleColumnKeys.includes(`${OrganizationCategoryGroupKey}_${ocg.id}`)
        ) ?? []
    );
  }
}
