import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TimeBudgetTree } from '@entity/time-budget';
import { HttpClient, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class TimeBudgetService {
  private readonly baseUrl = `api/1.0/mandanten`;

  constructor(private httpClient: HttpClient) {}

  private static getParams(focusYear: number): HttpParams {
    return new HttpParams().append('focusYear', focusYear);
  }

  getTimeBudgets(mandantId: number, focusYear: number): Observable<TimeBudgetTree> {
    const params = TimeBudgetService.getParams(focusYear);
    return this.httpClient.get<TimeBudgetTree>(`${this.baseUrl}/${mandantId}/time-budgets`, { params });
  }

  updateTimeBudgets(mandantId: number, focusYear: number, timeBudgetTree: TimeBudgetTree): Observable<TimeBudgetTree> {
    const params = TimeBudgetService.getParams(focusYear);
    return this.httpClient.put<TimeBudgetTree>(`${this.baseUrl}/${mandantId}/time-budgets`, timeBudgetTree, { params });
  }

  importTimeBudgetFromPreviousYear(mandantId: number, focusYear: number): Observable<TimeBudgetTree> {
    const params = TimeBudgetService.getParams(focusYear);
    return this.httpClient.post<TimeBudgetTree>(
      `${this.baseUrl}/${mandantId}/time-budgets/import-from-previous-year`,
      {},
      { params }
    );
  }
}
