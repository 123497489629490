<p-card [header]="title">
  <form (ngSubmit)="onSubmit()" [formGroup]="form" novalidate>
    <div class="grid">
      @if (organisation.id) {
        <app-form-text-input [form]="form" [twoColumns]="true" id="organisationId" label="GLOBAL.ID" />
      }
    </div>
    <div class="grid">
      <app-form-text-input [form]="form" [twoColumns]="true" id="bezeichnung" label="GLOBAL.NAME" />
      <app-form-text-input
        [form]="form"
        [twoColumns]="true"
        id="kostenstelle"
        label="ORGANISATIONAL_UNITS.COST_CENTER" />
      <app-form-text-input [form]="form" [maxLength]="10" [twoColumns]="true" id="kuerzel" label="GLOBAL.ACRONYM" />
      <app-form-date-input [form]="form" [twoColumns]="true" id="benchmarkAb" label="MANDANTS.BENCHMARK_AB" />
      <app-form-date-input [form]="form" [twoColumns]="true" id="gueltigAb" label="GLOBAL.GUELTIG_AB" />
      <app-form-date-input [form]="form" [twoColumns]="true" id="gueltigBis" label="GLOBAL.GUELTIG_BIS" />

      <app-form-dropdown
        [form]="form"
        [options]="erfassungstypOptions"
        [twoColumns]="true"
        id="erfassungstyp"
        label="ORGANISATIONAL_UNITS.ERFASSUNGSTYP" />

      <app-form-switch [form]="form" [twoColumns]="true" id="sabEnabled" label="ORGANISATIONAL_UNITS.SAB_ENABLED" />

      <app-form-dropdown
        [form]="form"
        [options]="organisationalUnitTypeOptions"
        [twoColumns]="true"
        id="organisationalUnitType"
        label="ORGANISATIONAL_UNITS.ORGANISATIONAL_UNIT_TYPE" />

      <app-form-switch
        [form]="form"
        [twoColumns]="true"
        id="creationOfCustomerEnabled"
        label="ORGANISATIONAL_UNITS.CREATION_OF_CUSTOMER_ENABLED" />

      <app-form-time-period
        [form]="form"
        [options]="tacsCodeKreisOptions"
        [twoColumns]="false"
        id="kategorieSets"
        label="ORGANISATIONAL_UNITS.CATEGORY_SET" />

      <app-form-number-input [form]="form" [twoColumns]="false" id="sortierNr" label="GLOBAL.SORT_NUMBER" />

      <app-form-button-bar [form]="form" [saveDisabled]="submitting"></app-form-button-bar>
    </div>
  </form>
</p-card>
