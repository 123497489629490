import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ServiceAccount } from '@entity/service-account';
import { ClientSecret } from '@entity/client-secret';
import { ServiceAccountEnabledUpdate } from '@entity/service-account-enabled-update';

@Injectable({
  providedIn: 'root',
})
export class ServiceAccountService {
  constructor(private httpClient: HttpClient) {}

  getServiceAccounts(): Observable<ServiceAccount[]> {
    return this.httpClient.get<ServiceAccount[]>('api/1.0/serviceaccounts');
  }

  getServiceAccountClientSecret(clientId: string): Observable<ClientSecret> {
    return this.httpClient.get<ClientSecret>(`api/1.0/serviceaccounts/${clientId}/clientsecret`);
  }

  createServiceAccount(clientId: string, name: string, hospitalInformationSystem: boolean): Observable<ServiceAccount> {
    return this.httpClient.post<ServiceAccount>('api/1.0/serviceaccounts', {
      clientId,
      enabled: true,
      hospitalInformationSystem: hospitalInformationSystem,
      name,
    } as ServiceAccount);
  }

  deleteServiceAccount(serviceAccount: ServiceAccount): Observable<unknown> {
    return this.httpClient.delete(`api/1.0/serviceaccounts/${serviceAccount.id}`);
  }

  resetClientSecret(serviceAccount: ServiceAccount): Observable<unknown> {
    return this.httpClient.post(`api/1.0/serviceaccounts/${serviceAccount.id}/reset-client-secret`, {});
  }

  deactivateServiceAccount(serviceAccount: ServiceAccount): Observable<unknown> {
    return this.httpClient.put(`api/1.0/serviceaccounts/${serviceAccount.id}`, {
      enabled: false,
    } as ServiceAccountEnabledUpdate);
  }

  activateServiceAccount(serviceAccount: ServiceAccount): Observable<unknown> {
    return this.httpClient.put(`api/1.0/serviceaccounts/${serviceAccount.id}`, {
      enabled: true,
    } as ServiceAccountEnabledUpdate);
  }
}
