import { TableService } from '@shared/table/table-service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ContextService } from '@service/context.service';
import { TreeNode } from 'primeng/api';
import { map } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { CatalogDetail, CatalogGroup } from '@entity/catalog';
import { MainDataService } from '@service/main-data.service';
import { Column } from '@shared/table/column';
import { CatalogService } from '@service/catalog.service';

@Injectable()
export class MappingCatalogDetailTableService extends TableService<TreeNode> {
  constructor(
    private catalogService: CatalogService,
    private mainDataService: MainDataService,
    private contextService: ContextService,
    private activatedRoute: ActivatedRoute
  ) {
    super();
  }

  createEntry(): TreeNode {
    return {
      children: [],
      data: {
        deletable: true,
        mappedValues: {},
      },
    } as TreeNode;
  }

  getData(): Observable<{ entities: TreeNode[] }> {
    if (this.activatedRoute.snapshot.data.isMandantSection) {
      return this.mainDataService
        .getCatalogGroups(this.activatedRoute.snapshot.params.id)
        .pipe(map((result) => this.transform(result)))
        .pipe(map((entities) => ({ entities })));
    } else {
      return this.catalogService
        .getCatalogGroups(this.contextService.getMandantIdInstant(), this.activatedRoute.snapshot.params.id)
        .pipe(map((result) => this.transform(result)))
        .pipe(map((entities) => ({ entities })));
    }
  }

  isEditable(e: TreeNode, column: Column): boolean {
    return e.data.detail || column.field === 'id' || column.field === 'name';
  }

  isBatchUpdateSupported(): boolean {
    return true;
  }

  batchUpdate(value: TreeNode[]): Observable<TreeNode[]> {
    const catalogGroups = value.map(
      (v) =>
        ({
          id: v.data.id,
          name: v.data.name,
          dbId: v.data.dbId,
          catalogDetails: v.children?.map(
            (c) =>
              ({
                id: c.data.id,
                name: c.data.name,
                dbId: c.data.dbId,
                active: c.data.active,
                mappingIds: Object.keys(c.data.mappedValues)
                  .filter((key) => c.data.mappedValues[key])
                  .map((id) => id.replace(/_/g, '.')),
              }) as CatalogDetail
          ),
        }) as CatalogGroup
    );

    if (this.activatedRoute.snapshot.data.isMandantSection) {
      return this.mainDataService
        .updateCatalogGroups(this.activatedRoute.snapshot.params.id, catalogGroups)
        .pipe(map((result) => this.transform(result)));
    } else {
      return this.catalogService
        .updateCatalogGroups(
          this.contextService.getMandantIdInstant(),
          this.activatedRoute.snapshot.params.id,
          catalogGroups
        )
        .pipe(map((result) => this.transform(result)));
    }
  }

  private transform(result: CatalogGroup[]): TreeNode[] {
    return result.map(
      (c) =>
        ({
          data: {
            ...c,
            deletable: this.activatedRoute.snapshot.data.isMandantSection ?? false,
          },
          expanded: true,
          children: c.catalogDetails.map((cd) => ({
            data: {
              ...cd,
              deletable: this.activatedRoute.snapshot.data.isMandantSection ?? false,
              mappedValues:
                cd.mappingIds.filter((m) => !!m).length > 0
                  ? cd.mappingIds
                      .filter((m) => !!m)
                      .reduce(
                        (obj, item) => ({
                          ...obj,
                          [item.replace(/\./g, '_')]: true,
                        }),
                        {}
                      )
                  : {},
              detail: true,
            },
          })),
        }) as TreeNode
    );
  }
}
