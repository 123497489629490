import { ChangeDetectionStrategy, Component, Injector, OnInit } from '@angular/core';
import { AbstractContentComponent } from '../abstract-content.component';
import { Context } from '@service/context.service';
import { Market } from '@entity/market';
import { Column } from '@shared/table/column';
import { MarketService } from '@service/market.service';
import { ActionButtonDirective } from '@shared/table/action-button.directive';
import { ToolbarButtonDirective } from '@shared/table/toolbar-button.directive';
import { TableComponent } from '@shared/table/table.component';

@Component({
  selector: 'app-markets',
  templateUrl: './markets.component.html',
  styleUrls: ['./markets.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TableComponent, ToolbarButtonDirective, ActionButtonDirective],
})
export class MarketsComponent extends AbstractContentComponent implements OnInit {
  markets: Market[] = [];
  columns: Column[] = [];

  constructor(
    private marketService: MarketService,
    injector: Injector
  ) {
    super(injector, Context.Global, 'MENU.MARKETS');
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.setColumns();
    this.loadMarkets();
  }

  loadMarkets(): void {
    this.startLoading();
    this.marketService.getMarkets().subscribe((data) => {
      this.markets = data;
      this.markForCheck();
      this.loadingDone();
    });
  }

  onLanguageChange(): void {
    this.setColumns();
    this.markForCheck();
  }

  createNewMarket(): void {
    this.navigate(['/markets/new']);
  }

  deleteMarket(market: Market): void {
    this.confirm({
      header: this.translate('MARKETS.DELETE_HEADER'),
      message: this.translate('MARKETS.DELETE_MESSAGE', market),
      accept: () => {
        this.marketService.deleteMarket(market.id).subscribe({
          next: () => {
            this.showSuccessMessage(this.translate('MARKETS.DELETE_SUCCESSFULLY', market));
            this.loadMarkets();
          },
          error: () => {
            this.showErrorMessage(this.translate('MARKETS.DELETE_FAILED', market));
          },
        });
      },
    });
  }

  private setColumns(): void {
    this.columns = [
      {
        field: 'names.' + this.getLanguageCaseSensitive(),
        headerKey: 'GLOBAL.NAME',
        minWidth: '300px',
        route: '/markets/${id}',
      },
      { field: 'validFrom', headerKey: 'GLOBAL.GUELTIG_AB', width: '130px', filterType: 'date' },
      { field: 'validTo', headerKey: 'GLOBAL.GUELTIG_BIS', width: '130px', filterType: 'date' },
    ];
  }
}
