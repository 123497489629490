import { Directive, OnDestroy, OnInit } from '@angular/core';
import { Dropdown } from 'primeng/dropdown';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[appScrollIntoView]',
  standalone: true,
})
export class ScrollIntoViewDirective implements OnInit, OnDestroy {
  private subscription!: Subscription;

  constructor(private dropdown: Dropdown) {}

  ngOnInit(): void {
    this.subscription = this.dropdown.onShow.asObservable().subscribe(($event) => {
      $event.element.querySelector('.p-dropdown-item.p-highlight')?.scrollIntoView();
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
