@if (form) {
  <form class="grid" novalidate (ngSubmit)="onSubmit()" [formGroup]="form">
    <div class="col-12">
      <b>{{ columnName }}:</b>
      {{ columnValidFrom | date: 'dd.MM.yyyy' }} - {{ columnValidTo | date: 'dd.MM.yyyy' }}
    </div>
    <div class="col-12">
      <b>{{ rowName }}:</b>
      {{ rowValidFrom | date: 'dd.MM.yyyy' }} - {{ rowValidTo | date: 'dd.MM.yyyy' }}
    </div>
    <app-form-time-period id="assignments" [form]="form" [maxDate]="maxDate" [minDate]="minDate"></app-form-time-period>
    @if (editable) {
      <app-form-button-bar
        (onCancel)="cancel()"
        [saveDisabled]="submitting"
        [form]="form"
        [offset]="false"></app-form-button-bar>
    }
  </form>
}
