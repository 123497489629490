<app-tree-table
  sortField="name"
  [actionColumnWidth]="450"
  [columns]="columns"
  [firstColumnFrozen]="true"
  [loading]="loading"
  (focusYearChange)="focusYearChange($event)"
  [focusYear]="focusYear"
  [hasFocusYear]="true"
  [value]="tree">
  <ng-template
    appToolbarButton
    icon="fal fa-plus"
    labelKey="ANWENDERBETRIEBE.CREATE_NEW"
    (onClick)="create()"></ng-template>
  <ng-template
    appActionButton
    icon="fal fa-trash"
    labelKey="ACTIONS.DELETE"
    type="danger"
    (onClick)="delete($event.row)"></ng-template>
  <ng-template
    appActionButton
    icon="fal fa-undo"
    labelKey="ANWENDERBETRIEBE.DELETE_DATA_ONLY"
    type="warning"
    (onClick)="deleteDataOnly($event.row)"></ng-template>
  <ng-template
    appActionButton
    displayIf="node.data.isAnwenderbetrieb"
    icon="fal fa-download"
    labelKey="ANWENDERBETRIEBE.DOWNLOAD_LICENSE_PROOF"
    (onClick)="downloadLicenseProof($event.row)"></ng-template>
</app-tree-table>
<p-dialog [closable]="false" [contentStyle]="{ width: '135px', height: '135px' }" [modal]="true" [visible]="deleting">
  <p-header translate>GLOBAL.DELETING</p-header>
  <p-progressSpinner></p-progressSpinner>
</p-dialog>
