import { ChangeDetectionStrategy, Component, Injector, OnInit } from '@angular/core';
import { ReportingService } from '@service/reporting.service';
import { Context } from '@service/context.service';
import { AbstractContentComponent } from '../../abstract-content.component';
import { LoginStatistics } from '@entity/login-statistics';
import { Column } from '@shared/table/column';
import { TableComponent } from '../../shared/table/table.component';

@Component({
  selector: 'app-login-statistics',
  templateUrl: './login-statistics.component.html',
  styleUrls: ['./login-statistics.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TableComponent],
})
export class LoginStatisticsComponent extends AbstractContentComponent implements OnInit {
  rows: LoginStatistics[] = [];

  columns: Column[] = [
    {
      headerKey: 'LOGIN_STATISTICS.ANWENDERBETRIEB',
      field: 'anwenderbetrieb',
      minWidth: '200px',
    },
    {
      headerKey: 'LOGIN_STATISTICS.FULL_NAME',
      field: 'fullName',
      width: '200px',
    },
    {
      headerKey: 'LOGIN_STATISTICS.USER_NAME',
      field: 'userName',
      width: '200px',
    },
    {
      headerKey: 'LOGIN_STATISTICS.E_MAIL',
      field: 'email',
      width: '200px',
    },
    {
      headerKey: 'LOGIN_STATISTICS.LAST_LOGIN',
      field: 'lastLogin',
      width: '200px',
      filterType: 'date',
    },
    {
      headerKey: 'LOGIN_STATISTICS.APPLICATION',
      field: 'application',
      width: '200px',
      options: [
        { label: 'Dashbaord', value: 'Dashboard' },
        { label: 'Reporting', value: 'Reporting' },
      ],
    },
    {
      headerKey: 'LOGIN_STATISTICS.LOGIN_COUNT',
      field: 'loginCount',
      width: '200px',
      filterType: 'number',
    },
  ];

  constructor(
    injector: Injector,
    private reportingService: ReportingService
  ) {
    super(injector, Context.Global, 'MENU.LOGIN_STATISTICS');
  }

  ngOnInit(): void {
    this.startLoading();
    this.reportingService.getLoginStatistics().subscribe((rows) => {
      this.rows = rows;
      this.loadingDone();
    });
  }
}
