import { ChangeDetectionStrategy, Component, Injector, OnInit } from '@angular/core';
import { AbstractContentComponent } from '../../abstract-content.component';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { Context } from '@service/context.service';
import { Beneficiary } from '@entity/beneficiary';
import { BeneficiaryService } from '@service/beneficiary.service';
import { finalize } from 'rxjs';
import { FormButtonBarComponent } from '@shared/form-button-bar/form-button-bar.component';
import { FormDateInputComponent } from '@shared/form-date-input/form-date-input.component';
import { FormTextInputComponent } from '@shared/form-text-input/form-text-input.component';
import { CardModule } from 'primeng/card';
import { FormNumberInputComponent } from '@shared/form-number-input/form-number-input.component';

@Component({
  selector: 'app-beneficiary-detail',
  templateUrl: './beneficiary-detail.component.html',
  styleUrls: ['./beneficiary-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CardModule,
    FormsModule,
    ReactiveFormsModule,
    FormTextInputComponent,
    FormDateInputComponent,
    FormButtonBarComponent,
    FormNumberInputComponent,
  ],
})
export class BeneficiaryDetailComponent extends AbstractContentComponent implements OnInit {
  public title!: string;
  public form!: FormGroup<{
    code: FormControl<string>;
    name: FormControl<string>;
    description: FormControl<string | undefined>;
    costCenter: FormControl<string>;
    sortNumber: FormControl<number | undefined>;
    validFrom: FormControl<Date>;
    validTo: FormControl<Date | undefined>;
  }>;
  submitting = false;
  private beneficiary: Beneficiary = { deletable: true } as Beneficiary;

  constructor(
    private beneficiaryService: BeneficiaryService,
    private fb: FormBuilder,
    injector: Injector
  ) {
    super(injector, Context.Global);
  }

  ngOnInit(): void {
    this.activatedRoute.data.subscribe((data) => {
      this.beneficiary = data.beneficiary ?? this.beneficiary;
    });
    this.initializeForm();
    this.onLanguageChange();
  }

  minDate(): ValidatorFn {
    return (control) => {
      if (!this.form || !control.value) {
        return null;
      }
      const valid = control.value >= this.form.getRawValue().validFrom;
      return valid ? null : { minDate: false };
    };
  }

  onSubmit(): void {
    this.submitting = true;
    const formValue = this.form.value;
    const updatedBeneficiary: Beneficiary = {
      ...this.beneficiary,
      ...formValue,
    };
    const beneficiaryObservable = this.beneficiary.id
      ? this.beneficiaryService.update(this.getMandantId(), this.beneficiary.id, updatedBeneficiary)
      : this.beneficiaryService.create(this.getMandantId(), updatedBeneficiary);
    beneficiaryObservable.pipe(finalize(() => (this.submitting = false))).subscribe({
      next: () => {
        this.showSuccessMessage();
        this.navigate(['/beneficiaries/overview']);
      },
      error: () => {
        this.showErrorMessage();
      },
    });
  }

  protected onLanguageChange(): void {
    this.title = this.beneficiary.id
      ? this.translate('BENEFICIARIES.EDIT_TITLE', this.beneficiary)
      : this.translate('BENEFICIARIES.CREATE_TITLE');

    this.setBreadcrumbs([
      { label: 'MENU.BENEFICIARY', routerLink: ['/beneficiaries/overview'] },
      { label: this.title },
    ]);
  }

  private initializeForm(): void {
    this.form = this.fb.nonNullable.group({
      code: new FormControl(
        {
          value: this.beneficiary.code,
          disabled: !this.beneficiary.deletable,
        },
        { validators: Validators.required, nonNullable: true }
      ),
      name: [this.beneficiary.name, Validators.required],
      description: this.beneficiary.description,
      costCenter: this.beneficiary.costCenter,
      validFrom: [this.beneficiary.validFrom ?? new Date(), Validators.required],
      validTo: [this.beneficiary.validTo, this.minDate()],
      sortNumber: [this.beneficiary.sortNumber],
    });
  }
}
