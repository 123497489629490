import { KategorieSet } from './mandant';

export interface Organisation {
  creationOfCustomerEnabled: boolean;
  sabEnabled: boolean;
  erfassungstyp: number;
  id: number;
  organisationId: string;
  bezeichnung: string;
  kostenstelle: string;
  gueltigAb: Date;
  gueltigBis?: Date;
  kuerzel: string;
  benchmarkFreigabe: boolean;
  benchmarkAb?: Date;
  kategorieSets: KategorieSet[];
  organisationCategoryDescription: string;
  mandantId: number;
  organisationalUnitType: OrganisationalUnitType;
  sortierNr?: number;
}

export enum OrganisationalUnitType {
  CUSTOMER = 'Customer',
  EMPLOYEE = 'Employee',
  UNSPECIFIED = 'Unspecified',
}
