import { ChangeDetectionStrategy, Component, Inject, Injector, OnInit } from '@angular/core';
import { AbstractContentComponent } from '../../abstract-content.component';
import { Context } from '@service/context.service';
import { MappedValue } from '@entity/catalog';
import { TreeNode } from 'primeng/api';
import { Column } from '@shared/table/column';
import { MappingCatalogDetailDialogComponent } from './mapping-catalog-detail-dialog/mapping-catalog-detail-dialog.component';
import { DynamicDialogRef } from 'primeng/dynamicdialog/dynamicdialog-ref';
import { TABLE_SERVICE } from '@shared/table/table-service';
import { MappingCatalogDetailTableService } from './mapping-catalog-detail-table.service';
import { downloadBlob } from '../../download';
import { CatalogService } from '@service/catalog.service';
import { MainDataService } from '@service/main-data.service';
import { ActionButtonDirective } from '@shared/table/action-button.directive';

import { ToolbarButtonDirective } from '@shared/table/toolbar-button.directive';
import { TreeTableComponent } from '@shared/table/tree-table.component';
import { AuthorizationService } from '@service/authorization.service';

@Component({
  selector: 'app-mapping-catalog-detail',
  templateUrl: './mapping-catalog-detail.component.html',
  styleUrls: ['./mapping-catalog-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: TABLE_SERVICE,
      useClass: MappingCatalogDetailTableService,
    },
  ],
  standalone: true,
  imports: [TreeTableComponent, ToolbarButtonDirective, ActionButtonDirective],
})
export class MappingCatalogDetailComponent extends AbstractContentComponent implements OnInit {
  value: TreeNode[] = [];
  columns: Column[] = [];
  additionalColumns: MappedValue[] = [];
  isCreateAllowed = false;

  constructor(
    injector: Injector,
    @Inject(TABLE_SERVICE) private mappingCatalogDetailTableService: MappingCatalogDetailTableService,
    private catalogService: CatalogService,
    private mainDataService: MainDataService,
    private authService: AuthorizationService
  ) {
    super(injector, Context.Global);
    this.setBreadcrumbs([
      {
        label: this.translate('MENU.MAPPING_CATALOGS'),
        routerLink: [
          this.activatedRoute.snapshot.data.isMandantSection ? 'admin-mapping-catalogs' : 'mapping-catalogs',
        ],
      },
      { label: this.translate('GLOBAL.DETAILS') },
    ]);
  }

  ngOnInit(): void {
    this.isCreateAllowed = this.activatedRoute.snapshot.data.isMandantSection;
    this.setColumns();
  }

  showColumnSelection(): void {
    const ref = this.showDetail(undefined, this.additionalColumns);
    ref.onClose.subscribe((result) => {
      if (result) {
        this.additionalColumns = result;
        this.setColumns();
        this.markForCheck();
      }
    });
  }

  showDetailForRow(row: TreeNode): void {
    const ref = this.showDetail(row);
    ref.onClose.subscribe((result) => {
      if (result) {
        this.mappingCatalogDetailTableService.reload();
      }
    });
  }

  showDetail(row?: TreeNode, selectedColumns?: MappedValue[]): DynamicDialogRef {
    return this.dialogService.open(MappingCatalogDetailDialogComponent, {
      modal: true,
      height: 'calc(100vh - 100px)',
      header: this.translate('MAPPING_CATALOGS.' + this.activatedRoute.snapshot.queryParams.type.toUpperCase()),
      contentStyle: {
        width: '700px',
      },
      data: {
        mandantId: this.activatedRoute.snapshot.data.isMandantSection ? null : this.getMandantId(),
        catalogId: this.activatedRoute.snapshot.params.id,
        mappingType: this.activatedRoute.snapshot.queryParams.type,
        detailId: row?.data?.dbId,
        selectedColumns,
      },
    });
  }

  resetToDefault(): void {
    this.confirm({
      header: this.translate('MAPPING_CATALOGS.RESET_TO_DEFAULT'),
      message: this.translate('MAPPING_CATALOGS.RESET_TO_DEFAULT_MESSAGE'),
      acceptLabel: this.translate('GLOBAL.YES'),
      rejectLabel: this.translate('GLOBAL.NO'),
      accept: () => {
        this.showLoadingDialog();
        this.catalogService.resetCatalogMapping(this.getMandantId(), this.activatedRoute.snapshot.params.id).subscribe({
          next: () => {
            this.hideLoadingDialog();
            this.showSuccessMessage();
            this.mappingCatalogDetailTableService.reload();
          },
          error: () => this.showErrorMessage(),
        });
      },
    });
  }

  downloadMappingCatalog(): void {
    let action;
    if (this.isCreateAllowed) {
      action = this.mainDataService.downloadMappingCatalog(this.activatedRoute.snapshot.params.id);
    } else {
      action = this.catalogService.downloadMappingCatalog(this.getMandantId(), this.activatedRoute.snapshot.params.id);
    }
    this.showLoadingDialog();
    action.subscribe((result) => {
      if (result.body) {
        downloadBlob(result.body, 'mapping.xlsx');
      }
      this.hideLoadingDialog();
    });
  }

  private setColumns(): void {
    this.columns = [
      {
        headerKey: 'GLOBAL.ID',
        field: 'id',
        width: '200px',
        editable: this.isCreateAllowed,
      },
      {
        headerKey: 'GLOBAL.NAME',
        field: 'name',
        minWidth: '200px',
        editable: this.isCreateAllowed,
      },
    ];

    if (!this.isCreateAllowed && this.authService.isRodixAdmin()) {
      this.columns.push({
        headerKey: 'GLOBAL.ACTIVE',
        field: 'active',
        width: '150px',
        filterType: 'boolean',
        editable: true,
      });
    }

    this.additionalColumns.forEach((c) => {
      this.columns.push({
        header: c.tacsId + ' ' + c.name,
        field: 'mappedValues.' + c.tacsId.replace(/\./g, '_'),
        minWidth: '200px',
        filterType: 'boolean',
        fastEdit: true,
        editable: true,
      });
    });
  }
}
