@if (editable) {
  @if (editModeEnabled && formControl) {
    <div class="p-inputgroup" pFocusTrap style="height: 34px" (keydown.enter)="save()" (keydown.escape)="cancel()">
      @switch (type) {
        @case ('date') {
          <p-calendar
            appendTo="body"
            dateFormat="dd.mm.yy"
            tooltipEvent="focus"
            tooltipPosition="top"
            tooltipStyleClass="error"
            (onBlur)="onBlur()"
            [defaultDate]="defaultDate"
            [formControl]="formControl"
            [maxDate]="maxDate"
            [minDate]="minDate"
            [pTooltip]="'ERRORS.' + error | translate"
            [showButtonBar]="true"
            [tooltipDisabled]="!error"></p-calendar>
        }
        @case ('boolean') {
          <div style="text-align: center; width: 100%; padding-top: 8px">
            <p-inputSwitch [formControl]="formControl"></p-inputSwitch>
          </div>
        }
        @case ('percent') {
          <p-inputNumber
            (onBlur)="onBlur()"
            [formControl]="formControl"
            [locale]="'de-CH'"
            [maxFractionDigits]="2"
            [max]="100"
            [minFractionDigits]="2"
            [min]="0"
            [showButtons]="false"
            [suffix]="'%'"></p-inputNumber>
        }
        @case ('time') {
          <p-calendar appendTo="body" [formControl]="formControl" [timeOnly]="true"></p-calendar>
        }
        @case ('number') {
          <p-inputNumber
            tooltipEvent="focus"
            tooltipPosition="top"
            tooltipStyleClass="error"
            (onBlur)="onBlur()"
            [formControl]="formControl"
            [locale]="'de-CH'"
            [minFractionDigits]="1"
            [pTooltip]="'ERRORS.' + error | translate"
            [tooltipDisabled]="!error"
            [useGrouping]="false"></p-inputNumber>
        }
        @case ('integer') {
          <p-inputNumber
            tooltipEvent="focus"
            tooltipPosition="top"
            tooltipStyleClass="error"
            (onBlur)="onBlur()"
            [formControl]="formControl"
            [locale]="'de-CH'"
            [minFractionDigits]="0"
            [maxFractionDigits]="0"
            [pTooltip]="'ERRORS.' + error | translate"
            [tooltipDisabled]="!error"
            [useGrouping]="false"></p-inputNumber>
        }
        @default {
          @if (options) {
            <p-dropdown
              appScrollIntoView
              appendTo="body"
              [filter]="true"
              [formControl]="formControl"
              [options]="options"
              [placeholder]="'-'"
              [showClear]="!required"></p-dropdown>
          } @else {
            <input
              pInputText
              tooltipEvent="focus"
              tooltipPosition="top"
              tooltipStyleClass="error"
              type="text"
              [formControl]="formControl"
              [pTooltip]="'ERRORS.' + error | translate"
              [tooltipDisabled]="!error" />
          }
        }
      }
      @if (column?.fastEdit !== true) {
        <button
          class="p-button-primary"
          icon="fal fa-check"
          pButton
          type="button"
          (click)="save()"
          [disabled]="!formControl.valid"></button>
        <button class="p-button-secondary" icon="fal fa-times" pButton type="button" (click)="cancel()"></button>
      }
    </div>
  } @else {
    <div
      class="editable"
      tabindex="0"
      tooltipPosition="top"
      tooltipStyleClass="error"
      (click)="enableEditMode()"
      [pTooltip]="'ERRORS.' + error | translate"
      [tooltipDisabled]="!error"
      [class.changed]="changed"
      [class.error]="error"
      (keydown.enter)="enableEditMode()">
      <ng-container *ngTemplateOutlet="view"></ng-container>
    </div>
  }
} @else {
  <div class="view">
    <ng-container *ngTemplateOutlet="view"></ng-container>
  </div>
}

<ng-template #view>
  @switch (type) {
    @case ('date') {
      {{ value | date: 'dd.MM.yyyy' }}
    }
    @case ('boolean') {
      <div style="text-align: center; width: 100%">
        @if (!!value) {
          <i class="fal fa-check"></i>
        } @else {
          <i class="fal fa-minus"></i>
        }
      </div>
    }
    @case ('time') {
      {{ formatTime(value) }}
    }
    @case ('percent') {
      {{ formatPercent(value) }}
    }
    @case ('number') {
      <span [style]="{ color: column?.color }">{{ formatNumber(value) }}</span>
    }
    @case ('dateRangeAssignment') {
      <div style="text-align: center; width: 100%">
        @if (value && value[0]?.from && !value[0]?.to) {
          <i class="fal fa-arrow-right"></i>
        }
        @if (value && value[0]?.from && value[0]?.to) {
          <i class="fal fa-arrow-to-right"></i>
        }
      </div>
    }
    @default {
      @if (route) {
        <a [routerLink]="route" [title]="value">{{ value }}</a>
      } @else if (url) {
        <a [href]="url" [title]="value" target="_blank">{{ value }}</a>
      } @else {
        @if (options) {
          {{ getOptionText() | translate }}
        } @else {
          <span [style]="{ color: column?.color }" [title]="value">{{ value }}</span>
        }
      }
    }
  }
</ng-template>
