import {
  AfterContentInit,
  ChangeDetectorRef,
  ContentChildren,
  Directive,
  EventEmitter,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
} from '@angular/core';
import { ActionButtonDirective } from './action-button.directive';
import { ToolbarButtonDirective } from './toolbar-button.directive';
import { Column } from './column';
import { downloadBlob } from '../../download';
import { Row, Workbook, Worksheet } from 'exceljs';
import { TranslateService } from '@ngx-translate/core';
import { FilterMetadata, MessageService, SelectItem } from 'primeng/api';
import { TABLE_SERVICE, TableService } from '@shared/table/table-service';
import { catchError, skip, tap } from 'rxjs/operators';
import { forkJoin, Observable, of, Subscription } from 'rxjs';
import { formatDate } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { ContextService } from '@service/context.service';
import { AnyValue } from '@shared/table/table.types';
import { TreeTableLazyLoadEvent } from 'primeng/treetable';
import { InputNumberInputEvent } from 'primeng/inputnumber';

export enum SortOrder {
  ASC = 1,
  DESC = -1,
}

@Directive()
export abstract class AbstractTableComponent implements OnInit, AfterContentInit, OnChanges, OnDestroy {
  @Input()
  columns: Column[] = [];

  @Input()
  actionColumnWidth = 200;

  @Input()
  sortField = '';

  @Input()
  sortOrder = SortOrder.ASC;

  @Input()
  dataKey = '';

  @Input()
  filename = 'export';

  @Input()
  editable = false;

  @Input()
  value: AnyValue[] = [];

  @Output()
  changesSaved = new EventEmitter<AnyValue>();

  @Input()
  selectable = false;

  @Output()
  selectionChange = new EventEmitter<AnyValue>();

  @Input()
  selection: AnyValue[] = [];

  @Input()
  columnSelectable = false;

  @Output()
  columnSelectionChange = new EventEmitter<AnyValue>();

  @Input()
  columnSelection: (Column | HeaderGroup)[] = [];

  @Input()
  focusDate?: Date;

  @Output()
  focusDateChange = new EventEmitter<Date>();

  @Input()
  hasFocusDate = false;

  @Input()
  focusYear?: number;

  @Input()
  focusYearRangeStart = new Date().getFullYear() - 10;

  @Input()
  focusYearRangeEnd = new Date().getFullYear() + 10;

  @Output()
  focusYearChange = new EventEmitter<number>();

  @Input()
  hasFocusYear = false;

  @Input()
  firstColumnFrozen = false;

  @Input()
  showFrozenColumnSelection = true;

  @Input()
  loading = false;

  @Input()
  headerGroups: HeaderGroup[][] = [];

  @Input()
  footer = false;

  @ContentChildren(ActionButtonDirective) actionButtons?: QueryList<ActionButtonDirective>;
  @ContentChildren(ToolbarButtonDirective) toolbarButtons?: QueryList<ToolbarButtonDirective>;

  actionColumnWidthInPx?: string = undefined;

  @Input()
  isCreateAllowed = true;

  @Input()
  pagination = true;

  @Input()
  lazy = false;

  isCreateDisabled = true;
  isCancelDisabled = true;
  isSaveDisabled = true;

  scrollableColumns: Column[] = [];
  frozenColumns: Column[] = [];
  frozenWidth!: string;
  focusYearRange: SelectItem[] = [];
  totalRecords = 0;
  filters: { [key: string]: FilterMetadata } = {};
  calculatedHeaderGroups: HeaderGroup[][] = [];
  protected translateService: TranslateService;
  protected messageService: MessageService;
  protected tableService: TableService<AnyValue> | null;
  protected changes: Changes = {
    updated: [],
    added: [],
    deleted: [],
  };
  private changeDetectorRef: ChangeDetectorRef;
  private activatedRoute: ActivatedRoute;
  private contextService: ContextService;
  private mandantIdChangeSubscription?: Subscription;
  private treeTableLazyLoadEvent?: TreeTableLazyLoadEvent;

  protected constructor(injector: Injector) {
    this.changeDetectorRef = injector.get(ChangeDetectorRef);
    this.translateService = injector.get(TranslateService);
    this.messageService = injector.get(MessageService);
    this.contextService = injector.get(ContextService);
    this.activatedRoute = injector.get(ActivatedRoute);
    this.tableService = injector.get<TableService<AnyValue> | null>(TABLE_SERVICE, null);
  }

  private static styleHeaderRow(headerRow: Row): void {
    headerRow.eachCell((cell) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '008BD2' },
      };
    });
    headerRow.alignment = { vertical: 'middle' };
    headerRow.font = { name: 'arial', family: 4, size: 16, bold: true, color: { argb: 'FFFFFF' } };
    headerRow.height = 20;
  }

  private static formatDate(date?: Date): string {
    if (!date) {
      return '';
    }
    return formatDate(date, 'dd.MM.yyyy', 'en-US');
  }

  ngOnInit(): void {
    this.focusYearRange = Array(this.focusYearRangeEnd - this.focusYearRangeStart + 1)
      .fill(this.focusYearRangeStart)
      .map((x, i) => ({ value: x + i, label: `${x + i}` }));

    this.filters = this.getFilter();
    this.mandantIdChangeSubscription = this.contextService
      .getMandantId()
      .pipe(skip(1))
      .subscribe(() => this.resetFilter());
  }

  ngOnDestroy(): void {
    this.mandantIdChangeSubscription?.unsubscribe();
  }

  ngAfterContentInit(): void {
    if ((this.actionButtons && this.actionButtons.length) || (this.editable && this.actionColumnWidth)) {
      this.actionColumnWidthInPx = this.actionColumnWidth + 'px';
    }

    if (this.tableService) {
      if (!this.lazy) {
        this.loadData();
      }
      this.tableService?.getLoadDataObservable().subscribe(() => {
        this.loadData(this.treeTableLazyLoadEvent);
      });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.columns) {
      this.columns
        .filter((c) => c.headerKey)
        .forEach((c) => (c.header = this.translateService.instant(c.headerKey ?? '')));
      const minWidthColumns = this.columns.filter((c) => c.minWidth);
      minWidthColumns.forEach((c) => (c.width = 100 / minWidthColumns.length + '%'));
      if (this.firstColumnFrozen) {
        this.frozenColumns = this.columns.filter((c, i) => i < 1);
      } else {
        this.frozenColumns = [];
      }
      this.frozenColumnsChange();
    }
  }

  getData(originalData: AnyValue, field: string): AnyValue | null {
    if (!originalData || !field) {
      return null;
    }
    let data = originalData;
    const parts = field.split('.');
    parts.forEach((part) => {
      if (!data) {
        return null;
      }
      data = data[part];
    });
    return data;
  }

  setData(originalData: AnyValue, field: string, value: AnyValue): AnyValue {
    if (!originalData || !field) {
      return null;
    }
    let data = originalData;
    const parts = field.split('.');
    for (let i = 0; i < parts.length - 1; i++) {
      const part = parts[i];
      if (!data[part]) {
        data[part] = {};
      }
      data = data[part];
    }
    data[parts[parts.length - 1]] = value;
  }

  getRouterLink(route: string, row: AnyValue): string | undefined {
    if (!route || route.length === 0) {
      return undefined;
    }
    let result = route;
    const match = route.match(/\${(\w*)}/g);
    if (match) {
      match.forEach((m) => {
        result = result.replace(m, row[m.substring(2, m.length - 1)]);
      });
    }
    return result;
  }

  getUrl(row: AnyValue, url?: string): string | undefined {
    if (!url) {
      return undefined;
    }
    return row[url];
  }

  markForCheck(): void {
    this.changeDetectorRef.markForCheck();
  }

  exportExcel(value: AnyValue[]): void {
    const workbook: Workbook = this.createWorkbook(value);
    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      downloadBlob(blob, `${this.translateService.instant(this.filename)}.xlsx`);
    });
  }

  exportCSV(value: AnyValue[]): void {
    const workbook: Workbook = this.createWorkbook(value);
    workbook.csv.writeBuffer().then((data) => {
      const blob = new Blob([data], { type: 'text/csv' });
      downloadBlob(blob, `${this.translateService.instant(this.filename)}.csv`);
    });
  }

  hasChanges(): boolean {
    return this.changes.deleted.length > 0 || this.changes.updated.length > 0 || this.changes.added.length > 0;
  }

  save(): void {
    this.isCancelDisabled = true;
    this.isSaveDisabled = true;

    if (this.tableService?.isBatchUpdateSupported()) {
      this.batchUpdate();
      return;
    }
    const saveOperations = [];

    if (this.changes.added.length > 0) {
      saveOperations.push(...this.addNewEntities());
    }

    if (this.changes.updated.length > 0) {
      saveOperations.push(...this.updateEntities());
    }

    if (this.changes.deleted.length > 0) {
      saveOperations.push(...this.deleteEntities());
    }

    forkJoin(saveOperations).subscribe({
      next: () => this.changesSaved.emit(this.value),
      error: () => {
        // do nothing, because the error has already been handled.
      },
    });
  }

  loadData(event?: TreeTableLazyLoadEvent): void {
    this.loading = true;
    this.markForCheck();
    this.tableService?.getData(this.focusDate || this.focusYear, event).subscribe((data) => {
      this.loading = false;
      this.value = data.entities;
      this.totalRecords = data.total ?? data.entities.length;
      this.isCreateDisabled = false;
      this.markForCheck();
    });
  }

  cancel(): void {
    this.selection = [];
    this.selectionChange.emit([]);
    this.loadData(this.treeTableLazyLoadEvent);
    this.changes = {
      updated: [],
      added: [],
      deleted: [],
    };
    this.updateButtons();
  }

  frozenColumnsChange(): void {
    this.scrollableColumns = this.columns.filter((c) => !this.frozenColumns.includes(c));
    let width = 0;
    this.frozenColumns.forEach((c) => {
      width += parseInt(c.minWidth || c.width || '200', 10);
    });
    if (this.selectable) {
      width += 50;
    }
    this.frozenWidth = width + 'px';

    this.calculatedHeaderGroups = this.getHeaderGroups();
  }

  setFocusDate(focusDate?: Date): void {
    if (!focusDate || this.focusDate === focusDate) {
      return;
    }

    this.focusDate = focusDate;
    this.focusDateChange.emit(focusDate);
  }

  clearSelection(): void {
    this.selection = [];
    this.selectionChange.emit(this.selection);
  }

  isEditable(row: AnyValue, column: Column): boolean {
    return this.tableService?.isEditable(row, column) ?? false;
  }

  getHeaderGroups(): HeaderGroup[][] {
    if (!this.headerGroups) {
      return [];
    }
    return this.headerGroups.map((group) => {
      const result: HeaderGroup[] = [];

      const titles: string[] = [];
      let index = 0;
      group.forEach((g) => {
        for (let i = 0; i < g.colSpan; i++) {
          const isFrozen = this.frozenColumns.includes(this.columns[index]);
          if (!isFrozen) {
            titles.push(g.title);
          }
          index++;
        }
      });

      if (titles.length === 0) {
        return result;
      }

      let colSpan = 0;
      let lastTitle = titles[0];
      titles.forEach((title) => {
        if (title !== lastTitle) {
          result.push({ title: lastTitle, colSpan });
          colSpan = 1;
          lastTitle = title;
        } else {
          colSpan++;
        }
      });
      result.push({ title: lastTitle ?? '', colSpan });

      return result;
    });
  }

  onFilter($event: AnyValue): void {
    const filter = $event.filters;
    const key = this.getTableStorageKey();
    if (!this.lazy) {
      window.localStorage.setItem(key, JSON.stringify(filter));
    }
    this.changeDetectorRef.markForCheck();
  }

  getFilter(): { [key: string]: FilterMetadata } {
    const key = this.getTableStorageKey();
    const json = window.localStorage.getItem(key);
    return json ? JSON.parse(json) : {};
  }

  resetFilter(): void {
    this.filters = {};
    const key = this.getTableStorageKey();
    window.localStorage.removeItem(key);
  }

  getDateFilter(field: string): Date | null {
    const value = this.filters[field]?.value;
    if (!value) {
      return null;
    }
    if (value instanceof Date) {
      return value;
    }
    const newDate = new Date(value);
    this.filters[field].value = newDate;
    return newDate;
  }

  loadLazyData(event: TreeTableLazyLoadEvent): void {
    this.treeTableLazyLoadEvent = event;
    this.loadData(event);
  }

  toNumber($event: InputNumberInputEvent): number {
    return Number($event.value);
  }

  protected updateButtons(): void {
    const hasChanges = this.hasChanges();
    this.isCancelDisabled = !hasChanges;
    this.isSaveDisabled = !hasChanges || !this.validate();
  }

  protected cleanErrorsAndChanges(row: AnyValue): void {
    this.getMetaStore(row)._errors = undefined;
    this.getMetaStore(row)._changes = undefined;
    this.getMetaStore(row)._new = undefined;
  }

  protected addError(row: AnyValue, error: { field: string; error: string }): void {
    const field = error.field.substring(0, 1).toLowerCase() + error.field.substring(1);
    if (row[field] !== undefined) {
      if (this.getMetaStore(row)._errors === undefined) {
        this.getMetaStore(row)._errors = {};
      }
      this.getMetaStore(row)._errors[field] = error.error;
    }
  }

  protected markAsNew(row: AnyValue): void {
    this.getMetaStore(row)._new = true;
  }

  protected validate(): boolean {
    let valid = true;
    this.value.forEach((row) => {
      const errors: AnyValue = {};
      this.columns.forEach((column) => {
        if (column.validator) {
          const validator = column.validator;
          const result = validator(row[column.field], row);
          if (result) {
            valid = false;
            errors[column.field] = result;
          }
        }
        this.getMetaStore(row)._errors = errors;
      });
    });

    return valid;
  }

  protected abstract getMetaStore(row: AnyValue): AnyValue;

  private createWorkbook(values: AnyValue[]): Workbook {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet(this.translateService.instant(this.filename));

    this.addHeaders(worksheet);
    this.addRows([...values], worksheet);
    this.addFooter(worksheet);

    this.columnAutosize(worksheet);

    return workbook;
  }

  private addHeaders(worksheet: Worksheet): void {
    this.headerGroups.forEach((group) => {
      const data: string[] = [];
      group.forEach((g) => {
        for (let i = 0; i < g.colSpan; i++) {
          data.push(g.title);
        }
      });
      const headerRow = worksheet.addRow(data);
      AbstractTableComponent.styleHeaderRow(headerRow);

      let index = 1;
      group.forEach((g) => {
        const master = headerRow.getCell(index);
        for (let i = 1; i < g.colSpan; i++) {
          headerRow.getCell(index + i).merge(master, true);
        }
        index += g.colSpan;
      });
    });

    AbstractTableComponent.styleHeaderRow(worksheet.addRow(this.columns.map((column) => column.header)));
  }

  private addFooter(worksheet: Worksheet): void {
    const data: AnyValue[] = [];
    this.columns.forEach((c) => {
      if (c.footerKey) {
        data.push(this.translateService.instant(c.footerKey));
      } else if (c.footer) {
        if (c.filterType === 'percent' || c.filterType === 'number') {
          data.push(Number(c.footer));
        } else {
          data.push(c.footer);
        }
      } else {
        data.push('');
      }
    });

    const footer = worksheet.addRow(data);
    footer.font = { name: 'arial', family: 2, size: 14, bold: true, color: { argb: '000000' } };
    footer.height = 16;
  }

  private addRows(values: AnyValue[], worksheet: Worksheet): void {
    values.forEach((value, index) => {
      const row = worksheet.addRow(
        this.columns.map((c) => {
          let data = this.getData(value, c.field);
          if (!data) {
            return null;
          }
          if (c.filterType === 'date' && data instanceof Date) {
            data = new Date(data.toJSON().substring(0, 10));
          } else if (c.filterType === 'date' && data.length >= 10) {
            data = new Date(data.substring(0, 10));
          } else if (c.options && data) {
            data = c.options.filter((o) => o.value === data)[0]?.label;
          } else if (c.filterType === 'dateRangeAssignment') {
            data =
              data.length > 0
                ? AbstractTableComponent.formatDate(data[0].from) +
                  ' - ' +
                  AbstractTableComponent.formatDate(data[0].to)
                : '';
          } else if (c.filterType === 'percent') {
            data = data * 100;
          } else if (!c.filterType) {
            data = data + '';
          }
          return data;
        })
      );
      row.font = { name: 'arial', family: 2, size: 14, bold: false, color: { argb: '000000' } };
      row.height = 16;
      row.alignment = { vertical: 'middle' };

      this.columns.forEach((column, columnIndex) => {
        const cell = row.getCell(columnIndex + 1);
        cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: index % 2 ? 'FFFFFF' : 'E7E7E7' } };
      });
    });
  }

  private columnAutosize(worksheet: Worksheet): void {
    worksheet.columns.forEach((column) => {
      let columnMaxWidth = 0;
      column.values?.forEach((value) => {
        if (!(value instanceof Date)) {
          columnMaxWidth = Math.max((value + '').length, columnMaxWidth);
        }
      });
      column.width = columnMaxWidth + 8;
    });
  }

  private batchUpdate(): void {
    this.loading = true;
    this.tableService?.batchUpdate(this.value, this.focusDate ?? this.focusYear).subscribe({
      next: (updatedValue) => {
        this.loading = false;
        this.messageService.add({
          severity: 'success',
          summary: this.translateService.instant('GLOBAL.SUCCESSFULLY_SAVED'),
        });
        this.value = updatedValue;
        this.changesSaved.emit(this.value);
        this.changes = {
          updated: [],
          added: [],
          deleted: [],
        };
        this.updateButtons();
        this.clearSelection();
        this.markForCheck();
      },
      error: () => {
        this.loading = false;
        this.updateButtons();
        this.messageService.add({
          severity: 'error',
          summary: this.translateService.instant('GLOBAL.NOT_SUCCESSFULLY_SAVED'),
        });
        this.markForCheck();
      },
    });
  }

  private addNewEntities(): Observable<AnyValue>[] {
    return [...this.changes.added].map((node) => {
      if (!this.tableService) return of(node);
      return this.tableService.create(node).pipe(
        tap((added) => {
          node.id = added.id;
          this.changes.added = this.changes.added.filter((n) => n !== node);
          this.updateButtons();
          this.success(node);
        }),
        catchError((error) => {
          this.error(node, error);
          throw error;
        })
      );
    });
  }

  private updateEntities(): Observable<AnyValue>[] {
    return [...this.changes.updated].map((node) => {
      if (!this.tableService) return of(node);
      return this.tableService.update(node).pipe(
        tap(() => {
          this.changes.updated = this.changes.updated.filter((n) => n !== node);
          this.updateButtons();
          this.success(node);
        }),
        catchError((error) => {
          this.error(node, error);
          throw error;
        })
      );
    });
  }

  private deleteEntities(): Observable<AnyValue>[] {
    return [...this.changes.deleted].map((node) => {
      if (!this.tableService) return of(node);
      return this.tableService.remove(node).pipe(
        tap(() => {
          this.changes.deleted = this.changes.deleted.filter((n) => n !== node);
          this.updateButtons();
          this.success(node);
        }),
        catchError((err) => {
          this.changes.deleted = this.changes.deleted.filter((n) => n !== node);
          this.error(node, err);
          this.value = [node, ...this.value];
          throw err;
        })
      );
    });
  }

  private success(row: AnyValue): void {
    this.cleanErrorsAndChanges(row);
    this.updateButtons();
    this.messageService.add({
      severity: 'success',
      summary: this.translateService.instant('GLOBAL.SUCCESSFULLY_SAVED'),
    });
    this.markForCheck();
  }

  private error(row: AnyValue, response: AnyValue): void {
    if (response.error?.field != null && response.error?.error != null) {
      this.addError(row, response.error);
    }
    this.messageService.add({
      severity: 'error',
      summary: this.translateService.instant('GLOBAL.NOT_SUCCESSFULLY_SAVED'),
    });
    this.markForCheck();
  }

  private getTableStorageKey(): string {
    return 'table_' + this.activatedRoute.snapshot.url.map((u) => u.path).join('_');
  }
}

export interface Changes {
  updated: AnyValue[];
  added: AnyValue[];
  deleted: AnyValue[];
}

export interface HeaderGroup {
  colSpan: number;
  rowSpan?: number;
  title: string;
  width?: number;
  minWidth?: number;
  frozen?: boolean;
}
