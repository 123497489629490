import { ChangeDetectorRef, Directive, Injector, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BreadcrumbService } from './breadcrumb/breadcrumb.service';
import { Subscription } from 'rxjs';
import { Confirmation, ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthorizationService } from '@service/authorization.service';
import { Context, ContextService } from '@service/context.service';
import { Language } from '@entity/language';
import { DialogService } from 'primeng/dynamicdialog';
import { LoadingDialogComponent } from '@shared/loading-dialog/loading-dialog.component';
import { DynamicDialogRef } from 'primeng/dynamicdialog/dynamicdialog-ref';

@Directive()
export abstract class AbstractContentComponent implements OnInit, OnDestroy {
  authorizationService: AuthorizationService;
  loading = false;
  protected readonly subscriptions: Subscription[] = [];
  protected dialogService: DialogService;
  protected activatedRoute: ActivatedRoute;
  private translateService: TranslateService;
  private breadcrumbService: BreadcrumbService;
  private contextService: ContextService;
  private changeDetectorRef: ChangeDetectorRef;
  private messageService: MessageService;
  private confirmationService: ConfirmationService;
  private router: Router;
  private loadingDialogRef?: DynamicDialogRef;

  protected constructor(injector: Injector, context: Context, breadcrumbLabelKey?: string) {
    this.translateService = injector.get(TranslateService);
    this.breadcrumbService = injector.get(BreadcrumbService);
    this.contextService = injector.get(ContextService);
    this.changeDetectorRef = injector.get(ChangeDetectorRef);
    this.messageService = injector.get(MessageService);
    this.confirmationService = injector.get(ConfirmationService);
    this.authorizationService = injector.get(AuthorizationService);
    this.activatedRoute = injector.get(ActivatedRoute);
    this.dialogService = injector.get(DialogService);
    this.router = injector.get(Router);

    if (breadcrumbLabelKey) {
      this.setBreadcrumbs([{ label: breadcrumbLabelKey }]);
    }

    this.contextService.setContext(context);
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.contextService.getAnwenderbetriebId().subscribe((id) => this.onAnwenderbetriebIdChange(id))
    );
    this.subscriptions.push(this.contextService.getMandantId().subscribe((id) => this.onMandantIdChange(id)));
    this.subscriptions.push(this.translateService.onLangChange.subscribe(() => this.onLanguageChange()));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  markForCheck(): void {
    this.changeDetectorRef.markForCheck();
  }

  getAnwenderbetriebId(): number | null {
    return this.contextService.getAnwenderbetriebIdInstant();
  }

  setAnwenderbetriebId(id: number): void {
    return this.contextService.setAnwenderbetriebId(id);
  }

  getMandantId(): number {
    return this.contextService.getMandantIdInstant();
  }

  getMandantLanguages(): Language[] {
    return this.contextService.getMandantLanguages();
  }

  isRodixAdmin(): boolean {
    return this.authorizationService.isRodixAdmin();
  }

  isManager(): boolean {
    return this.authorizationService.isManager();
  }

  isNotRodixAdmin(): boolean {
    return !this.isRodixAdmin();
  }

  startLoading(): void {
    this.loading = true;
    this.markForCheck();
  }

  loadingDone(): void {
    this.loading = false;
    this.markForCheck();
  }

  showLoadingDialog(): void {
    this.loadingDialogRef = this.dialogService.open(LoadingDialogComponent, {
      height: '180px',
      width: '142px',
      closable: false,
      modal: true,
    });
  }

  hideLoadingDialog(): void {
    this.loadingDialogRef?.close();
    this.markForCheck();
  }

  protected translate(key: string, interpolateParams?: object): string {
    return this.translateService.instant(key, interpolateParams);
  }

  protected navigate(commands: unknown[] | string | number, queryParams = {}): void {
    void this.router.navigate(Array.isArray(commands) ? commands : [commands], {
      relativeTo: this.activatedRoute,
      queryParams,
    });
  }

  protected confirm(confirmation: Confirmation): void {
    this.confirmationService.confirm({
      rejectButtonStyleClass: 'p-button-secondary p-button-outlined',
      icon: 'fal fa-exclamation-triangle',
      ...confirmation,
    });
  }

  protected setBreadcrumbs(items: MenuItem[]): void {
    this.breadcrumbService.setItems(items);
  }

  protected showSuccessMessage(summary?: string): void {
    this.messageService.add({
      severity: 'success',
      summary: summary ?? this.translate('GLOBAL.SUCCESSFULLY_SAVED'),
    });
    this.markForCheck();
  }

  protected showErrorMessage(summary?: string, detail?: string): void {
    this.messageService.add({
      severity: 'error',
      detail,
      summary: summary ?? this.translate('GLOBAL.NOT_SUCCESSFULLY_SAVED'),
    });
    this.markForCheck();
  }

  protected getLanguage(): string {
    return this.translateService.currentLang;
  }

  protected getLanguageCaseSensitive(): Language {
    const lang = this.getLanguage();
    return (lang.substring(0, 1).toUpperCase() + lang.substring(1, lang.length).toLowerCase()) as Language;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  protected onAnwenderbetriebIdChange(id: number | null): void {
    // do nothing
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  protected onMandantIdChange(id: number | null): void {
    // do nothing
  }

  protected onLanguageChange(): void {
    // do nothing
  }
}
