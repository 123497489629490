import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { AbstractFormElement } from '../abstract-form-element';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CalendarModule } from 'primeng/calendar';
import { FormElementComponent } from '../form-element/form-element.component';

@Component({
  selector: 'app-form-date-input',
  templateUrl: './form-date-input.component.html',
  styleUrls: ['./form-date-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [FormElementComponent, CalendarModule, FormsModule, ReactiveFormsModule],
})
export class FormDateInputComponent extends AbstractFormElement<Date> implements OnChanges {
  @Input() minDate?: Date;
  @Input() maxDate?: Date;
  @Input() timeOnly = false;
  internalMinDate: Date = new Date(200, 1, 1);
  internalMaxDate: Date = new Date(3000, 1, 1);
  @Input() defaultDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 8, 0, 0, 0);

  ngOnChanges(): void {
    super.ngOnChanges();
    if (this.minDate) {
      this.internalMinDate = this.minDate;
    }
    if (this.maxDate) {
      this.internalMaxDate = this.maxDate;
    }
  }
}
