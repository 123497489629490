import { ChangeDetectionStrategy, ChangeDetectorRef, Component, computed, effect, inject, signal } from '@angular/core';
import { AbstractFormElement } from '@shared/abstract-form-element';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { FormElementComponent } from '@shared/form-element/form-element.component';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { PaginatorModule } from 'primeng/paginator';
import { ScrollIntoViewDirective } from '@shared/scroll-into-view.directive';
import { VarHealthcareService } from '@service/var-healthcare.service';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { TableLazyLoadEvent, TableModule } from 'primeng/table';
import { VarHealthcareDocument } from '@entity/var-healthcare';
import { DialogModule } from 'primeng/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';
import { InputGroupAddonModule } from 'primeng/inputgroupaddon';
import { InputGroupModule } from 'primeng/inputgroup';
import { DividerModule } from 'primeng/divider';

@Component({
  selector: 'app-var-healthcare-form-element',
  standalone: true,
  imports: [
    ButtonModule,
    CalendarModule,
    DropdownModule,
    FormElementComponent,
    InputNumberModule,
    InputTextModule,
    PaginatorModule,
    ScrollIntoViewDirective,
    OverlayPanelModule,
    TableModule,
    DialogModule,
    TranslateModule,
    DatePipe,
    InputGroupAddonModule,
    InputGroupModule,
    DividerModule,
  ],
  templateUrl: './var-healthcare-form-element.component.html',
  styleUrl: './var-healthcare-form-element.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VarHealthcareFormElementComponent extends AbstractFormElement<number[]> {
  varDialogIndex = signal<number | undefined>(undefined);
  varDialogVisible = computed(() => this.varDialogIndex() !== undefined);
  loading = signal<boolean>(false);
  documents = signal<VarHealthcareDocument[]>([]);
  values = signal<VarItem[]>([]);
  private readonly varHealthcareService = inject(VarHealthcareService);

  constructor(c: ChangeDetectorRef) {
    super(c);
    effect(() => {
      const values = this.values();
      const invalid = values.some((v) => v.documentId == undefined);
      if (invalid) {
        this.formControl.setErrors({ Invalid: true });
      } else {
        this.formControl.setErrors({});
        this.formControl.setValue(values.map((v) => v.documentId ?? 0));
      }
    });
  }

  // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
  ngOnChanges(): void {
    super.ngOnChanges();
    if (this.formControl) {
      this.values.set(
        this.formControl.value.map((v) => ({
          documentId: v,
          url: '',
          title: '',
        }))
      );

      this.values().forEach((v, i) => this.update(i, v.documentId));
    }
  }

  add(index: number | undefined = undefined): void {
    this.values.update((values) => {
      const newValues = [...values];
      newValues.splice((index ?? values.length - 1) + 1, 0, {});
      return newValues;
    });
  }

  remove(index: number): void {
    this.values.update((values) => {
      const newValues = [...values];
      newValues.splice(index, 1);
      return newValues;
    });
  }

  update(index: number, value?: number): void {
    this.values.update((values) => {
      values[index].documentId = value;
      values[index].title = undefined;
      values[index].url = undefined;
      return [...values];
    });
    if (value !== undefined && value !== null) {
      this.varHealthcareService.getDocument(value ?? 0).subscribe((res) => {
        this.values.update((values) => {
          values[index].title = res?.title ?? '';
          values[index].url = res?.url ?? '';
          return [...values];
        });
      });
    }
  }

  openVarDialog(index: number): void {
    this.varDialogIndex.set(index);
  }

  lazyLoad($event: TableLazyLoadEvent): void {
    let filters = $event.filters?.global ?? [];
    if (!Array.isArray(filters)) {
      filters = [filters];
    }

    if (!filters[0]?.value?.length) {
      this.documents.set([]);
      this.changeDetectorRef.markForCheck();
      return;
    }

    this.loading.set(true);
    this.varHealthcareService.search(filters[0].value).subscribe((result) => {
      this.documents.set(result.documents);
      this.loading.set(false);
      this.changeDetectorRef.markForCheck();
    });
  }

  selectVarDocument(document: VarHealthcareDocument): void {
    const index = this.varDialogIndex();
    if (index !== undefined) {
      this.update(index, document.id ?? 0);
      this.varDialogIndex.set(undefined);
    }
  }

  openLink(url: string | undefined): void {
    window.open(url);
  }
}

export interface VarItem {
  documentId?: number;
  title?: string;
  url?: string;
}
