import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { AbstractFormElement } from '../abstract-form-element';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MultiSelectModule } from 'primeng/multiselect';
import { FormElementComponent } from '../form-element/form-element.component';

@Component({
  selector: 'app-form-multiselect',
  templateUrl: './form-multiselect.component.html',
  styleUrls: ['./form-multiselect.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [FormElementComponent, MultiSelectModule, FormsModule, ReactiveFormsModule, TranslateModule],
})
export class FormMultiselectComponent extends AbstractFormElement<SelectItem[]> {
  @Input()
  options: SelectItem[] = [];

  @Output()
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  onChange = new EventEmitter();

  change(): void {
    this.onChange.emit();
  }
}
