import { ChangeDetectionStrategy, Component, HostListener, Inject, Injector, OnInit, ViewChild } from '@angular/core';
import { Context } from '@service/context.service';
import { AbstractContentComponent } from '../abstract-content.component';
import { SelectItem, TreeNode } from 'primeng/api';
import { Column } from '@shared/table/column';
import { TreeTableComponent } from '@shared/table/tree-table.component';
import { SaveableDataComponent } from '@shared/saveable-data-component';
import { EmployeesTreeTableService } from './employees-tree-table.service';
import { forkJoin } from 'rxjs';
import { TABLE_SERVICE } from '@shared/table/table-service';
import { AnwenderbetriebService } from '@service/anwenderbetrieb.service';
import { HeaderGroup } from '@shared/table/abstract-table.component';

@Component({
  selector: 'app-employees',
  templateUrl: './employees.component.html',
  styleUrls: ['./employees.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: TABLE_SERVICE,
      useClass: EmployeesTreeTableService,
    },
  ],
  standalone: true,
  imports: [TreeTableComponent],
})
export class EmployeesComponent extends AbstractContentComponent implements OnInit, SaveableDataComponent {
  tree: TreeNode[] = [];
  columns: Column[] = [];
  headerGroupColumns: HeaderGroup[][] = [];
  @ViewChild('treeTable') private treeTableComponent!: TreeTableComponent;

  constructor(
    @Inject(TABLE_SERVICE) private treeTableService: EmployeesTreeTableService,
    private anwenderbetriebService: AnwenderbetriebService,
    injector: Injector
  ) {
    super(injector, Context.Mandant, 'MENU.EMPLOYEES');
  }

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: { returnValue: boolean }): void {
    if (this.hasUnsavedData()) {
      $event.returnValue = true;
    }
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.setColumns();
    this.setHeaderGroupColumns();
  }

  isTableEditable(): boolean {
    return this.isRodixAdmin();
  }

  hasUnsavedData(): boolean {
    return this.treeTableComponent?.hasChanges();
  }

  protected onLanguageChange(): void {
    this.setColumns();
    this.setHeaderGroupColumns();
    this.markForCheck();
  }

  protected onMandantIdChange(): void {
    this.setColumns();
    this.setHeaderGroupColumns();
  }

  private setColumns(): void {
    const anwenderbetriebId = this.getAnwenderbetriebId();
    if (!anwenderbetriebId) return;
    forkJoin([
      this.anwenderbetriebService.getOrganisationalUnits(anwenderbetriebId),
      this.anwenderbetriebService.getActivePersonnelCategories(anwenderbetriebId),
    ]).subscribe((data) => {
      const [organisationalUnits, personnelCategories] = data;
      this.columns = [
        {
          field: 'employeeId',
          headerKey: 'EMPLOYEES.EMPLOYEE_ID',
          width: '180px',
          level: 0,
          editable: this.isTableEditable(),
          onlyInitialModificationAllowed: true,
          required: true,
        },
        {
          field: 'firstName',
          headerKey: 'GLOBAL.FIRST_NAME',
          minWidth: '250px',
          level: 0,
          editable: this.isTableEditable(),
        },
        {
          field: 'lastName',
          headerKey: 'GLOBAL.LAST_NAME',
          minWidth: '250px',
          level: 0,
          editable: this.isTableEditable(),
        },
        {
          field: 'dateOfBirth',
          headerKey: 'GLOBAL.DATE_OF_BIRTH',
          width: '180px',
          filterType: 'date',
          level: 0,
          editable: this.isTableEditable(),
        },
        {
          field: 'employmentId',
          headerKey: 'EMPLOYEES.EMPLOYMENT_ID',
          width: '180px',
          level: 1,
          editable: this.isTableEditable(),
          onlyInitialModificationAllowed: true,
          required: true,
        },
        {
          field: 'entryDate',
          headerKey: 'EMPLOYEES.ENTRY_DATE',
          width: '180px',
          filterType: 'date',
          level: 1,
          editable: this.isTableEditable(),
          required: true,
        },
        {
          field: 'validFrom',
          headerKey: 'GLOBAL.GUELTIG_AB',
          width: '180px',
          filterType: 'date',
          level: 1,
          editable: this.isTableEditable(),
          required: true,
        },
        {
          field: 'validTo',
          headerKey: 'GLOBAL.GUELTIG_BIS',
          width: '180px',
          filterType: 'date',
          level: 1,
          editable: this.isTableEditable(),
        },
        {
          field: 'organisationalUnitId',
          headerKey: 'EMPLOYEES.ORGANISATIONAL_UNIT_ID',
          width: '400px',
          level: 1,
          editable: this.isTableEditable(),
          options: organisationalUnits
            .sort((a, b) => (a.organisationId < b.organisationId ? -1 : 1))
            .map(
              (o) =>
                ({
                  value: o.organisationId,
                  label: `${o.organisationId} - ${o.bezeichnung}`,
                }) as SelectItem
            ),
        },
        {
          field: 'salaryBase',
          headerKey: 'EMPLOYEES.SALARY_BASE',
          minWidth: '250px',
          level: 1,
          editable: this.isTableEditable(),
          options: [
            { label: this.translate('EMPLOYEES.SALARY_BASE_TYPE.M'), value: 'M' },
            { label: this.translate('EMPLOYEES.SALARY_BASE_TYPE.H'), value: 'H' },
          ],
        },
        {
          field: 'costCenter',
          headerKey: 'EMPLOYEES.COST_CENTER',
          minWidth: '250px',
          level: 1,
          editable: this.isTableEditable(),
        },
        {
          field: 'jobTitleTacsId',
          headerKey: 'EMPLOYEES.JOB_TITLE',
          width: '450px',
          level: 1,
          editable: this.isTableEditable(),
          options: Object.keys(personnelCategories)
            .map(
              (key) =>
                ({
                  value: key,
                  label: `${key} - ${personnelCategories[key]}`,
                }) as SelectItem
            )
            .sort((a, b) => ((a.label ?? '') < (b.label ?? '') ? -1 : 1)),
        },
        {
          field: 'workloadPercentage',
          headerKey: 'EMPLOYEES.WORKLOAD_PERCENTAGE',
          minWidth: '250px',
          level: 1,
          editable: this.isTableEditable(),
        },
        {
          field: 'workloadHours',
          headerKey: 'EMPLOYEES.WORKLOAD_HOURS',
          minWidth: '250px',
          level: 1,
          editable: this.isTableEditable(),
        },
      ];
      this.markForCheck();
    });
  }

  private setHeaderGroupColumns(): void {
    this.headerGroupColumns = [
      [
        { title: this.translate('EMPLOYEES.EMPLOYEE'), colSpan: 4 },
        { title: this.translate('EMPLOYEES.EMPLOYMENT'), colSpan: 10 },
      ],
    ];
  }
}
