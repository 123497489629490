import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { NgClass } from '@angular/common';

@Component({
  selector: 'app-form-element',
  templateUrl: './form-element.component.html',
  styleUrls: ['./form-element.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass, TranslateModule],
})
export class FormElementComponent implements OnDestroy, OnChanges {
  @HostBinding('class.grid') grid = true;

  error = '';
  @Input() id!: string;
  @Input() label?: string;
  @Input() twoColumns = false;
  @Input() required = false;
  @Input() control?: FormControl;
  private subscription?: Subscription;

  constructor(
    private translateService: TranslateService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.control) {
      this.subscription?.unsubscribe();
      if (this.control) {
        this.subscription = this.control.statusChanges.subscribe(() => {
          this.error = Object.keys(this.control?.errors ?? {})
            .map((key) => this.translateService.instant('ERRORS.' + key))
            .join(' ');
          this.changeDetectorRef.markForCheck();
        });
      }
    }
  }
}
